import React, { useEffect, useState } from "react";
import Select from 'react-select'
import CommonColorForm from "../../../components/common-color-form/commonColorForm";
import CommonInputFull from "../../../components/input/common-input-full/commonInputFull";
import CommonInputHalf from "../../../components/input/common-input-half/commonInputHalf";
import CommonInputRow from "../../../components/input/common-input-row/commonInputRow";
import ScreenLabel from "../../../components/label/screen-label/screenLabel";
// import SubTextLabel from "../../components/label/sub-text-label/subTextLabel";
import CommonScreenContent from "../../../components/screen/common-screen-content/commonScreenContent";
import './styles.css'
import Loader from '../../../components/loader/loader.component'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'react-phone-number-input/style.css'
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input'
import { useDispatch, useSelector } from 'react-redux'
import ButtonRow from "../../../components/button/button-row/buttonRow";
import CommonButton from "../../../components/button/common-button/commonButton";
import FormFieldLabel from "../../../components/label/form-field-label/formFieldLabel";
import { addSpecialCharAndChangeToCamel, displayErrorToast, displaySuccessToast, isEmpty, isNull, removeSpecialCharAndChangeToPascal } from "../../../utils/Utils";
import { API_CONSTANTS, APP_CONSTANTS } from "../../../utils/AppConstants";
import { addDigitalCardWithDetailsRequest, addDigitalCardWithDetailsRequestStatus } from '../../../redux/digital-card/digitalCard.action'
import { useNavigate } from 'react-router-dom';
import CommonInputRowSmall from "../../../components/input/common-input-row-small/common-input-row-small";
import CommonInputFloatFull from "../../../components/input/common-input-float-full/commonInputFloatFull";
import CommonInputFloatHalf from "../../../components/input/common-input-float-half/commonInputFloafHalf";
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import UserCardPreview from '../user-card/userCardPreview'
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Paper from '@mui/material/Paper';

const AddCard = props => {
    const [isLoading, setLoading] = useState(false)
    const dispatch = useDispatch()
    let navigate = useNavigate();
    const { user, commonError } = useSelector(state => state.user)
    const { addDigitalCardWithDetailSuccess, addDigitalCardWithDetailError, addDigitalCardWithDetailReqStatus,
        cardListSuccess, cardListError } = useSelector((state) => state.digitalCard)
    const [cardName, setCardName] = useState('')
    const [cardDescription, setCardDescription] = useState('')
    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [primaryEmail, setPrimaryEmail] = useState('')
    const [secondaryEmail, setSecondaryEmail] = useState('')
    const [alternateEmail, setAlternateEmail] = useState('')
    const [workAddress, setWorkAddress] = useState('')
    const [homeAddress, setHomeAddress] = useState('')
    const [homePhoneNumber, setHomePhoneNumber] = useState('')
    const [mobilePhoneNumber, setMobilePhoneNumber] = useState('')
    const [faxNumber, setFaxNumber] = useState('')
    const [workNumber1, setWorkNumber1] = useState('')
    const [workNumberExt1, setWorkNumberExt1] = useState('')
    const [workNumber2, setWorkNumber2] = useState('')
    const [workNumberExt2, setWorkNumberExt2] = useState('')
    const [companyName, setCompanyName] = useState('')
    const [jobTitle, setJobTitle] = useState('')
    const [facebookLink, setFacebookLink] = useState('')
    const [linkedInLink, setLinkedInLink] = useState('')
    const [twitterLink, setTwitterLink] = useState('')
    const [instagramLink, setInstagramLink] = useState('')
    const [snapchatLink, setSnapchatLink] = useState('')
    const [profileImage, setProfileImage] = useState(null);
    const [bannerImage, setBannerImage] = useState(null)
    const [brandImage, setBrandImage] = useState(null)
    const [imagePath, setImagePath] = useState(null)
    const [imageName, setImageName] = useState('')
    const [bannerImagePath, setBannerImagePath] = useState(null)
    const [bannerImageName, setBannerImageName] = useState('')
    const [brandImagePath, setBrandImagePath] = useState(null)
    const [brandImageName, setBrandImageName] = useState('')
    const [otherLink, setOtherLink] = useState('')
    const [otherSocialLink, setOtherSocialLink] = useState([])
    const [summary, setSummary] = useState('')
    const [showNextButton, setShowNextButton] = useState(true)
    const [showGeneralDetails, setShowGeneralDetails] = useState(true)
    const [showContactDetails, setShowContactDetails] = useState(false)
    const [showProfessionDetails, setShowProfessionDetails] = useState(false)
    const [showSocialMediaDetails, setShowSocialMediaDetails] = useState(false)
    const [showFieldSelection, setShowFieldSelection] = useState(true)

    const [cardForm, setCardForm] = useState({
        profile_image: false,
        brand_logo: false,
        banner_image: false,
        card_description: false,
        name: false,
        summary: false,
        email_ids: false,
        primary_email: false,
        secondary_email: false,
        alternate_email: false,
        phone: false,
        home_number: false,
        mobile_number: false,
        work_number: false,
        work_number_1: false,
        work_number_2: false,
        fax_number: false,
        addresses: false,
        work_address: false,
        home_address: false,
        company_name: false,
        job_title: false,
        facebook: false,
        linkedin: false,
        twitter: false,
        instagram: false,
        snapchat: false,
        other_links: false
    });

    useEffect(() => {
        if (!isNull(commonError)) {
            if (commonError.status === 401) {
                setLoading(false)
                displayErrorToast(commonError.message)
                dispatch({ type: APP_CONSTANTS.LOGOUT })
            }
        }
    }, [commonError])

    useEffect(() => {
        if (addDigitalCardWithDetailReqStatus === API_CONSTANTS.SUCCESS_STATUS) {
            setLoading(false)
            displaySuccessToast(addDigitalCardWithDetailSuccess.message)
            // clearFields()
            navigate(-1)
        } else if (addDigitalCardWithDetailReqStatus === API_CONSTANTS.ERROR_STATUS) {
            setLoading(false)
            displayErrorToast(addDigitalCardWithDetailError.message)
        }
        if (addDigitalCardWithDetailReqStatus.length) {
            dispatch(addDigitalCardWithDetailsRequestStatus(''))
        }
    }, [addDigitalCardWithDetailSuccess, addDigitalCardWithDetailError, addDigitalCardWithDetailReqStatus])

    const clearFields = () => {
        setCardName('')
        setCardDescription('')
        setFirstName('')
        setLastName('')
        setPrimaryEmail('')
        setSecondaryEmail('')
        setAlternateEmail('')
        setSummary('')
        setHomePhoneNumber('')
        setMobilePhoneNumber('')
        setFaxNumber('')
        setWorkNumber1('')
        setWorkNumberExt1('')
        setWorkNumber2('')
        setWorkNumberExt2('')
        setCompanyName('')
        setJobTitle('')
        setFacebookLink('')
        setLinkedInLink('')
        setTwitterLink('')
        setInstagramLink('')
        setSnapchatLink('')
        setProfileImage(null)
        setImagePath(null)
        setImageName('')
        setBannerImage(null)
        setBannerImagePath(null)
        setBannerImageName('')
        setBrandImage(null)
        setBrandImagePath(null)
        setBrandImageName('')
        setOtherLink('')
        setOtherSocialLink([])
    }

    useEffect(() => {
        if (cardForm.primary_email === false && cardForm.secondary_email === false && cardForm.alternate_email === false) {
            cardForm.email_ids = false
            setCardForm(oldValue => ({
                ...oldValue,
                ...cardForm
            }));
        }
    }, [cardForm.primary_email, cardForm.secondary_email, cardForm.alternate_email])

    useEffect(() => {
        if (!cardForm.email_ids) {
            setPrimaryEmail('')
            setSecondaryEmail('')
            setAlternateEmail('')
            cardForm.primary_email = false
            cardForm.secondary_email = false
            cardForm.alternate_email = false
            setCardForm(oldValue => ({
                ...oldValue,
                ...cardForm
            }));
        }
    }, [cardForm.email_ids])

    useEffect(() => {
        if (!cardForm.addresses) {
            setWorkAddress('')
            setHomeAddress('')
            cardForm.work_address = false
            cardForm.home_address = false
            setCardForm(oldValue => ({
                ...oldValue,
                ...cardForm
            }));
        }
    }, [cardForm.addresses])

    useEffect(() => {
        if (cardForm.work_address === false && cardForm.home_address === false) {
            cardForm.addresses = false
            setCardForm(oldValue => ({
                ...oldValue,
                ...cardForm
            }));
        }
    }, [cardForm.work_address, cardForm.home_address])

    useEffect(() => {
        if (!cardForm.phone) {
            setHomePhoneNumber('')
            setMobilePhoneNumber('')
            setWorkNumber1('')
            setWorkNumberExt1('')
            setWorkNumber2('')
            setWorkNumberExt2('')
            setFaxNumber('')
            cardForm.home_number = false
            cardForm.mobile_number = false
            cardForm.work_number = false
            cardForm.work_number_1 = false
            cardForm.work_number_2 = false
            cardForm.fax_number = false
            setCardForm(oldValue => ({
                ...oldValue,
                ...cardForm
            }));
        }
    }, [cardForm.phone])

    useEffect(() => {
        if (!cardForm.work_number) {
            setWorkNumber1('')
            setWorkNumberExt1('')
            setWorkNumber2('')
            setWorkNumberExt2('')
            cardForm.work_number_1 = false
            cardForm.work_number_2 = false
            setCardForm(oldValue => ({
                ...oldValue,
                ...cardForm
            }));
        }
    }, [cardForm.work_number])

    useEffect(() => {
        if (cardForm.home_number === false && cardForm.work_number === false && cardForm.mobile_number === false && cardForm.fax_number === false) {
            cardForm.phone = false
            setCardForm(oldValue => ({
                ...oldValue,
                ...cardForm
            }));
        }
    }, [cardForm.home_number, cardForm.work_number, cardForm.mobile_number, cardForm.fax_number])

    useEffect(() => {
        if (cardForm.work_number_1 === false && cardForm.work_number_2 === false) {
            cardForm.work_number = false
            setCardForm(oldValue => ({
                ...oldValue,
                ...cardForm
            }));
        }
    }, [cardForm.work_number_1, cardForm.work_number_2])


    useEffect(() => {
        if (!isNull(cardListSuccess) && !isEmpty(cardListSuccess.data)) {
            let dataObj = cardListSuccess.data[0]
            setCardName(dataObj.cardName)
            setCardDescription(dataObj.description)
            let isCardFormChanged = false
            if (!isEmpty(dataObj.cardDetails[0].profileImage) && !dataObj.cardDetails[0].profileImage.includes('placeholder')) {
                cardForm.profile_image = true
                isCardFormChanged = true

            }
            if (!isEmpty(dataObj.cardDetails[0].brandLogoImg) && !dataObj.cardDetails[0].brandLogoImg.includes('placeholder')) {
                cardForm.brand_logo = true
                isCardFormChanged = true

            }
            if (!isEmpty(dataObj.cardDetails[0].firstName)) {
                setFirstName(dataObj.cardDetails[0].firstName)
                cardForm.name = true
                isCardFormChanged = true
            }
            if (!isEmpty(dataObj.cardDetails[0].lastName)) {
                setLastName(dataObj.cardDetails[0].lastName)
                cardForm.name = true
                isCardFormChanged = true
            }
            if (!isEmpty(dataObj.cardDetails[0].summary)) {
                setSummary(dataObj.cardDetails[0].summary)
                cardForm.summary = true
                isCardFormChanged = true
            }
            if (!isEmpty(dataObj.cardDetails[0].email1)) {
                cardForm.email_ids = true
                cardForm.primary_email = true
                isCardFormChanged = true
                setPrimaryEmail(dataObj.cardDetails[0].email1)
            }
            if (!isEmpty(dataObj.cardDetails[0].email2)) {
                cardForm.email_ids = true
                cardForm.secondary_email = true
                isCardFormChanged = true
                setSecondaryEmail(dataObj.cardDetails[0].email2)
            }
            if (!isEmpty(dataObj.cardDetails[0].email3)) {
                cardForm.email_ids = true
                cardForm.alternate_email = true
                isCardFormChanged = true
                setAlternateEmail(dataObj.cardDetails[0].email3)
            }
            if (!isEmpty(dataObj.cardDetails[0].homeNumber)) {
                cardForm.phone = true
                cardForm.home_number = true
                isCardFormChanged = true
                setHomePhoneNumber(dataObj.cardDetails[0].homeNumber)
            }
            if (!isEmpty(dataObj.cardDetails[0].mobileNumber)) {
                cardForm.phone = true
                cardForm.mobile_number = true
                isCardFormChanged = true
                setMobilePhoneNumber(dataObj.cardDetails[0].mobileNumber)
            }
            if (!isEmpty(dataObj.cardDetails[0].workNumber1)) {
                cardForm.phone = true
                cardForm.work_number = true
                cardForm.work_number_1 = true
                isCardFormChanged = true
                setWorkNumber1(dataObj.cardDetails[0].workNumber1)
            }
            if (!isEmpty(dataObj.cardDetails[0].workNumber1Ext)) {
                setWorkNumberExt1(dataObj.cardDetails[0].workNumber1Ext)
            }
            if (!isEmpty(dataObj.cardDetails[0].workNumber2)) {
                cardForm.phone = true
                cardForm.work_number = true
                cardForm.work_number_2 = true
                isCardFormChanged = true
                setWorkNumber2(dataObj.cardDetails[0].workNumber2)
            }
            if (!isEmpty(dataObj.cardDetails[0].workNumber2Ext)) {
                setWorkNumberExt2(dataObj.cardDetails[0].workNumber2Ext)
            }
            if (!isEmpty(dataObj.cardDetails[0].fax)) {
                cardForm.phone = true
                cardForm.fax_number = true
                isCardFormChanged = true
                setFaxNumber(dataObj.cardDetails[0].fax)
            }
            if (!isEmpty(dataObj.cardDetails[0].homeAddress)) {
                cardForm.addresses = true
                cardForm.home_address = true
                isCardFormChanged = true
                setHomeAddress(dataObj.cardDetails[0].homeAddress)
            }
            if (!isEmpty(dataObj.cardDetails[0].workAddress)) {
                cardForm.addresses = true
                cardForm.work_address = true
                isCardFormChanged = true
                setWorkAddress(dataObj.cardDetails[0].workAddress)
            }
            if (!isEmpty(dataObj.cardDetails[0].otherSocialLink)) {
                cardForm.other_links = true
                isCardFormChanged = true
                setOtherSocialLink(dataObj.cardDetails[0].otherSocialLink)
            }
            if (!isEmpty(dataObj.cardDetails[0].companyName)) {
                setCompanyName(dataObj.cardDetails[0].companyName)
                cardForm.company_name = true
                isCardFormChanged = true
            }
            if (!isEmpty(dataObj.cardDetails[0].jobTitle)) {
                setJobTitle(dataObj.cardDetails[0].jobTitle)
                cardForm.job_title = true
                isCardFormChanged = true
            }
            if (!isEmpty(dataObj.cardDetails[0].facebookLink)) {
                setFacebookLink(dataObj.cardDetails[0].facebookLink)
                cardForm.facebook = true
                isCardFormChanged = true
            }
            if (!isEmpty(dataObj.cardDetails[0].linkedInLink)) {
                setLinkedInLink(dataObj.cardDetails[0].linkedInLink)
                cardForm.linkedin = true
                isCardFormChanged = true
            }
            if (!isEmpty(dataObj.cardDetails[0].twitterLink)) {
                setTwitterLink(dataObj.cardDetails[0].twitterLink)
                cardForm.twitter = true
                isCardFormChanged = true
            }
            if (!isEmpty(dataObj.cardDetails[0].instagramLink)) {
                setInstagramLink(dataObj.cardDetails[0].instagramLink)
                cardForm.instagram = true
                isCardFormChanged = true
            }
            if (!isEmpty(dataObj.cardDetails[0].snapchatLink)) {
                setSnapchatLink(dataObj.cardDetails[0].snapchatLink)
                cardForm.snapchat = true
                isCardFormChanged = true
            }
            if (isCardFormChanged === true) {
                setCardForm(oldValue => ({
                    ...oldValue,
                    ...cardForm
                }));
            }
        }
    }, [cardListSuccess])

    const pickProfileImage = () => {
        const input = document.createElement('input');
        input.type = 'file';
        input.accept = 'image/*';
        input.click();

        input.onchange = e => {
            const file = e.target.files[0];
            setImagePath(e.target.files[0])
            setImageName(e.target.files[0].name)
            const reader = new FileReader();
            reader.onload = e => {
                setProfileImage(e.target.result);
            };
            reader.readAsDataURL(file);
        };
    };

    const pickBrandLogo = () => {
        const input = document.createElement('input');
        input.type = 'file';
        input.accept = 'image/*';
        input.click();

        input.onchange = e => {
            const file = e.target.files[0];
            setBrandImagePath(e.target.files[0])
            setBrandImageName(e.target.files[0].name)
            const reader = new FileReader();
            reader.onload = e => {
                setBrandImage(e.target.result);
            };
            reader.readAsDataURL(file);
        };
    };

    const pickBannerImage = () => {
        const input = document.createElement('input');
        input.type = 'file';
        input.accept = 'image/*';
        input.click();

        input.onchange = e => {
            const file = e.target.files[0];
            setBannerImagePath(e.target.files[0])
            setBannerImageName(e.target.files[0].name)
            const reader = new FileReader();
            reader.onload = e => {
                setBannerImage(e.target.result);
            };
            reader.readAsDataURL(file);
        };
    };

    const handleSubmitClick = () => {
        if (isEmpty(cardName)) {
            displayErrorToast('Card name cannot be blank')
        } {
            let fd = new FormData()
            fd.append('userId', user.data.userDetails.userId)
            fd.append('cardName', cardName)
            fd.append('productCode', APP_CONSTANTS.PRODUCT_NAME)
            if (!isEmpty(cardDescription))
                fd.append('description', cardDescription)
            if (!isEmpty(summary))
                fd.append('summary', summary)
            if (!isEmpty(firstName))
                fd.append('firstName', firstName)
            if (!isEmpty(lastName))
                fd.append('lastName', lastName)
            if (!isEmpty(homePhoneNumber)) {
                fd.append('homeNumber', homePhoneNumber)
            }
            if (!isEmpty(mobilePhoneNumber)) {
                fd.append('mobileNumber', mobilePhoneNumber)
            }
            if (!isEmpty(faxNumber)) {
                fd.append('fax', faxNumber)
            }
            if (!isEmpty(workNumber1)) {
                fd.append('workNumber1', workNumber1)
                if (isEmpty(workNumberExt1)) {
                    fd.append('workNumber1Ext', workNumberExt1)
                }
            }
            if (!isEmpty(workNumber2)) {
                fd.append('workNumber2', workNumber2)
                if (isEmpty(workNumberExt2)) {
                    fd.append('workNumber2Ext', workNumberExt2)
                }
            }
            if (!isEmpty(homeAddress)) {
                fd.append('homeAddress', homeAddress)
            }
            if (!isEmpty(workAddress)) {
                fd.append('workAddress', workAddress)
            }
            if (!isEmpty(primaryEmail)) {
                fd.append('email1', primaryEmail)
            }
            if (!isEmpty(secondaryEmail)) {
                fd.append('email2', secondaryEmail)
            }
            if (!isEmpty(alternateEmail)) {
                fd.append('email3', alternateEmail)
            }
            if (!isEmpty(companyName))
                fd.append('companyName', companyName)
            if (!isEmpty(jobTitle))
                fd.append('jobTitle', jobTitle)
            if (!isEmpty(facebookLink))
                fd.append('facebookLink', facebookLink)
            if (!isEmpty(twitterLink))
                fd.append('twitterLink', twitterLink)
            if (!isEmpty(instagramLink))
                fd.append('instagramLink', instagramLink)
            if (!isEmpty(linkedInLink))
                fd.append('linkedInLink', linkedInLink)
            if (!isEmpty(snapchatLink))
                fd.append('snapchatLink', snapchatLink)
            if (!isEmpty(otherSocialLink)) {
                fd.append('otherSocialLink', JSON.stringify(otherSocialLink))
            }
            if (!isNull(imagePath) && !isEmpty(imageName)) {
                fd.append('profileImage', imagePath, imageName)
            } else {
                if (!isNull(cardListSuccess) && !isEmpty(cardListSuccess.data)) {
                    let dataObj = cardListSuccess.data[0]
                    fd.append('isProfileImagePath', dataObj.cardDetails[0].profileImage)
                }
            }
            if (!isNull(brandImagePath) && !isEmpty(brandImageName)) {
                fd.append('brandLogoImg', brandImagePath, brandImageName)
            } else {
                if (!isNull(cardListSuccess) && !isEmpty(cardListSuccess.data)) {
                    let dataObj = cardListSuccess.data[0]
                    fd.append('isBrandLogoImagePath', dataObj.cardDetails[0].brandLogoImg)
                }
            }
            if (!isNull(bannerImagePath) && !isEmpty(bannerImageName)) {
                fd.append('bannerImage', bannerImagePath, bannerImageName)
            } else {
                if (!isNull(cardListSuccess) && !isEmpty(cardListSuccess.data)) {
                    let dataObj = cardListSuccess.data[0]
                    fd.append('isBannerImagePath', dataObj.cardDetails[0].bannerImage)
                }
            }
            setLoading(true)
            dispatch(addDigitalCardWithDetailsRequest(fd))
        }
    }

    const handleAddOtherLinkClick = () => {
        if (!isEmpty(otherLink)) {
            let currentList = otherSocialLink
            currentList.push(otherLink)
            setOtherSocialLink(currentList)
            setOtherLink('')
        } else {
            displayErrorToast('Other link field cannot be blank')
        }
    }

    const handleLinkDeleteClick = e => {
        let updatedItems = otherSocialLink.filter(item => item !== e)
        setOtherSocialLink(updatedItems)
    }

    const handleBannerDeleteClick = () => {
        setBannerImage(null)
        setBannerImagePath(null)
        setBannerImageName('')
    }

    const handleProfileDeleteClick = () => {
        setProfileImage(null);
        setImagePath(null)
        setImageName('')
    }

    const handleBrandLogoDeleteClick = () => {
        setBrandImage(null);
        setBrandImagePath(null)
        setBrandImageName('')
    }

    const isBannerImageFromUrl = () => {
        let flag = false
        if (!isNull(cardListSuccess) && !isNull(cardListSuccess.data[0]) && !isEmpty(cardListSuccess.data[0].cardDetails)) {
            if ((!isEmpty(cardListSuccess.data[0].cardDetails[0].bannerImage) && !cardListSuccess.data[0].cardDetails[0].bannerImage.includes('placeholder'))) {
                flag = true
            }
        }
        if (!isNull(bannerImagePath) && !isEmpty(bannerImageName)) {
            flag = false
        }

        return flag
    }

    const isProfileImageFromUrl = () => {
        let flag = false
        if (!isNull(cardListSuccess) && !isNull(cardListSuccess.data[0]) && !isEmpty(cardListSuccess.data[0].cardDetails)) {
            if ((!isEmpty(cardListSuccess.data[0].cardDetails[0].profileImage) && !cardListSuccess.data[0].cardDetails[0].profileImage.includes('placeholder'))) {
                flag = true
            }
        }
        if (!isNull(imagePath) && !isEmpty(imageName)) {
            flag = false
        }
        return flag
    }

    const isBrandLogoFromUrl = () => {
        let flag = false
        if (!isNull(cardListSuccess) && !isNull(cardListSuccess.data[0]) && !isEmpty(cardListSuccess.data[0].cardDetails)) {
            if ((!isEmpty(cardListSuccess.data[0].cardDetails[0].brandLogoImg) && !cardListSuccess.data[0].cardDetails[0].brandLogoImg.includes('placeholder'))) {
                flag = true
            }
        }
        if (!isNull(brandImagePath) && !isEmpty(brandImageName)) {
            flag = false
        }
        return flag
    }

    const checkForFieldSelection = (btnType) => {
        if (cardForm.email_ids === true && cardForm.primary_email === false && cardForm.secondary_email === false && cardForm.alternate_email === false) {
            displayErrorToast('Email type is not selected')
        } else if (cardForm.phone === true && cardForm.home_number === false && cardForm.mobile_number === false && cardForm.work_number === false && cardForm.fax_number === false) {
            displayErrorToast('Phone number type is not selected')
        } else if (cardForm.phone === true && cardForm.work_number === true && cardForm.work_number_1 === false && cardForm.work_number_2 === false) {
            displayErrorToast('Work number type is not selected')
        } else if (cardForm.addresses === true && cardForm.home_address === false && cardForm.work_address === false) {
            displayErrorToast('Address type is not selected')
        } else {
            if (btnType === 'NEXT') {
                setShowNextButton(false)
                setShowFieldSelection(false)
            } else {
                setShowFieldSelection(false)
            }
        }
    }

    return (
        <>
            <CommonScreenContent>
                <ScreenLabel label='Create Card' />
                {showFieldSelection && <CommonColorForm>
                    <FormFieldLabel className='up-bold-label mb-2' label='Select details to show on card' />
                    <div className="label-row-container" onClick={() => setShowGeneralDetails(!showGeneralDetails)}>
                        <FormFieldLabel label='General Details' />
                        <div className="label-icon-container">
                            <img src={showGeneralDetails ? require('../../../assets/ic_arrow_up.png') : require('../../../assets/ic_arrow_down.png')} className='img' />
                        </div>
                    </div>
                    {showGeneralDetails ? (<div>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={true}
                                    inputProps={{ 'aria-label': 'controlled' }}
                                    color="success"
                                    size="medium"
                                    disabled
                                />}
                            label='Card Nickname'
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={cardForm.name}
                                    onChange={e => {
                                        cardForm.name = e.target.checked
                                        setCardForm(oldValue => ({
                                            ...oldValue,
                                            ...cardForm
                                        }));
                                    }}
                                    inputProps={{ 'aria-label': 'controlled' }}
                                    color="success"
                                    size="medium"
                                />}
                            label='Name'
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={cardForm.summary}
                                    onChange={e => {
                                        cardForm.summary = e.target.checked
                                        setCardForm(oldValue => ({
                                            ...oldValue,
                                            ...cardForm
                                        }));
                                    }}
                                    inputProps={{ 'aria-label': 'controlled' }}
                                    color="success"
                                    size="medium"
                                />}
                            label='Summary'
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={cardForm.profile_image}
                                    onChange={e => {
                                        cardForm.profile_image = e.target.checked
                                        setCardForm(oldValue => ({
                                            ...oldValue,
                                            ...cardForm
                                        }));
                                    }}
                                    inputProps={{ 'aria-label': 'controlled' }}
                                    color="success"
                                    size="medium"
                                />}
                            label='Profile Image'
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={cardForm.brand_logo}
                                    onChange={e => {
                                        cardForm.brand_logo = e.target.checked
                                        setCardForm(oldValue => ({
                                            ...oldValue,
                                            ...cardForm
                                        }));
                                    }}
                                    inputProps={{ 'aria-label': 'controlled' }}
                                    color="success"
                                    size="medium"
                                />}
                            label='Brand Logo'
                        />
                        {/* <FormControlLabel
                            control={
                                <Checkbox
                                    checked={cardForm.banner_image}
                                    onChange={e => {
                                        cardForm.banner_image = e.target.checked
                                        setCardForm(oldValue => ({
                                            ...oldValue,
                                            ...cardForm
                                        }));
                                    }}
                                    inputProps={{ 'aria-label': 'controlled' }}
                                    color="success"
                                    size="medium"
                                />}
                            label='Banner Image'
                        /> */}
                    </div>) : null}

                    <div className="label-row-container" onClick={() => setShowContactDetails(!showContactDetails)}>
                        <FormFieldLabel label='Contact Details' />
                        <div className="label-icon-container">
                            <img src={showContactDetails ? require('../../../assets/ic_arrow_up.png') : require('../../../assets/ic_arrow_down.png')} className='img' />
                        </div>
                    </div>
                    {showContactDetails ? (<div>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={cardForm.email_ids}
                                    onChange={e => {
                                        cardForm.email_ids = e.target.checked
                                        setCardForm(oldValue => ({
                                            ...oldValue,
                                            ...cardForm
                                        }));
                                    }}
                                    inputProps={{ 'aria-label': 'controlled' }}
                                    color="success"
                                    size="medium"
                                />}
                            label='Email'
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={cardForm.phone}
                                    onChange={e => {
                                        cardForm.phone = e.target.checked
                                        setCardForm(oldValue => ({
                                            ...oldValue,
                                            ...cardForm
                                        }));
                                    }}
                                    inputProps={{ 'aria-label': 'controlled' }}
                                    color="success"
                                    size="medium"
                                />}
                            label='Phone'
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={cardForm.addresses}
                                    onChange={e => {
                                        cardForm.addresses = e.target.checked
                                        setCardForm(oldValue => ({
                                            ...oldValue,
                                            ...cardForm
                                        }));
                                    }}
                                    inputProps={{ 'aria-label': 'controlled' }}
                                    color="success"
                                    size="medium"
                                />}
                            label='Address'
                        />
                        {cardForm.email_ids && <div>
                            <FormFieldLabel label='Email Ids' />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={cardForm.primary_email}
                                        onChange={e => {
                                            cardForm.primary_email = e.target.checked
                                            setCardForm(oldValue => ({
                                                ...oldValue,
                                                ...cardForm
                                            }));
                                        }}
                                        inputProps={{ 'aria-label': 'controlled' }}
                                        color="success"
                                        size="medium"
                                    />}
                                label='Primary Email'
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={cardForm.secondary_email}
                                        onChange={e => {
                                            cardForm.secondary_email = e.target.checked
                                            setCardForm(oldValue => ({
                                                ...oldValue,
                                                ...cardForm
                                            }));
                                        }}
                                        inputProps={{ 'aria-label': 'controlled' }}
                                        color="success"
                                        size="medium"
                                    />}
                                label='Secondary Email'
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={cardForm.alternate_email}
                                        onChange={e => {
                                            cardForm.alternate_email = e.target.checked
                                            setCardForm(oldValue => ({
                                                ...oldValue,
                                                ...cardForm
                                            }));
                                        }}
                                        inputProps={{ 'aria-label': 'controlled' }}
                                        color="success"
                                        size="medium"
                                    />}
                                label='Alternate Email'
                            />
                        </div>}
                        {cardForm.phone && <div>
                            <FormFieldLabel label='Phone Number' />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={cardForm.home_number}
                                        onChange={e => {
                                            cardForm.home_number = e.target.checked
                                            setCardForm(oldValue => ({
                                                ...oldValue,
                                                ...cardForm
                                            }));
                                        }}
                                        inputProps={{ 'aria-label': 'controlled' }}
                                        color="success"
                                        size="medium"
                                    />}
                                label='Home Number'
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={cardForm.mobile_number}
                                        onChange={e => {
                                            cardForm.mobile_number = e.target.checked
                                            setCardForm(oldValue => ({
                                                ...oldValue,
                                                ...cardForm
                                            }));
                                        }}
                                        inputProps={{ 'aria-label': 'controlled' }}
                                        color="success"
                                        size="medium"
                                    />}
                                label='Mobile Number'
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={cardForm.work_number}
                                        onChange={e => {
                                            cardForm.work_number = e.target.checked
                                            setCardForm(oldValue => ({
                                                ...oldValue,
                                                ...cardForm
                                            }));
                                        }}
                                        inputProps={{ 'aria-label': 'controlled' }}
                                        color="success"
                                        size="medium"
                                    />}
                                label='Work Number'
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={cardForm.fax_number}
                                        onChange={e => {
                                            cardForm.fax_number = e.target.checked
                                            setCardForm(oldValue => ({
                                                ...oldValue,
                                                ...cardForm
                                            }));
                                        }}
                                        inputProps={{ 'aria-label': 'controlled' }}
                                        color="success"
                                        size="medium"
                                    />}
                                label='Fax Number'
                            />
                        </div>}
                        {cardForm.work_number && <div>
                            <FormFieldLabel label='Work Phone Numbers' />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={cardForm.work_number_1}
                                        onChange={e => {
                                            cardForm.work_number_1 = e.target.checked
                                            setCardForm(oldValue => ({
                                                ...oldValue,
                                                ...cardForm
                                            }));
                                        }}
                                        inputProps={{ 'aria-label': 'controlled' }}
                                        color="success"
                                        size="medium"
                                    />}
                                label='Work Number 1'
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={cardForm.work_number_2}
                                        onChange={e => {
                                            cardForm.work_number_2 = e.target.checked
                                            setCardForm(oldValue => ({
                                                ...oldValue,
                                                ...cardForm
                                            }));
                                        }}
                                        inputProps={{ 'aria-label': 'controlled' }}
                                        color="success"
                                        size="medium"
                                    />}
                                label='Work Number 2'
                            />
                        </div>}
                        {cardForm.addresses && <div>
                            <FormFieldLabel label='Addresses' />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={cardForm.home_address}
                                        onChange={e => {
                                            cardForm.home_address = e.target.checked
                                            setCardForm(oldValue => ({
                                                ...oldValue,
                                                ...cardForm
                                            }));
                                        }}
                                        inputProps={{ 'aria-label': 'controlled' }}
                                        color="success"
                                        size="medium"
                                    />}
                                label='Home Address'
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={cardForm.work_address}
                                        onChange={e => {
                                            cardForm.work_address = e.target.checked
                                            setCardForm(oldValue => ({
                                                ...oldValue,
                                                ...cardForm
                                            }));
                                        }}
                                        inputProps={{ 'aria-label': 'controlled' }}
                                        color="success"
                                        size="medium"
                                    />}
                                label='Work Address'
                            />
                        </div>}
                    </div>) : null}
                    <div className="label-row-container" onClick={() => setShowProfessionDetails(!showProfessionDetails)}>
                        <FormFieldLabel label='Profession Details' />
                        <div className="label-icon-container">
                            <img src={showProfessionDetails ? require('../../../assets/ic_arrow_up.png') : require('../../../assets/ic_arrow_down.png')} className='img' />
                        </div>
                    </div>
                    {showProfessionDetails ? (<div>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={cardForm.company_name}
                                    onChange={e => {
                                        cardForm.company_name = e.target.checked
                                        setCardForm(oldValue => ({
                                            ...oldValue,
                                            ...cardForm
                                        }));
                                    }}
                                    inputProps={{ 'aria-label': 'controlled' }}
                                    color="success"
                                    size="medium"
                                />}
                            label='Company Name'
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={cardForm.job_title}
                                    onChange={e => {
                                        cardForm.job_title = e.target.checked
                                        setCardForm(oldValue => ({
                                            ...oldValue,
                                            ...cardForm
                                        }));
                                    }}
                                    inputProps={{ 'aria-label': 'controlled' }}
                                    color="success"
                                    size="medium"
                                />}
                            label='Job Title'
                        />
                    </div>) : null}
                    <div className="label-row-container" onClick={() => setShowSocialMediaDetails(!showSocialMediaDetails)}>
                        <FormFieldLabel label='Social Media Details' />
                        <div className="label-icon-container">
                            <img src={showSocialMediaDetails ? require('../../../assets/ic_arrow_up.png') : require('../../../assets/ic_arrow_down.png')} className='img' />
                        </div>
                    </div>
                    {showSocialMediaDetails ? (<div>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={cardForm.facebook}
                                    onChange={e => {
                                        cardForm.facebook = e.target.checked
                                        setCardForm(oldValue => ({
                                            ...oldValue,
                                            ...cardForm
                                        }));
                                    }}
                                    inputProps={{ 'aria-label': 'controlled' }}
                                    color="success"
                                    size="medium"
                                />}
                            label='Facebook'
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={cardForm.linkedin}
                                    onChange={e => {
                                        cardForm.linkedin = e.target.checked
                                        setCardForm(oldValue => ({
                                            ...oldValue,
                                            ...cardForm
                                        }));
                                    }}
                                    inputProps={{ 'aria-label': 'controlled' }}
                                    color="success"
                                    size="medium"
                                />}
                            label='LinkedIn'
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={cardForm.twitter}
                                    onChange={e => {
                                        cardForm.twitter = e.target.checked
                                        setCardForm(oldValue => ({
                                            ...oldValue,
                                            ...cardForm
                                        }));
                                    }}
                                    inputProps={{ 'aria-label': 'controlled' }}
                                    color="success"
                                    size="medium"
                                />}
                            label='X (Twitter)'
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={cardForm.instagram}
                                    onChange={e => {
                                        cardForm.instagram = e.target.checked
                                        setCardForm(oldValue => ({
                                            ...oldValue,
                                            ...cardForm
                                        }));
                                    }}
                                    inputProps={{ 'aria-label': 'controlled' }}
                                    color="success"
                                    size="medium"
                                />}
                            label='Instagram'
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={cardForm.snapchat}
                                    onChange={e => {
                                        cardForm.snapchat = e.target.checked
                                        setCardForm(oldValue => ({
                                            ...oldValue,
                                            ...cardForm
                                        }));
                                    }}
                                    inputProps={{ 'aria-label': 'controlled' }}
                                    color="success"
                                    size="medium"
                                />}
                            label='Snapchat'
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={cardForm.other_links}
                                    onChange={e => {
                                        cardForm.other_links = e.target.checked
                                        setCardForm(oldValue => ({
                                            ...oldValue,
                                            ...cardForm
                                        }));
                                    }}
                                    inputProps={{ 'aria-label': 'controlled' }}
                                    color="success"
                                    size="medium"
                                />}
                            label='Other Links'
                        />
                    </div>) : null}
                    <ButtonRow>
                        {!showNextButton && <CommonButton isInverted label='Cancel' onClick={() => {
                            // setShowFieldSelection(false)
                            checkForFieldSelection('CANCEL')
                        }} />}
                        <CommonButton isInverted label={showNextButton ? 'Next' : 'Done'} onClick={() => {
                            // setShowNextButton(false)
                            // setShowFieldSelection(false)
                            checkForFieldSelection('NEXT')
                        }} />
                    </ButtonRow>
                </CommonColorForm>}
                {!showFieldSelection && <div className="row">
                    <div className="col-sm-6 d-flex justify-content-center align-items-center">
                        <CommonColorForm>
                            {/* <SubTextLabel label='Add details to create new digital card' /> */}
                            {/* {bannerImage ? (
                                <div className="banner">
                                    <img alt="BannerImage" src={bannerImage} />
                                    <div className="edit-image-button" onClick={pickBannerImage}>
                                        <img alt="" src={require('../../../assets/ic_circle_edit.png')} className='img' />
                                    </div>
                                    <div className="delete-image-button" onClick={handleBannerDeleteClick}>
                                        <img src={require('../../../assets/ic_delete_circle.png')} className='img' />
                                    </div>
                                </div>
                            ) : (
                                <div className="banner">
                                    <img src={require('../../../assets/banner_placeholder_2.png')} alt="BannerImage" />
                                    <div className="edit-image-button" onClick={pickBannerImage}>
                                        <img alt="" src={require('../../../assets/ic_circle_edit.png')} className='img' />
                                    </div>
                                </div>
                            )} */}
                            {cardForm.brand_logo && <div>
                                {brandImage ? (
                                    <div className="ac-brandLogo">
                                        <img src={brandImage} alt="BrandImage" />
                                        <div className="edit-image-button-bl" onClick={pickBrandLogo}>
                                            <img alt="" src={require('../../../assets/ic_circle_edit.png')} className='img' />
                                        </div>
                                        <div className="delete-image-button-bl" onClick={handleBrandLogoDeleteClick}>
                                            <img src={require('../../../assets/ic_delete_circle.png')} className='img' />
                                        </div>
                                    </div>
                                ) : (
                                    <div className="ac-brandLogo">
                                        <img src={require('../../../assets/brand_placeholder.png')} alt="BrandImage" />
                                        <div className="edit-image-button-bl" onClick={pickBrandLogo}>
                                            <img alt="" src={require('../../../assets/ic_circle_edit.png')} className='img' />
                                        </div>
                                    </div>
                                )}
                            </div>}
                            {cardForm.profile_image && <div>
                                {profileImage ? (
                                    <div className="profile">
                                        <img src={profileImage} alt="ProfileImage" />
                                        <div className="edit-image-button" onClick={pickProfileImage}>
                                            <img alt="" src={require('../../../assets/ic_circle_edit.png')} className='img' />
                                        </div>
                                        <div className="delete-image-button" onClick={handleProfileDeleteClick}>
                                            <img src={require('../../../assets/ic_delete_circle.png')} className='img' />
                                        </div>
                                    </div>
                                ) : (
                                    <div className="profile">
                                        <img src={require('../../../assets/profile_placeholder_1.png')} alt="ProfileImage" />
                                        <div className="edit-image-button" onClick={pickProfileImage}>
                                            <img alt="" src={require('../../../assets/ic_circle_edit.png')} className='img' />
                                        </div>
                                    </div>
                                )}
                            </div>}
                            <FormFieldLabel className='up-bold-label mb-2' label='Card Details' />
                            <Paper elevation={3} style={{ background: '#fbfbfb' }} className='ac-card'>
                                <FormFieldLabel className='mb-3' label='General Details' />
                                <CommonInputFloatFull id='cardName'
                                    onChange={e => setCardName(e.target.value)}
                                    placeholder='Card Nickname'
                                    label='Card Nickname'
                                    type='text'
                                    value={cardName}
                                    isRequired={true}
                                    isSubtext={true}
                                    subText="A nickname to remember your card"
                                />
                                {cardForm.name && <CommonInputRow>
                                    <CommonInputFloatHalf
                                        id='firstName'
                                        onChange={e => setFirstName(e.target.value)}
                                        placeholder='First Name'
                                        type='text'
                                        value={firstName}
                                        isRequired={true}
                                    />
                                    <CommonInputFloatHalf
                                        id='lastName'
                                        onChange={e => setLastName(e.target.value)}
                                        placeholder='Last Name'
                                        type='text'
                                        value={lastName}
                                        isRequired={true}
                                    />
                                </CommonInputRow>}
                                {
                                    cardForm.summary &&
                                    <CommonInputRow>
                                        <CommonInputFloatFull id='summary'
                                            onChange={e => setSummary(e.target.value)}
                                            placeholder='Summary'
                                            type='text'
                                            value={summary}
                                            multiline
                                            rows={3}
                                        />
                                        <div className="input-icon-container" onClick={() => {
                                            if (isEmpty(summary)) {
                                                displayErrorToast('Summary is empty. Click delete to remove')
                                            } else {
                                                displaySuccessToast('Summary added successfully')
                                            }
                                        }}>
                                            <img alt="" src={require('../../../assets/ic_done.png')} className='img' />
                                        </div>
                                        <div className="ac-social-link-img-container" onClick={() => {
                                            cardForm.summary = false;
                                            setCardForm(oldValue => ({
                                                ...oldValue,
                                                ...cardForm
                                            }));
                                        }}>
                                            <img alt="" src={require('../../../assets/ic_delete.png')} className='img' />
                                        </div>
                                    </CommonInputRow>
                                }
                            </Paper>
                            {(cardForm.email_ids || cardForm.phone || cardForm.addresses) &&
                                <Paper elevation={3} style={{ background: '#fbfbfb' }} className='ac-card'>
                                    <FormFieldLabel className='mb-3' label='Contact Details' />
                                    {cardForm.email_ids && <FormFieldLabel label='Email Ids' />}
                                    {
                                        cardForm.primary_email &&
                                        <CommonInputRow>
                                            <CommonInputFloatFull id='primaryEmail'
                                                onChange={e => setPrimaryEmail(e.target.value)}
                                                placeholder='Primary Email'
                                                type='text'
                                                value={primaryEmail}
                                            />
                                            <div className="input-icon-container" onClick={() => {
                                                if (isEmpty(primaryEmail)) {
                                                    displayErrorToast('Primary email is empty. Click delete to remove.')
                                                } else {
                                                    displaySuccessToast('Primary email id added successfully')
                                                }
                                            }}>
                                                <img alt="" src={require('../../../assets/ic_done.png')} className='img' />
                                            </div>
                                            <div className="ac-social-link-img-container" onClick={() => {
                                                cardForm.primary_email = false;
                                                setPrimaryEmail('')
                                                setCardForm(oldValue => ({
                                                    ...oldValue,
                                                    ...cardForm
                                                }));
                                            }}>
                                                <img alt="" src={require('../../../assets/ic_delete.png')} className='img' />
                                            </div>
                                        </CommonInputRow>
                                    }
                                    {
                                        cardForm.secondary_email &&
                                        <CommonInputRow>
                                            <CommonInputFloatFull id='secondaryEmail'
                                                onChange={e => setSecondaryEmail(e.target.value)}
                                                placeholder='Secondary Email'
                                                type='text'
                                                value={secondaryEmail}
                                            />
                                            <div className="input-icon-container" onClick={() => {
                                                if (isEmpty(secondaryEmail)) {
                                                    displayErrorToast('Secondary email is empty. Click delete to remove.')
                                                } else {
                                                    displaySuccessToast('Secondary email id added successfully')
                                                }
                                            }}>
                                                <img alt="" src={require('../../../assets/ic_done.png')} className='img' />
                                            </div>
                                            <div className="ac-social-link-img-container" onClick={() => {
                                                cardForm.secondary_email = false;
                                                setSecondaryEmail('')
                                                setCardForm(oldValue => ({
                                                    ...oldValue,
                                                    ...cardForm
                                                }));
                                            }}>
                                                <img alt="" src={require('../../../assets/ic_delete.png')} className='img' />
                                            </div>
                                        </CommonInputRow>
                                    }
                                    {
                                        cardForm.alternate_email &&
                                        <CommonInputRow>
                                            <CommonInputFloatFull id='alternateEmail'
                                                onChange={e => setAlternateEmail(e.target.value)}
                                                placeholder='Alternate Email'
                                                type='text'
                                                value={alternateEmail}
                                            />
                                            <div className="input-icon-container" onClick={() => {
                                                if (isEmpty(alternateEmail)) {
                                                    displayErrorToast('Alternate email is empty. Click delete to remove.')
                                                } else {
                                                    displaySuccessToast('Alternate email id added successfully')
                                                }
                                            }}>
                                                <img alt="" src={require('../../../assets/ic_done.png')} className='img' />
                                            </div>
                                            <div className="ac-social-link-img-container" onClick={() => {
                                                cardForm.alternate_email = false;
                                                setAlternateEmail('')
                                                setCardForm(oldValue => ({
                                                    ...oldValue,
                                                    ...cardForm
                                                }));
                                            }}>
                                                <img alt="" src={require('../../../assets/ic_delete.png')} className='img' />
                                            </div>
                                        </CommonInputRow>
                                    }
                                    {cardForm.addresses && <FormFieldLabel label='Addresses' />}
                                    {
                                        cardForm.work_address &&
                                        <CommonInputRow>
                                            <CommonInputFloatFull id='workAddress'
                                                onChange={e => setWorkAddress(e.target.value)}
                                                placeholder='Work Address'
                                                type='text'
                                                value={workAddress}
                                            />
                                            <div className="input-icon-container" onClick={() => {
                                                if (isEmpty(workAddress)) {
                                                    displayErrorToast('Work address is empty. Click delete to remove.')
                                                } else {
                                                    displaySuccessToast('Work address is added successfully')
                                                }
                                            }}>
                                                <img alt="" src={require('../../../assets/ic_done.png')} className='img' />
                                            </div>
                                            <div className="ac-social-link-img-container" onClick={() => {
                                                cardForm.work_address = false;
                                                setWorkAddress('')
                                                setCardForm(oldValue => ({
                                                    ...oldValue,
                                                    ...cardForm
                                                }));
                                            }}>
                                                <img alt="" src={require('../../../assets/ic_delete.png')} className='img' />
                                            </div>
                                        </CommonInputRow>
                                    }
                                    {
                                        cardForm.home_address &&
                                        <CommonInputRow>
                                            <CommonInputFloatFull id='homeAddress'
                                                onChange={e => setHomeAddress(e.target.value)}
                                                placeholder='Home Address'
                                                type='text'
                                                value={homeAddress}
                                            />
                                            <div className="input-icon-container" onClick={() => {
                                                if (isEmpty(homeAddress)) {
                                                    displayErrorToast('Home address is empty. Click delete to remove.')
                                                } else {
                                                    displaySuccessToast('Home address is added successfully')
                                                }
                                            }}>
                                                <img alt="" src={require('../../../assets/ic_done.png')} className='img' />
                                            </div>
                                            <div className="ac-social-link-img-container" onClick={() => {
                                                cardForm.home_address = false;
                                                setHomeAddress('')
                                                setCardForm(oldValue => ({
                                                    ...oldValue,
                                                    ...cardForm
                                                }));
                                            }}>
                                                <img alt="" src={require('../../../assets/ic_delete.png')} className='img' />
                                            </div>
                                        </CommonInputRow>
                                    }
                                    {cardForm.phone && <FormFieldLabel label='Phone Numbers' />}
                                    {
                                        cardForm.home_number &&
                                        <CommonInputRow>
                                            <CommonInputFloatFull id='homeNumber'
                                                onChange={e => setHomePhoneNumber(e.target.value)}
                                                placeholder='Home Number'
                                                type='tel'
                                                value={homePhoneNumber}
                                            />
                                            <div className="input-icon-container" onClick={() => {
                                                if (isEmpty(homePhoneNumber)) {
                                                    displayErrorToast('Home phone number is empty. Click delete to remove.')
                                                } else {
                                                    displaySuccessToast('Home phone number is added successfully')
                                                }
                                            }}>
                                                <img alt="" src={require('../../../assets/ic_done.png')} className='img' />
                                            </div>
                                            <div className="ac-social-link-img-container" onClick={() => {
                                                cardForm.home_number = false;
                                                setHomePhoneNumber('')
                                                setCardForm(oldValue => ({
                                                    ...oldValue,
                                                    ...cardForm
                                                }));
                                            }}>
                                                <img alt="" src={require('../../../assets/ic_delete.png')} className='img' />
                                            </div>
                                        </CommonInputRow>
                                    }
                                    {
                                        cardForm.mobile_number &&
                                        <CommonInputRow>
                                            <CommonInputFloatFull id='mobileNumber'
                                                onChange={e => setMobilePhoneNumber(e.target.value)}
                                                placeholder='Mobile Number'
                                                type='tel'
                                                value={mobilePhoneNumber}
                                            />
                                            <div className="input-icon-container" onClick={() => {
                                                if (isEmpty(mobilePhoneNumber)) {
                                                    displayErrorToast('Mobile number is empty. Click delete to remove.')
                                                } else {
                                                    displaySuccessToast('Mobile number is added successfully')
                                                }
                                            }}>
                                                <img alt="" src={require('../../../assets/ic_done.png')} className='img' />
                                            </div>
                                            <div className="ac-social-link-img-container" onClick={() => {
                                                cardForm.mobile_number = false;
                                                setMobilePhoneNumber('')
                                                setCardForm(oldValue => ({
                                                    ...oldValue,
                                                    ...cardForm
                                                }));
                                            }}>
                                                <img alt="" src={require('../../../assets/ic_delete.png')} className='img' />
                                            </div>
                                        </CommonInputRow>
                                    }
                                    {
                                        cardForm.work_number_1 &&
                                        <CommonInputRow>
                                            <CommonInputFloatFull
                                                classname="field-mb-0"
                                                id='workNumber1'
                                                onChange={e => setWorkNumber1(e.target.value)}
                                                placeholder='Work Number 1'
                                                type='number'
                                                value={workNumber1} />
                                            <div className="phone-ext-container ms-2">
                                                <CommonInputFloatFull
                                                    classname="field-mb-0"
                                                    id='workNumberExt1'
                                                    onChange={e => setWorkNumberExt1(e.target.value)}
                                                    placeholder='Ext.'
                                                    type='number'
                                                    value={workNumberExt1}
                                                    color="success" />
                                            </div>
                                            <div className="input-icon-container" onClick={() => {
                                                if (isEmpty(workNumber1)) {
                                                    displayErrorToast('Work number is empty. Click delete to remove.')
                                                } else {
                                                    displaySuccessToast('Work number is added successfully')
                                                }
                                            }}>
                                                <img alt="" src={require('../../../assets/ic_done.png')} className='img' />
                                            </div>
                                            <div className="ac-social-link-img-container" onClick={() => {
                                                cardForm.work_number_1 = false;
                                                setWorkNumber1('')
                                                setWorkNumberExt1('')
                                                setCardForm(oldValue => ({
                                                    ...oldValue,
                                                    ...cardForm
                                                }));
                                            }}>
                                                <img alt="" src={require('../../../assets/ic_delete.png')} className='img' />
                                            </div>
                                        </CommonInputRow>
                                    }
                                    {
                                        cardForm.work_number_2 &&
                                        <CommonInputRow>
                                            <CommonInputFloatFull
                                                classname="field-mb-0"
                                                id='workNumber2'
                                                onChange={e => setWorkNumber2(e.target.value)}
                                                placeholder='Work Number 2'
                                                type='number'
                                                value={workNumber2} />
                                            <div className="phone-ext-container ms-2">
                                                <CommonInputFloatFull
                                                    classname="field-mb-0"
                                                    id='workNumberExt2'
                                                    onChange={e => setWorkNumberExt2(e.target.value)}
                                                    placeholder='Ext.'
                                                    type='number'
                                                    value={workNumberExt2}
                                                    color="success" />
                                            </div>
                                            <div className="input-icon-container" onClick={() => {
                                                if (isEmpty(workNumber2)) {
                                                    displayErrorToast('Work number is empty. Click delete to remove.')
                                                } else {
                                                    displaySuccessToast('Work number is added successfully')
                                                }
                                            }}>
                                                <img alt="" src={require('../../../assets/ic_done.png')} className='img' />
                                            </div>
                                            <div className="ac-social-link-img-container" onClick={() => {
                                                cardForm.work_number_2 = false;
                                                setWorkNumber2('')
                                                setWorkNumberExt2('')
                                                setCardForm(oldValue => ({
                                                    ...oldValue,
                                                    ...cardForm
                                                }));
                                            }}>
                                                <img alt="" src={require('../../../assets/ic_delete.png')} className='img' />
                                            </div>
                                        </CommonInputRow>
                                    }
                                    {
                                        cardForm.fax_number &&
                                        <CommonInputRow>
                                            <CommonInputFloatFull id='faxNumber'
                                                onChange={e => setFaxNumber(e.target.value)}
                                                placeholder='Fax Number'
                                                type='tel'
                                                value={faxNumber}
                                            />
                                            <div className="input-icon-container" onClick={() => {
                                                if (isEmpty(faxNumber)) {
                                                    displayErrorToast('Fax number is empty. Click delete to remove.')
                                                } else {
                                                    displaySuccessToast('Fax number is added successfully')
                                                }
                                            }}>
                                                <img alt="" src={require('../../../assets/ic_done.png')} className='img' />
                                            </div>
                                            <div className="ac-social-link-img-container" onClick={() => {
                                                cardForm.fax_number = false;
                                                setFaxNumber('')
                                                setCardForm(oldValue => ({
                                                    ...oldValue,
                                                    ...cardForm
                                                }));
                                            }}>
                                                <img alt="" src={require('../../../assets/ic_delete.png')} className='img' />
                                            </div>
                                        </CommonInputRow>
                                    }
                                </Paper>}
                            {(cardForm.company_name || cardForm.job_title) &&
                                <Paper elevation={3} style={{ background: '#fbfbfb' }} className='ac-card'>
                                    <FormFieldLabel label='Profession Details' />
                                    {
                                        cardForm.company_name &&
                                        <CommonInputRow>
                                            <CommonInputFloatFull id='companyName'
                                                onChange={e => setCompanyName(e.target.value)}
                                                placeholder='Company Name'
                                                type='text'
                                                value={companyName}
                                            />
                                            <div className="input-icon-container" onClick={() => {
                                                if (isEmpty(companyName)) {
                                                    displayErrorToast('Company name is empty. Click delete to remove.')
                                                } else {
                                                    displaySuccessToast('Company name added successfully')
                                                }
                                            }}>
                                                <img alt="" src={require('../../../assets/ic_done.png')} className='img' />
                                            </div>
                                            <div className="ac-social-link-img-container" onClick={() => {
                                                cardForm.company_name = false;
                                                setCompanyName('')
                                                setCardForm(oldValue => ({
                                                    ...oldValue,
                                                    ...cardForm
                                                }));
                                            }}>
                                                <img alt="" src={require('../../../assets/ic_delete.png')} className='img' />
                                            </div>
                                        </CommonInputRow>
                                    }
                                    {
                                        cardForm.job_title &&
                                        <CommonInputRow>
                                            <CommonInputFloatFull id='jobTitle'
                                                onChange={e => setJobTitle(e.target.value)}
                                                placeholder='Job Title'
                                                type='text'
                                                value={jobTitle}
                                            />
                                            <div className="input-icon-container" onClick={() => {
                                                if (isEmpty(jobTitle)) {
                                                    displayErrorToast('Job title is empty. Click delete to remove.')
                                                } else {
                                                    displaySuccessToast('Job title added successfully')
                                                }
                                            }}>
                                                <img alt="" src={require('../../../assets/ic_done.png')} className='img' />
                                            </div>
                                            <div className="ac-social-link-img-container" onClick={() => {
                                                cardForm.job_title = false;
                                                setJobTitle('')
                                                setCardForm(oldValue => ({
                                                    ...oldValue,
                                                    ...cardForm
                                                }));
                                            }}>
                                                <img alt="" src={require('../../../assets/ic_delete.png')} className='img' />
                                            </div>
                                        </CommonInputRow>
                                    }
                                </Paper>}
                            {
                                cardForm.card_description &&
                                <CommonInputRow>
                                    <CommonInputFloatFull id='cardDescription'
                                        onChange={e => setCardDescription(e.target.value)}
                                        placeholder='Card Description'
                                        type='text'
                                        value={cardDescription}
                                    />
                                    <div className="input-icon-container" onClick={() => {
                                        if (isEmpty(cardDescription)) {
                                            displayErrorToast('Card description is empty. Click delete to remove.')
                                        } else {
                                            displaySuccessToast('Card description added successfully')
                                        }
                                    }}>
                                        <img alt="" src={require('../../../assets/ic_done.png')} className='img' />
                                    </div>
                                    <div className="ac-social-link-img-container" onClick={() => {
                                        cardForm.card_description = false;
                                        setCardForm(oldValue => ({
                                            ...oldValue,
                                            ...cardForm
                                        }));
                                    }}>
                                        <img alt="" src={require('../../../assets/ic_delete.png')} className='img' />
                                    </div>
                                </CommonInputRow>
                            }
                            {(cardForm.facebook || cardForm.linkedin || cardForm.twitter || cardForm.instagram || cardForm.snapchat || cardForm.other_links || !isEmpty(otherSocialLink)) &&
                                <Paper elevation={3} style={{ background: '#fbfbfb' }} className='ac-card'>
                                    <FormFieldLabel label='Social Media Details' />
                                    {
                                        cardForm.facebook && <CommonInputRow>
                                            <CommonInputFloatFull
                                                id='facebookUserName'
                                                onChange={e => setFacebookLink(e.target.value)}
                                                placeholder='Facebook Profile Link'
                                                type='text'
                                                value={facebookLink}
                                            />
                                            <div className="input-icon-container" onClick={() => {
                                                if (isEmpty(facebookLink)) {
                                                    displayErrorToast('Facebook link is empty. Click delete to remove.')
                                                } else {
                                                    displaySuccessToast('Facebook linke added successfully')
                                                }
                                            }}>
                                                <img alt="" src={require('../../../assets/ic_done.png')} className='img' />
                                            </div>
                                            <div className="ac-social-link-img-container" onClick={() => {
                                                cardForm.facebook = false;
                                                setFacebookLink('')
                                                setCardForm(oldValue => ({
                                                    ...oldValue,
                                                    ...cardForm
                                                }));
                                            }}>
                                                <img alt="" src={require('../../../assets/ic_delete.png')} className='img' />
                                            </div>
                                        </CommonInputRow>
                                    }

                                    {
                                        cardForm.linkedin && <CommonInputRow>
                                            <CommonInputFloatFull
                                                id='linkedInUserName'
                                                onChange={e => setLinkedInLink(e.target.value)}
                                                placeholder='LinkedIn Profile Link'
                                                type='text'
                                                value={linkedInLink}
                                            />
                                            <div className="input-icon-container" onClick={() => {
                                                if (isEmpty(linkedInLink)) {
                                                    displayErrorToast('LinkedIn link is empty. Click delete to remove.')
                                                } else {
                                                    displaySuccessToast('LinkedIn link added successfully')
                                                }
                                            }}>
                                                <img alt="" src={require('../../../assets/ic_done.png')} className='img' />
                                            </div>
                                            <div className="ac-social-link-img-container" onClick={() => {
                                                cardForm.linkedin = false;
                                                setLinkedInLink('')
                                                setCardForm(oldValue => ({
                                                    ...oldValue,
                                                    ...cardForm
                                                }));
                                            }}>
                                                <img alt="" src={require('../../../assets/ic_delete.png')} className='img' />
                                            </div>
                                        </CommonInputRow>
                                    }
                                    {
                                        cardForm.twitter && <CommonInputRow>
                                            <CommonInputFloatFull
                                                id='twitterName'
                                                onChange={e => setTwitterLink(e.target.value)}
                                                placeholder='X (Twitter) Profile Link'
                                                type='text'
                                                value={twitterLink}
                                            />
                                            <div className="input-icon-container" onClick={() => {
                                                if (isEmpty(twitterLink)) {
                                                    displayErrorToast('X (Twitter) link is empty. Click delete to remove.')
                                                } else {
                                                    displaySuccessToast('X (Twitter) link added successfully')
                                                }
                                            }}>
                                                <img alt="" src={require('../../../assets/ic_done.png')} className='img' />
                                            </div>
                                            <div className="ac-social-link-img-container" onClick={() => {
                                                cardForm.twitter = false;
                                                setTwitterLink('')
                                                setCardForm(oldValue => ({
                                                    ...oldValue,
                                                    ...cardForm
                                                }));
                                            }}>
                                                <img alt="" src={require('../../../assets/ic_delete.png')} className='img' />
                                            </div>
                                        </CommonInputRow>
                                    }
                                    {
                                        cardForm.instagram && <CommonInputRow>
                                            <CommonInputFloatFull
                                                id='instagramName'
                                                onChange={e => setInstagramLink(e.target.value)}
                                                placeholder='Instagram Profile Link'
                                                type='text'
                                                value={instagramLink}
                                            />
                                            <div className="input-icon-container" onClick={() => {
                                                if (isEmpty(instagramLink)) {
                                                    displayErrorToast('Instagram link is empty. Click delete to remove.')
                                                } else {
                                                    displaySuccessToast('Instagram link added successfully')
                                                }
                                            }}>
                                                <img alt="" src={require('../../../assets/ic_done.png')} className='img' />
                                            </div>
                                            <div className="ac-social-link-img-container" onClick={() => {
                                                cardForm.instagram = false;
                                                setInstagramLink('')
                                                setCardForm(oldValue => ({
                                                    ...oldValue,
                                                    ...cardForm
                                                }));
                                            }}>
                                                <img alt="" src={require('../../../assets/ic_delete.png')} className='img' />
                                            </div>

                                        </CommonInputRow>
                                    }
                                    {
                                        cardForm.snapchat && <CommonInputRow>
                                            <CommonInputFloatFull
                                                id='snapchat'
                                                onChange={e => setSnapchatLink(e.target.value)}
                                                placeholder='Snapchat Profile Link'
                                                type='text'
                                                value={snapchatLink}
                                            />
                                            <div className="input-icon-container" onClick={() => {
                                                if (isEmpty(snapchatLink)) {
                                                    displayErrorToast('Snapchat link is empty. Click delete to remove.')
                                                } else {
                                                    displaySuccessToast('Snapchat link added successfully')
                                                }
                                            }}>
                                                <img alt="" src={require('../../../assets/ic_done.png')} className='img' />
                                            </div>
                                            <div className="ac-social-link-img-container" onClick={() => {
                                                cardForm.snapchat = false;
                                                setSnapchatLink('')
                                                setCardForm(oldValue => ({
                                                    ...oldValue,
                                                    ...cardForm
                                                }));
                                            }}>
                                                <img alt="" src={require('../../../assets/ic_delete.png')} className='img' />
                                            </div>

                                        </CommonInputRow>
                                    }
                                    {
                                        cardForm.other_links && <div className="ac-input-row">
                                            <CommonInputFloatFull
                                                classname="field-mb-0"
                                                type='text'
                                                id='otherLink'
                                                onChange={e => setOtherLink(e.target.value)}
                                                placeholder='Other Link'
                                                isSubtext={true}
                                                subText='Ex. https://www.abc.com'
                                                value={otherLink} />
                                            <div className="ac-add-img-container" onClick={() => handleAddOtherLinkClick()}>
                                                <img alt="" src={require('../../../assets/ic_done.png')} className='img' />
                                            </div>
                                            <div className="input-icon-container" onClick={() => {
                                                cardForm.other_links = false;
                                                setCardForm(oldValue => ({
                                                    ...oldValue,
                                                    ...cardForm
                                                }));
                                            }}>
                                                <img alt="" src={require('../../../assets/ic_delete.png')} className='img' />
                                            </div>
                                            {/* <button type="button" className="btn ac-add-button" onClick={() => handleAddOtherLinkClick()}>Add</button> */}
                                        </div>
                                    }
                                    {!isEmpty(otherSocialLink) && <div className="ac-social-link-container">
                                        {otherSocialLink.map(e => (
                                            <div className="ac-social-link-item-container">
                                                <div className="ac-social-link-item">{e}</div>
                                                <div className="ac-social-link-img-container" onClick={() => { handleLinkDeleteClick(e) }}>
                                                    <img alt="" src={require('../../../assets/ic_edit_blue.png')} className='img' />
                                                </div>
                                            </div>
                                        ))}
                                    </div>}
                                </Paper>
                            }
                            <ButtonRow>
                                <CommonButton label='More Options' onClick={() => {
                                    setShowFieldSelection(true)
                                }} />
                                <CommonButton label='Create Card'
                                    onClick={() => { handleSubmitClick() }} />
                                {/* <p>By Clicking Create Card button, you agree all details are added</p> */}
                            </ButtonRow>

                        </CommonColorForm>
                    </div>
                    <div className="col-sm-6">
                        <h4 className="uc-email-container">Card Preview</h4>
                        <UserCardPreview
                            isBannerImageFromUrl={isBannerImageFromUrl()}
                            isProfileImageFromUrl={isProfileImageFromUrl()}
                            showProfileImage={cardForm.profile_image}
                            showBrandLogo={cardForm.brand_logo}
                            isBrandLogoFromUrl={isBrandLogoFromUrl()}
                            cardObject={
                                {
                                    bannerImage: isBannerImageFromUrl() ? cardListSuccess.data[0].cardDetails[0].bannerImage : bannerImage,
                                    profileImage: isProfileImageFromUrl() ? cardListSuccess.data[0].cardDetails[0].profileImage : profileImage,
                                    brandLogoImg: isBrandLogoFromUrl() ? cardListSuccess.data[0].cardDetails[0].brandLogoImg : brandImage,
                                    firstName: firstName,
                                    lastName: lastName,
                                    companyName: companyName,
                                    jobTitle: jobTitle,
                                    summary: summary,
                                    facebookLink: facebookLink,
                                    linkedInLink: linkedInLink,
                                    twitterLink: twitterLink,
                                    instagramLink: instagramLink,
                                    snapchatLink: snapchatLink,
                                    otherSocialLink: otherSocialLink,
                                    email1: primaryEmail,
                                    email2: secondaryEmail,
                                    email3: alternateEmail,
                                    homeNumber: homePhoneNumber,
                                    mobileNumber: mobilePhoneNumber,
                                    workNumber1: workNumber1,
                                    workNumber1Ext: workNumberExt1,
                                    workNumber2: workNumber2,
                                    workNumber2Ext: workNumberExt2,
                                    fax: faxNumber,
                                    homeAddress: homeAddress,
                                    workAddress: workAddress
                                }
                            } />
                    </div>

                </div>}

            </CommonScreenContent>
            <ToastContainer />
            <Loader active={isLoading} text='Please wait...' />
        </>
    )
}

export default AddCard