import { EnquiryActionTypes } from './enquiry.types'

//Add Enquiry
export const addEnquiryRequest = data => ({
    type: EnquiryActionTypes.ADD_ENQUIRY_REQUEST,
    payload: data
})

export const addEnquiryRequestSuccess = data => ({
    type: EnquiryActionTypes.ADD_ENQUIRY_REQUEST_SUCCESS,
    payload: data
})

export const addEnquiryRequestError = data => ({
    type: EnquiryActionTypes.ADD_ENQUIRY_REQUEST_ERROR,
    payload: data
})

export const addEnquiryRequestStatus = data => ({
    type: EnquiryActionTypes.ADD_ENQUIRY_REQUEST_STATUS,
    payload: data
})

//Enquiry List
export const enquiryListRequest = data => ({
    type: EnquiryActionTypes.ENQUIRY_LIST_REQUEST,
    payload: data
})

export const enquiryListRequestSuccess = data => ({
    type: EnquiryActionTypes.ENQUIRY_LIST_REQUEST_SUCCESS,
    payload: data
})

export const enquiryListRequestError = data => ({
    type: EnquiryActionTypes.ENQUIRY_LIST_REQUEST_ERROR,
    payload: data
})

//Update Enquiry
export const updateEnquiryRequest = data => ({
    type: EnquiryActionTypes.UPDATE_ENQUIRY_REQUEST,
    payload: data
})

export const updateEnquiryRequestSuccess = data => ({
    type: EnquiryActionTypes.UPDATE_ENQUIRY_REQUEST_SUCCESS,
    payload: data
})

export const updateEnquiryRequestError = data => ({
    type: EnquiryActionTypes.UPDATE_ENQUIRY_REQUEST_ERROR,
    payload: data
})

export const updateEnquiryRequestStatus = data => ({
    type: EnquiryActionTypes.UPDATE_ENQUIRY_REQUEST_STATUS,
    payload: data
})