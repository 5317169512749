export const NfcCardOrderActionTypes = {
    LOGOUT: 'LOGOUT',
    COMMON_ERROR: 'COMMON_ERROR',

    PLACE_NFC_CARD_ORDER_REQUEST: 'PLACE_NFC_CARD_ORDER_REQUEST',
    PLACE_NFC_CARD_ORDER_REQUEST_SUCCESS: 'PLACE_NFC_CARD_ORDER_REQUEST_SUCCESS',
    PLACE_NFC_CARD_ORDER_REQUEST_ERROR: 'PLACE_NFC_CARD_ORDER_REQUEST_ERROR',
    PLACE_NFC_CARD_ORDER_REQUEST_STATUS: 'PLACE_NFC_CARD_ORDER_REQUEST_STATUS',

    NFC_CARD_ORDER_LIST_REQUEST: 'NFC_CARD_ORDER_LIST_REQUEST',
    NFC_CARD_ORDER_LIST_REQUEST_SUCCESS: 'NFC_CARD_ORDER_LIST_REQUEST_SUCCESS',
    NFC_CARD_ORDER_LIST_REQUEST_ERROR: 'NFC_CARD_ORDER_LIST_REQUEST_ERROR',

    NFC_CARD_ORDER_DETAIL_REQUEST: 'NFC_CARD_ORDER_DETAIL_REQUEST',
    NFC_CARD_ORDER_DETAIL_REQUEST_SUCCESS: 'NFC_CARD_ORDER_DETAIL_REQUEST_SUCCESS',
    NFC_CARD_ORDER_DETAIL_REQUEST_ERROR: 'NFC_CARD_ORDER_DETAIL_REQUEST_ERROR',

    UPDATE_NFC_CARD_ORDER_DETAIL_REQUEST: 'UPDATE_NFC_CARD_ORDER_DETAIL_REQUEST',
    UPDATE_NFC_CARD_ORDER_DETAIL_REQUEST_SUCCESS: 'UPDATE_NFC_CARD_ORDER_DETAIL_REQUEST_SUCCESS',
    UPDATE_NFC_CARD_ORDER_DETAIL_REQUEST_ERROR: 'UPDATE_NFC_CARD_ORDER_DETAIL_REQUEST_ERROR',
    UPDATE_NFC_CARD_ORDER_DETAIL_REQUEST_STATUS: 'UPDATE_NFC_CARD_ORDER_DETAIL_REQUEST_STATUS'
}
