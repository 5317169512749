import { takeLatest, call, put } from 'redux-saga/effects'
import {
    addNewsLetterRecordAPI, newsLetterRecordListAPI
} from '../api-requests/newsLetter';
import {
    newsLetterAddRecordRequestError, newsLetterAddRecordRequestSuccess,
    newsLetterRecordListRequestError, newsLetterRecordListRequestSuccess
} from './newsLetter.action';
import { NewsLetterActionTypes } from './newsLetter.types'
import { displayConsoleLog } from '../../utils/Utils'
import { decryptResponseData } from '../../utils/DataDecryptionUtils';
import { APP_CONSTANTS } from '../../utils/AppConstants';

function* handleNewsLetterListRequest({ payload }) {
    try {
        const response = yield call(newsLetterRecordListAPI, payload);
        if (response.status >= 200 && response.status < 300) {
            const { data } = response
            const decryptedData = decryptResponseData(data)
            if (decryptedData.data !== null) {
                yield put(newsLetterRecordListRequestSuccess(decryptedData))
            } else {
                yield put(newsLetterRecordListRequestError(decryptedData))
            }
        } else {
            throw response
        }
    } catch (error) {
        if (error.response?.status === 401) {
            const errData = {
                status: error.response?.status,
                isSessionError: true,
                message: APP_CONSTANTS.SESSION_EXPIRE_MESSAGE
            }
            yield put({ type: NewsLetterActionTypes.COMMON_ERROR, errData })
        } else {
            displayConsoleLog(error.response.status)
            displayConsoleLog(error.response?.data)
            yield put(newsLetterRecordListRequestError(error.response.data))
        }
    }
}

export function* newsLetterListReq() {
    yield takeLatest(NewsLetterActionTypes.NEWS_LETTER_RECORD_LIST_REQUEST, handleNewsLetterListRequest)
}

function* handleNewsLetterAddRequest({ payload }) {
    try {
        const response = yield call(addNewsLetterRecordAPI, payload);
        if (response.status >= 200 && response.status < 300) {
            const { data } = response
            const decryptedData = decryptResponseData(data)
            if (decryptedData.data !== null) {
                yield put(newsLetterAddRecordRequestSuccess(decryptedData))
            } else {
                yield put(newsLetterAddRecordRequestError(decryptedData))
            }
        } else {
            throw response
        }
    } catch (error) {
        if (error.response?.status === 401) {
            const errData = {
                status: error.response?.status,
                isSessionError: true,
                message: APP_CONSTANTS.SESSION_EXPIRE_MESSAGE
            }
            yield put({ type: NewsLetterActionTypes.COMMON_ERROR, errData })
        } else {
            displayConsoleLog(error.response.status)
            displayConsoleLog(error.response?.data)
            yield put(newsLetterAddRecordRequestError(error.response.data))
        }
    }
}

export function* newsLetterAddRecordReq() {
    yield takeLatest(NewsLetterActionTypes.NEWS_LETTER_ADD_RECORD_REQUEST, handleNewsLetterAddRequest)
}