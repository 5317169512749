import { takeLatest, call, put } from 'redux-saga/effects'
import {
    addFeedbackAPI
} from '../api-requests/feedback';
import {
    addFeedbackRequestError, addFeedbackRequestSuccess
} from './feedback.action';
import { FeedbackActionTypes } from './feedback.types'
import { displayConsoleLog } from '../../utils/Utils'
import { decryptResponseData } from '../../utils/DataDecryptionUtils';
import { APP_CONSTANTS } from '../../utils/AppConstants';

function* handleAddFeedback({ payload }) {
    try {
        const response = yield call(addFeedbackAPI, payload);
        if (response.status >= 200 && response.status < 300) {
            const { data } = response
            const decryptedData = decryptResponseData(data)
            if (decryptedData.data !== null) {
                yield put(addFeedbackRequestSuccess(decryptedData))
            } else {
                yield put(addFeedbackRequestError(decryptedData))
            }
        } else {
            throw response
        }
    } catch (error) {
        displayConsoleLog(error)
        if (error.response?.status === 401) {
            const errData = {
                status: error.response?.status,
                isSessionError: true,
                message: APP_CONSTANTS.SESSION_EXPIRE_MESSAGE
            }
            yield put({ type: FeedbackActionTypes.COMMON_ERROR, errData })
        } else {
            displayConsoleLog(error.response?.data)
            yield put(addFeedbackRequestError(error.response.data))
        }
    }
}

export function* addFeedbackReq() {
    yield takeLatest(FeedbackActionTypes.ADD_FEEDBACK_REQUEST, handleAddFeedback)
}