import { TestimonialActionTypes } from './testimonial.types'
import { API_CONSTANTS } from '../../utils/AppConstants'

const INITIAL_STATE = {
    addTestimonialSuccess: null,
    addTestimonialError: null,
    addTestimonialReqStatus: '',
    testimonialListSuccess: null,
    testimonialListError: null,
    updateTestimonialSuccess: null,
    updateTestimonialError: null,
    updateTestimonialReqStatus: '',
    deleteTestimonialSuccess: null,
    deleteTestimonialError: null,
    deleteTestimonialReqStatus: ''
}

const testimonialReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case TestimonialActionTypes.ADD_TESTIMONIAL_REQUEST_SUCCESS:
            return {
                ...state,
                addTestimonialSuccess: action.payload,
                addTestimonialError: null,
                addTestimonialReqStatus: API_CONSTANTS.SUCCESS_STATUS
            }
        case TestimonialActionTypes.ADD_TESTIMONIAL_REQUEST_ERROR:
            return {
                ...state,
                addTestimonialSuccess: null,
                addTestimonialError: action.payload,
                addTestimonialReqStatus: API_CONSTANTS.ERROR_STATUS
            }
        case TestimonialActionTypes.ADD_TESTIMONIAL_REQUEST_STATUS:
            return {
                ...state,
                addTestimonialReqStatus: ''
            }
        case TestimonialActionTypes.TESTIMONIAL_LIST_REQUEST_SUCCESS:
            return {
                ...state,
                testimonialListSuccess: action.payload,
                testimonialListError: null
            }
        case TestimonialActionTypes.TESTIMONIAL_LIST_REQUEST_ERROR:
            return {
                ...state,
                testimonialListSuccess: null,
                testimonialListError: action.payload
            }
        case TestimonialActionTypes.UPDATE_TESTIMONIAL_REQUEST_SUCCESS:
            return {
                ...state,
                updateTestimonialSuccess: action.payload,
                updateTestimonialError: null,
                updateTestimonialReqStatus: API_CONSTANTS.SUCCESS_STATUS
            }
        case TestimonialActionTypes.UPDATE_TESTIMONIAL_REQUEST_ERROR:
            return {
                ...state,
                updateTestimonialSuccess: null,
                updateTestimonialError: action.payload,
                updateTestimonialReqStatus: API_CONSTANTS.ERROR_STATUS
            }
        case TestimonialActionTypes.UPDATE_TESTIMONIAL_REQUEST_STATUS:
            return {
                ...state,
                updateTestimonialReqStatus: ''
            }
        case TestimonialActionTypes.DELETE_TESTIMONIAL_REQUEST_SUCCESS:
            return {
                ...state,
                deleteTestimonialSuccess: action.payload,
                deleteTestimonialError: null,
                deleteTestimonialReqStatus: API_CONSTANTS.SUCCESS_STATUS
            }
        case TestimonialActionTypes.DELETE_TESTIMONIAL_REQUEST_ERROR:
            return {
                ...state,
                deleteTestimonialSuccess: null,
                deleteTestimonialError: action.payload,
                deleteTestimonialReqStatus: API_CONSTANTS.ERROR_STATUS
            }
        case TestimonialActionTypes.DELETE_TESTIMONIAL_REQUEST_STATUS:
            return {
                ...state,
                deleteTestimonialReqStatus: ''
            }
        case TestimonialActionTypes.LOGOUT:
            return INITIAL_STATE
        default:
            return state
    }
}

export default testimonialReducer