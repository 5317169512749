import React, { useEffect, useState } from "react";
import './styles.css'
import Loader from '../../../../../components/loader/loader.component'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useDispatch, useSelector } from 'react-redux'
import { employeeDetailRequest } from '../../../../../redux/employee/employee.action'
import { displayErrorToast, isEmpty, isNull } from "../../../../../utils/Utils";
import UserCardPreview from "../../../user-card/userCardPreview";
import { useParams, useLocation } from "react-router-dom";
import { config } from "../../../../../config";

const EmployeeCardView = props => {
    const [isLoading, setLoading] = useState(false)
    const dispatch = useDispatch()
    const { employeeDetailSuccess, employeeDetailError } = useSelector(state => state.employee)
    let { employeeId } = useParams();
    const [qrCodeUrl, setQRCodeUrl] = useState('')
    const { state } = useLocation()

    useEffect(() => {
        setLoading(true)
        if (!isEmpty(state)) {
            dispatch(employeeDetailRequest(state))
        } else if (!isEmpty(employeeId)) {
            dispatch(employeeDetailRequest(employeeId))
        }
    }, [])

    useEffect(() => {
        if (!isEmpty(employeeDetailSuccess)) {
            setLoading(false)
            setQRCodeUrl(`${config.employeeQrCodeUrl}/${employeeDetailSuccess.data.employeeId}`)
        } else if (!isEmpty(employeeDetailError)) {
            setLoading(false)
            displayErrorToast(employeeDetailError.message)
        }
    }, [employeeDetailSuccess, employeeDetailError])

    const downloadContactFile = vcfText => {
        const element = document.createElement("a");
        const file = new Blob([vcfText], { type: "text/plain;charset=utf-8" });
        element.href = URL.createObjectURL(file);
        let rNumber = Math.floor(100 + Math.random() * 900)
        element.download = `contactCard${rNumber}.vcf`;
        document.body.appendChild(element);
        element.click();
    }

    const getPhoneLabel = type => {
        if (type === 'Home') {
            return 'HOME,VOICE:'
        } else if (type === 'Mobile') {
            return 'CELL:'
        } else if (type === 'Work') {
            return 'WORK,VOICE:'
        } else if (type === 'Fax') {
            return 'WORK,FAX:'
        }
    }

    const getAddressLabel = type => {
        if (type === 'Home') {
            return 'HOME'
        } else if (type === 'Work') {
            return 'WORK'
        }
    }

    const CreateVCard = () => {
        let vCardData = `BEGIN:VCARD
        VERSION:3.0
        FN;CHARSET=UTF-8:${employeeDetailSuccess.data.firstName} ${employeeDetailSuccess.data.lastName ? employeeDetailSuccess.data.lastName : ''}
        N;CHARSET=UTF-8:${employeeDetailSuccess.data.lastName ? employeeDetailSuccess.data.lastName : ''};${employeeDetailSuccess.data.firstName};;;
        ${!isEmpty(employeeDetailSuccess.data.email) ? `EMAIL;CHARSET=UTF-8;type=WORK,INTERNET:${employeeDetailSuccess.data.email}` : ''}
        ${!isEmpty(employeeDetailSuccess.data.mobile) ? `TEL;TYPE=${getPhoneLabel('Work')}${employeeDetailSuccess.data.mobile}` : ''}
        ${!isEmpty(employeeDetailSuccess.data.address) ? `ADR;TYPE=${getAddressLabel('Work')};LABEL="${employeeDetailSuccess.data.address}":;;${employeeDetailSuccess.data.address}` : ''}
        ${!isEmpty(employeeDetailSuccess.data.designation) ? `TITLE;CHARSET=UTF-8:${employeeDetailSuccess.data.designation}` : ''}
        URL;CHARSET=UTF-8:${config.employeeQrCodeUrl}/${employeeDetailSuccess.data.employeeId}
        REV:${new Date().toISOString()}
        END:VCARD`

        return vCardData.replace(/^(?=\n)$|^\s*|\s*$|\n\n+/gm, "");
    };

    return (
        <>
            {!isNull(employeeDetailSuccess) && !isNull(employeeDetailSuccess.data) &&
                <UserCardPreview
                    cardObject={{
                        ...employeeDetailSuccess.data,
                        jobTitle: employeeDetailSuccess.data.designation,
                        email1: employeeDetailSuccess.data.email,
                        workNumber1: employeeDetailSuccess.data.mobile,
                        workAddress: employeeDetailSuccess.data.address
                    }}
                    isApiData={true}
                    qrCodeUrl={qrCodeUrl}
                    showDownloadButton={true}
                    downloadClickListener={() => downloadContactFile(CreateVCard())} />
            }
            <ToastContainer />
            <Loader active={isLoading} text='Please wait...' />
        </>
    )
}

export default EmployeeCardView