import { EnquiryActionTypes } from './enquiry.types'
import { API_CONSTANTS } from '../../utils/AppConstants'

const INITIAL_STATE = {
    addEnquirySuccess: null,
    addEnquiryError: null,
    addEnquiryReqStatus: '',
    enquiryListSuccess: null,
    enquiryListError: null,
    updateEnquirySuccess: null,
    updateEnquiryError: null,
    updateEnquiryReqStatus: ''
}

const enquiryReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case EnquiryActionTypes.ADD_ENQUIRY_REQUEST_SUCCESS:
            return {
                ...state,
                addEnquirySuccess: action.payload,
                addEnquiryError: null,
                addEnquiryReqStatus: API_CONSTANTS.SUCCESS_STATUS
            }
        case EnquiryActionTypes.ADD_ENQUIRY_REQUEST_ERROR:
            return {
                ...state,
                addEnquirySuccess: null,
                addEnquiryError: action.payload,
                addEnquiryReqStatus: API_CONSTANTS.ERROR_STATUS
            }
        case EnquiryActionTypes.ADD_ENQUIRY_REQUEST_STATUS:
            return {
                ...state,
                addEnquiryReqStatus: ''
            }
        case EnquiryActionTypes.ENQUIRY_LIST_REQUEST_SUCCESS:
            return {
                ...state,
                enquiryListSuccess: action.payload,
                enquiryListError: null
            }
        case EnquiryActionTypes.ENQUIRY_LIST_REQUEST_ERROR:
            return {
                ...state,
                enquiryListSuccess: null,
                enquiryListError: action.payload
            }
        case EnquiryActionTypes.UPDATE_ENQUIRY_REQUEST_SUCCESS:
            return {
                ...state,
                updateEnquirySuccess: action.payload,
                updateEnquiryError: null,
                updateEnquiryReqStatus: API_CONSTANTS.SUCCESS_STATUS
            }
        case EnquiryActionTypes.UPDATE_ENQUIRY_REQUEST_ERROR:
            return {
                ...state,
                updateEnquirySuccess: null,
                updateEnquiryError: action.payload,
                updateEnquiryReqStatus: API_CONSTANTS.ERROR_STATUS
            }
        case EnquiryActionTypes.UPDATE_ENQUIRY_REQUEST_STATUS:
            return {
                ...state,
                updateEnquiryReqStatus: ''
            }
        case EnquiryActionTypes.LOGOUT:
            return INITIAL_STATE
        default:
            return state
    }
}

export default enquiryReducer