import axios from 'axios'
import { config } from '../../config'
import { APP_CONSTANTS } from '../../utils/AppConstants'
import API_ENDPOINTS from './endpoints'

export function addSiteVisitorAnalyticsRecordAPI(payload) {
    return axios.request({
        method: 'post',
        url: `${config.baseUrl}/${API_ENDPOINTS.visitorAnalytics.addRecord}`,
        data: payload
    })
}

export function addCardVisitAnalyticsRecordAPI(payload) {
    return axios.request({
        method: 'put',
        url: `${config.baseUrl}/${API_ENDPOINTS.visitorAnalytics.cardVisitAddRecord}`,
        data: payload
    })
}

export function siteVisitorAnalyticsListAPI(payload) {
    return axios.request({
        method: 'get',
        url: `${config.baseUrl}/${API_ENDPOINTS.visitorAnalytics.recordList}`,
        params: payload
    })
}

export function siteAndUserAnalyticsListAPI(payload) {
    return axios.request({
        method: 'get',
        url: `${config.baseUrl}/${API_ENDPOINTS.visitorAnalytics.siteAndUserList}`,
        params: payload
    })
}