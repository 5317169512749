import { takeLatest, call, put } from 'redux-saga/effects'
import {
    addQrCodeAPI, deletQrCodeAPI, qrCodeListAPI, updateQrCodeAPI, qrCodeDetailAPI
} from '../api-requests/customQrCode';
import {
    addQrCodeRequestError, addQrCodeRequestSuccess, deletQrCodeRequestError, deletQrCodeRequestSuccess,
    qrCodeListRequestError, qrCodeListRequestSuccess, updateQrCodeRequestError, updateQrCodeRequestSuccess,
    qrCodeDetailRequestError, qrCodeDetailRequestSuccess
} from './customQrCode.action';
import { QRCodeActionTypes } from './customQrCode.types'
import { displayConsoleLog } from '../../utils/Utils'
import { decryptResponseData } from '../../utils/DataDecryptionUtils';
import { API_CONSTANTS, APP_CONSTANTS } from '../../utils/AppConstants';

function* handleCustomQrCodeDetailRequest({ payload }) {
    try {
        const response = yield call(qrCodeDetailAPI, payload);
        if (response.status >= 200 && response.status < 300) {
            const { data } = response
            const decryptedData = decryptResponseData(data)
            displayConsoleLog('decryptedData', decryptedData)
            if (decryptedData.data !== null) {
                yield put(qrCodeDetailRequestSuccess(decryptedData))
            } else {
                yield put(qrCodeDetailRequestError(decryptedData))
            }
        } else {
            throw response
        }
    } catch (error) {
        displayConsoleLog(error)
        if (error.response?.status === 401) {
            const errData = {
                status: error.response?.status,
                isSessionError: true,
                message: APP_CONSTANTS.SESSION_EXPIRE_MESSAGE
            }
            yield put({ type: APP_CONSTANTS.COMMON_ERROR, errData })
        } else {
            displayConsoleLog(error.response?.data)
            yield put(qrCodeDetailRequestError(error.response.data))
        }
    }
}

export function* customQrCodeDetailReq() {
    yield takeLatest(QRCodeActionTypes.QR_CODE_DETAIL_REQUEST, handleCustomQrCodeDetailRequest)
}

function* handleUpdateCustomQrCodeRequest({ payload }) {
    try {
        const response = yield call(updateQrCodeAPI, payload);
        if (response.status >= 200 && response.status < 300) {
            const { data } = response
            const decryptedData = decryptResponseData(data)
            displayConsoleLog('decryptedData', decryptedData)
            if (decryptedData.data !== null) {
                yield put(updateQrCodeRequestSuccess(decryptedData))
            } else {
                yield put(updateQrCodeRequestError(decryptedData))
            }
        } else {
            throw response
        }
    } catch (error) {
        displayConsoleLog(error)
        if (error.response?.status === 401) {
            const errData = {
                status: error.response?.status,
                isSessionError: true,
                message: APP_CONSTANTS.SESSION_EXPIRE_MESSAGE
            }
            yield put({ type: APP_CONSTANTS.COMMON_ERROR, errData })
        } else {
            displayConsoleLog(error.response?.data)
            yield put(updateQrCodeRequestError(error.response.data))
        }
    }
}

export function* updateCustomQrCodeReq() {
    yield takeLatest(QRCodeActionTypes.UPDATE_QR_CODE_REQUEST, handleUpdateCustomQrCodeRequest)
}

function* handleCustomQrCodeListRequest({ payload }) {
    try {
        const response = yield call(qrCodeListAPI, payload);
        if (response.status >= 200 && response.status < 300) {
            const { data } = response
            const decryptedData = decryptResponseData(data)
            displayConsoleLog('decryptedData', decryptedData)
            if (decryptedData.data !== null) {
                yield put(qrCodeListRequestSuccess(decryptedData))
            } else {
                yield put(qrCodeListRequestError(decryptedData))
            }
        } else {
            throw response
        }
    } catch (error) {
        displayConsoleLog(error)
        if (error.response?.status === 401) {
            const errData = {
                status: error.response?.status,
                isSessionError: true,
                message: APP_CONSTANTS.SESSION_EXPIRE_MESSAGE
            }
            yield put({ type: APP_CONSTANTS.COMMON_ERROR, errData })
        } else {
            displayConsoleLog(error.response?.data)
            yield put(qrCodeListRequestError(error.response.data))
        }
    }
}

export function* customQrCodeListReq() {
    yield takeLatest(QRCodeActionTypes.CUSTOM_QR_CODE_LIST_REQUEST, handleCustomQrCodeListRequest)
}

function* handleDeleteCustomQrCodeRequest({ payload }) {
    try {
        const response = yield call(deletQrCodeAPI, payload);
        if (response.status >= 200 && response.status < 300) {
            const { data } = response
            const decryptedData = decryptResponseData(data)
            if (decryptedData.data !== null) {
                yield put(deletQrCodeRequestSuccess(decryptedData))
            } else {
                yield put(deletQrCodeRequestError(decryptedData))
            }
        } else {
            throw response
        }
    } catch (error) {
        displayConsoleLog(error)
        if (error.response?.status === 401) {
            const errData = {
                status: error.response?.status,
                isSessionError: true,
                message: APP_CONSTANTS.SESSION_EXPIRE_MESSAGE
            }
            yield put({ type: APP_CONSTANTS.COMMON_ERROR, errData })
        } else {
            displayConsoleLog(error.response?.data)
            yield put(deletQrCodeRequestError(error.response.data))
        }
    }
}

export function* deleteCustomQrCodeReq() {
    yield takeLatest(QRCodeActionTypes.DELETE_CUSTOM_QR_CODE_REQUEST, handleDeleteCustomQrCodeRequest)
}

function* handleAddCustomQrCodeRequest({ payload }) {
    try {
        const response = yield call(addQrCodeAPI, payload);
        if (response.status >= 200 && response.status < 300) {
            const { data } = response
            const decryptedData = decryptResponseData(data)
            if (decryptedData.data !== null) {
                yield put(addQrCodeRequestSuccess(decryptedData))
            } else {
                yield put(addQrCodeRequestError(decryptedData))
            }
        } else {
            throw response
        }
    } catch (error) {
        displayConsoleLog(error)
        if (error.response?.status === 401) {
            const errData = {
                status: error.response?.status,
                isSessionError: true,
                message: APP_CONSTANTS.SESSION_EXPIRE_MESSAGE
            }
            yield put({ type: APP_CONSTANTS.COMMON_ERROR, errData })
        } else {
            displayConsoleLog(error.response?.data)
            yield put(addQrCodeRequestError(error.response.data))
        }
    }
}

export function* addCustomQrCodeReq() {
    yield takeLatest(QRCodeActionTypes.ADD_CUSTOM_QR_CODE_REQUEST, handleAddCustomQrCodeRequest)
}