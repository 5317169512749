import { takeLatest, call, put } from 'redux-saga/effects'
import {
    addPointRecordAPI, pointRecordListAPI
} from '../api-requests/hotelPoint';
import {
    addHotelPointRequestError, addHotelPointRequestSuccess, hotelPointRecordListRequestError,
    hotelPointRecordListRequestSuccess
} from './hotelPoint.action';
import { HotelPointActionTypes } from './hotelPoint.types'
import { displayConsoleLog } from '../../utils/Utils'
import { decryptResponseData } from '../../utils/DataDecryptionUtils';
import { APP_CONSTANTS } from '../../utils/AppConstants';

function* handleHotelPointListRequest({ payload }) {
    try {
        const response = yield call(pointRecordListAPI, payload);
        if (response.status >= 200 && response.status < 300) {
            const { data } = response
            const decryptedData = decryptResponseData(data)
            if (decryptedData.data !== null) {
                yield put(hotelPointRecordListRequestSuccess(decryptedData))
            } else {
                yield put(hotelPointRecordListRequestError(decryptedData))
            }
        } else {
            throw response
        }
    } catch (error) {
        displayConsoleLog(error)
        if (error.response?.status === 401) {
            const errData = {
                status: error.response?.status,
                isSessionError: true,
                message: APP_CONSTANTS.SESSION_EXPIRE_MESSAGE
            }
            yield put({ type: HotelPointActionTypes.COMMON_ERROR, errData })
        } else {
            displayConsoleLog(error.response?.data)
            yield put(hotelPointRecordListRequestError(error.response.data))
        }
    }
}

export function* hotelPointRecordListReq() {
    yield takeLatest(HotelPointActionTypes.HOTEL_POINT_RECORD_LIST_REQUEST, handleHotelPointListRequest)
}

function* handleAddHotelPointRequest({ payload }) {
    try {
        const response = yield call(addPointRecordAPI, payload);
        if (response.status >= 200 && response.status < 300) {
            const { data } = response
            const decryptedData = decryptResponseData(data)
            if (decryptedData.data !== null) {
                yield put(addHotelPointRequestSuccess(decryptedData))
            } else {
                yield put(addHotelPointRequestError(decryptedData))
            }
        } else {
            throw response
        }
    } catch (error) {
        displayConsoleLog(error)
        if (error.response?.status === 401) {
            const errData = {
                status: error.response?.status,
                isSessionError: true,
                message: APP_CONSTANTS.SESSION_EXPIRE_MESSAGE
            }
            yield put({ type: HotelPointActionTypes.COMMON_ERROR, errData })
        } else {
            displayConsoleLog(error.response?.data)
            yield put(addHotelPointRequestError(error.response.data))
        }
    }
}

export function* addHotelPointRecordReq() {
    yield takeLatest(HotelPointActionTypes.ADD_HOTEL_POINT_REQUEST, handleAddHotelPointRequest)
}