import axios from 'axios'
import { config } from '../../config'
import { APP_CONSTANTS } from '../../utils/AppConstants'
import API_ENDPOINTS from './endpoints'

export function addNewsLetterRecordAPI(payload) {
    return axios.request({
        method: 'post',
        url: `${config.baseUrl}/${API_ENDPOINTS.newsLetter.addRecord}`,
        data: payload
    })
}

export function newsLetterRecordListAPI(payload) {
    return axios.request({
        method: 'get',
        url: `${config.baseUrl}/${API_ENDPOINTS.newsLetter.recordList}`,
        params: payload
    })
}