import React, { useEffect, useState, useRef } from "react";
import './styles.css'
import Loader from '../../../components/loader/loader.component'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useDispatch, useSelector } from 'react-redux'
import moment from "moment";
import { qrCodeListRequest, deletQrCodeRequest, deletQrCodeRequestStatus, updateQrCodeRequest, updateQrCodeRequestStatus } from '../../../redux/custom-qr-code/customQrCode.action'
import { displayConsoleLog, displayErrorToast, displaySuccessToast, isEmpty, isNull } from "../../../utils/Utils";
import CommonScreenContent from "../../../components/screen/common-screen-content/commonScreenContent";
import ScreenLabel from "../../../components/label/screen-label/screenLabel";
import { API_CONSTANTS, APP_CONSTANTS } from "../../../utils/AppConstants";
import Modal from 'react-bootstrap/Modal';
// import { QRCode } from 'react-qrcode-logo';
import QRCode from 'qrcode.react';
import { RWebShare } from 'react-web-share'
// import html2canvas from 'html2canvas'
import CommonButton from "../../../components/button/common-button/commonButton";
import Input from '@mui/material/Input';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import { toSvg, toPng, toJpeg } from "html-to-image";
import download from "downloadjs";
import FormFieldLabel from "../../../components/label/form-field-label/formFieldLabel";
import FormControlLabel from '@mui/material/FormControlLabel';
import { config } from "../../../config";
import CommonInputFloatFull from "../../../components/input/common-input-float-full/commonInputFloatFull";
import CommonInputRow from "../../../components/input/common-input-row/commonInputRow";
import CommonInputFloatHalf from "../../../components/input/common-input-float-half/commonInputFloafHalf";

const CustomQrCodeList = props => {
    const [isLoading, setLoading] = useState(false)
    const { user, commonError } = useSelector((state) => state.user)
    const dispatch = useDispatch()
    const { qrCodeListSuccess, qrCodeListError, deleteQrCodeSuccess, deleteQrCodeError, deleteQrCodeReqStatus,
        updateQrCodeSuccess, updateQrCodeError, updateQrCodeReqStatus } = useSelector((state) => state.qrCode)
    const [showShareModal, setShowShareModal] = useState(false)
    const [showPreviewModal, setShowPreviewModal] = useState(false)
    const [qrCodeUrl, setQrCodeUrl] = useState('')
    const [qrCodeCustomText, setQrCodeCustomText] = useState('')
    const [forColor, setForColor] = useState('#000000')
    const [backColor, setBackColor] = useState('#ffffff')
    const [qrStyle, setQrStyle] = useState('squares')
    const [qrLogoStyle, setQrLogoStyle] = useState('square')
    const [customMessage, setCustomMessage] = useState('')
    const ariaLabel = { 'aria-label': 'description' };
    const [downloadType, setDownloadType] = useState('png')
    const svgRef = useRef(null);
    const [qrLogoImagePreview, setQrLogoImagePreview] = useState('');
    const [showUpdateQrModal, setShowUpdateQrModal] = useState(false)

    const [linkText, setLinkText] = useState('')
    const [text, setText] = useState('')
    const [phoneNumber, setPhoneNumber] = useState('')
    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [toEmail, setToEmail] = useState('')
    const [emailSubject, setEmailSubject] = useState('')
    const [emailBody, setEmailBody] = useState('')
    const [ssid, setSsid] = useState('')
    const [password, setPassword] = useState('')
    const [security, setSecurity] = useState('')
    const [qrCodetype, setQrCodeType] = useState('')
    const [qrCodeId, setQrCodeId] = useState('')
    const [qrSize, setQrSize] = useState(256)
    const [qrImgSize, setQrImgSize] = useState({ width: 35, height: 17.5 })
    const [labelSize, setLabelSize] = useState(14)
    const [customTextSize, setCustomTextSize] = useState(28)

    useEffect(() => {
        if (!isNull(commonError)) {
            if (commonError.status === 401) {
                setLoading(false)
                displayErrorToast(commonError.message)
                dispatch({ type: APP_CONSTANTS.LOGOUT })
            }
        }
    }, [commonError])

    useEffect(() => {
        fetchQrCodeList()
    }, [])

    const fetchQrCodeList = () => {
        setLoading(true)
        dispatch(qrCodeListRequest(user.data.userDetails.userId))
    }

    useEffect(() => {
        if (!isNull(qrCodeListSuccess)) {
            setLoading(false)
        } else if (!isNull(qrCodeListError)) {
            setLoading(false)
        }
    }, [qrCodeListSuccess, qrCodeListError])

    useEffect(() => {
        if (deleteQrCodeReqStatus === API_CONSTANTS.SUCCESS_STATUS) {
            setLoading(false)
            displaySuccessToast(deleteQrCodeSuccess.message)
            fetchQrCodeList()
        } else if (deleteQrCodeReqStatus === API_CONSTANTS.ERROR_STATUS) {
            setLoading(false)
            displayErrorToast(deleteQrCodeError.message)
        }
        if (deleteQrCodeReqStatus.length)
            dispatch(deletQrCodeRequestStatus(''))
    }, [deleteQrCodeSuccess, deleteQrCodeError, deleteQrCodeReqStatus])

    useEffect(() => {
        if (updateQrCodeReqStatus === API_CONSTANTS.SUCCESS_STATUS) {
            setLoading(false)
            displaySuccessToast(updateQrCodeSuccess.message)
            fetchQrCodeList()
        } else if (updateQrCodeReqStatus === API_CONSTANTS.ERROR_STATUS) {
            setLoading(false)
            displayErrorToast(updateQrCodeError.message)
        }
        if (updateQrCodeReqStatus?.length) {
            dispatch(updateQrCodeRequestStatus(''))
        }
    }, [updateQrCodeSuccess, updateQrCodeError, updateQrCodeReqStatus])

    const handleDeleteQrCodeClick = item => {
        const data = {
            qrCodeId: item.qrCodeId
        }
        setLoading(true)
        dispatch(deletQrCodeRequest(data))
    }

    const downloadQR = () => {
        if (isEmpty(downloadType)) {
            displayErrorToast('Download type not selected')
        } else {
            let rNumber = Math.floor(100 + Math.random() * 900)
            const width = svgRef.current.offsetWidth;
            if (downloadType === 'png') {
                toPng(svgRef.current, { width })
                    .then(function (dataUrl) {
                        download(dataUrl, `qr-code${rNumber}.png`);
                    })
                    .catch(function (error) {
                        console.error("oops, something went wrong!", error);
                    });
            } else if (downloadType === 'jpeg') {
                toJpeg(svgRef.current, { width })
                    .then(function (dataUrl) {
                        download(dataUrl, `qr-code${rNumber}.jpg`);
                    })
                    .catch(function (error) {
                        console.error("oops, something went wrong!", error);
                    });
            } else if (downloadType === 'svg') {
                toSvg(svgRef.current, { width })
                    .then(function (dataUrl) {
                        download(dataUrl, `qr-code${rNumber}.svg`)
                    }).catch(function (error) {
                        console.error("oops, something went wrong!", error);
                    });
            }
        }
    }

    // const downloadQR = async () => {
    //     setLoading(true)
    //     const element = document.getElementById('qrCode'),
    //         canvas = await html2canvas(element),
    //         data = canvas.toDataURL('image/jpg'),
    //         link = document.createElement('a');

    //     link.href = data;
    //     link.download = 'DigitalCardQRCode.jpg';

    //     document.body.appendChild(link);
    //     link.click();
    //     document.body.removeChild(link);
    //     setLoading(false)
    // }

    const handlePreviewClick = item => {
        setQrSize(256)
        setQrImgSize({ width: 35, height: 17.5 })
        setLabelSize(14)
        setCustomTextSize(28)
        if (item.isDynamicQr) {
            setQrCodeUrl(`${config.dynamicQrCodeUrl}/${item.qrCodeId}`)
        } else {
            setQrCodeUrl(item.qrCodeText)
        }

        if (!isEmpty(item.qrLogo) && !item.qrLogo.includes('placeholder')) {
            setQrLogoImagePreview(`${config.imageUrl}${item.qrLogo}`)
        } else {
            setQrLogoImagePreview('')
        }
        if (!isEmpty(item.label))
            setCustomMessage(item.label)
        else {
            setCustomMessage('')
        }
        setForColor(item.forColor ? item.forColor : '#000000')
        setBackColor(item.backColor ? item.backColor : '#ffffff')
        setQrStyle(item.qrStyle ? item.qrStyle : 'squares')
        setShowShareModal(true)
        displayConsoleLog(qrCodeUrl)
    }

    const handleEditClick = item => {
        setQrCodeType(item.content.type)
        setQrCodeId(item.qrCodeId)
        if (item.content.type === 'LINK' || item.content.type === 'ADDRESS' || item.content.type === 'SOCIAL' || item.content.type === 'APP STORE' || item.content.type === 'TEXT') {
            setLinkText(item.content.linkText)
        } else if (item.content.type === 'CARD') {
            setFirstName(item.content.firstName)
            setLastName(item.content.lastName)
            setPhoneNumber(item.content.phoneNumber)
        } else if (item.content.type === 'CALL') {
            setPhoneNumber(item.content.phoneNumber)
        } else if (item.content.type === 'WHATSAPP' || item.content.type === 'SMS') {
            setPhoneNumber(item.content.phoneNumber)
            setText(item.content.text)
        } else if (item.content.type === 'EMAIL') {
            setToEmail(item.content.toEmail)
            setEmailSubject(item.content.emailSubject)
            setEmailBody(item.content.emailBody)
        } else if (item.content.type === 'WIFI') {
            setSsid(item.content.ssid)
            setSecurity(item.content.security)
            setPassword(item.content.password)
        }

        if (!isEmpty(item.label))
            setCustomMessage(item.label)
        else {
            setCustomMessage('')
        }
        setForColor(item.forColor ? item.forColor : '#000000')
        setBackColor(item.backColor ? item.backColor : '#ffffff')
        setShowUpdateQrModal(true)
    }

    const handleUpdateQrClick = () => {
        if ((qrCodetype === 'LINK' || qrCodetype === 'ADDRESS' || qrCodetype === 'SOCIAL' || qrCodetype === 'APP STORE' || qrCodetype === 'TEXT') && isEmpty(linkText)) {
            displayErrorToast('Link text cannot be blank')
        } else if (qrCodetype === 'CARD' && (isEmpty(firstName) || isEmpty(lastName) || isEmpty(phoneNumber))) {
            displayErrorToast('One or more field is blank. Please check')
        } else if (qrCodetype === 'CALL' && isEmpty(phoneNumber)) {
            displayErrorToast('Phone number cannot be blank')
        } else if ((qrCodetype === 'WHATSAPP' || qrCodetype === 'SMS') && (isEmpty(phoneNumber) || isEmpty(text))) {
            displayErrorToast('One or more field is blank. Please check')
        } else if (qrCodetype === 'EMAIL' && (isEmpty(toEmail) || isEmpty(emailSubject) || isEmpty(emailBody))) {
            displayErrorToast('One or more field is blank. Please check')
        } else if (qrCodetype === 'WIFI' && (isEmpty(ssid) || isEmpty(security) || isEmpty(password))) {
            displayErrorToast('One or more field is blank. Please check')
        } else {
            const fd = new FormData()
            fd.append('qrCodeId', qrCodeId)
            fd.append('qrCodeText', getQRCodeTextValue())
            fd.append('label', !isEmpty(customMessage) ? customMessage : '')
            fd.append('forColor', forColor)
            fd.append('backColor', backColor)
            let content = {
                type: qrCodetype
            }

            if (qrCodetype === 'LINK' || qrCodetype === 'ADDRESS' || qrCodetype === 'SOCIAL' || qrCodetype === 'APP STORE' || qrCodetype === 'TEXT') {
                content.linkText = linkText
            } else if (qrCodetype === 'CARD') {
                content.firstName = firstName
                content.lastName = lastName
                content.phoneNumber = phoneNumber
            } else if (qrCodetype === 'CALL') {
                content.phoneNumber = phoneNumber
            } else if (qrCodetype === 'WHATSAPP' || qrCodetype === 'SMS') {
                content.phoneNumber = phoneNumber
                content.text = text
            } else if (qrCodetype === 'EMAIL') {
                content.toEmail = toEmail
                content.emailSubject = emailSubject
                content.emailBody = emailBody
            } else if (qrCodetype === 'WIFI') {
                content.ssid = ssid
                content.security = security
                content.password = password
            }

            fd.append('content', JSON.stringify(content))
            setShowUpdateQrModal(false)
            setLoading(true)
            dispatch(updateQrCodeRequest(fd))
        }
    }

    const vCardData = `BEGIN:VCARD
VERSION:3.0
N:${lastName};${firstName};;;
FN:${firstName} ${lastName}
TEL;TYPE=CELL:${phoneNumber}
END:VCARD`;

    const getQRCodeTextValue = () => {
        if (qrCodetype === 'LINK' || qrCodetype === 'ADDRESS' || qrCodetype === 'SOCIAL' || qrCodetype === 'APP STORE' || qrCodetype === 'TEXT') {
            return linkText
        } else if (qrCodetype === 'CARD') {
            // text1 = `data:text/vcard;charset=utf-8,${encodeURIComponent(vCardData)}`
            return vCardData.replace(/^(?=\n)$|^\s*|\s*$|\n\n+/gm, "")
        } else if (qrCodetype === 'CALL') {
            // text1 = `tel:${phoneNumber}`
            return `tel://${phoneNumber}`
        } else if (qrCodetype === 'WHATSAPP') {
            return `https://wa.me/${phoneNumber}?text=${encodeURIComponent(text)}`
        } else if (qrCodetype === 'EMAIL') {
            return `SMTP:${toEmail}:${encodeURIComponent(emailSubject)}:${encodeURIComponent(emailBody)}`;
        } else if (qrCodetype === 'SMS') {
            // text1 = `sms:${phoneNumber}?body=${encodeURIComponent(text)}`;
            return `smsto:${phoneNumber}:${encodeURIComponent(text)}`;
        } else if (qrCodetype === 'WIFI') {
            return `WIFI:S:${ssid};T:${security};P:${password};;`
        }
    }

    const renderQRCode = () => {
        if (!isEmpty(qrLogoImagePreview)) {
            return (
                <QRCode
                    value={qrCodeUrl}
                    size={qrSize < 38 ? 38 : qrSize}
                    fgColor={forColor}
                    bgColor={backColor}
                    renderAs='svg'
                    imageSettings={{
                        src: `${qrLogoImagePreview ? qrLogoImagePreview : ''}`,
                        x: undefined,
                        y: undefined,
                        height: qrImgSize.height,
                        width: qrImgSize.width
                    }}
                />
            )
        } else {
            return (<QRCode
                value={qrCodeUrl}
                size={qrSize < 38 ? 38 : qrSize}
                fgColor={forColor}
                bgColor={backColor}
                renderAs='svg'
            />)
        }
    }

    useEffect(() => {
        if (qrSize < 38) {
            displayErrorToast('QR Code size cannot be less than 38 pixels')
        }
        let newQrSize = qrSize < 38 ? 38 : qrSize
        let percentChange = Math.round((newQrSize - 38) / 38)
        let newWidth = 5 + (5 * percentChange)
        let newHeight = 2.5 + (2.5 * percentChange)
        let newLabelSize = 4 + (4 * percentChange)
        let newCustomTextSize = 8 + (8 * percentChange)
        setQrImgSize({ width: newWidth, height: newHeight })
        setLabelSize(newLabelSize / 2)
        setCustomTextSize(newCustomTextSize / 2)
    }, [qrSize])

    const getHeadingText = () => {
        if (qrCodetype === 'LINK') {
            return 'Enter Link to any Website URL'
        } else if (qrCodetype === 'CARD') {
            return 'Enter Card details'
        } else if (qrCodetype === 'TEXT') {
            return 'Enter Text to share'
        } else if (qrCodetype === 'EMAIL') {
            return 'Enter Email details'
        } else if (qrCodetype === 'WHATSAPP') {
            return 'Enter Whatsapp details'
        } else if (qrCodetype === 'SMS') {
            return 'Enter Sms details'
        } else if (qrCodetype === 'WIFI') {
            return 'Enter Wifi details'
        } else if (qrCodetype === 'ADDRESS') {
            return 'Enter Address link'
        } else if (qrCodetype === 'SOCIAL') {
            return 'Enter Social page/profile link'
        } else if (qrCodetype === 'APP STORE') {
            return 'Enter App Store link to download app from Google/iOS'
        } else if (qrCodetype === 'CALL') {
            return 'Enter Call details'
        }
    }

    const getSubText = () => {
        if (qrCodetype === 'LINK') {
            return 'Ex. Website, Url, Feedback Page, Link'
        } else if (qrCodetype === 'ADDRESS') {
            return 'Enter address link. Select TEXT if text address'
        } else if (qrCodetype === 'SOCIAL') {
            return 'Ex. https://www.facebook.com/username'
        } else if (qrCodetype === 'WHATSAPP') {
            return 'Phone number must have country code without +'
        } else {
            return ''
        }
    }

    const getDisplaySubText = () => {
        if (qrCodetype === 'LINK') {
            return true
        } else if (qrCodetype === 'ADDRESS') {
            return true
        } else if (qrCodetype === 'SOCIAL') {
            return true
        } else if (qrCodetype === 'WHATSAPP') {
            return true
        } else {
            return false
        }
    }

    return (
        <>
            <CommonScreenContent>
                <ScreenLabel label='QR Codes' />
                <div className="table-scroll-container">
                    {qrCodeListSuccess && !isNull(qrCodeListSuccess.data) && <table className="table table-hover mt-4">
                        <thead>
                            <tr>
                                <th scope="col" className="text-center">QR Type</th>
                                <th scope="col" className="text-center">Dynamic QR</th>
                                <th scope="col" className="text-center">Date</th>
                                <th scope="col" className="text-center">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {qrCodeListSuccess.data.map(element => (
                                <tr className="loc-table-row-container">
                                    <td className="loc-list-card-name">{element.content?.type}</td>
                                    <td className="loc-list-card-name">{element.isDynamicQr ? 'Yes' : 'No'}</td>
                                    <td className="loc-list-card-name">{moment(element.createdOn).utc().format('DD MMM YYYY')}</td>
                                    <td>
                                        <div className="loc-list-button-container">
                                            <div className="loc-list-cta-icon" onClick={() => { handlePreviewClick(element) }}>
                                                <img src={require('../../../assets/ic_card_preview.png')} className='img' />
                                            </div>
                                            {element.isDynamicQr && <div className="loc-list-cta-icon" onClick={() => { handleEditClick(element) }}>
                                                <img src={require('../../../assets/ic_card_edit.png')} className='img' />
                                            </div>}
                                            <div className="loc-list-cta-icon" onClick={() => { handleDeleteQrCodeClick(element) }}>
                                                <img src={require('../../../assets/ic_card_delete1.png')} className='img' />
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            ))}

                        </tbody>
                    </table>}
                </div>
                {!isNull(qrCodeListError) && <div className="mt-2">{qrCodeListError.message}</div>}
            </CommonScreenContent>
            <Modal
                onHide={() => { setShowShareModal(false) }}
                show={showShareModal}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Share QR Code
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Input className="mt-2 mb-2"
                        onChange={t => setCustomMessage(t.target.value)}
                        value={customMessage}
                        placeholder="Type Custom Message"
                        inputProps={ariaLabel}
                        fullWidth />
                    {/* <div id="qrCode" className="qr-container"> */}
                    <div className="qr-container">
                        <div id="qrCode" className="cqc-qr-container" ref={svgRef}>
                            <div className="qr-code">
                                {renderQRCode()}
                            </div>
                            <div className="cqc-powered-label" style={{ fontSize: labelSize }}>Powered By ClickTran.com</div>
                            <div className="qr-code-footer">
                                <div className="qr-footer-text" style={{ fontSize: customTextSize }}>{customMessage}</div>
                            </div>
                            {/* <QRCode value={qrCodeUrl} size={256}
                            fgColor={forColor}
                            bgColor={backColor}
                            qrStyle={qrStyle}
                        /> */}
                            {/* <div className="qr-name-label-light">{customMessage}</div> */}
                        </div>
                    </div>
                    <FormFieldLabel className='mt-3' label='Select Download Format' />
                    <RadioGroup
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="row-radio-buttons-group"
                        defaultValue={downloadType}
                    >
                        <FormControlLabel onChange={e => setDownloadType('png')} value="png" control={<Radio />} label="PNG" />
                        <FormControlLabel onChange={e => setDownloadType('jpeg')} value="jpeg" control={<Radio />} label="JPEG" />
                        <FormControlLabel onChange={e => setDownloadType('svg')} value="svg" control={<Radio />} label="SVG" />
                    </RadioGroup>
                    <CommonInputFloatFull
                        onChange={t => setQrSize(t.target.value)}
                        placeholder='Desired QR Code Size (In pixels)'
                        type='number'
                        value={qrSize}
                        isSubtext={true}
                        subText='Minimum size should be 38px (1cm)' />
                </Modal.Body>
                <Modal.Footer>
                    <CommonButton onClick={() => downloadQR()}
                        label='Download' />
                    <RWebShare
                        data={{
                            url: qrCodeUrl,
                            title: ''
                        }}
                        onClick={() => { }}
                    >
                        <CommonButton label='Share Card' />
                    </RWebShare>
                </Modal.Footer>
            </Modal>
            <Modal
                onHide={() => { setShowUpdateQrModal(false) }}
                show={showUpdateQrModal}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Update QR Code Details
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {(qrCodetype === 'LINK' || qrCodetype === 'ADDRESS' || qrCodetype === 'SOCIAL' || qrCodetype === 'APP STORE' || qrCodetype === 'TEXT') &&
                        <CommonInputFloatFull
                            onChange={t => setLinkText(t.target.value)}
                            value={linkText}
                            placeholder="Type Here"
                            subText={getSubText()}
                            isSubtext={getDisplaySubText()}
                            type='text'
                            isRequired={true}
                        />}
                    {qrCodetype === 'CARD' && <div>
                        <CommonInputRow>
                            <CommonInputFloatHalf
                                onChange={t => setFirstName(t.target.value)}
                                value={firstName}
                                placeholder="First Name"
                                isRequired={true}
                                type='text'
                            />
                            <CommonInputFloatHalf
                                onChange={t => setLastName(t.target.value)}
                                value={lastName}
                                placeholder="Last Name"
                                isRequired={true}
                                type='text'
                            />
                        </CommonInputRow>
                        <CommonInputFloatFull
                            onChange={t => setPhoneNumber(t.target.value)}
                            value={phoneNumber}
                            placeholder="Phone Number"
                            subText={getSubText()}
                            isSubtext={getDisplaySubText()}
                            type='number'
                            isRequired={true}
                        />
                    </div>}
                    {qrCodetype === 'CALL' && <CommonInputFloatFull
                        onChange={t => setPhoneNumber(t.target.value)}
                        value={phoneNumber}
                        placeholder="Phone Number"
                        subText={getSubText()}
                        isSubtext={getDisplaySubText()}
                        isRequired={true}
                        type='number'
                    />}
                    {qrCodetype === 'WHATSAPP' && <div>
                        <CommonInputFloatFull
                            onChange={t => setPhoneNumber(t.target.value)}
                            value={phoneNumber}
                            placeholder="Phone Number"
                            subText={getSubText()}
                            isSubtext={getDisplaySubText()}
                            isRequired={true}
                            type='number'
                        />
                        <CommonInputFloatFull
                            onChange={t => setText(t.target.value)}
                            value={text}
                            placeholder="Message"
                            type='text'
                        />
                    </div>}
                    {qrCodetype === 'EMAIL' && <div>
                        <CommonInputFloatFull
                            onChange={t => setToEmail(t.target.value)}
                            value={toEmail}
                            placeholder="To"
                            type='text'
                            isRequired={true}
                        />
                        <CommonInputFloatFull
                            onChange={t => setEmailSubject(t.target.value)}
                            value={emailSubject}
                            placeholder="Subject"
                            type='text'
                            isRequired={true}
                        />
                        <CommonInputFloatFull
                            onChange={t => setEmailBody(t.target.value)}
                            value={emailBody}
                            placeholder="Body"
                            type='text'
                            isRequired={true}
                        />
                    </div>}
                    {qrCodetype === 'SMS' && <div>
                        <CommonInputFloatFull
                            onChange={t => setPhoneNumber(t.target.value)}
                            value={phoneNumber}
                            placeholder="Phone Number"
                            type='number'
                            isRequired={true}
                        />
                        <CommonInputFloatFull
                            onChange={t => setText(t.target.value)}
                            value={text}
                            placeholder="Message"
                            type='text'
                            isRequired={true}
                        />
                    </div>}
                    {qrCodetype === 'WIFI' && <div>
                        <CommonInputFloatFull
                            onChange={t => setSsid(t.target.value)}
                            value={ssid}
                            placeholder="SSID"
                            type='text'
                            isRequired={true}
                        />
                        <CommonInputFloatFull
                            onChange={t => setPassword(t.target.value)}
                            value={password}
                            placeholder="Password"
                            type='text'
                            isRequired={true}
                        />
                        <CommonInputFloatFull
                            onChange={t => setSecurity(t.target.value)}
                            value={security}
                            placeholder="Security (Ex. WPA/WEP/None)"
                            type='text'
                            isRequired={true}
                        />
                    </div>}
                    <CommonInputFloatFull
                        onChange={t => setCustomMessage(t.target.value)}
                        value={customMessage}
                        placeholder="Type Custom Message"
                        type='text'
                    />

                    {/* <FormFieldLabel label='Select QR Code Color' />
                    <RadioGroup
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="row-radio-buttons-group"
                        defaultValue={forColor}
                    >
                        <FormControlLabel onChange={e => setForColor('#000000')} value="#000000" control={<Radio />} label="Black" />
                        <FormControlLabel onChange={e => setForColor('#ffffff')} value="#ffffff" control={<Radio />} label="White" />
                        <FormControlLabel onChange={e => setForColor('#9e9e9e')} value="#9e9e9e" control={<Radio />} label="Grey" />
                        <FormControlLabel onChange={e => setForColor('#2196f3')} value="#2196f3" control={<Radio />} label="Blue" />
                        <FormControlLabel onChange={e => setForColor('#ff9800')} value="#ff9800" control={<Radio />} label="Orange" />
                        <FormControlLabel onChange={e => setForColor('#f44336')} value="#f44336" control={<Radio />} label="Red" />
                        <FormControlLabel onChange={e => setForColor('#4caf50')} value="#4caf50" control={<Radio />} label="Green" />
                        <FormControlLabel onChange={e => setForColor('#ffeb3b')} value="#ffeb3b" control={<Radio />} label="Yellow" />

                    </RadioGroup>
                    <FormFieldLabel className='mt-3' label='QR Code Background Color' />
                    <RadioGroup
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="row-radio-buttons-group"
                        defaultValue={backColor}
                    >
                        <FormControlLabel onChange={e => setBackColor('#000000')} value="#000000" control={<Radio />} label="Black" />
                        <FormControlLabel onChange={e => setBackColor('#ffffff')} value="#ffffff" control={<Radio />} label="White" />
                        <FormControlLabel onChange={e => setBackColor('#9e9e9e')} value="#9e9e9e" control={<Radio />} label="Grey" />
                        <FormControlLabel onChange={e => setBackColor('#2196f3')} value="#2196f3" control={<Radio />} label="Blue" />
                        <FormControlLabel onChange={e => setBackColor('#ff9800')} value="#ff9800" control={<Radio />} label="Orange" />
                        <FormControlLabel onChange={e => setBackColor('#f44336')} value="#f44336" control={<Radio />} label="Red" />
                        <FormControlLabel onChange={e => setBackColor('#4caf50')} value="#4caf50" control={<Radio />} label="Green" />
                        <FormControlLabel onChange={e => setBackColor('#ffeb3b')} value="#ffeb3b" control={<Radio />} label="Yellow" />

                    </RadioGroup> */}
                    {/* <div className="qr-container">
                        <div id="qrCode" className="cqc-qr-container" ref={svgRef}>
                            <div className="qr-code">
                                {renderQRCode()}
                            </div>
                            <div className="cqc-powered-label">Powered By ClickTran.com</div>
                            <div className="qr-code-footer">
                                <div className="qr-footer-text">{customMessage}</div>
                            </div>
                        </div>
                    </div> */}
                </Modal.Body>
                <Modal.Footer>
                    <CommonButton onClick={() => handleUpdateQrClick()}
                        label='Update' />
                </Modal.Footer>
            </Modal>
            <ToastContainer />
            <Loader active={isLoading} text='Please wait...' />
        </>
    )
}

export default CustomQrCodeList