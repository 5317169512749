export const FeedbackActionTypes = {
    LOGOUT: 'LOGOUT',

    ADD_FEEDBACK_REQUEST: 'ADD_FEEDBACK_REQUEST',
    ADD_FEEDBACK_REQUEST_SUCCESS: 'ADD_FEEDBACK_REQUEST_SUCCESS',
    ADD_FEEDBACK_REQUEST_ERROR: 'ADD_FEEDBACK_REQUEST_ERROR',
    ADD_FEEDBACK_REQUEST_STATUS: 'ADD_FEEDBACK_REQUEST_STATUS',
    
    COMMON_ERROR: 'COMMON_ERROR',
}
