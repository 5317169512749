import React, { useEffect, useState } from 'react'
import './styles.css'
import Loader from '../../../../components/loader/loader.component'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useDispatch, useSelector } from 'react-redux'
import {
    clearLocalStorage,
    displayErrorToast, displaySuccessToast, isNull, screenCaptureAnalytics
} from '../../../../utils/Utils'
import { useNavigate } from 'react-router-dom';
import { APP_CONSTANTS, ROUTES } from '../../../../utils/AppConstants';
import { loginUserRequest, displaySideBarAct } from '../../../../redux/user/user.action'
import CommonScreenContent from '../../../../components/screen/common-screen-content/commonScreenContent';
import CommonScreenContentCenter from '../../../../components/screen/common-screen-content-center/commonScreenContentCenter';
import CommonColorForm from '../../../../components/common-color-form/commonColorForm';
import SubTextLabel from '../../../../components/label/sub-text-label/subTextLabel';
import CommonInputFull from '../../../../components/input/common-input-full/commonInputFull';
import ButtonRow from '../../../../components/button/button-row/buttonRow';
import CommonButton from '../../../../components/button/common-button/commonButton';
import CommonInputFloatFull from '../../../../components/input/common-input-float-full/commonInputFloatFull';
import Paper from '@mui/material/Paper';

const CoporateLoginInScreen = props => {
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [isLoading, setLoading] = useState(false)
    const { user, isLogin, loginErrorData } = useSelector((state) => state.user)
    const dispatch = useDispatch()
    let navigate = useNavigate();

    useEffect(() => {
        const data = {
            screen: 'SIGN_IN',
            screenClass: 'SIGN_IN'
        }
        screenCaptureAnalytics(data)
    }, [])

    useEffect(() => {
        sessionStorage.clear()
        clearLocalStorage()
        dispatch({ type: APP_CONSTANTS.LOGOUT })
    }, [])

    useEffect(() => {
        if (isLogin === true) {
            setLoading(false)
            displaySuccessToast('Login successfull')
            sessionStorage.setItem(APP_CONSTANTS.USER, JSON.stringify(user.data))
            sessionStorage.setItem(APP_CONSTANTS.USER_TOKEN, user.data.authToken)
            dispatch(displaySideBarAct(true))
            navigate(ROUTES.employeeList, { replace: true })
        } else {
            if (!isNull(loginErrorData)) {
                setLoading(false)
                displayErrorToast(loginErrorData.message)
            }
        }
    }, [user, isLogin, loginErrorData])

    const handleSignIn = () => {
        if (email.length && password.length) {
            setLoading(true)
            const data = {
                email: email,
                password: password
            }
            dispatch(loginUserRequest(data))
        } else {
            displayErrorToast('Email or password cannot be blank', true)
        }
    }

    const handleForgotPassClick = () => {
        navigate(ROUTES.forgotPassword)
    }

    const handlePasswordChange = e => {
        const text = e.target.value
        setPassword(text)
    }

    const handleEmailChange = e => {
        const text = e.target.value
        setEmail(text)
    }

    const createAccountClick = () => {
        navigate(ROUTES.corporateCreateAccount)
    }

    return (
        <>
            <CommonScreenContentCenter>
                <div className="logo-img-contain">
                    <img src={require('../../../../assets/nLogo2.png')} className="img" />
                </div>
                <Paper elevation={3} style={{ background: '#fbfbfb' }} className='ac-card paper-container'>
                    <SubTextLabel label='Login with your registered coporate credentials' />
                    <CommonInputFloatFull
                        type="email"
                        id="email"
                        placeholder="Email Id"
                        value={email}
                        isRequired={true}
                        onChange={handleEmailChange} />
                    <CommonInputFloatFull
                        type="password"
                        id="password"
                        value={password}
                        onChange={handlePasswordChange}
                        placeholder='Password' />
                    <div className='forgot-password-label mb-3' onClick={() => handleForgotPassClick()}>Forgot Password</div>
                    <ButtonRow>
                        <CommonButton
                            label='Log In'
                            onClick={() => handleSignIn()} />
                        <CommonButton
                            label='Create Account'
                            isInverted={true}
                            onClick={() => createAccountClick()} />
                    </ButtonRow>
                </Paper>
            </CommonScreenContentCenter>
            <ToastContainer />
            <Loader active={isLoading} text='Please wait...' />
        </>
    )
}

export default CoporateLoginInScreen