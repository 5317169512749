export const VisitorBookActionTypes = {
    LOGOUT: 'LOGOUT',
    COMMON_ERROR: 'COMMON_ERROR',

    VISITOR_ADD_RECORD_REQUEST: 'VISITOR_ADD_RECORD_REQUEST',
    VISITOR_ADD_RECORD_REQUEST_SUCCESS: 'VISITOR_ADD_RECORD_REQUEST_SUCCESS',
    VISITOR_ADD_RECORD_REQUEST_ERROR: 'VISITOR_ADD_RECORD_REQUEST_ERROR',
    VISITOR_ADD_RECORD_REQUEST_STATUS: 'VISITOR_ADD_RECORD_REQUEST_STATUS',

    VISITOR_RECORD_LIST_REQUEST: 'VISITOR_RECORD_LIST_REQUEST',
    VISITOR_RECORD_LIST_REQUEST_SUCCESS: 'VISITOR_RECORD_LIST_REQUEST_SUCCESS',
    VISITOR_RECORD_LIST_REQUEST_ERROR: 'VISITOR_RECORD_LIST_REQUEST_ERROR',

    VISITOR_MARK_EXIT_REQUEST: 'VISITOR_MARK_EXIT_REQUEST',
    VISITOR_MARK_EXIT_REQUEST_SUCCESS: 'VISITOR_MARK_EXIT_REQUEST_SUCCESS',
    VISITOR_MARK_EXIT_REQUEST_ERROR: 'VISITOR_MARK_EXIT_REQUEST_ERROR',
    VISITOR_MARK_EXIT_REQUEST_STATUS: 'VISITOR_MARK_EXIT_REQUEST_STATUS'
}
