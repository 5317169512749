import React, { useEffect, useState, useRef } from "react";
import CommonScreenContent from "../../../components/screen/common-screen-content/commonScreenContent";
import './styles.css'
import Loader from '../../../components/loader/loader.component'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ScreenLabel from "../../../components/label/screen-label/screenLabel";
import CommonInputRow from "../../../components/input/common-input-row/commonInputRow";
import TopButton from "../../../components/button/top-button/topButton";
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom';
import moment from "moment";
import { API_CONSTANTS, APP_CONSTANTS, ROUTES } from "../../../utils/AppConstants";
import { displayConsoleLog, displayErrorToast, displaySuccessToast, isEmpty, isNull } from "../../../utils/Utils";
import { digitalCardListRequest, deleteCardRequest, deleteCardRequestStatus } from '../../../redux/digital-card/digitalCard.action'
import { config } from '../../../config'
import Modal from 'react-bootstrap/Modal';
import CommonButton from "../../../components/button/common-button/commonButton";
// import { QRCode } from 'react-qrcode-logo';
import QRCode from 'qrcode.react';
import CommonInputFull from "../../../components/input/common-input-full/commonInputFull";
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
import { customerCardRequest, customerCardRequestStatus } from '../../../redux/user/user.action'
import { RWebShare } from 'react-web-share'
import html2canvas from 'html2canvas'
import UserCardPreview from "../user-card/userCardPreview";
import Input from '@mui/material/Input';
import CustomFab from "../../../components/custom-fab/customFab";
import { FcAbout, FcBusinessman, FcCamera, FcFullTrash } from "react-icons/fc"
import FormFieldLabel from "../../../components/label/form-field-label/formFieldLabel";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import { toSvg, toPng, toJpeg } from "html-to-image";
import download from "downloadjs";
import FormControlLabel from '@mui/material/FormControlLabel';
import CommonInputFloatFull from "../../../components/input/common-input-float-full/commonInputFloatFull";
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';

const CardList = props => {
    const [isLoading, setLoading] = useState(false)
    const { user, customerCardRequestSuccess, customerCardRequestError, customerCardRequestReqStatus, commonError } = useSelector((state) => state.user)
    const { cardListSuccess, cardListError,
        deleteCardSuccess, deleteCardError, deleteCardReqStatus } = useSelector((state) => state.digitalCard)
    const dispatch = useDispatch()
    let navigate = useNavigate();
    const [qrCodeUrl, setQRCodeUrl] = useState('')
    const [showQRCodeDialog, setShowQRCodeDialog] = useState(false)
    const [showErrorDialog, setShowErrorDialog] = useState(false)
    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [mobile, setMobile] = useState()
    const [fullName, setFullName] = useState('')
    const [showAddCardDialog, setShowAddCardDialog] = useState(false)
    const [showCardPreview, setShowCardPreview] = useState(false)
    const [previewCardObj, setPreviewCardObj] = useState(null)
    const [cardNickName, setCardNickName] = useState('')
    const [customMessage, setCustomMessage] = useState('')
    const ariaLabel = { 'aria-label': 'description' };
    const [downloadType, setDownloadType] = useState('png')
    const svgRef = useRef(null);
    const [requiredCardCount, setRequiredCardCount] = useState('')
    const [qrSize, setQrSize] = useState(256)
    const [qrImgSize, setQrImgSize] = useState({ width: 35, height: 17.5 })
    const [labelSize, setLabelSize] = useState(14)
    const [customTextSize, setCustomTextSize] = useState(28)

    useEffect(() => {
        if (!isNull(commonError)) {
            if (commonError.status === 401) {
                setLoading(false)
                displayErrorToast(commonError.message)
                dispatch({ type: APP_CONSTANTS.LOGOUT })
            }
        }
    }, [commonError])

    useEffect(() => {
        setShowAddCardDialog(false)
        fetchCardList()
    }, [])

    const fetchCardList = () => {
        setLoading(true)
        const data = {
            userId: user.data.userDetails.userId
        }
        dispatch(digitalCardListRequest(data))
    }

    useEffect(() => {
        if (!isNull(cardListSuccess)) {
            setLoading(false)
        } else if (!isNull(cardListError)) {
            setLoading(false)
            if (isEmpty(localStorage.getItem("addCardDialog"))) {
                setShowAddCardDialog(true)
                localStorage.setItem("addCardDialog", "YES")
            }
        }
    }, [cardListSuccess, cardListError])

    useEffect(() => {
        if (customerCardRequestReqStatus === API_CONSTANTS.SUCCESS_STATUS) {
            setLoading(false)
            displaySuccessToast(customerCardRequestSuccess.message)
            setName('')
            setEmail('')
            setMobile()
        } else if (customerCardRequestReqStatus === API_CONSTANTS.ERROR_STATUS) {
            setLoading(false)
            displayErrorToast(customerCardRequestError.message)
        }
        if (customerCardRequestReqStatus?.length) {
            dispatch(customerCardRequestStatus(''))
        }
    }, [customerCardRequestSuccess, customerCardRequestError, customerCardRequestReqStatus])

    useEffect(() => {
        if (deleteCardReqStatus === API_CONSTANTS.SUCCESS_STATUS) {
            setLoading(false)
            displaySuccessToast(deleteCardSuccess.message)
            fetchCardList()
        } else if (deleteCardReqStatus === API_CONSTANTS.ERROR_STATUS) {
            setLoading(false)
            displayErrorToast(deleteCardError.message)
        }
        if (deleteCardReqStatus.length)
            dispatch(deleteCardRequestStatus(''))
    }, [deleteCardSuccess, deleteCardError, deleteCardReqStatus])

    const handleAddCard = () => {
        if (!isNull(cardListError)) {
            navigate(ROUTES.addCard)
        } else if (!isNull(cardListSuccess) && isEmpty(cardListSuccess.data)) {
            navigate(ROUTES.addCard)
        } else if (!isNull(cardListSuccess) && !isEmpty(cardListSuccess.data) && cardListSuccess.data.length < 2) {
            navigate(ROUTES.addCard)
        } else {
            setShowErrorDialog(true)
        }
    }

    const handleViewDetail = item => {
        navigate(ROUTES.cardDetail, { state: item.userAlias })
    }

    const handlePreviewClick = item => {
        displayConsoleLog(`${config.qrCodeUrl}/${item.userAlias}`)
        setPreviewCardObj(item)
        setQRCodeUrl(`${config.qrCodeUrl}/${item.userAlias}`)
        setShowCardPreview(true)
    }

    const handleShareClick = item => {
        setQrSize(256)
        setQrImgSize({ width: 35, height: 17.5 })
        setLabelSize(14)
        setCustomTextSize(28)
        setCardNickName(item.cardName)
        setFullName(`${item.cardDetails[0].firstName ? item.cardDetails[0].firstName : ''} ${item.cardDetails[0].lastName ? item.cardDetails[0].lastName : ''}`)
        setQRCodeUrl(`${config.qrCodeUrl}/${item.userAlias}`)
        displayConsoleLog(`${config.qrCodeUrl}/${item.userAlias}`)
        setShowQRCodeDialog(true)
    }

    const handleDeleteClick = item => {
        const data = {
            digitalCardId: item.digitalCardId,
            digitalCardDetailId: item.cardDetails[0].digitalCardDetailId
        }
        setLoading(true)
        dispatch(deleteCardRequest(data))
    }

    const handleGetNFCClick = item => {
        navigate(ROUTES.nfcTemplateSelection, {
            state: {
                cardId: item.digitalCardId,
                alias: item.userAlias
            }
        })
    }

    const handleQRCodeDialogClose = () => {
        setShowQRCodeDialog(false)
    }

    const handleRequestSubmitClick = () => {
        if (isEmpty(name)) {
            displayErrorToast('Full name cannot be blank')
        } else if (isEmpty(email)) {
            displayErrorToast('Email cannot be blank')
        } else if (isEmpty(mobile)) {
            displayErrorToast('Mobile number cannot be blank')
        } else if (isEmpty(requiredCardCount)) {
            displayErrorToast('Number of cards required not selected')
        } else {
            setShowErrorDialog(false)
            setLoading(true)
            const mobileWithoutPlusSymbol = mobile?.replace('+', "")
            const data = {
                name: name,
                email: email,
                mobile: parseInt(mobileWithoutPlusSymbol),
                requiredCard: requiredCardCount
            }
            dispatch(customerCardRequest(data))
        }
    }

    const downloadQR = () => {
        if (isEmpty(downloadType)) {
            displayErrorToast('Download type not selected')
        } else {
            const width = svgRef.current.offsetWidth;
            let rNumber = Math.floor(100 + Math.random() * 900)
            if (downloadType === 'png') {
                toPng(svgRef.current, { width })
                    .then(function (dataUrl) {
                        download(dataUrl, `qr-code${rNumber}.png`);
                    })
                    .catch(function (error) {
                        console.error("oops, something went wrong!", error);
                    });
            } else if (downloadType === 'jpeg') {
                toJpeg(svgRef.current, { width })
                    .then(function (dataUrl) {
                        download(dataUrl, `qr-code${rNumber}.jpg`);
                    })
                    .catch(function (error) {
                        console.error("oops, something went wrong!", error);
                    });
            } else if (downloadType === 'svg') {
                toSvg(svgRef.current, { width })
                    .then(function (dataUrl) {
                        download(dataUrl, `qr-code${rNumber}.svg`)
                    }).catch(function (error) {
                        console.error("oops, something went wrong!", error);
                    });
            }
        }
        // setLoading(true)
        // const element = document.getElementById('qrCode'),
        //     canvas = await html2canvas(element),
        //     data = canvas.toDataURL('image/jpg'),
        //     link = document.createElement('a');

        // link.href = data;
        // link.download = 'DigitalCardQRCode.jpg';

        // document.body.appendChild(link);
        // link.click();
        // document.body.removeChild(link);
        // setLoading(false)
    }

    const action = [
        { label: 'Create Card', icon: require('../../../assets/ic_float_icon.png'), onClick: () => handleAddCard() },
        { label: 'Generate Custom QR Code', icon: require('../../../assets/ic_float_qr.png'), onClick: () => navigate(ROUTES.generateQrCode) }
    ]

    const displayProfileImage = (cardObj) => {
        let flag = false
        if (!isNull(cardObj)) {
            if ((!isEmpty(cardObj.profileImage) && !cardObj.profileImage.includes('placeholder'))) {
                flag = true
            }
        }
        return flag
    }

    const displayLogoImage = (cardObj) => {
        let flag = false
        if (!isNull(cardObj)) {
            if ((!isEmpty(cardObj.brandLogoImg) && !cardObj.brandLogoImg.includes('placeholder'))) {
                flag = true
            }
        }
        return flag
    }

    useEffect(() => {
        if (qrSize < 38) {
            displayErrorToast('QR Code size cannot be less than 38 pixels')
        }
        let newQrSize = qrSize < 38 ? 38 : qrSize
        let percentChange = Math.round((newQrSize - 38) / 38)
        let newWidth = 5 + (5 * percentChange)
        let newHeight = 2.5 + (2.5 * percentChange)
        let newLabelSize = 4 + (4 * percentChange)
        let newCustomTextSize = 8 + (8 * percentChange)
        setQrImgSize({ width: newWidth, height: newHeight })
        setLabelSize(newLabelSize / 2)
        setCustomTextSize(newCustomTextSize / 2)
    }, [qrSize])

    return (
        <>
            <CommonScreenContent>
                <div className="mt-2 mb-1">
                    <CommonInputRow>
                        <ScreenLabel label='Card List' />
                        {/* <TopButton label='Create Card'
                            onClick={() => { handleAddCard() }} /> */}
                    </CommonInputRow>
                    <div className="table-scroll-container">
                        {cardListSuccess && !isNull(cardListSuccess.data) && <table className="table table-hover mt-4">
                            <thead>
                                <tr>
                                    <th scope="col" className="text-center">Card Name</th>
                                    {/* <th scope="col" className="text-center">Card URL</th>
                                    <th scope="col">Date</th> */}
                                    <th scope="col" className="text-center">Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {cardListSuccess.data.map(element => (
                                    <tr className="loc-table-row-container">
                                        <td className="loc-list-card-name">{element.cardName}</td>
                                        {/* <td className="loc-list-card-name">skdfnlksnflknsd sdfndklflkdl</td> */}
                                        {/* <td>{`${config.qrCodeUrl}/${element.userAlias}`}</td>
                                        <td>{moment(element.createdOn).utc().format('DD MMM YYYY')}</td> */}
                                        <td>
                                            <div className="loc-list-button-container">
                                                <div className="loc-list-cta-icon" onClick={() => { handlePreviewClick(element) }}>
                                                    <img src={require('../../../assets/ic_card_preview.png')} className='img' />
                                                </div>
                                                <div className="loc-list-cta-icon" onClick={() => { handleShareClick(element) }}>
                                                    <img src={require('../../../assets/ic_card_share.png')} className='img' />
                                                </div>
                                                <div className="loc-list-cta-icon" onClick={() => { handleViewDetail(element) }}>
                                                    <img src={require('../../../assets/ic_card_edit.png')} className='img' />
                                                </div>
                                                <div className="loc-list-cta-icon" onClick={() => { handleGetNFCClick(element) }}>
                                                    <img src={require('../../../assets/ic_nfc_icon1.png')} className='img' />
                                                </div>
                                                <div className="loc-list-cta-icon" onClick={() => { handleDeleteClick(element) }}>
                                                    <img src={require('../../../assets/ic_card_delete1.png')} className='img' />
                                                </div>
                                                {/* <div className="loc-list-button ms-2" onClick={() => { handleShareClick(element) }}>Share</div>
                                                <div className="loc-list-button" onClick={() => { handleViewDetail(element) }}>Edit</div>
                                                <div className="loc-list-button" onClick={() => { handleDeleteClick(element) }}>Delete</div> */}
                                            </div>
                                        </td>
                                    </tr>
                                ))}

                            </tbody>
                        </table>}
                    </div>
                    {!isNull(cardListError) && <div className="mt-2">{cardListError.message}</div>}
                </div>
                {/* <CustomFab actions={action} /> */}
                {/* <div className="cl-fab-container" onClick={() => handleAddCard()}>
                    <img src={require('../../../assets/ic_float_icon.png')} className="cl-fab-icon" />
                </div> */}
                <div className="round-fab-container" onClick={() => handleAddCard()}>
                    <div className="round-fab-container-icon">
                        <img src={require('../../../assets/ic_plus_icon.png')} className="img" />
                    </div>

                </div>
            </CommonScreenContent>
            <Modal
                onHide={() => { handleQRCodeDialogClose() }}
                show={showQRCodeDialog}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Share Card
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Input className="mt-2 mb-2"
                        onChange={t => setCustomMessage(t.target.value)}
                        value={customMessage}
                        placeholder="Type Custom Message"
                        inputProps={ariaLabel}
                        fullWidth />
                    <div id="qrCode" className="qr-container">
                        <div className="cqc-qr-container" ref={svgRef}>
                            <div className="qr-name-label">{cardNickName}</div>
                            <QRCode value={qrCodeUrl} size={qrSize < 38 ? 38 : qrSize}
                                renderAs='svg'
                                imageSettings={{
                                    src: require('../../../assets/nLogo2.png'),
                                    height: qrImgSize.height,
                                    width: qrImgSize.width,
                                    excavate: true
                                }}

                            />
                            <div className="cqc-powered-label" style={{ fontSize: labelSize }}>Powered By ClickTran.com</div>
                            <div className="qr-code-footer">
                                <div className="qr-footer-text" style={{ fontSize: customTextSize }}>{customMessage}</div>
                            </div>
                        </div>
                    </div>

                    <FormFieldLabel className='margin-top-10' label='Select Download Format' />
                    <RadioGroup
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="row-radio-buttons-group"
                        defaultValue={downloadType}
                    >
                        <FormControlLabel onChange={e => setDownloadType('png')} value="png" control={<Radio />} label="PNG" />
                        <FormControlLabel onChange={e => setDownloadType('jpeg')} value="jpeg" control={<Radio />} label="JPEG" />
                        <FormControlLabel onChange={e => setDownloadType('svg')} value="svg" control={<Radio />} label="SVG" />
                    </RadioGroup>
                    <CommonInputFloatFull
                        onChange={t => setQrSize(t.target.value)}
                        placeholder='Desired QR Code Size (In pixels)'
                        type='number'
                        value={qrSize}
                        isSubtext={true}
                        subText='Minimum size should be 38px (1cm)' />
                </Modal.Body>
                <Modal.Footer>
                    <CommonButton onClick={() => downloadQR()}
                        label='Download' />
                    <RWebShare
                        data={{
                            url: qrCodeUrl,
                            title: fullName
                        }}
                        onClick={() => { }}
                    >
                        <CommonButton label='Share Card' />
                    </RWebShare>
                </Modal.Footer>
            </Modal>
            <Modal
                onHide={() => { setShowErrorDialog(false) }}
                show={showErrorDialog}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Additional ClickTran cards
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>Currently limited to 2 digital cards.</div>
                    <div>For additional digital cards, please send request.</div>
                    <div className="mt-3" />
                    <CommonInputFloatFull
                        id='name'
                        onChange={e => setName(e.target.value)}
                        placeholder='Full Name'
                        type='text'
                        value={name} />
                    <CommonInputFloatFull
                        id='email'
                        onChange={e => setEmail(e.target.value)}
                        placeholder='Email Id'
                        type='email'
                        value={name} />
                    <div className="mt-3 mb-3 w-100">
                        <PhoneInput
                            defaultCountry="US"
                            placeholder="Mobile Number"
                            value={mobile}
                            onChange={setMobile} />
                    </div>
                    <div className="mb-3 w-100">
                        <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">No. of Cards</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={requiredCardCount}
                                onChange={e => setRequiredCardCount(e.target.value)}
                                autoWidth
                                label="No. of Cards"
                            >
                                <MenuItem value="" disabled>
                                    <em>None</em>
                                </MenuItem>
                                <MenuItem value='1'>1</MenuItem>
                                <MenuItem value='2'>2</MenuItem>
                                <MenuItem value='3'>3</MenuItem>
                                <MenuItem value='4'>4</MenuItem>
                                <MenuItem value='5'>5</MenuItem>
                                <MenuItem value='6'>6</MenuItem>
                                <MenuItem value='7'>7</MenuItem>
                                <MenuItem value='8'>8</MenuItem>
                                <MenuItem value='9'>9</MenuItem>
                                <MenuItem value='10'>10</MenuItem>
                                <MenuItem value='10+'>10+</MenuItem>
                            </Select>
                        </FormControl>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <CommonButton onClick={() => handleRequestSubmitClick()}
                        label='Submit' />
                </Modal.Footer>
            </Modal>
            <Modal
                onHide={() => { setShowAddCardDialog(false) }}
                show={showAddCardDialog}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Welcome
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>You do not have ClickTran card to share. Do you want to create new ClickTran card, click continue.</div>
                </Modal.Body>
                <Modal.Footer>
                    <CommonButton onClick={() => handleAddCard()}
                        label='Continue' />
                </Modal.Footer>
            </Modal>
            <Modal
                onHide={() => { setShowCardPreview(false) }}
                show={showCardPreview}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        {!isNull(previewCardObj) ? `${previewCardObj.cardName}` : ''}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {previewCardObj && <UserCardPreview
                        qrSize={180}
                        showQrBorder={true}
                        isApiData={true}
                        qrCodeUrl={qrCodeUrl}
                        showProfileImage={displayProfileImage(previewCardObj.cardDetails[0])}
                        showBrandLogo={displayLogoImage(previewCardObj.cardDetails[0])}
                        cardObject={previewCardObj.cardDetails[0]}
                    />}
                </Modal.Body>
            </Modal>
            <ToastContainer />
            <Loader active={isLoading} text='Please wait...' />
        </>
    )
}

export default CardList