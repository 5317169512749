import { AppointmentActionTypes } from './appointment.types'
import { API_CONSTANTS } from '../../utils/AppConstants'

const INITIAL_STATE = {
    addAppointmentSuccess: null,
    addAppointmentError: null,
    addAppointmentReqStatus: '',
    appointmentListSuccess: null,
    appointmentListError: null,
    updateAppointmentSuccess: null,
    updateAppointmentError: null,
    updateAppointmentReqStatus: ''
}

const appointmentReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case AppointmentActionTypes.ADD_APPOINTMENT_REQUEST_SUCCESS:
            return {
                ...state,
                addAppointmentSuccess: action.payload,
                addAppointmentError: null,
                addAppointmentReqStatus: API_CONSTANTS.SUCCESS_STATUS
            }
        case AppointmentActionTypes.ADD_APPOINTMENT_REQUEST_ERROR:
            return {
                ...state,
                addAppointmentSuccess: null,
                addAppointmentError: action.payload,
                addAppointmentReqStatus: API_CONSTANTS.ERROR_STATUS
            }
        case AppointmentActionTypes.ADD_APPOINTMENT_REQUEST_STATUS:
            return {
                ...state,
                addAppointmentReqStatus: ''
            }
        case AppointmentActionTypes.APPOINTMENT_LIST_REQUEST_SUCCESS:
            return {
                ...state,
                appointmentListSuccess: action.payload,
                appointmentListError: null
            }
        case AppointmentActionTypes.APPOINTMENT_LIST_REQUEST_ERROR:
            return {
                ...state,
                appointmentListSuccess: null,
                appointmentListError: action.payload
            }
        case AppointmentActionTypes.UPDATE_APPOINTMENT_REQUEST_SUCCESS:
            return {
                ...state,
                updateAppointmentSuccess: action.payload,
                updateAppointmentError: null,
                updateAppointmentReqStatus: API_CONSTANTS.SUCCESS_STATUS
            }
        case AppointmentActionTypes.UPDATE_APPOINTMENT_REQUEST_ERROR:
            return {
                ...state,
                updateAppointmentSuccess: null,
                updateAppointmentError: action.payload,
                updateAppointmentReqStatus: API_CONSTANTS.ERROR_STATUS
            }
        case AppointmentActionTypes.UPDATE_APPOINTMENT_REQUEST_STATUS:
            return {
                ...state,
                updateAppointmentReqStatus: ''
            }
        case AppointmentActionTypes.LOGOUT:
            return INITIAL_STATE
        default:
            return state
    }
}

export default appointmentReducer