export const ServiceActionTypes = {
    LOGOUT: 'LOGOUT',

    ADD_SERVICE_REQUEST: 'ADD_SERVICE_REQUEST',
    ADD_SERVICE_REQUEST_SUCCESS: 'ADD_SERVICE_REQUEST_SUCCESS',
    ADD_SERVICE_REQUEST_ERROR: 'ADD_SERVICE_REQUEST_ERROR',
    ADD_SERVICE_REQUEST_STATUS: 'ADD_SERVICE_REQUEST_STATUS',

    SERVICE_LIST_REQUEST: 'SERVICE_LIST_REQUEST',
    SERVICE_LIST_REQUEST_SUCCESS: 'SERVICE_LIST_REQUEST_SUCCESS',
    SERVICE_LIST_REQUEST_ERROR: 'SERVICE_LIST_REQUEST_ERROR',

    UPDATE_SERVICE_REQUEST: 'UPDATE_SERVICE_REQUEST',
    UPDATE_SERVICE_REQUEST_SUCCESS: 'UPDATE_SERVICE_REQUEST_SUCCESS',
    UPDATE_SERVICE_REQUEST_ERROR: 'UPDATE_SERVICE_REQUEST_ERROR',
    UPDATE_SERVICE_REQUEST_STATUS: 'UPDATE_SERVICE_REQUEST_STATUS',

    DELETE_SERVICE_REQUEST: 'DELETE_SERVICE_REQUEST',
    DELETE_SERVICE_REQUEST_SUCCESS: 'DELETE_SERVICE_REQUEST_SUCCESS',
    DELETE_SERVICE_REQUEST_ERROR: 'DELETE_SERVICE_REQUEST_ERROR',
    DELETE_SERVICE_REQUEST_STATUS: 'DELETE_SERVICE_REQUEST_STATUS',
    
    COMMON_ERROR: 'COMMON_ERROR',
}
