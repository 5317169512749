export const DigitalCardActionTypes = {
    LOGOUT: 'LOGOUT',

    ADD_DIGITAL_CARD_REQUEST: 'ADD_DIGITAL_CARD_REQUEST',
    ADD_DIGITAL_CARD_REQUEST_SUCCESS: 'ADD_DIGITAL_CARD_REQUEST_SUCCESS',
    ADD_DIGITAL_CARD_REQUEST_ERROR: 'ADD_DIGITAL_CARD_REQUEST_ERROR',
    ADD_DIGITAL_CARD_REQUEST_STATUS: 'ADD_DIGITAL_CARD_REQUEST_STATUS',

    CHECK_ALIAS_AVAILABILITY_REQUEST: 'CHECK_ALIAS_AVAILABILITY_REQUEST',
    CHECK_ALIAS_AVAILABILITY_REQUEST_SUCCESS: 'CHECK_ALIAS_AVAILABILITY_REQUEST_SUCCESS',
    CHECK_ALIAS_AVAILABILITY_REQUEST_ERROR: 'CHECK_ALIAS_AVAILABILITY_REQUEST_ERROR',

    DIGITAL_CARD_LIST_REQUEST: 'DIGITAL_CARD_LIST_REQUEST',
    DIGITAL_CARD_LIST_REQUEST_SUCCESS: 'DIGITAL_CARD_LIST_REQUEST_SUCCESS',
    DIGITAL_CARD_LIST_REQUEST_ERROR: 'DIGITAL_CARD_LIST_REQUEST_ERROR',

    UPDATE_DIGITAL_CARD_REQUEST: 'UPDATE_DIGITAL_CARD_REQUEST',
    UPDATE_DIGITAL_CARD_REQUEST_SUCCESS: 'UPDATE_DIGITAL_CARD_REQUEST_SUCCESS',
    UPDATE_DIGITAL_CARD_REQUEST_ERROR: 'UPDATE_DIGITAL_CARD_REQUEST_ERROR',
    UPDATE_DIGITAL_CARD_REQUEST_STATUS: 'UPDATE_DIGITAL_CARD_REQUEST_STATUS',

    ADD_CARD_DETAILS_REQUEST: 'ADD_CARD_DETAILS_REQUEST',
    ADD_CARD_DETAILS_REQUEST_SUCCESS: 'ADD_CARD_DETAILS_REQUEST_SUCCESS',
    ADD_CARD_DETAILS_REQUEST_ERROR: 'ADD_CARD_DETAILS_REQUEST_ERROR',
    ADD_CARD_DETAILS_REQUEST_STATUS: 'ADD_CARD_DETAILS_REQUEST_STATUS',

    UPDATE_CARD_DETAILS_REQUEST: 'UPDATE_CARD_DETAILS_REQUEST',
    UPDATE_CARD_DETAILS_REQUEST_SUCCESS: 'UPDATE_CARD_DETAILS_REQUEST_SUCCESS',
    UPDATE_CARD_DETAILS_REQUEST_ERROR: 'UPDATE_CARD_DETAILS_REQUEST_ERROR',
    UPDATE_CARD_DETAILS_REQUEST_STATUS: 'UPDATE_CARD_DETAILS_REQUEST_STATUS',

    CARD_BY_ALIAS_REQUEST: 'CARD_BY_ALIAS_REQUEST',
    CARD_BY_ALIAS_REQUEST_SUCCESS: 'CARD_BY_ALIAS_REQUEST_SUCCESS',
    CARD_BY_ALIAS_REQUEST_ERROR: 'CARD_BY_ALIAS_REQUEST_ERROR',

    ADD_CARD_WITH_DETAILS_REQUEST: 'ADD_CARD_WITH_DETAILS_REQUEST',
    ADD_CARD_WITH_DETAILS_REQUEST_SUCCESS: 'ADD_CARD_WITH_DETAILS_REQUEST_SUCCESS',
    ADD_CARD_WITH_DETAILS_REQUEST_ERROR: 'ADD_CARD_WITH_DETAILS_REQUEST_ERROR',
    ADD_CARD_WITH_DETAILS_REQUEST_STATUS: 'ADD_CARD_WITH_DETAILS_REQUEST_STATUS',

    DELETE_CARD_REQUEST: 'DELETE_CARD_REQUEST',
    DELETE_CARD_REQUEST_SUCCESS: 'DELETE_CARD_REQUEST_SUCCESS',
    DELETE_CARD_REQUEST_ERROR: 'DELETE_CARD_REQUEST_ERROR',
    DELETE_CARD_REQUEST_STATUS: 'DELETE_CARD_REQUEST_STATUS',

    CLEAR_ALIAS_DATA: 'CLEAR_ALIAS_DATA',

    CLEAR_CARD_DETAIL: 'CLEAR_CARD_DETAIL',
    
    COMMON_ERROR: 'COMMON_ERROR',
}
