import React, { useEffect, useState, useContext, useRef } from "react";
import './styles.css'
import CommonScreenContentCenter from "../../../components/screen/common-screen-content-center/commonScreenContentCenter";
import { useParams, useSearchParams } from "react-router-dom";
import Loader from '../../../components/loader/loader.component'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useDispatch, useSelector } from 'react-redux'
import Paper from '@mui/material/Paper';
import SubTextLabel from "../../../components/label/sub-text-label/subTextLabel";
import CommonInputFloatFull from "../../../components/input/common-input-float-full/commonInputFloatFull";
import ButtonRow from "../../../components/button/button-row/buttonRow";
import CommonButton from "../../../components/button/common-button/commonButton";
import { displayConsoleLog, displayErrorToast, displaySuccessToast, isEmpty, isNull } from "../../../utils/Utils";
import { API_CONSTANTS, APP_CONSTANTS } from "../../../utils/AppConstants";
import { visitorAddRecordRequest, visitorAddRecordRequestStatus } from '../../../redux/visitor-book/visitorBook.action'
import { businessDetailsRequest } from '../../../redux/user/user.action'
import { clearVisitorConfigUpdateNotif } from '../../../redux/socket/socket.action'
import { SocketContext } from '../../../SocketCtx'
import { config } from "../../../config";
import Modal from 'react-bootstrap/Modal';
import FormFieldLabel from "../../../components/label/form-field-label/formFieldLabel";

const VisitorForm = props => {
    const dispatch = useDispatch()
    const [isLoading, setLoading] = useState(false)
    let { businessId } = useParams();
    const [name, setName] = useState('')
    const [address, setAddress] = useState('')
    const [mobile, setMobile] = useState('')
    const [emailId, setEmailId] = useState('')
    const [vehicleInformation, setVehicleInformation] = useState('')
    const [visitorId, setVisitorId] = useState('')
    const [businessName, setBusinessName] = useState('')
    const [meetingTo, setMeetingTo] = useState('')
    const [purpose, setPurpose] = useState('')
    const [hostEmailId, setHostEmailId] = useState('')
    const [hostPhoneNumber, setHostPhoneNumber] = useState('')
    const { businessDetailsSuccess, businessDetailsError } = useSelector((state) => state.user)
    const { visitorAddRecordSuccess, visitorAddRecordError, visitorAddRecordReqStatus } = useSelector((state) => state.visitor)
    const { visitorConfigUpdate } = useSelector(state => state.socket)
    const socket = useContext(SocketContext)
    const [displayBusinessLogo, setDisplayBusinessLogo] = useState(false)
    const [businessDetailsAvailable, setBusinessDetailsAvailable] = useState(false)
    const [displaySuccessModal, setDisplaySuccessModal] = useState(false)
    const videoRef = useRef(null);
    const [imageSrc, setImageSrc] = useState('');
    const [isVideoLoaded, setIsVideoLoaded] = useState(false);
    const [caputreImgData, setCaptureImgData] = useState(null)
    const [captureImgName, setCaptureImgName] = useState('')
    const [streamObj, setStreamObj] = useState(null)
    const [showVisitorName, setShowVisitorName] = useState(true)
    const [showVisitorContact, setShowVisitorContact] = useState(true)
    const [showVisitorEmailId, setShowVisitorEmailId] = useState(false)
    const [showVisitorAddress, setShowVisitorAddress] = useState(false)
    const [showVisitorCompanyName, setShowVisitorCompanyName] = useState(false)
    const [showVisitorVehicleInformation, setShowVisitorVehicleInformation] = useState(false)
    const [showVisitorId, setShowVisitorId] = useState(false)
    const [showVisitorImage, setShowVisitorImage] = useState(false)
    const [showPurpose, setShowPurpose] = useState(false)
    const [showHostName, setShowHostName] = useState(false)
    const [showHostEmailId, setShowHostEmailId] = useState(false)
    const [showHostMobile, setShowHostMobile] = useState(false)
    const [showVisitorNameMandatory, setShowVisitorNameMandatory] = useState(false)
    const [showVisitorContactMandatory, setShowVisitorContactMandatory] = useState(false)
    const [showVisitorEmailIdMandatory, setShowVisitorEmailIdMandatory] = useState(false)
    const [showVisitorAddressMandatory, setShowVisitorAddressMandatory] = useState(false)
    const [showVisitorCompanyNameMandatory, setShowVisitorCompanyNameMandatory] = useState(false)
    const [showVisitorVehicleInformationMandatory, setShowVisitorVehicleInformationMandatory] = useState(false)
    const [showVisitorIdMandatory, setShowVisitorIdMandatory] = useState(false)
    const [showVisitorImageMandatory, setShowVisitorImageMandatory] = useState(false)
    const [showPurposeMandatory, setShowPurposeMandatory] = useState(false)
    const [showHostNameMandatory, setShowHostNameMandatory] = useState(false)
    const [showHostEmailIdMandatory, setShowHostEmailIdMandatory] = useState(false)
    const [showHostMobileMandatory, setShowHostMobileMandatory] = useState(false)

    useEffect(() => {
        fetchBusinessDetails()
    }, [])

    const fetchBusinessDetails = () => {
        if (!isEmpty(businessId)) {
            setLoading(true)
            const data = {
                businessId: businessId
            }
            dispatch(businessDetailsRequest(data))
        }
    }

    useEffect(() => {
        if (visitorConfigUpdate !== null) {
            if (Number(businessId) === Number(visitorConfigUpdate.businessId)) {
                fetchBusinessDetails()
                dispatch(clearVisitorConfigUpdateNotif(''))
            }
        }
    }, [visitorConfigUpdate])

    useEffect(() => {
        if (visitorAddRecordReqStatus === API_CONSTANTS.SUCCESS_STATUS) {
            setLoading(false)
            const data = {
                businessId: businessId,
                visitorName: name
            }
            // socket.emit('visitor-add-success', data)
            clearFields()
            setDisplaySuccessModal(true)
        } else if (visitorAddRecordReqStatus === API_CONSTANTS.ERROR_STATUS) {
            setLoading(false)
            displayErrorToast(visitorAddRecordError.message)
        }
        if (visitorAddRecordReqStatus.length)
            dispatch(visitorAddRecordRequestStatus(''))
    }, [visitorAddRecordSuccess, visitorAddRecordError, visitorAddRecordReqStatus])

    useEffect(() => {
        if (!isEmpty(businessDetailsSuccess)) {
            setLoading(false)
            setBusinessDetailsAvailable(true)
            if (!isEmpty(businessDetailsSuccess.data.image) && !businessDetailsSuccess.data.image.includes('placeholder')) {
                setDisplayBusinessLogo(true)
            } else {
                setDisplayBusinessLogo(false)
            }
            if (businessDetailsSuccess && businessDetailsSuccess.data && businessDetailsSuccess.data.visitorConfig) {
                businessDetailsSuccess.data.visitorConfig.forEach(element => {
                    if (element.type === 'visitorName') {
                        setShowVisitorName(element.display)
                        setShowVisitorNameMandatory(element.isMandatory)
                    } else if (element.type === 'visitorPhoneNumber') {
                        setShowVisitorContact(element.display)
                        setShowVisitorContactMandatory(element.isMandatory)
                    } else if (element.type === 'visitorAddress') {
                        setShowVisitorAddress(element.display)
                        setShowVisitorAddressMandatory(element.isMandatory)
                    } else if (element.type === 'visitorEmailId') {
                        setShowVisitorEmailId(element.display)
                        setShowVisitorEmailIdMandatory(element.isMandatory)
                    } else if (element.type === 'visitorCompanyName') {
                        setShowVisitorCompanyName(element.display)
                        setShowVisitorCompanyNameMandatory(element.isMandatory)
                    } else if (element.type === 'visitorVehicleInformation') {
                        setShowVisitorVehicleInformation(element.display)
                        setShowVisitorVehicleInformationMandatory(element.isMandatory)
                    } else if (element.type === 'visitorId') {
                        setShowVisitorId(element.display)
                        setShowVisitorIdMandatory(element.isMandatory)
                    } else if (element.type === 'hostName') {
                        setShowHostName(element.display)
                        setShowHostNameMandatory(element.isMandatory)
                    } else if (element.type === 'hostPhoneNumber') {
                        setShowHostMobile(element.display)
                        setShowHostMobileMandatory(element.isMandatory)
                    } else if (element.type === 'hostEmailId') {
                        setShowHostEmailId(element.display)
                        setShowHostEmailIdMandatory(element.isMandatory)
                    } else if (element.type === 'purpose') {
                        setShowPurpose(element.display)
                        setShowPurposeMandatory(element.isMandatory)
                    } else if (element.type === 'visitorImage') {
                        setShowVisitorImage(element.display)
                        setShowVisitorImageMandatory(element.isMandatory)
                    }
                });
            }
        } else if (!isEmpty(businessDetailsError)) {
            setLoading(false)
            setBusinessDetailsAvailable(false)
        }
    }, [businessDetailsSuccess, businessDetailsError])

    const clearFields = () => {
        setName('')
        setAddress('')
        setMobile('')
        setBusinessName('')
        setMeetingTo('')
        setPurpose('')
    }

    const handleSubmitClick = () => {
        if (businessDetailsAvailable === false) {
            displayErrorToast('Business details not available. Please try refreshing the page or try again later')
        } else if (showVisitorName && showVisitorNameMandatory && isEmpty(name)) {
            displayErrorToast('Visitor name cannot be blank')
        } else if (showVisitorContact && showVisitorContactMandatory && isEmpty(mobile)) {
            displayErrorToast('Visitor contact number cannot be blank')
        } else if (showVisitorAddress && showVisitorAddressMandatory && isEmpty(address)) {
            displayErrorToast('Visitor address cannot be blank')
        } else if (showVisitorEmailId && showVisitorEmailIdMandatory && isEmpty(emailId)) {
            displayErrorToast('Visitor email id cannot be be blank')
        } else if (showVisitorCompanyName && showVisitorCompanyNameMandatory && isEmpty(businessName)) {
            displayErrorToast('Visitor company name cannot be be blank')
        } else if (showVisitorVehicleInformation && showVisitorVehicleInformationMandatory && isEmpty(vehicleInformation)) {
            displayErrorToast('Visitor vehicle information cannot be be blank')
        } else if (showVisitorId && showVisitorIdMandatory && isEmpty(visitorId)) {
            displayErrorToast('Visitor Id cannot be be blank')
        } else if (showHostName && showHostNameMandatory && isEmpty(meetingTo)) {
            displayErrorToast('Host name cannot be blank')
        } else if (showHostMobile && showHostMobileMandatory && isEmpty(hostPhoneNumber)) {
            displayErrorToast('Host phone number cannot be blank')
        } else if (showHostEmailId && showHostEmailIdMandatory && isEmpty(hostEmailId)) {
            displayErrorToast('Host email id cannot be blank')
        } else if (showPurpose && showPurposeMandatory && isEmpty(purpose)) {
            displayErrorToast('Purpose cannot be blank')
        } else if (showVisitorImage && showVisitorImageMandatory && isNull(caputreImgData)) {
            displayErrorToast('Visitor image not clicked')
        }
        else {
            let fd = new FormData()
            fd.append('businessId', businessId)
            fd.append('name', name)
            if (!isEmpty(mobile))
                fd.append('mobile', mobile)
            if (!isEmpty(emailId))
                fd.append('emailId', emailId)
            if (!isEmpty(vehicleInformation))
                fd.append('vehicleInformation', vehicleInformation)
            if (!isEmpty(visitorId))
                fd.append('visitorIdCode', visitorId)
            if (!isEmpty(meetingTo))
                fd.append('hostName', meetingTo)
            fd.append('productCode', APP_CONSTANTS.PRODUCT_NAME)
            if (!isEmpty(address)) {
                fd.append('address', address)
            }
            if (!isEmpty(purpose)) {
                fd.append('purpose', purpose)
            }
            if (!isEmpty(businessName)) {
                fd.append('companyName', businessName)
            }
            if (!isEmpty(hostPhoneNumber)) {
                fd.append('hostPhoneNumber', hostPhoneNumber)
            }
            if (!isEmpty(hostEmailId)) {
                fd.append('hostEmailId', hostEmailId)
            }
            // image
            if (!isNull(caputreImgData)) {
                fd.append('image', caputreImgData, captureImgName)
            }
            setLoading(true)
            dispatch(visitorAddRecordRequest(fd))
        }
    }

    useEffect(() => {
        if (isVideoLoaded) {
            if (videoRef.current) {
                videoRef.current.srcObject = streamObj;
            }
            // navigator.mediaDevices.getUserMedia({ video: true })
            //     .then(stream => {
            //         console.log('videoRef.current', videoRef.current)
            //         if (videoRef.current) {
            //             videoRef.current.srcObject = stream;
            //         }
            //     })
            //     .catch(error => {
            //         console.error('Error accessing camera', error);
            //     });
        }
    }, [isVideoLoaded])

    function loadVideo() {
        // Access the camera and set the video source
        navigator.mediaDevices.getUserMedia({ video: true })
            .then(stream => {
                setStreamObj(stream)
                setIsVideoLoaded(true);
            })
            .catch(error => {
                console.error('Error accessing camera', error);
            });
    }

    function captureImage() {
        if (!videoRef.current) {
            return;
        }
        // Capture an image from the video feed
        const canvas = document.createElement('canvas');
        canvas.width = videoRef.current.videoWidth;
        canvas.height = videoRef.current.videoHeight;
        const ctx = canvas.getContext('2d');
        ctx.drawImage(videoRef.current, 0, 0, canvas.width, canvas.height);
        const dataURI = canvas.toDataURL('image/png');
        setImageSrc(dataURI)

        // Convert the data URI to a blob
        const byteString = atob(dataURI.split(',')[1]);
        const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
        const arrayBuffer = new ArrayBuffer(byteString.length);
        const uint8Array = new Uint8Array(arrayBuffer);
        for (let i = 0; i < byteString.length; i++) {
            uint8Array[i] = byteString.charCodeAt(i);
        }
        const blob = new Blob([arrayBuffer], { type: mimeString });

        // Create the form data and append the blob and filename
        setCaptureImgData(blob)
        setCaptureImgName('captured-image.png');
        setIsVideoLoaded(false)
        // navigator.mediaDevices.enumerateDevices()
        //     .then(devices => {
        //         const videoDevices = devices.filter(device => device.kind === 'videoinput');
        //         return navigator.mediaDevices.getUserMedia({ video: { deviceId: videoDevices[0].deviceId } });
        //     })
        //     .then(stream => {
        //         stream.getTracks().forEach(track => track.stop());
        //         stream.getVideoTracks().forEach(track => track.stop());
        //     })
        //     .catch(error => {
        //         console.error('Error resetting camera permissions', error);
        //     });
        videoRef.current = null
    }

    function handleSuccessDialogClose() {
        setDisplaySuccessModal(false)
        window.location.reload();
    }

    return (
        <>
            <CommonScreenContentCenter>
                <div className="logo-img-contain" >
                    {!displayBusinessLogo && <img src={require('../../../assets/nLogo2.png')} className="img" />}
                    {displayBusinessLogo && <img src={`${config.imageUrl}${businessDetailsSuccess.data.image}`} className="img" />}
                </div>
                {businessDetailsSuccess && businessDetailsSuccess.data && <div className="business-name-label">Welcome to {businessDetailsSuccess.data.businessName}</div>}
                {businessDetailsSuccess && businessDetailsSuccess.data && businessDetailsSuccess.data.buildingAddress && <div className="building-address-label">{businessDetailsSuccess.data.buildingAddress}</div>}
                <Paper elevation={3} style={{ background: '#fbfbfb' }} className='ac-card paper-container'>
                    <SubTextLabel label='Enter your details below' />
                    {showVisitorName && <CommonInputFloatFull
                        type='text'
                        id='name'
                        placeholder='Visitor Name'
                        onChange={e => setName(e.target.value)}
                        value={name}
                        isRequired={showVisitorNameMandatory} />}
                    {showVisitorContact && <CommonInputFloatFull
                        type='number'
                        id='mobile'
                        placeholder='Phone Number'
                        onChange={e => setMobile(e.target.value)}
                        value={mobile}
                        isRequired={showVisitorContactMandatory} />}
                    {showVisitorEmailId && <CommonInputFloatFull
                        type='text'
                        id='mobile'
                        placeholder='Email Id'
                        onChange={e => setEmailId(e.target.value)}
                        value={emailId}
                        isRequired={showVisitorEmailIdMandatory} />}
                    {showVisitorAddress && <CommonInputFloatFull
                        type='text'
                        id='address'
                        placeholder='Address'
                        onChange={e => setAddress(e.target.value)}
                        value={address}
                        isRequired={showVisitorAddressMandatory} />}
                    {showVisitorCompanyName && <CommonInputFloatFull
                        type='text'
                        id='businessName'
                        placeholder='Company Name'
                        onChange={e => setBusinessName(e.target.value)}
                        value={businessName}
                        isRequired={showVisitorCompanyNameMandatory} />}
                    {showVisitorId && <CommonInputFloatFull
                        type='text'
                        id='businessName'
                        placeholder='Visitor Id'
                        onChange={e => setVisitorId(e.target.value)}
                        value={visitorId}
                        isRequired={showVisitorIdMandatory} />}
                    {showVisitorVehicleInformation && <CommonInputFloatFull
                        type='text'
                        id='businessName'
                        placeholder='Vehicle Information'
                        onChange={e => setVehicleInformation(e.target.value)}
                        value={vehicleInformation}
                        isRequired={showVisitorVehicleInformationMandatory} />}
                    {showHostName && <CommonInputFloatFull
                        type='text'
                        id='meetingTo'
                        placeholder='Host Name'
                        onChange={e => setMeetingTo(e.target.value)}
                        value={meetingTo}
                        isRequired={showHostNameMandatory} />}
                    {showHostMobile && <CommonInputFloatFull
                        type='number'
                        id='meetingTo'
                        placeholder='Host Mobile'
                        onChange={e => setHostPhoneNumber(e.target.value)}
                        value={hostPhoneNumber}
                        isRequired={showHostMobileMandatory} />}
                    {showHostEmailId && <CommonInputFloatFull
                        type='text'
                        id='meetingTo'
                        placeholder='Host Email Id'
                        onChange={e => setHostEmailId(e.target.value)}
                        value={hostEmailId}
                        isRequired={showHostEmailIdMandatory} />}
                    {showPurpose && <CommonInputFloatFull
                        type='text'
                        id='purpose'
                        placeholder='Purpose'
                        onChange={e => setPurpose(e.target.value)}
                        value={purpose}
                        isRequired={showPurposeMandatory} />}
                    {showVisitorImage && <FormFieldLabel label={`${showVisitorImageMandatory ? 'Capture Your Photo *' : 'Capture Your Photo'}`} />}
                    {showVisitorImage && !isVideoLoaded && <div className="load-camera-btn" onClick={loadVideo}>Load Camera</div>}
                    {isVideoLoaded && (
                        <div>
                            <video className="video-player-container" ref={videoRef} autoPlay />
                            <div className="load-camera-btn mt-2" onClick={captureImage}>Capture Photo</div>

                        </div>
                    )}
                    {imageSrc &&
                        <div className="capture-img-container">
                            <img src={imageSrc} alt="Captured Image" className="img" />
                        </div>}
                    <ButtonRow>
                        <CommonButton label='Submit' onClick={() => { handleSubmitClick() }} />
                    </ButtonRow>
                </Paper>
            </CommonScreenContentCenter>
            <Modal
                onHide={handleSuccessDialogClose}
                show={displaySuccessModal}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                </Modal.Header>
                <Modal.Body>
                    <div className="qr-container">
                        <div className="success-img">
                            <img src={require('../../../assets/ic_tick_done.png')} className="img" />
                        </div>
                        <div className="success-img-label">Visitor record submitted successfully</div>
                    </div>
                </Modal.Body>
            </Modal>
            <ToastContainer />
            <Loader active={isLoading} text='Please wait...' />
        </>
    )
}

export default VisitorForm