import { UserActionTypes } from './user.types'

//Login
export const loginUserRequest = data => ({
    type: UserActionTypes.LOGIN_USER_REQUEST,
    payload: data
})

export const loginSuccess = user => ({
    type: UserActionTypes.LOGIN_USER_SUCCESS,
    payload: user
})

export const loginError = data => ({
    type: UserActionTypes.LOGIN_USER_ERROR,
    payload: data
})

//Register
export const registerRequest = data => ({
    type: UserActionTypes.REGISTER_REQUEST,
    payload: data
})

export const registerRequestSuccess = data => ({
    type: UserActionTypes.REGISTER_REQUEST_SUCCESS,
    payload: data
})

export const registerRequestError = data => ({
    type: UserActionTypes.REGISTER_REQUEST_ERROR,
    payload: data
})

export const registerRequestStatus = data => ({
    type: UserActionTypes.REGISTER_REQUEST_STATUS,
    payload: data
})

//Register By Mobile
export const registerByMobileRequest = data => ({
    type: UserActionTypes.REGISTER_REQUEST_BY_MOBILE,
    payload: data
})

export const registerByMobileRequestSuccess = data => ({
    type: UserActionTypes.REGISTER_REQUEST_BY_MOBILE_SUCCESS,
    payload: data
})

export const registerByMobileRequestError = data => ({
    type: UserActionTypes.REGISTER_REQUEST_BY_MOBILE_ERROR,
    payload: data
})

export const registerByMobileRequestStatus = data => ({
    type: UserActionTypes.REGISTER_REQUEST_BY_MOBILE_STATUS,
    payload: data
})

//Customer card request
export const customerCardRequest = data => ({
    type: UserActionTypes.CUSTOMER_CARD_REQUEST,
    payload: data
})

export const customerCardRequestSuccess = data => ({
    type: UserActionTypes.CUSTOMER_CARD_REQUEST_SUCCESS,
    payload: data
})

export const customerCardRequestError = data => ({
    type: UserActionTypes.CUSTOMER_CARD_REQUEST_ERROR,
    payload: data
})

export const customerCardRequestStatus = data => ({
    type: UserActionTypes.CUSTOMER_CARD_REQUEST_STATUS,
    payload: data
})

//Subscription list
export const subscriptionListRequest = () => ({
    type: UserActionTypes.SUBSCRIPTION_LIST_REQUEST
})

export const subscriptionListRequestSuccess = data => ({
    type: UserActionTypes.SUBSCRIPTION_LIST_REQUEST_SUCCESS,
    payload: data
})

export const subscriptionListRequestError = data => ({
    type: UserActionTypes.SUBSCRIPTION_LIST_REQUEST_ERROR,
    payload: data
})

//User update notification token
export const updateUserNotificationTokenRequest = data => ({
    type: UserActionTypes.UPDATE_NOTIFICATION_TOKEN_REQUEST,
    payload: data
})

export const updateUserNotificationTokenRequestSuccess = data => ({
    type: UserActionTypes.UPDATE_NOTIFICATION_TOKEN_REQUEST_SUCCESS,
    payload: data
})

export const updateUserNotificationTokenRequestError = data => ({
    type: UserActionTypes.UPDATE_NOTIFICATION_TOKEN_REQUEST_ERROR,
    payload: data
})

//Forgot Password
export const forgotPasswordRequest = data => ({
    type: UserActionTypes.FORGOT_PASSWORD_REQUEST,
    payload: data
})

export const forgotPasswordRequestSuccess = data => ({
    type: UserActionTypes.FORGOT_PASSWORD_REQUEST_SUCCESS,
    payload: data
})

export const forgotPasswordRequestError = data => ({
    type: UserActionTypes.FORGOT_PASSWORD_REQUEST_ERROR,
    payload: data
})

export const forgotPasswordRequestStatus = data => ({
    type: UserActionTypes.FORGOT_PASSWORD_REQUEST_STATUS,
    payload: data
})

//Change Password
export const changePasswordRequest = data => ({
    type: UserActionTypes.CHANGE_PASSWORD_REQUEST,
    payload: data
})

export const changePasswordRequestSuccess = data => ({
    type: UserActionTypes.CHANGE_PASSWORD_REQUEST_SUCCESS,
    payload: data
})

export const changePasswordRequestError = data => ({
    type: UserActionTypes.CHANGE_PASSWORD_REQUEST_ERROR,
    payload: data
})

export const changePasswordRequestStatus = data => ({
    type: UserActionTypes.CHANGE_PASSWORD_REQUEST_STATUS,
    payload: data
})

//Country List
export const countryListRequest = () => ({
    type: UserActionTypes.COUNTRY_LIST_REQUEST
})

export const countryListRequestSuccess = data => ({
    type: UserActionTypes.COUNTRY_LIST_REQUEST_SUCCESS,
    payload: data
})

export const countryListRequestError = data => ({
    type: UserActionTypes.COUNTRY_LIST_REQUEST_ERROR,
    payload: data
})

//State List
export const stateListRequest = data => ({
    type: UserActionTypes.STATE_LIST_REQUEST,
    payload: data
})

export const stateListRequestSuccess = data => ({
    type: UserActionTypes.STATE_LIST_REQUEST_SUCCESS,
    payload: data
})

export const stateListRequestError = data => ({
    type: UserActionTypes.STATE_LIST_REQUEST_ERROR,
    payload: data
})

//City List
export const cityListRequest = data => ({
    type: UserActionTypes.CITY_LIST_REQUEST,
    payload: data
})

export const cityListRequestSuccess = data => ({
    type: UserActionTypes.CITY_LIST_REQUEST_SUCCESS,
    payload: data
})

export const cityListRequestError = data => ({
    type: UserActionTypes.CITY_LIST_REQUEST_ERROR,
    payload: data
})

export const displayTourAction = data => ({
    type: UserActionTypes.DISPLAY_TOUR,
    payload: data
})

//IP Location
export const ipLocationRequest = data => ({
    type: UserActionTypes.IP_LOCATION_REQUEST,
    payload: data
})

export const ipLocationRequestSuccess = data => ({
    type: UserActionTypes.IP_LOCATION_REQUEST_SUCCESS,
    payload: data
})

export const ipLocationRequestError = data => ({
    type: UserActionTypes.IP_LOCATION_REQUEST_ERROR,
    payload: data
})

export const displaySideBarAct = data => ({
    type: UserActionTypes.DISPLAY_SIDE_BAR,
    payload: data
})

//Contact Us
export const contactUsRequest = data => ({
    type: UserActionTypes.CONTACT_US_REQUEST,
    payload: data
})

export const contactUsRequestSuccess = data => ({
    type: UserActionTypes.CONTACT_US_REQUEST_SUCCESS,
    payload: data
})

export const contactUsRequestError = data => ({
    type: UserActionTypes.CONTACT_US_REQUEST_ERROR,
    payload: data
})

export const contactUsRequestStatus = data => ({
    type: UserActionTypes.CONTACT_US_REQUEST_STATUS,
    payload: data
})

//User Register Business
export const userRegistrationBusinessRequest = data => ({
    type: UserActionTypes.USER_REGISTRATION_BUSINESS_REQUEST,
    payload: data
})

export const userRegistrationBusinessRequestSuccess = data => ({
    type: UserActionTypes.USER_REGISTRATION_BUSINESS_REQUEST_SUCCESS,
    payload: data
})

export const userRegistrationBusinessRequestError = data => ({
    type: UserActionTypes.USER_REGISTRATION_BUSINESS_REQUEST_ERROR,
    payload: data
})

export const userRegistrationBusinessRequestStatus = data => ({
    type: UserActionTypes.USER_REGISTRATION_BUSINESS_REQUEST_STATUS,
    payload: data
})

//Operator registeration
export const operatorRegisterationRequest = data => ({
    type: UserActionTypes.OPERATOR_REGISTERATION_REQUEST,
    payload: data
})
export const operatorRegisterationRequestSuccess = data => ({
    type: UserActionTypes.OPERATOR_REGISTERATION_REQUEST_SUCCESS,
    payload: data
})

export const operatorRegisterationRequestError = data => ({
    type: UserActionTypes.OPERATOR_REGISTERATION_REQUEST_ERROR,
    payload: data
})

export const operatorRegisterationRequestStatus = data => ({
    type: UserActionTypes.OPERATOR_REGISTERATION_REQUEST_STATUS,
    payload: data
})

//Update operator location
export const updateOperatorLocationRequest = data => ({
    type: UserActionTypes.UPDATE_OPERATOR_LOCATION_REQUEST,
    payload: data
})

export const updateOperatorLocationRequestSuccess = data => ({
    type: UserActionTypes.UPDATE_OPERATOR_LOCATION_REQUEST_SUCCESS,
    payload: data
})

export const updateOperatorLocationRequestError = data => ({
    type: UserActionTypes.UPDATE_OPERATOR_LOCATION_REQUEST_ERROR,
    payload: data
})

export const updateOperatorLocationRequestStatus = data => ({
    type: UserActionTypes.UPDATE_OPERATOR_LOCATION_REQUEST_STATUS,
    payload: data
})

//Operator List
export const operatorListRequest = data => ({
    type: UserActionTypes.OPERATOR_LIST_REQUEST,
    payload: data
})

export const operatorListRequestSuccess = data => ({
    type: UserActionTypes.OPERATOR_LIST_REQUEST_SUCCESS,
    payload: data
})

export const operatorListRequestError = data => ({
    type: UserActionTypes.OPERATOR_LIST_REQUEST_ERROR,
    payload: data
})

//Operator Detail
export const operatorDetailRequest = data => ({
    type: UserActionTypes.OPERATOR_DETAIL_REQUEST,
    payload: data
})

export const operatorDetailRequestSuccess = data => ({
    type: UserActionTypes.OPERATOR_DETAIL_REQUEST_SUCCESS,
    payload: data
})

export const operatorDetailRequestError = data => ({
    type: UserActionTypes.OPERATOR_DETAIL_REQUEST_ERROR,
    payload: data
})

//Business details
export const businessDetailsRequest = data => ({
    type: UserActionTypes.BUSINESS_DETAILS_REQUEST,
    payload: data
})

export const businessDetailsRequestSuccess = data => ({
    type: UserActionTypes.BUSINESS_DETAILS_REQUEST_SUCCESS,
    payload: data
})

export const businessDetailsRequestError = data => ({
    type: UserActionTypes.BUSINESS_DETAILS_REQUEST_ERROR,
    payload: data
})

//Business visitor config update
export const businessVisitorConfigUpdateRequest = data => ({
    type: UserActionTypes.BUSINESS_VISITOR_CONFIG_UPDATE_REQUEST,
    payload: data
})

export const businessVisitorConfigUpdateRequestSuccess = data => ({
    type: UserActionTypes.BUSINESS_VISITOR_CONFIG_UPDATE_REQUEST_SUCCESS,
    payload: data
})

export const businessVisitorConfigUpdateRequestError = data => ({
    type: UserActionTypes.BUSINESS_VISITOR_CONFIG_UPDATE_REQUEST_ERROR,
    payload: data
})

export const businessVisitorConfigUpdateRequestStatus = data => ({
    type: UserActionTypes.BUSINESS_VISITOR_CONFIG_UPDATE_REQUEST_STATUS,
    payload: data
})

//Business update notification token
export const businessUpdateNotificationTokenRequest = data => ({
    type: UserActionTypes.BUSINESS_UPDATE_NOTIFICATION_REQUEST,
    payload: data
})

export const businessUpdateNotificationTokenRequestSuccess = data => ({
    type: UserActionTypes.BUSINESS_UPDATE_NOTIFICATION_REQUEST_SUCCESS,
    payload: data
})

export const businessUpdateNotificationTokenRequestError = data => ({
    type: UserActionTypes.BUSINESS_UPDATE_NOTIFICATION_REQUEST_ERROR,
    payload: data
})

export const businessUpdateNotificationTokenRequestStatus = data => ({
    type: UserActionTypes.BUSINESS_UPDATE_NOTIFICATION_REQUEST_STATUS,
    payload: data
})