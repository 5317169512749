import React, { useEffect, useState } from 'react'
import './styles.css'
import CommonScreenContent from '../../../components/screen/common-screen-content/commonScreenContent'
import ScreenLabel from '../../../components/label/screen-label/screenLabel'
import CommonColorForm from '../../../components/common-color-form/commonColorForm'
import SubTextLabel from '../../../components/label/sub-text-label/subTextLabel'
import ButtonRow from '../../../components/button/button-row/buttonRow'
import CommonButton from '../../../components/button/common-button/commonButton'
import Loader from '../../../components/loader/loader.component'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'react-phone-number-input/style.css'
import { useDispatch, useSelector } from 'react-redux'
import { displayErrorToast, displaySuccessToast, isEmpty, isNull } from '../../../utils/Utils'
import CommonInputFull from '../../../components/input/common-input-full/commonInputFull'
import CommonInputFloatFull from '../../../components/input/common-input-float-full/commonInputFloatFull'
import { API_CONSTANTS, APP_CONSTANTS } from '../../../utils/AppConstants'
import Rating from '@mui/material/Rating';
import { addFeedbackRequest, addFeedbackRequestStatus } from '../../../redux/feedback/feedback.action'

const AddFeedback = props => {
    const [isLoading, setLoading] = useState(false)
    const dispatch = useDispatch()
    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [rating, setRating] = useState(0)
    const [feedback, setFeedback] = useState('')
    const { addFeedbackSuccess, addFeedbackError, addFeedbackReqStatus } = useSelector((state) => state.feedback)

    useEffect(() => {
        if (addFeedbackReqStatus === API_CONSTANTS.SUCCESS_STATUS) {
            setLoading(false)
            displaySuccessToast(addFeedbackSuccess.message)
            setName('')
            setEmail('')
            setFeedback('')
            setRating(0)
        } else if (addFeedbackReqStatus === API_CONSTANTS.ERROR_STATUS) {
            setLoading(false)
            displayErrorToast(addFeedbackError.message)
        }
        if (addFeedbackReqStatus.length) {
            dispatch(addFeedbackRequestStatus(''))
        }
    }, [addFeedbackSuccess, addFeedbackError, addFeedbackReqStatus])

    const handleSubmitFeedback = () => {
        if (isEmpty(name)) {
            displayErrorToast('Name cannot be blank')
        } else if (isEmpty(feedback)) {
            displayErrorToast('Feedback cannot be blank')
        } else {
            setLoading(true)
            const data = {
                name: name,
                email: !isEmpty(email) ? email : '',
                message: feedback,
                productCode: APP_CONSTANTS.PRODUCT_NAME,
                rating: !isNull(rating) ? rating : 0
            }
            dispatch(addFeedbackRequest(data))
        }
    }

    return (
        <>
            <CommonScreenContent>
                <ScreenLabel label='Add Feedback' />
                <CommonColorForm>
                    <SubTextLabel label='Enter below detail to submit feedback' />
                    <div className='star-container'>
                        <Rating name="size-large" defaultValue={rating} precision={0.5} value={rating} onChange={e => setRating(e.target.value)} size="large" sx={{ fontSize: '3rem' }} max={5} />
                    </div>
                    <CommonInputFloatFull id='name'
                        placeholder='Name'
                        onChange={e => setName(e.target.value)}
                        type='text'
                        isRequired={true}
                        value={name} />
                    <CommonInputFloatFull id='email'
                        placeholder='Email'
                        onChange={e => setEmail(e.target.value)}
                        type='text'
                        value={email} />
                    <CommonInputFloatFull id='feedback'
                        placeholder='Feedback'
                        onChange={e => setFeedback(e.target.value)}
                        type='text'
                        isRequired={true}
                        value={feedback} />
                    <ButtonRow>
                        <CommonButton label='Submit' onClick={() => handleSubmitFeedback()} />
                    </ButtonRow>
                </CommonColorForm>
            </CommonScreenContent>
            <ToastContainer />
            <Loader active={isLoading} text='Please wait...' />
        </>
    )
}

export default AddFeedback