import { FeedbackActionTypes } from './feedback.types'
import { API_CONSTANTS } from '../../utils/AppConstants'

const INITIAL_STATE = {
    addFeedbackSuccess: null,
    addFeedbackError: null,
    addFeedbackReqStatus: ''
}

const feedbackReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case FeedbackActionTypes.ADD_FEEDBACK_REQUEST_SUCCESS:
            return {
                ...state,
                addFeedbackSuccess: action.payload,
                addFeedbackError: null,
                addFeedbackReqStatus: API_CONSTANTS.SUCCESS_STATUS
            }
        case FeedbackActionTypes.ADD_FEEDBACK_REQUEST_ERROR:
            return {
                ...state,
                addFeedbackSuccess: null,
                addFeedbackError: action.payload,
                addFeedbackReqStatus: API_CONSTANTS.ERROR_STATUS
            }
        case FeedbackActionTypes.ADD_FEEDBACK_REQUEST_STATUS:
            return {
                ...state,
                addFeedbackReqStatus: ''
            }
        case FeedbackActionTypes.LOGOUT:
            return INITIAL_STATE
        default:
            return state
    }
}

export default feedbackReducer