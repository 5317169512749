export const BlogActionTypes = {
    LOGOUT: 'LOGOUT',

    ADD_BLOG_REQUEST: 'ADD_BLOG_REQUEST',
    ADD_BLOG_REQUEST_SUCCESS: 'ADD_BLOG_REQUEST_SUCCESS',
    ADD_BLOG_REQUEST_ERROR: 'ADD_BLOG_REQUEST_ERROR',
    ADD_BLOG_REQUEST_STATUS: 'ADD_BLOG_REQUEST_STATUS',

    BLOG_LIST_REQUEST: 'BLOG_LIST_REQUEST',
    BLOG_LIST_REQUEST_SUCCESS: 'BLOG_LIST_REQUEST_SUCCESS',
    BLOG_LIST_REQUEST_ERROR: 'BLOG_LIST_REQUEST_ERROR',

    UPDATE_BLOG_REQUEST: 'UPDATE_BLOG_REQUEST',
    UPDATE_BLOG_REQUEST_SUCCESS: 'UPDATE_BLOG_REQUEST_SUCCESS',
    UPDATE_BLOG_REQUEST_ERROR: 'UPDATE_BLOG_REQUEST_ERROR',
    UPDATE_BLOG_REQUEST_STATUS: 'UPDATE_BLOG_REQUEST_STATUS',

    DELETE_BLOG_REQUEST: 'DELETE_BLOG_REQUEST',
    DELETE_BLOG_REQUEST_SUCCESS: 'DELETE_BLOG_REQUEST_SUCCESS',
    DELETE_BLOG_REQUEST_ERROR: 'DELETE_BLOG_REQUEST_ERROR',
    DELETE_BLOG_REQUEST_STATUS: 'DELETE_BLOG_REQUEST_STATUS',
    
    COMMON_ERROR: 'COMMON_ERROR',
}
