import { takeLatest, call, put } from 'redux-saga/effects'
import {
    addCardDetailsAPI, addDigitalCardAPI, cardByAliasAPI, checkAliasAvailabilityAPI, digitalCardListAPI,
    updateCardDetailsAPI, updateDigitalCardAPI, addDigitalCardWithDetailsAPI,
    deleteCardAPI
} from '../api-requests/digitalCard';
import {
    addCardDetailsRequestError, addCardDetailsRequestSuccess, addDigitalCardRequestError,
    addDigitalCardRequestSuccess, cardByAliasRequestError, cardByAliasRequestSuccess,
    checkCardAliasRequestError, checkCardAliasRequestSuccess, digitalCardListRequestError,
    digitalCardListRequestSuccess, updateCardDetailsRequestError, updateCardDetailsRequestSuccess,
    updateDigitalCardRequestError, updateDigitalCardRequestSuccess, addDigitalCardWithDetailsRequestSuccess,
    addDigitalCardWithDetailsRequestError, deleteCardRequestError, deleteCardRequestSuccess
} from './digitalCard.action';
import { DigitalCardActionTypes } from './digitalCard.types'
import { displayConsoleLog } from '../../utils/Utils'
import { decryptResponseData } from '../../utils/DataDecryptionUtils';
import { APP_CONSTANTS } from '../../utils/AppConstants';

function* handleDeleteDigitalCard({ payload }) {
    try {
        const response = yield call(deleteCardAPI, payload);
        if (response.status >= 200 && response.status < 300) {
            const { data } = response
            const decryptedData = decryptResponseData(data)
            if (decryptedData.data !== null) {
                yield put(deleteCardRequestSuccess(decryptedData))
            } else {
                yield put(deleteCardRequestError(decryptedData))
            }
        } else {
            throw response
        }
    } catch (error) {
        displayConsoleLog(error)
        if (error.response?.status === 401) {
            const errData = {
                status: error.response?.status,
                isSessionError: true,
                message: APP_CONSTANTS.SESSION_EXPIRE_MESSAGE
            }
            yield put({ type: APP_CONSTANTS.COMMON_ERROR, errData })
        } else {
            displayConsoleLog(error.response?.data)
            yield put(deleteCardRequestError(error.response.data))
        }
    }
}

export function* deleteDigitalCardReq() {
    yield takeLatest(DigitalCardActionTypes.DELETE_CARD_REQUEST, handleDeleteDigitalCard)
}

function* handleAddDigitalCardWithDetail({ payload }) {
    try {
        const response = yield call(addDigitalCardWithDetailsAPI, payload);
        if (response.status >= 200 && response.status < 300) {
            const { data } = response
            const decryptedData = decryptResponseData(data)
            if (decryptedData.data !== null) {
                yield put(addDigitalCardWithDetailsRequestSuccess(decryptedData))
            } else {
                yield put(addDigitalCardWithDetailsRequestError(decryptedData))
            }
        } else {
            throw response
        }
    } catch (error) {
        displayConsoleLog(error)
        if (error.response?.status === 401) {
            const errData = {
                status: error.response?.status,
                isSessionError: true,
                message: APP_CONSTANTS.SESSION_EXPIRE_MESSAGE
            }
            yield put({ type: APP_CONSTANTS.COMMON_ERROR, errData })
        } else {
            displayConsoleLog(error.response?.data)
            yield put(addDigitalCardWithDetailsRequestError(error.response.data))
        }
    }
}

export function* addCardWithDetailsReq() {
    yield takeLatest(DigitalCardActionTypes.ADD_CARD_WITH_DETAILS_REQUEST, handleAddDigitalCardWithDetail)
}

function* handleUpdateDigitalCard({ payload }) {
    try {
        const response = yield call(updateDigitalCardAPI, payload);
        if (response.status >= 200 && response.status < 300) {
            const { data } = response
            const decryptedData = decryptResponseData(data)
            if (decryptedData.data !== null) {
                yield put(updateDigitalCardRequestSuccess(decryptedData))
            } else {
                yield put(updateDigitalCardRequestError(decryptedData))
            }
        } else {
            throw response
        }
    } catch (error) {
        displayConsoleLog(error)
        if (error.response?.status === 401) {
            const errData = {
                status: error.response?.status,
                isSessionError: true,
                message: APP_CONSTANTS.SESSION_EXPIRE_MESSAGE
            }
            yield put({ type: APP_CONSTANTS.COMMON_ERROR, errData })
        } else {
            displayConsoleLog(error.response?.data)
            yield put(updateDigitalCardRequestError(error.response.data))
        }
    }
}

export function* updateDigitalCardReq() {
    yield takeLatest(DigitalCardActionTypes.UPDATE_DIGITAL_CARD_REQUEST, handleUpdateDigitalCard)
}

function* handleUpdateCardDetails({ payload }) {
    try {
        const response = yield call(updateCardDetailsAPI, payload);
        if (response.status >= 200 && response.status < 300) {
            const { data } = response
            const decryptedData = decryptResponseData(data)
            if (decryptedData.data !== null) {
                yield put(updateCardDetailsRequestSuccess(decryptedData))
            } else {
                yield put(updateCardDetailsRequestError(decryptedData))
            }
        } else {
            throw response
        }
    } catch (error) {
        displayConsoleLog(error)
        if (error.response?.status === 401) {
            const errData = {
                status: error.response?.status,
                isSessionError: true,
                message: APP_CONSTANTS.SESSION_EXPIRE_MESSAGE
            }
            yield put({ type: APP_CONSTANTS.COMMON_ERROR, errData })
        } else {
            displayConsoleLog(error.response?.data)
            yield put(updateCardDetailsRequestError(error.response.data))
        }
    }
}

export function* updateCardDetailsReq() {
    yield takeLatest(DigitalCardActionTypes.UPDATE_CARD_DETAILS_REQUEST, handleUpdateCardDetails)
}

function* handleDigitalCardList({ payload }) {
    try {
        const response = yield call(digitalCardListAPI, payload);
        if (response.status >= 200 && response.status < 300) {
            const { data } = response
            const decryptedData = decryptResponseData(data)
            if (decryptedData.data !== null) {
                yield put(digitalCardListRequestSuccess(decryptedData))
            } else {
                yield put(digitalCardListRequestError(decryptedData))
            }
        } else {
            throw response
        }
    } catch (error) {
        displayConsoleLog(error)
        if (error.response?.status === 401) {
            const errData = {
                status: error.response?.status,
                isSessionError: true,
                message: APP_CONSTANTS.SESSION_EXPIRE_MESSAGE
            }
            yield put({ type: APP_CONSTANTS.COMMON_ERROR, errData })
        } else {
            displayConsoleLog(error.response?.data)
            yield put(digitalCardListRequestError(error.response.data))
        }
    }
}

export function* digitalCardListReq() {
    yield takeLatest(DigitalCardActionTypes.DIGITAL_CARD_LIST_REQUEST, handleDigitalCardList)
}

function* handleCheckAliasAvailability({ payload }) {
    try {
        const response = yield call(checkAliasAvailabilityAPI, payload);
        if (response.status >= 200 && response.status < 300) {
            const { data } = response
            const decryptedData = decryptResponseData(data)
            if (decryptedData.data !== null) {
                yield put(checkCardAliasRequestSuccess(decryptedData))
            } else {
                yield put(checkCardAliasRequestError(decryptedData))
            }
        } else {
            throw response
        }
    } catch (error) {
        displayConsoleLog(error)
        if (error.response?.status === 401) {
            const errData = {
                status: error.response?.status,
                isSessionError: true,
                message: APP_CONSTANTS.SESSION_EXPIRE_MESSAGE
            }
            yield put({ type: APP_CONSTANTS.COMMON_ERROR, errData })
        } else {
            displayConsoleLog(error.response?.data)
            yield put(checkCardAliasRequestError(error.response.data))
        }
    }
}

export function* checkAliasAvailabilityReq() {
    yield takeLatest(DigitalCardActionTypes.CHECK_ALIAS_AVAILABILITY_REQUEST, handleCheckAliasAvailability)
}

function* handleCardByAlias({ payload }) {
    try {
        const response = yield call(cardByAliasAPI, payload);
        if (response.status >= 200 && response.status < 300) {
            const { data } = response
            const decryptedData = decryptResponseData(data)
            if (decryptedData.data !== null) {
                yield put(cardByAliasRequestSuccess(decryptedData))
            } else {
                yield put(cardByAliasRequestError(decryptedData))
            }
        } else {
            throw response
        }
    } catch (error) {
        displayConsoleLog(error)
        if (error.response?.status === 401) {
            const errData = {
                status: error.response?.status,
                isSessionError: true,
                message: APP_CONSTANTS.SESSION_EXPIRE_MESSAGE
            }
            yield put({ type: APP_CONSTANTS.COMMON_ERROR, errData })
        } else {
            displayConsoleLog(error.response?.data)
            yield put(cardByAliasRequestError(error.response.data))
        }
    }
}

export function* cardByAliasReq() {
    yield takeLatest(DigitalCardActionTypes.CARD_BY_ALIAS_REQUEST, handleCardByAlias)
}

function* handleAddDigitalCard({ payload }) {
    try {
        const response = yield call(addDigitalCardAPI, payload);
        if (response.status >= 200 && response.status < 300) {
            const { data } = response
            const decryptedData = decryptResponseData(data)
            if (decryptedData.data !== null) {
                yield put(addDigitalCardRequestSuccess(decryptedData))
            } else {
                yield put(addDigitalCardRequestError(decryptedData))
            }
        } else {
            throw response
        }
    } catch (error) {
        displayConsoleLog(error)
        if (error.response?.status === 401) {
            const errData = {
                status: error.response?.status,
                isSessionError: true,
                message: APP_CONSTANTS.SESSION_EXPIRE_MESSAGE
            }
            yield put({ type: APP_CONSTANTS.COMMON_ERROR, errData })
        } else {
            displayConsoleLog(error.response?.data)
            yield put(addDigitalCardRequestError(error.response.data))
        }
    }
}

export function* addDigitalCardReq() {
    yield takeLatest(DigitalCardActionTypes.ADD_DIGITAL_CARD_REQUEST, handleAddDigitalCard)
}

function* handleAddCardDetail({ payload }) {
    try {
        const response = yield call(addCardDetailsAPI, payload);
        if (response.status >= 200 && response.status < 300) {
            const { data } = response
            const decryptedData = decryptResponseData(data)
            if (decryptedData.data !== null) {
                yield put(addCardDetailsRequestSuccess(decryptedData))
            } else {
                yield put(addCardDetailsRequestError(decryptedData))
            }
        } else {
            throw response
        }
    } catch (error) {
        displayConsoleLog(error)
        if (error.response?.status === 401) {
            const errData = {
                status: error.response?.status,
                isSessionError: true,
                message: APP_CONSTANTS.SESSION_EXPIRE_MESSAGE
            }
            yield put({ type: APP_CONSTANTS.COMMON_ERROR, errData })
        } else {
            displayConsoleLog(error.response?.data)
            yield put(addCardDetailsRequestError(error.response.data))
        }
    }
}

export function* addCardDetailsReq() {
    yield takeLatest(DigitalCardActionTypes.ADD_CARD_DETAILS_REQUEST, handleAddCardDetail)
}