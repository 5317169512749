import React, { useEffect, useState } from "react";
import './styles.css'
import Loader from '../../../components/loader/loader.component'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
import { useDispatch, useSelector } from 'react-redux'
import {
    clearLocalStorage,
    displayErrorToast, displaySuccessToast, isEmpty, isNull, isValidEmail, screenCaptureAnalytics
} from '../../../utils/Utils'
import { API_CONSTANTS, APP_CONSTANTS, ROUTES } from "../../../utils/AppConstants";
import { userRegistrationBusinessRequest, displaySideBarAct } from '../../../redux/user/user.action'
import { useNavigate } from 'react-router-dom';
import CommonScreenContent from "../../../components/screen/common-screen-content/commonScreenContent";
import CommonScreenContentCenter from "../../../components/screen/common-screen-content-center/commonScreenContentCenter";
import CommonColorForm from "../../../components/common-color-form/commonColorForm";
import SubTextLabel from "../../../components/label/sub-text-label/subTextLabel";
import CommonInputRow from "../../../components/input/common-input-row/commonInputRow";
import CommonInputHalf from "../../../components/input/common-input-half/commonInputHalf";
import ButtonRow from "../../../components/button/button-row/buttonRow";
import CommonButton from "../../../components/button/common-button/commonButton";
import CommonInputFloatHalf from "../../../components/input/common-input-float-half/commonInputFloafHalf";
import CommonInputFloatFull from "../../../components/input/common-input-float-full/commonInputFloatFull";
import Paper from '@mui/material/Paper';
import FormFieldLabel from "../../../components/label/form-field-label/formFieldLabel";

const SignUpScreen = props => {
    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [email, setEmail] = useState('')
    const [businessName, setBusinessName] = useState('')
    const [buildingAddress, setBuildingAddress] = useState('')
    const [password, setPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')
    const [isLoading, setLoading] = useState(false)
    const dispatch = useDispatch()
    const { user, loginErrorData, isLogin } = useSelector(state => state.user)
    let navigate = useNavigate();
    const [imagePath, setImagePath] = useState(null)
    const [imageName, setImageName] = useState('')

    useEffect(() => {
        const data = {
            screen: 'SIGN_UP',
            screenClass: 'SIGN_UP'
        }
        screenCaptureAnalytics(data)
    }, [])

    useEffect(() => {
        dispatch({ type: APP_CONSTANTS.RESET_STATE_CITY })
        sessionStorage.clear()
        clearLocalStorage()
        dispatch({ type: APP_CONSTANTS.LOGOUT })
    }, [])

    useEffect(() => {
        if (isLogin) {
            setLoading(false)
            displaySuccessToast('User registration successfull')
            sessionStorage.setItem(APP_CONSTANTS.USER, JSON.stringify(user.data))
            sessionStorage.setItem(APP_CONSTANTS.USER_TOKEN, user.data.authToken)
            dispatch(displaySideBarAct(true))
            navigate(ROUTES.visitorList, { replace: true })
        } else {
            if (loginErrorData) {
                setLoading(false)
                displayErrorToast(loginErrorData.message)
            }
        }
    }, [user, isLogin, loginErrorData])

    const handleCreateClick = () => {
        if (isEmpty(firstName)) {
            displayErrorToast('First name cannot be blank', true)
        } else if (isEmpty(lastName)) {
            displayErrorToast('Last name cannot be blank', true)
        } else if (isEmpty(email)) {
            displayErrorToast('Email cannot be blank', true)
        } else if (!isValidEmail(email)) {
            displayErrorToast('Invalid email id', true)
        } else if (isEmpty(businessName)) {
            displayErrorToast('Company name cannot be blank', true)
        } else if (isEmpty(password)) {
            displayErrorToast('Password cannot be blank', true)
        } else if (password.length <= 6) {
            displayErrorToast('Password should be minimum 7 characters', true)
        } else if (isEmpty(confirmPassword)) {
            displayErrorToast('Confirm pasword cannot be blank', true)
        } else if (password !== confirmPassword) {
            displayErrorToast('Password does not match', true)
        } else {
            setLoading(true)
            // dispatch(ipLocationRequest(''))
            callRegisterRequest()
        }
    }

    const callRegisterRequest = () => {
        // const mobileWithoutPlusSymbol = mobile?.replace('+', "")
        let fd = new FormData()
        fd.append("firstName", firstName)
        fd.append("lastName", lastName)
        fd.append("email", email)
        fd.append("password", password)
        fd.append("userType", APP_CONSTANTS.USER_TYPE_BUSINESS)
        fd.append("productCode", APP_CONSTANTS.PRODUCT_NAME)
        fd.append("businessName", businessName)
        fd.append("registeredPlatform", APP_CONSTANTS.REGISTERED_PLATFORM.VISITOR_MANAGEMENT)
        if (!isEmpty(buildingAddress)) {
            fd.append("buildingAddress", buildingAddress)
        }
        if (!isNull(localStorage.getItem(APP_CONSTANTS.KEY_FCM_TOKEN)) && !isEmpty(localStorage.getItem(APP_CONSTANTS.KEY_FCM_TOKEN))) {
            fd.append("notificationToken", localStorage.getItem(APP_CONSTANTS.KEY_FCM_TOKEN))
        }
        if (!isNull(imagePath) && !isEmpty(imageName))
            fd.append('image', imagePath, imageName)
        dispatch(userRegistrationBusinessRequest(fd))
    }

    const navigateToHome = () => {
        navigate(-1)
    }

    const handleImageChange = event => {
        setImagePath(event.target.files[0])
        setImageName(event.target.files[0].name)
    }

    return (
        <>
            <CommonScreenContentCenter>
                <div className="logo-img-contain" onClick={() => { navigateToHome() }}>
                    {/* <img src={require('../../../assets/GoMoBites.png')} className="img" /> */}
                    <img src={require('../../../assets/nLogo2.png')} className="img" />
                </div>
                <Paper elevation={3} style={{ background: '#fbfbfb' }} className='ac-card paper-container'>
                    <SubTextLabel label='Enter details to create new business account' />
                    <CommonInputRow>
                        <CommonInputFloatHalf
                            type="name"
                            id="name"
                            placeholder="First Name"
                            value={firstName}
                            isRequired={true}
                            onChange={e => setFirstName(e.target.value)} />
                        <CommonInputFloatHalf
                            type="name"
                            id="name"
                            value={lastName}
                            isRequired={true}
                            onChange={e => setLastName(e.target.value)}
                            placeholder='Last Name' />
                    </CommonInputRow>
                    <CommonInputFloatFull
                        type="email"
                        id="email"
                        placeholder="Email Id"
                        value={email}
                        isRequired={true}
                        onChange={e => setEmail(e.target.value)}
                    />
                    <CommonInputFloatFull
                        type="email"
                        id="email"
                        placeholder="Company Name"
                        value={businessName}
                        isRequired={true}
                        onChange={e => setBusinessName(e.target.value)}
                    />
                    <CommonInputFloatFull
                        type="text"
                        id="email"
                        placeholder="Building"
                        value={buildingAddress}
                        onChange={e => setBuildingAddress(e.target.value)}
                        subText='Ex. Building / Tower xx or other identifier'
                        isSubtext={true}
                    />
                    <CommonInputRow>
                        <CommonInputFloatHalf
                            type="password"
                            id="name"
                            placeholder="Password"
                            value={password}
                            isRequired={true}
                            onChange={e => setPassword(e.target.value)} />
                        <CommonInputFloatHalf
                            type="name"
                            id="name"
                            value={confirmPassword}
                            isRequired={true}
                            onChange={e => setConfirmPassword(e.target.value)}
                            placeholder='Confirm Password' />
                    </CommonInputRow>
                    <FormFieldLabel label='Select Business Logo' />
                    <div className="input-group">
                        <input type="file" className="form-control" id="inputGroupFile04" aria-describedby="inputGroupFileAddon04" aria-label="Upload Image" onChange={handleImageChange} />
                    </div>
                    <ButtonRow>
                        <CommonButton label='Submit'
                            onClick={() => handleCreateClick()} />
                    </ButtonRow>
                </Paper>

            </CommonScreenContentCenter>
            <ToastContainer />
            <Loader active={isLoading} text='Please wait...' />
        </>
    )
}

export default SignUpScreen