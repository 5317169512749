import { takeLatest, call, put } from 'redux-saga/effects'
import {
    nfcCardOrderDetailsAPI, nfcCardOrderListAPI, placeNfcCardOrderAPI,
    updateNfcCardOrderDetailsAPI
} from '../api-requests/nfcCardOrder';
import {
    nfcCardOrderDetailRequestError, nfcCardOrderDetailRequestSuccess, nfcCardOrderListRequestError,
    nfcCardOrderListRequestSuccess, placeNfcCardOrderRequestError, placeNfcCardOrderRequestSuccess,
    updateNfcCardOrderDetailsRequestError, updateNfcCardOrderDetailsRequestSuccess
} from './nfcCardOrder.action';
import { NfcCardOrderActionTypes } from './nfcCardOrder.types'
import { displayConsoleLog } from '../../utils/Utils'
import { decryptResponseData } from '../../utils/DataDecryptionUtils';
import { APP_CONSTANTS } from '../../utils/AppConstants';

function* handleUpdateNfcCardOrderDetails({ payload }) {
    try {
        const response = yield call(updateNfcCardOrderDetailsAPI, payload);
        if (response.status >= 200 && response.status < 300) {
            const { data } = response
            const decryptedData = decryptResponseData(data)
            if (decryptedData.data !== null) {
                yield put(updateNfcCardOrderDetailsRequestSuccess(decryptedData))
            } else {
                yield put(updateNfcCardOrderDetailsRequestError(decryptedData))
            }
        } else {
            throw response
        }
    } catch (error) {
        displayConsoleLog(error)
        if (error.response?.status === 401) {
            const errData = {
                status: error.response?.status,
                isSessionError: true,
                message: APP_CONSTANTS.SESSION_EXPIRE_MESSAGE
            }
            yield put({ type: APP_CONSTANTS.COMMON_ERROR, errData })
        } else {
            displayConsoleLog(error.response?.data)
            yield put(updateNfcCardOrderDetailsRequestError(error.response.data))
        }
    }
}

export function* updateNfcCardOrderDetailsReq() {
    yield takeLatest(NfcCardOrderActionTypes.UPDATE_NFC_CARD_ORDER_DETAIL_REQUEST, handleUpdateNfcCardOrderDetails)
}

function* handlePlaceNfcCardOrder({ payload }) {
    try {
        const response = yield call(placeNfcCardOrderAPI, payload);
        if (response.status >= 200 && response.status < 300) {
            const { data } = response
            const decryptedData = decryptResponseData(data)
            if (decryptedData.data !== null) {
                yield put(placeNfcCardOrderRequestSuccess(decryptedData))
            } else {
                yield put(placeNfcCardOrderRequestError(decryptedData))
            }
        } else {
            throw response
        }
    } catch (error) {
        displayConsoleLog(error)
        if (error.response?.status === 401) {
            const errData = {
                status: error.response?.status,
                isSessionError: true,
                message: APP_CONSTANTS.SESSION_EXPIRE_MESSAGE
            }
            yield put({ type: APP_CONSTANTS.COMMON_ERROR, errData })
        } else {
            displayConsoleLog(error.response?.data)
            yield put(placeNfcCardOrderRequestError(error.response.data))
        }
    }
}

export function* placeNfcCardOrderReq() {
    yield takeLatest(NfcCardOrderActionTypes.PLACE_NFC_CARD_ORDER_REQUEST, handlePlaceNfcCardOrder)
}

function* handleNfcCardOrderList({ payload }) {
    try {
        const response = yield call(nfcCardOrderListAPI, payload);
        if (response.status >= 200 && response.status < 300) {
            const { data } = response
            const decryptedData = decryptResponseData(data)
            if (decryptedData.data !== null) {
                yield put(nfcCardOrderListRequestSuccess(decryptedData))
            } else {
                yield put(nfcCardOrderListRequestError(decryptedData))
            }
        } else {
            throw response
        }
    } catch (error) {
        displayConsoleLog(error)
        if (error.response?.status === 401) {
            const errData = {
                status: error.response?.status,
                isSessionError: true,
                message: APP_CONSTANTS.SESSION_EXPIRE_MESSAGE
            }
            yield put({ type: APP_CONSTANTS.COMMON_ERROR, errData })
        } else {
            displayConsoleLog(error.response?.data)
            yield put(nfcCardOrderListRequestError(error.response.data))
        }
    }
}

export function* nfcCardOrderListReq() {
    yield takeLatest(NfcCardOrderActionTypes.NFC_CARD_ORDER_LIST_REQUEST, handleNfcCardOrderList)
}

function* handleNfcCardOrderDetail({ payload }) {
    try {
        const response = yield call(nfcCardOrderDetailsAPI, payload);
        if (response.status >= 200 && response.status < 300) {
            const { data } = response
            const decryptedData = decryptResponseData(data)
            if (decryptedData.data !== null) {
                yield put(nfcCardOrderDetailRequestSuccess(decryptedData))
            } else {
                yield put(nfcCardOrderDetailRequestError(decryptedData))
            }
        } else {
            throw response
        }
    } catch (error) {
        displayConsoleLog(error)
        if (error.response?.status === 401) {
            const errData = {
                status: error.response?.status,
                isSessionError: true,
                message: APP_CONSTANTS.SESSION_EXPIRE_MESSAGE
            }
            yield put({ type: APP_CONSTANTS.COMMON_ERROR, errData })
        } else {
            displayConsoleLog(error.response?.data)
            yield put(nfcCardOrderDetailRequestError(error.response.data))
        }
    }
}

export function* nfcCardOrderDetailReq() {
    yield takeLatest(NfcCardOrderActionTypes.NFC_CARD_ORDER_DETAIL_REQUEST, handleNfcCardOrderDetail)
}