import { QRCodeActionTypes } from './customQrCode.types'

//Add
export const addQrCodeRequest = data => ({
    type: QRCodeActionTypes.ADD_CUSTOM_QR_CODE_REQUEST,
    payload: data
})

export const addQrCodeRequestSuccess = data => ({
    type: QRCodeActionTypes.ADD_CUSTOM_QR_CODE_REQUEST_SUCCESS,
    payload: data
})

export const addQrCodeRequestError = data => ({
    type: QRCodeActionTypes.ADD_CUSTOM_QR_CODE_REQUEST_ERROR,
    payload: data
})

export const addQrCodeRequestStatus = data => ({
    type: QRCodeActionTypes.ADD_CUSTOM_QR_CODE_REQUEST_STATUS,
    payload: data
})

//List
export const qrCodeListRequest = data => ({
    type: QRCodeActionTypes.CUSTOM_QR_CODE_LIST_REQUEST,
    payload: data
})

export const qrCodeListRequestSuccess = data => ({
    type: QRCodeActionTypes.CUSTOM_QR_CODE_LIST_REQUEST_SUCCESS,
    payload: data
})

export const qrCodeListRequestError = data => ({
    type: QRCodeActionTypes.CUSTOM_QR_CODE_LIST_REQUEST_ERROR,
    payload: data
})

//Delete
export const deletQrCodeRequest = data => ({
    type: QRCodeActionTypes.DELETE_CUSTOM_QR_CODE_REQUEST,
    payload: data
})

export const deletQrCodeRequestSuccess = data => ({
    type: QRCodeActionTypes.DELETE_CUSTOM_QR_CODE_REQUEST_SUCCESS,
    payload: data
})

export const deletQrCodeRequestError = data => ({
    type: QRCodeActionTypes.DELETE_CUSTOM_QR_CODE_REQUEST_ERROR,
    payload: data
})

export const deletQrCodeRequestStatus = data => ({
    type: QRCodeActionTypes.DELETE_CUSTOM_QR_CODE_REQUEST_STATUS,
    payload: data
})

//Update
export const updateQrCodeRequest = data => ({
    type: QRCodeActionTypes.UPDATE_QR_CODE_REQUEST,
    payload: data
})

export const updateQrCodeRequestSuccess = data => ({
    type: QRCodeActionTypes.UPDATE_QR_CODE_REQUEST_SUCCESS,
    payload: data
})

export const updateQrCodeRequestError = data => ({
    type: QRCodeActionTypes.UPDATE_QR_CODE_REQUEST_ERROR,
    payload: data
})

export const updateQrCodeRequestStatus = data => ({
    type: QRCodeActionTypes.UPDATE_QR_CODE_REQUEST_STATUS,
    payload: data
})

//Qr Detail
export const qrCodeDetailRequest = data => ({
    type: QRCodeActionTypes.QR_CODE_DETAIL_REQUEST,
    payload: data
})

export const qrCodeDetailRequestSuccess = data => ({
    type: QRCodeActionTypes.QR_CODE_DETAIL_REQUEST_SUCCESS,
    payload: data
})

export const qrCodeDetailRequestError = data => ({
    type: QRCodeActionTypes.QR_CODE_DETAIL_REQUEST_ERROR,
    payload: data
})