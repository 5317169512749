import { ProductActionTypes } from './product.types'
import { API_CONSTANTS } from '../../utils/AppConstants'

const INITIAL_STATE = {
    addProductSuccess: null,
    addProductError: null,
    addProductReqStatus: '',
    productListSuccess: null,
    productListError: null,
    updateProductSuccess: null,
    updateProductError: null,
    updateProductReqStatus: '',
    deleteProductSuccess: null,
    deleteProductError: null,
    deleteProductReqStatus: ''
}

const productReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case ProductActionTypes.ADD_PRODUCT_REQUEST_SUCCESS:
            return {
                ...state,
                addProductSuccess: action.payload,
                addProductError: null,
                addProductReqStatus: API_CONSTANTS.SUCCESS_STATUS
            }
        case ProductActionTypes.ADD_PRODUCT_REQUEST_ERROR:
            return {
                ...state,
                addProductSuccess: null,
                addProductError: action.payload,
                addProductReqStatus: API_CONSTANTS.ERROR_STATUS
            }
        case ProductActionTypes.ADD_PRODUCT_REQUEST_STATUS:
            return {
                ...state,
                addProductReqStatus: ''
            }
        case ProductActionTypes.PRODUCT_LIST_REQUEST_SUCCESS:
            return {
                ...state,
                productListSuccess: action.payload,
                productListError: null
            }
        case ProductActionTypes.PRODUCT_LIST_REQUEST_ERROR:
            return {
                ...state,
                productListSuccess: null,
                productListError: action.payload
            }
        case ProductActionTypes.UPDATE_PRODUCT_REQUEST_SUCCESS:
            return {
                ...state,
                updateProductSuccess: action.payload,
                updateProductError: null,
                updateProductReqStatus: API_CONSTANTS.SUCCESS_STATUS
            }
        case ProductActionTypes.UPDATE_PRODUCT_REQUEST_ERROR:
            return {
                ...state,
                updateProductSuccess: null,
                updateProductError: action.payload,
                updateProductReqStatus: API_CONSTANTS.ERROR_STATUS
            }
        case ProductActionTypes.UPDATE_PRODUCT_REQUEST_STATUS:
            return {
                ...state,
                updateProductReqStatus: ''
            }
        case ProductActionTypes.DELETE_PRODUCT_REQUEST_SUCCESS:
            return {
                ...state,
                deleteProductSuccess: action.payload,
                deleteProductError: null,
                deleteProductReqStatus: API_CONSTANTS.SUCCESS_STATUS
            }
        case ProductActionTypes.DELETE_PRODUCT_REQUEST_ERROR:
            return {
                ...state,
                deleteProductSuccess: null,
                deleteProductError: action.payload,
                deleteProductReqStatus: API_CONSTANTS.ERROR_STATUS
            }
        case ProductActionTypes.DELETE_PRODUCT_REQUEST_STATUS:
            return {
                ...state,
                deleteProductReqStatus: ''
            }
        case ProductActionTypes.LOGOUT:
            return INITIAL_STATE
        default:
            return state
    }
}

export default productReducer