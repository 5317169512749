import React, { useEffect, useState } from "react";
import './styles.css'
import QRCode from "react-qr-code";
import { isEmpty } from "../../../../utils/Utils";

const NfcCardDesign1 = props => {
    let { name, designation, phoneNumber, email, address, qrCodeUrl,
        selectDesignHandler, isSelected, showButton = false } = props
    // phoneNumber = (!isEmpty(phoneNumber) && !phoneNumber.includes('+')) ? `+${phoneNumber}` : phoneNumber
    return (
        <div className="nts-card-parent-container">
            <div className="nts-card-container-one">
                <div className="nts-card-container-one-qr-container">
                    <QRCode value={qrCodeUrl} size={75}
                        fgColor="#fff"
                        bgColor="#000"
                    />
                </div>
                <div className="nts-card-container-one-name-container">
                    <div className="nts-card-container-one-name">{name}</div>
                    <div className="nts-card-container-one-designation">{designation}</div>
                </div>
                <div className="nts-card-container-one-other-container">
                    <div className="nts-card-container-one-other"><span>{!isEmpty(phoneNumber) ? (<div className="nts-card-container-one-icon-container">
                        <img src={require('../../../../assets/nfc/ic_phone_white.png')} className="img" alt="..." />
                    </div>) : null}</span><span className="nts-card-container-one-other-text">{phoneNumber}</span></div>
                    <div className="nts-card-container-one-other"><span>{!isEmpty(email) ? (<div className="nts-card-container-one-icon-container">
                        <img src={require('../../../../assets/nfc/ic_email_white.png')} className="img" alt="..." />
                    </div>) : null}</span><span className="nts-card-container-one-other-text">{email}</span></div>
                    <div className="nts-card-container-one-other-address"><span>{!isEmpty(address) ? (<div className="nts-card-container-one-icon-container">
                        <img src={require('../../../../assets/nfc/ic_address_white.png')} className="img" alt="..." />
                    </div>) : null}</span><span className="nts-card-container-one-other-text">{address}</span></div>
                </div>
            </div>
            {showButton === true && <div className="nts-select-design-label-container">
                <div className={`${isSelected === true ? "nts-select-design-label-selected" : "nts-select-design-label"}`} onClick={selectDesignHandler}>Select Design</div>
            </div>}
        </div>
    )
}

export default NfcCardDesign1