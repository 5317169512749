import { takeLatest, call, put } from 'redux-saga/effects'
import {
    addEnquiryAPI, enquiryListAPI, updateEnquiryStatusAPI
} from '../api-requests/enquiry';
import {
    addEnquiryRequestError, addEnquiryRequestSuccess, enquiryListRequestError,
    enquiryListRequestSuccess, updateEnquiryRequestError, updateEnquiryRequestSuccess
} from './enquiry.action';
import { EnquiryActionTypes } from './enquiry.types'
import { displayConsoleLog } from '../../utils/Utils'
import { decryptResponseData } from '../../utils/DataDecryptionUtils';
import { APP_CONSTANTS } from '../../utils/AppConstants';

function* handleUpdateEnquiryStatus({ payload }) {
    try {
        const response = yield call(updateEnquiryStatusAPI, payload);
        if (response.status >= 200 && response.status < 300) {
            const { data } = response
            const decryptedData = decryptResponseData(data)
            if (decryptedData.data !== null) {
                yield put(updateEnquiryRequestSuccess(decryptedData))
            } else {
                yield put(updateEnquiryRequestError(decryptedData))
            }
        } else {
            throw response
        }
    } catch (error) {
        displayConsoleLog(error)
        if (error.response?.status === 401) {
            const errData = {
                status: error.response?.status,
                isSessionError: true,
                message: APP_CONSTANTS.SESSION_EXPIRE_MESSAGE
            }
            yield put({ type: EnquiryActionTypes.COMMON_ERROR, errData })
        } else {
            displayConsoleLog(error.response?.data)
            yield put(updateEnquiryRequestError(error.response.data))
        }
    }
}

export function* updateEnquiryReq() {
    yield takeLatest(EnquiryActionTypes.UPDATE_ENQUIRY_REQUEST, handleUpdateEnquiryStatus)
}

function* handleEnquiryList({ payload }) {
    try {
        const response = yield call(enquiryListAPI, payload);
        if (response.status >= 200 && response.status < 300) {
            const { data } = response
            const decryptedData = decryptResponseData(data)
            if (decryptedData.data !== null) {
                yield put(enquiryListRequestSuccess(decryptedData))
            } else {
                yield put(enquiryListRequestError(decryptedData))
            }
        } else {
            throw response
        }
    } catch (error) {
        displayConsoleLog(error)
        if (error.response?.status === 401) {
            const errData = {
                status: error.response?.status,
                isSessionError: true,
                message: APP_CONSTANTS.SESSION_EXPIRE_MESSAGE
            }
            yield put({ type: EnquiryActionTypes.COMMON_ERROR, errData })
        } else {
            displayConsoleLog(error.response?.data)
            yield put(enquiryListRequestError(error.response.data))
        }
    }
}

export function* enquiryListReq() {
    yield takeLatest(EnquiryActionTypes.ENQUIRY_LIST_REQUEST, handleEnquiryList)
}

function* handleAddEnquiry({ payload }) {
    try {
        const response = yield call(addEnquiryAPI, payload);
        if (response.status >= 200 && response.status < 300) {
            const { data } = response
            const decryptedData = decryptResponseData(data)
            if (decryptedData.data !== null) {
                yield put(addEnquiryRequestSuccess(decryptedData))
            } else {
                yield put(addEnquiryRequestError(decryptedData))
            }
        } else {
            throw response
        }
    } catch (error) {
        displayConsoleLog(error)
        if (error.response?.status === 401) {
            const errData = {
                status: error.response?.status,
                isSessionError: true,
                message: APP_CONSTANTS.SESSION_EXPIRE_MESSAGE
            }
            yield put({ type: EnquiryActionTypes.COMMON_ERROR, errData })
        } else {
            displayConsoleLog(error.response?.data)
            yield put(addEnquiryRequestError(error.response.data))
        }
    }
}

export function* addEnquiryReq() {
    yield takeLatest(EnquiryActionTypes.ADD_ENQUIRY_REQUEST, handleAddEnquiry)
}