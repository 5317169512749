import React, { useEffect, useState } from "react";
import './styles.css'
import CommonScreenContent from "../../../components/screen/common-screen-content/commonScreenContent";
import ScreenLabel from "../../../components/label/screen-label/screenLabel";
import CommonColorForm from "../../../components/common-color-form/commonColorForm";
import FormFieldLabel from "../../../components/label/form-field-label/formFieldLabel";
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Loader from '../../../components/loader/loader.component'
import { ToastContainer } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux'
import Modal from 'react-bootstrap/Modal';
import CommonInputFloatFull from "../../../components/input/common-input-float-full/commonInputFloatFull";
import CommonButton from "../../../components/button/common-button/commonButton";
import { displayConsoleLog, displayErrorToast, isEmpty, isNull } from "../../../utils/Utils";
import { encryptResponse, decryptStringData } from "../../../utils/DataDecryptionUtils";
import { config } from "../../../config";
import { siteVisitorAnayticsListRequest, siteAndUserAnayticsListRequest } from '../../../redux/site-visitor-analytics/siteVisitorAnalytics.action'
import { APP_CONSTANTS } from "../../../utils/AppConstants";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import moment from "moment";
import '@wojtekmaj/react-daterange-picker/dist/DateRangePicker.css';
import 'react-calendar/dist/Calendar.css';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS } from 'chart.js/auto'

const SiteAnalytics = props => {
    const [isLoading, setLoading] = useState(false)
    const dispatch = useDispatch()
    const { user, commonError } = useSelector(state => state.user)
    const { siteVisitorAnalyticsListSuccess, siteVisitorAnalyticsListError,
        siteAndUserAnalyticsListSuccess, siteAndUserAnalyticsListError } = useSelector(state => state.siteVisitorAnalytics)
    const [showPasswordModal, setShowPasswordModal] = useState(false)
    // const [pagePassword, setPagePassword] = useState('ClickTran@2023')
    const [pagePassword, setPagePassword] = useState('')
    const [displayContent, setDisplayContent] = useState(false)
    const [selectedDateType, setSelectedDateType] = useState()
    const DATE_TYPE_LIST = [
        { key: APP_CONSTANTS.ANALYTICS_DATE_TYPE.PAST_24_HOURS, value: 'Last 24 Hours' },
        { key: APP_CONSTANTS.ANALYTICS_DATE_TYPE.PAST_2_DAYS, value: 'Last 2 Days' },
        { key: APP_CONSTANTS.ANALYTICS_DATE_TYPE.PAST_5_DAYS, value: 'Last 5 Days' },
        { key: APP_CONSTANTS.ANALYTICS_DATE_TYPE.PAST_WEEK, value: 'Last 7 Days' },
        { key: APP_CONSTANTS.ANALYTICS_DATE_TYPE.PAST_15_DAYS, value: 'Last 15 Days' },
        { key: APP_CONSTANTS.ANALYTICS_DATE_TYPE.PAST_MONTH, value: 'Last 1 Month' },
        { key: APP_CONSTANTS.ANALYTICS_DATE_TYPE.CUSTOM_DATE, value: 'Custom Date' },
    ]
    const [dateRange, setDateRange] = useState([new Date(), new Date()]);
    const [chartData, setChartData] = useState()
    const [displayCardList, setDisplayCardList] = useState(false)
    const [cardList, setCardList] = useState([])

    useEffect(() => {
        setShowPasswordModal(true)
        setDisplayContent(false)
    }, [])

    useEffect(() => {
        if (!isNull(siteVisitorAnalyticsListSuccess)) {
            setLoading(false)
            setChartData({
                labels: siteVisitorAnalyticsListSuccess.data.map((data) => data.visitDate),
                datasets: [{
                    label: 'Visitor',
                    data: siteVisitorAnalyticsListSuccess.data.map((data) => data.dailyCount),
                }]
            })
        } else if (!isNull(siteVisitorAnalyticsListError)) {
            setLoading(false)
            displayErrorToast(siteVisitorAnalyticsListError.message)
        }
    }, [siteVisitorAnalyticsListSuccess, siteVisitorAnalyticsListError])

    useEffect(() => {
        if (!isNull(siteAndUserAnalyticsListSuccess)) {
            setLoading(false)
            // setChartData({
            //     labels: siteAndUserAnalyticsListSuccess.data.map((data) => data.visitDate),
            //     datasets: [{
            //         label: 'Visitor',
            //         data: siteAndUserAnalyticsListSuccess.data.map((data) => data.dailyCount),
            //     }]
            // })
        } else if (!isNull(siteAndUserAnalyticsListError)) {
            setLoading(false)
            displayErrorToast(siteAndUserAnalyticsListError.message)
        }
    }, [siteAndUserAnalyticsListSuccess, siteAndUserAnalyticsListError])

    const fetchAnalyticsData = (dateType) => {
        resetCardList()
        setLoading(true)
        if (dateType === APP_CONSTANTS.ANALYTICS_DATE_TYPE.CUSTOM_DATE) {
            const data = {
                dateType: dateType,
                startDate: dateRange[0].toString(),
                endDate: dateRange[1].toString(),
                productCode: APP_CONSTANTS.PRODUCT_NAME
            }
            dispatch(siteAndUserAnayticsListRequest(data))
        } else {
            const data = {
                dateType: dateType,
                productCode: APP_CONSTANTS.PRODUCT_NAME
            }
            dispatch(siteAndUserAnayticsListRequest(data))
        }
    }

    useEffect(() => {
        if (!isNull(dateRange)) {
            let startDate = dateRange[0]
            let stringStartDate = startDate.toString()

            let endDate = dateRange[1]
            let stringEndDate = endDate.toString()
            // fetchUserDate(stringStartDate, stringEndDate)
            fetchAnalyticsData(APP_CONSTANTS.ANALYTICS_DATE_TYPE.CUSTOM_DATE)
        }
    }, [dateRange])

    const handleSelectChange = (event) => {
        setSelectedDateType(event.target.value)
    };

    const handlePasswordSubmitClick = () => {
        if (isEmpty(pagePassword)) {
            displayErrorToast('Password cannot be blank')
        } else {
            let encyptPassword = decryptStringData(config.siteAnalyticsPassword)
            if (encyptPassword === pagePassword) {
                setDisplayContent(true)
                setShowPasswordModal(false)
                fetchAnalyticsData(APP_CONSTANTS.ANALYTICS_DATE_TYPE.PAST_24_HOURS)
            } else {
                displayErrorToast('Incorrect Password. Try Again!')
                setDisplayContent(false)
            }
        }
    }

    useEffect(() => {
        if (!isEmpty(selectedDateType)) {
            if (selectedDateType !== APP_CONSTANTS.ANALYTICS_DATE_TYPE.CUSTOM_DATE) {
                fetchAnalyticsData(selectedDateType)
            }
        }
    }, [selectedDateType])

    const generateChartResponse = () => {
        const chartData = {
            labels: [],
            datasets: [
                {
                    label: 'Daily Count',
                    data: [],
                    fill: false,
                    borderColor: 'rgba(243,206,90,1)',
                    tension: 0.1
                }
            ]
        };

        siteAndUserAnalyticsListSuccess?.data?.siteVisitorList?.forEach(item => {
            let localDateTime = moment.utc(item.visitDate).local()
            chartData.labels.push(localDateTime.format('DD-MM-YYYY'));
            chartData.datasets[0].data.push(item.dailyCount);
        });
        return chartData;
    }

    const ChartComponent = () => {
        const chartData = generateChartResponse();

        const options = {
            scales: {
                y: {
                    min: 0,
                    max: 100,
                    stepSize: 10,
                    title: {
                        display: true,
                        text: 'Number of Visits'
                    }
                },
                x: {
                    title: {
                        display: true,
                        text: 'Date'
                    },
                    offset: true
                    // ,
                    // type: 'time',
                    // time: {
                    //     unit: 'day'
                    // }
                }
            },
            legend: {
                display: false
            }
        };

        return <div className="chart-container">
            <Line data={chartData} options={options} />
        </div>;
    };

    const getTotalUniqueVisit = (dataList) => {
        let uniqueVisits = 0
        if (!isEmpty(dataList)) {
            for (let i = 0; i < dataList.length; i++) {
                uniqueVisits += parseInt(dataList[i].uniqueCount);
            }
        }
        return uniqueVisits
    }

    const getTotalRegisteredVisit = (dataList) => {
        let uniqueVisits = 0
        if (!isEmpty(dataList)) {
            for (let i = 0; i < dataList.length; i++) {
                uniqueVisits += parseInt(dataList[i].registeredCount);
            }
        }
        return uniqueVisits
    }

    const getTotalDailyVisit = (dataList) => {
        let uniqueVisits = 0
        if (!isEmpty(dataList)) {
            for (let i = 0; i < dataList.length; i++) {
                uniqueVisits += parseInt(dataList[i].dailyCount);
            }
        }
        return uniqueVisits
    }

    const cardClickHandle = element => {
        if (displayCardList) {
            resetCardList()
        } else {
            if (!isEmpty(element)) {
                setCardList(element)
                setDisplayCardList(true)
            }
        }
    }

    const resetCardList = () => {
        setCardList([])
        setDisplayCardList(false)
    }

    return (
        <>
            <CommonScreenContent>
                <ScreenLabel label='ClickTran Analytics' />
                {displayContent && <div className="cutom-date-select">
                    <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">Select Date</InputLabel>
                        <Select
                            defaultValue={APP_CONSTANTS.ANALYTICS_DATE_TYPE.PAST_24_HOURS}
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={selectedDateType}
                            label="Select Date"
                            onChange={handleSelectChange}
                        >
                            {DATE_TYPE_LIST.map(e => (
                                <MenuItem value={e.key}>{e.value}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </div>}
                {displayContent && selectedDateType === APP_CONSTANTS.ANALYTICS_DATE_TYPE.CUSTOM_DATE &&
                    <div className="cutom-date-range">
                        <DateRangePicker
                            onChange={setDateRange}
                            value={dateRange} />
                    </div>}
                {displayContent && <CommonColorForm className='content-container-bg'>
                    <FormFieldLabel className='up-bold-label mb-2' label='Site Analaytics' />
                    {!isNull(siteAndUserAnalyticsListSuccess) && !isEmpty(siteAndUserAnalyticsListSuccess.data) && !isEmpty(siteAndUserAnalyticsListSuccess.data.siteVisitorList) && <ChartComponent />}
                    {!isNull(siteAndUserAnalyticsListSuccess) && !isEmpty(siteAndUserAnalyticsListSuccess.data) && !isEmpty(siteAndUserAnalyticsListSuccess.data.siteVisitorList) && <div className="dash-card-row">
                        <div className="dash-card">
                            <div className="dash-header-label-left">Unique Visitors</div>
                            <div className="dash-header-label-val">{getTotalUniqueVisit(siteAndUserAnalyticsListSuccess.data.siteVisitorList)}</div>
                        </div>
                        <div className="dash-card">
                            <div className="dash-header-label-left">Registered Visitors</div>
                            <div className="dash-header-label-val">{getTotalRegisteredVisit(siteAndUserAnalyticsListSuccess.data.siteVisitorList)}</div>
                        </div>
                        <div className="dash-card">
                            <div className="dash-header-label-left">Daily Visits</div>
                            <div className="dash-header-label-val">{getTotalDailyVisit(siteAndUserAnalyticsListSuccess.data.siteVisitorList)}</div>
                        </div>
                    </div>}
                </CommonColorForm>}
                {displayContent && <CommonColorForm className='content-container-bg'>
                    <FormFieldLabel className='up-bold-label mb-2' label='User Analaytics' />
                    {!isNull(siteAndUserAnalyticsListSuccess) && !isEmpty(siteAndUserAnalyticsListSuccess.data) && <div className="dash-card-row">
                        <div className="dash-card">
                            <div className="dash-header-label-left">Total Registration</div>
                            <div className="dash-header-label-val">{siteAndUserAnalyticsListSuccess.data.userList?.length}</div>
                        </div>
                        <div className="dash-card" onClick={() => { cardClickHandle(siteAndUserAnalyticsListSuccess.data.cardList) }}>
                            <div className="dash-header-label-left">Card Created</div>
                            <div className="dash-header-label-val">{siteAndUserAnalyticsListSuccess.data.cardList?.length}</div>
                        </div>
                    </div>}
                </CommonColorForm>}
                {displayContent && displayCardList && <CommonColorForm className='content-container-bg'>
                    <FormFieldLabel className='up-bold-label mb-2' label='Card List' />
                    <div className="table-scroll-container">
                        {!isEmpty(cardList) && <table className="table table-hover mt-4">
                            <thead>
                                <tr>
                                    <th scope="col" className="text-center">Name</th>
                                    <th scope="col" className="text-center">Business Name</th>
                                    <th scope="col" className="text-center">Email Id</th>
                                    <th scope="col" className="text-center">Mobile Number</th>
                                </tr>
                            </thead>
                            <tbody>
                                {cardList.map(element => (
                                    <tr className="loc-table-row-container">
                                        <td className="loc-list-card-name">{element.cardDetails[0]?.firstName ? element.cardDetails[0]?.firstName : '-'} {element.cardDetails[0]?.lastName ? element.cardDetails[0]?.lastName : '-'}</td>
                                        <td className="loc-list-card-name">{element.cardDetails[0]?.companyName ? element.cardDetails[0]?.companyName : '-'}</td>
                                        <td>
                                            <div className="loc-list-button-container">
                                                <div>{element.cardDetails[0]?.email1}</div>
                                                <div>{element.cardDetails[0]?.email2}</div>
                                                <div>{element.cardDetails[0]?.email3}</div>
                                            </div>
                                        </td>
                                        <td>
                                            <div className="loc-list-button-container">
                                                <div>{element.cardDetails[0]?.mobileNumber}</div>
                                                <div>{element.cardDetails[0]?.homeNumber}</div>
                                                <div>{element.cardDetails[0]?.workNumber1}</div>
                                                <div>{element.cardDetails[0]?.workNumber2}</div>
                                            </div>
                                        </td>
                                    </tr>
                                ))}

                            </tbody>
                        </table>}
                    </div>
                </CommonColorForm>}

            </CommonScreenContent>
            <Modal
                onHide={() => { setShowPasswordModal(false) }}
                show={showPasswordModal}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Authorization
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <FormFieldLabel className='up-bold-label mb-4' label='Enter Password to view analytics details' />
                    <CommonInputFloatFull
                        type='password'
                        id='password'
                        onChange={e => setPagePassword(e.target.value)}
                        placeholder='Password'
                        value={pagePassword} />
                </Modal.Body>
                <Modal.Footer>
                    <CommonButton label='Submit'
                        onClick={() => { handlePasswordSubmitClick() }} />
                </Modal.Footer>
            </Modal>
            <ToastContainer />
            <Loader active={isLoading} text='Please wait...' />
        </>
    )
}

export default SiteAnalytics