import { takeLatest, call, put } from 'redux-saga/effects'
import {
    addBlogAPI, blogListAPI, deleteBlogAPI, updateBlogAPI
} from '../api-requests/blog';
import {
    addBlogRequestError, addBlogRequestSuccess, blogListRequestError,
    blogListRequestSuccess, deleteBlogRequestError, deleteBlogRequestSuccess,
    updateBlogRequestError, updateBlogRequestSuccess
} from './blog.action';
import { BlogActionTypes } from './blog.types'
import { displayConsoleLog } from '../../utils/Utils'
import { decryptResponseData } from '../../utils/DataDecryptionUtils';

function* handleUpdateBlog({ payload }) {
    try {
        const response = yield call(updateBlogAPI, payload);
        if (response.status >= 200 && response.status < 300) {
            const { data } = response
            const decryptedData = decryptResponseData(data)
            if (decryptedData.data !== null) {
                yield put(updateBlogRequestSuccess(decryptedData))
            } else {
                yield put(updateBlogRequestError(decryptedData))
            }
        } else {
            throw response
        }
    } catch (error) {
        displayConsoleLog(error)
        displayConsoleLog(error.response?.data)
        yield put(updateBlogRequestError(error.response.data))
    }
}

export function* updateBlogReq() {
    yield takeLatest(BlogActionTypes.UPDATE_BLOG_REQUEST, handleUpdateBlog)
}

function* handleDeleteBlog({ payload }) {
    try {
        const response = yield call(deleteBlogAPI, payload);
        if (response.status >= 200 && response.status < 300) {
            const { data } = response
            const decryptedData = decryptResponseData(data)
            if (decryptedData.data !== null) {
                yield put(deleteBlogRequestSuccess(decryptedData))
            } else {
                yield put(deleteBlogRequestError(decryptedData))
            }
        } else {
            throw response
        }
    } catch (error) {
        displayConsoleLog(error)
        displayConsoleLog(error.response?.data)
        yield put(deleteBlogRequestError(error.response.data))
    }
}

export function* deleteBlogReq() {
    yield takeLatest(BlogActionTypes.DELETE_BLOG_REQUEST, handleDeleteBlog)
}

function* handleBlogList({ payload }) {
    try {
        const response = yield call(blogListAPI, payload);
        if (response.status >= 200 && response.status < 300) {
            const { data } = response
            const decryptedData = decryptResponseData(data)
            if (decryptedData.data !== null) {
                yield put(blogListRequestSuccess(decryptedData))
            } else {
                yield put(blogListRequestError(decryptedData))
            }
        } else {
            throw response
        }
    } catch (error) {
        displayConsoleLog(error)
        displayConsoleLog(error.response?.data)
        yield put(blogListRequestError(error.response.data))
    }
}

export function* blogListReq() {
    yield takeLatest(BlogActionTypes.BLOG_LIST_REQUEST, handleBlogList)
}

function* handleAddBlog({ payload }) {
    try {
        const response = yield call(addBlogAPI, payload);
        if (response.status >= 200 && response.status < 300) {
            const { data } = response
            const decryptedData = decryptResponseData(data)
            if (decryptedData.data !== null) {
                yield put(addBlogRequestSuccess(decryptedData))
            } else {
                yield put(addBlogRequestError(decryptedData))
            }
        } else {
            throw response
        }
    } catch (error) {
        displayConsoleLog(error)
        displayConsoleLog(error.response?.data)
        yield put(addBlogRequestError(error.response.data))
    }
}

export function* addBlogReq() {
    yield takeLatest(BlogActionTypes.ADD_BLOG_REQUEST, handleAddBlog)
}