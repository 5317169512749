import { BlogActionTypes } from './blog.types'
import { API_CONSTANTS } from '../../utils/AppConstants'

const INITIAL_STATE = {
    addBlogSuccess: null,
    addBlogError: null,
    addBlogReqStatus: '',
    blogListSuccess: null,
    blogListError: null,
    updateBlogSuccess: null,
    updateBlogError: null,
    updateBlogReqStatus: '',
    deleteBlogSuccess: null,
    deleteBlogError: null,
    deleteBlogReqStatus: ''
}

const blogReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case BlogActionTypes.ADD_BLOG_REQUEST_SUCCESS:
            return {
                ...state,
                addBlogSuccess: action.payload,
                addBlogError: null,
                addBlogReqStatus: API_CONSTANTS.SUCCESS_STATUS
            }
        case BlogActionTypes.ADD_BLOG_REQUEST_ERROR:
            return {
                ...state,
                addBlogSuccess: null,
                addBlogError: action.payload,
                addBlogReqStatus: API_CONSTANTS.ERROR_STATUS
            }
        case BlogActionTypes.ADD_BLOG_REQUEST_STATUS:
            return {
                ...state,
                addBlogReqStatus: ''
            }
        case BlogActionTypes.BLOG_LIST_REQUEST_SUCCESS:
            return {
                ...state,
                blogListSuccess: action.payload,
                blogListError: null
            }
        case BlogActionTypes.BLOG_LIST_REQUEST_ERROR:
            return {
                ...state,
                blogListSuccess: null,
                blogListError: action.payload
            }
        case BlogActionTypes.UPDATE_BLOG_REQUEST_SUCCESS:
            return {
                ...state,
                updateBlogSuccess: action.payload,
                updateBlogError: null,
                updateBlogReqStatus: API_CONSTANTS.SUCCESS_STATUS
            }
        case BlogActionTypes.UPDATE_BLOG_REQUEST_ERROR:
            return {
                ...state,
                updateBlogSuccess: null,
                updateBlogError: action.payload,
                updateBlogReqStatus: API_CONSTANTS.ERROR_STATUS
            }
        case BlogActionTypes.UPDATE_BLOG_REQUEST_STATUS:
            return {
                ...state,
                updateBlogReqStatus: ''
            }
        case BlogActionTypes.DELETE_BLOG_REQUEST_SUCCESS:
            return {
                ...state,
                deleteBlogSuccess: action.payload,
                deleteBlogError: null,
                deleteBlogReqStatus: API_CONSTANTS.SUCCESS_STATUS
            }
        case BlogActionTypes.DELETE_BLOG_REQUEST_ERROR:
            return {
                ...state,
                deleteBlogSuccess: null,
                deleteBlogError: action.payload,
                deleteBlogReqStatus: API_CONSTANTS.ERROR_STATUS
            }
        case BlogActionTypes.DELETE_BLOG_REQUEST_STATUS:
            return {
                ...state,
                deleteBlogReqStatus: ''
            }
        case BlogActionTypes.LOGOUT:
            return INITIAL_STATE
        default:
            return state
    }
}

export default blogReducer