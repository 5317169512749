import React, { useEffect, useState } from "react";
import './styles.css'
import CommonScreenContent from "../../../components/screen/common-screen-content/commonScreenContent";
import ScreenLabel from "../../../components/label/screen-label/screenLabel";
import CommonColorForm from "../../../components/common-color-form/commonColorForm";
import ButtonRow from "../../../components/button/button-row/buttonRow";
import CommonButton from "../../../components/button/common-button/commonButton";
import SubTextLabel from "../../../components/label/sub-text-label/subTextLabel";
import CommonInputRow from "../../../components/input/common-input-row/commonInputRow";
import CommonInputFloatHalf from "../../../components/input/common-input-float-half/commonInputFloafHalf";
import FormFieldLabel from "../../../components/label/form-field-label/formFieldLabel";
import CommonInputFloatFull from "../../../components/input/common-input-float-full/commonInputFloatFull";
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import CommonInputFull from "../../../components/input/common-input-full/commonInputFull";
import TextField from '@mui/material/TextField';
import { displayErrorToast, displaySuccessToast, isEmpty, isValidEmail } from "../../../utils/Utils";
import Loader from '../../../components/loader/loader.component'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useDispatch, useSelector } from 'react-redux'
import { addHotelPointRequest, addHotelPointRequestStatus } from '../../../redux/hotel-point/hotelPoint.action'
import { API_CONSTANTS } from "../../../utils/AppConstants";
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';

const Point = props => {
    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [designation, setDesignation] = useState('')
    const [companyName, setCompanyName] = useState('')
    const [city, setCity] = useState('')
    const [state, setState] = useState('')
    const [country, setCountry] = useState('')
    const [phoneNumber, setPhoneNumber] = useState('')
    const [email, setEmail] = useState('')

    const [isLoading, setLoading] = useState(false)
    const dispatch = useDispatch()
    const { addHotelPointSuccess, addHotelPointError, addHotelPointReqStatus } = useSelector(state => state.hotelPoint)

    const [hotelType, setHotelType] = useState('')
    const [roomType, setRoomType] = useState('')
    const [frequencyType, setFrequencyType] = useState('')
    const [hotelCity, setHotelCity] = useState('')

    const [hotelSpecificationList, setHotelSpecificationList] = useState([])

    useEffect(() => {
        if (addHotelPointReqStatus === API_CONSTANTS.SUCCESS_STATUS) {
            setLoading(false)
            displaySuccessToast(addHotelPointSuccess.message)
            clearField()
        } else if (addHotelPointReqStatus === API_CONSTANTS.ERROR_STATUS) {
            setLoading(false)
            displayErrorToast(addHotelPointError.message)
        }
        if (addHotelPointReqStatus?.length) {
            dispatch(addHotelPointRequestStatus(''))
        }
    }, [addHotelPointSuccess, addHotelPointError, addHotelPointReqStatus])

    const clearField = () => {
        setFirstName('')
        setLastName('')
        setDesignation('')
        setCompanyName('')
        setCity('')
        setState('')
        setCountry('')
        setPhoneNumber('')
        setEmail('')
        setHotelCity('')
        setHotelType('')
        setRoomType('')
        setFrequencyType('')
        setHotelSpecificationList([])
    }

    const handleSubmitClick = () => {
        if (isEmpty(firstName)) {
            displayErrorToast('First Name cannot be blank')
        } else if (isEmpty(phoneNumber)) {
            displayErrorToast('Contact number cannot be blank')
        } else if (isEmpty(email)) {
            displayErrorToast('Email cannot be blank')
        } else if (!isValidEmail(email)) {
            displayErrorToast('Invalid Email id')
        } else if (isEmpty(city)) {
            displayErrorToast('City cannot be blank')
        } else if (isEmpty(state)) {
            displayErrorToast('State cannot be blank')
        } else if (isEmpty(country)) {
            displayErrorToast('Country cannot be blank')
        } else if (isEmpty(hotelSpecificationList)) {
            displayErrorToast('Hotel specification not added')
        } else {
            setLoading(true)
            const data = {
                firstName: firstName,
                lastName: isEmpty(lastName) ? '' : lastName,
                companyName: !isEmpty(companyName) ? companyName : '',
                designation: !isEmpty(designation) ? designation : '',
                contactNumber: phoneNumber,
                email: email,
                city: city,
                state: state,
                country: country,
                hotelSpecification: hotelSpecificationList
            }
            dispatch(addHotelPointRequest(data))
        }
    }

    const handleClearClick = () => {
        setHotelType('')
        setRoomType('')
        setFrequencyType('')
        setHotelCity('')
    }

    const handleAddClick = () => {
        if (isEmpty(hotelType)) {
            displayErrorToast('Hotel type not selected')
        } else if (isEmpty(roomType)) {
            displayErrorToast('Average number of nights not selected')
        } else if (isEmpty(hotelCity)) {
            displayErrorToast('Hotel city not selected')
        } else {
            const data = {
                hotelType: hotelType,
                roomType: roomType,
                frequencyType: 'YEARLY',
                hotelCity: hotelCity
            }
            let currentList = hotelSpecificationList
            currentList.push(data)
            setHotelSpecificationList(currentList)
            handleClearClick()
        }
    }

    const handleDeleteClick = item => {
        let currentList = hotelSpecificationList.filter(e => e.hotelType !== item.hotelType && e.roomType !== item.roomType && e.hotelCity !== item.hotelCity)
        setHotelSpecificationList(currentList)
        handleClearClick()
    }

    return (
        <>
            <CommonScreenContent>
                <ScreenLabel label='ClickTran' />
                <Paper elevation={3} style={{ background: '#fbfbfb' }} className='cp-card paper-container'>
                    <FormFieldLabel className='up-bold-label' label='Contact Information' />
                    <CommonInputRow>
                        <CommonInputFloatHalf
                            type='text'
                            onChange={e => setFirstName(e.target.value)}
                            placeholder='First Name'
                            value={firstName}
                            isRequired={true} />
                        <CommonInputFloatHalf
                            type='text'
                            onChange={e => setLastName(e.target.value)}
                            placeholder='Last Name'
                            value={lastName} />
                    </CommonInputRow>
                    <CommonInputRow>
                        <CommonInputFloatHalf
                            type='text'
                            onChange={e => setCompanyName(e.target.value)}
                            placeholder='Company Name'
                            value={companyName} />
                        <CommonInputFloatHalf
                            type='text'
                            onChange={e => setDesignation(e.target.value)}
                            placeholder='Designation'
                            value={designation} />
                    </CommonInputRow>
                    <CommonInputRow>
                        <CommonInputFloatHalf
                            type='number'
                            onChange={e => setPhoneNumber(e.target.value)}
                            placeholder='Contact Number'
                            value={phoneNumber}
                            isRequired={true} />
                        <CommonInputFloatHalf
                            type='text'
                            onChange={e => setEmail(e.target.value)}
                            placeholder='Email Id'
                            value={email}
                            isRequired={true} />
                    </CommonInputRow>
                </Paper>
                <Paper elevation={3} style={{ background: '#fbfbfb' }} className='cp-card paper-container'>
                    <FormFieldLabel className='up-bold-label' label='Location Information' />
                    <CommonInputRow>
                        <CommonInputFloatHalf
                            type='text'
                            onChange={e => setCity(e.target.value)}
                            placeholder='City'
                            value={city}
                            isRequired={true}
                        />
                        <CommonInputFloatHalf
                            type='text'
                            onChange={e => setState(e.target.value)}
                            placeholder='State'
                            value={state}
                            isRequired={true}
                        />
                    </CommonInputRow>
                    <CommonInputFloatFull
                        type='text'
                        placeholder='Country'
                        onChange={e => setCountry(e.target.value)}
                        value={country}
                        isRequired={true}
                    />
                </Paper>
                <Paper elevation={3} style={{ background: '#fbfbfb' }} className='cp-card paper-container'>
                    <FormFieldLabel className='up-bold-label' label='Hotel Specification' />
                    {isEmpty(hotelSpecificationList) && <FormFieldLabel label='Select Hotel preference to stay' />}
                    {!isEmpty(hotelSpecificationList) && <FormFieldLabel className='cp-blue-label' label='For additional hotel preference please refill' />}
                    <div className="container">
                        <div className="row">
                            <div className="col-md-4 cp-row-margin">
                                <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label">Hotel type</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={hotelType}
                                        onChange={e => setHotelType(e.target.value)}
                                        autoWidth
                                        label="Hotel type"
                                    >
                                        <MenuItem value="" disabled>
                                            <em>None</em>
                                        </MenuItem>
                                        {/* <MenuItem value='1 Star'>1 Star</MenuItem>
                                        <MenuItem value='2 Star'>2 Star</MenuItem>
                                        <MenuItem value='3 Star'>3 Star</MenuItem>
                                        <MenuItem value='4 Star'>4 Star</MenuItem>
                                        <MenuItem value='5 Star'>5 Star</MenuItem> */}
                                        <MenuItem value='Luxury hotels'>Luxury hotels</MenuItem>
                                        <MenuItem value='Boutique hotels'>Boutique hotels</MenuItem>
                                        <MenuItem value='Budget hotels'>Budget hotels</MenuItem>
                                        <MenuItem value='Bed and Breakfasts'>Bed and Breakfasts</MenuItem>
                                        <MenuItem value='Private Home Room'>Private Home Room</MenuItem>
                                        <MenuItem value='Hostels'>Hostels</MenuItem>
                                        <MenuItem value='Resorts'>Resorts</MenuItem>
                                    </Select>
                                </FormControl>
                            </div>
                            <div className="col-md-4 cp-row-margin">
                                <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label">Average number of nights /yr</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={roomType}
                                        onChange={e => setRoomType(e.target.value)}
                                        autoWidth
                                        label="Average number of nights /yr"
                                    >
                                        <MenuItem value="" disabled>
                                            <em>None</em>
                                        </MenuItem>
                                        <MenuItem value='1 - 5'>1 - 5</MenuItem>
                                        <MenuItem value='6 - 30'>6 - 30</MenuItem>
                                        <MenuItem value='31 - 100'>31 - 100</MenuItem>
                                        <MenuItem value='100+'>100+</MenuItem>
                                    </Select>
                                </FormControl>
                            </div>
                            {/* <div className="col-md-3 cp-row-margin">
                                <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-autowidth-label">Stay Frequency</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-autowidth-label"
                                        id="demo-simple-select-autowidth"
                                        value={frequencyType}
                                        onChange={e => setFrequencyType(e.target.value)}
                                        autoWidth
                                        label="Stay Frequency"
                                    >
                                        <MenuItem value="">
                                            <em>None</em>
                                        </MenuItem>
                                        <MenuItem value='Monthly'>Monthly</MenuItem>
                                        <MenuItem value='Yearly'>Yearly</MenuItem>
                                    </Select>
                                </FormControl>
                            </div> */}
                            <div className="col-md-4 cp-row-margin">
                                <CommonInputFloatFull
                                    placeholder='City'
                                    type='text'
                                    value={hotelCity}
                                    onChange={e => setHotelCity(e.target.value)}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="cp-specification-row">
                        <div className="cp-form-icon" onClick={() => { handleClearClick() }}>
                            <img src={require('../../../assets/ic_form_cancel.png')} className="img" />
                        </div>
                        <div className="cp-form-icon" onClick={() => { handleAddClick() }}>
                            <img src={require('../../../assets/ic_form_done.png')} className="img" />
                        </div>
                    </div>
                    {!isEmpty(hotelSpecificationList) && <div className="cp-list-row-container">
                        {hotelSpecificationList.map(e => (<div className="cp-option-item-row">
                            <div className="cp-option-item-col">
                                <div className="cp-option-item-name">{e.hotelType}</div>
                                <div className="cp-option-item-description">{e.roomType} / yr</div>
                                <div className="cp-option-item-normal">{e.hotelCity}</div>
                            </div>
                            <div className="cp-option-item-col-icon" onClick={() => { handleDeleteClick(e) }}>
                                <img src={require('../../../assets/ic_card_delete1.png')} className="img" />
                            </div>
                        </div>))}
                    </div>}
                </Paper>
                <ButtonRow>
                    <CommonButton label='Submit' onClick={() => { handleSubmitClick() }} />
                </ButtonRow>
            </CommonScreenContent>
            <ToastContainer />
            <Loader active={isLoading} text='Please wait...' />
        </>
    )
}

export default Point