import axios from 'axios'
import { config } from '../../config'
import { APP_CONSTANTS } from '../../utils/AppConstants'
import API_ENDPOINTS from './endpoints'

export function addFeedbackAPI(payload) {
    return axios.request({
        method: 'post',
        url: `${config.baseUrl}/${API_ENDPOINTS.feedback.addFeedback}`,
        data: payload
    })
}