import axios from 'axios'
import { config } from '../../config'
import { APP_CONSTANTS } from '../../utils/AppConstants'
import API_ENDPOINTS from './endpoints'

export function userRegistrationAPI(payload) {
    return axios.request({
        method: 'post',
        url: `${config.baseUrl}/${API_ENDPOINTS.account.userRegistration}`,
        data: payload
    })
}

export function userRegistrationBusinessAPI(payload) {
    return axios.request({
        method: 'post',
        url: `${config.baseUrl}/${API_ENDPOINTS.account.userRegistrationBusiness}`,
        data: payload,
        headers: {
            "Content-Type": "multipart/form-data"
        }
    })
}

export function userRegistrationByMobileAPI(payload) {
    return axios.request({
        method: 'post',
        url: `${config.baseUrl}/${API_ENDPOINTS.account.userRegistrationByMobile}`,
        data: payload
    })
}

export function loginAPI(payload) {
    return axios.request({
        method: 'post',
        url: `${config.baseUrl}/${API_ENDPOINTS.account.signIn}`,
        data: payload
    })
}

export function customerCardRequestAPI(payload) {
    return axios.request({
        method: 'post',
        url: `${config.baseUrl}/${API_ENDPOINTS.account.customerCardRequest}`,
        data: payload
    })
}

export function subscriptionList() {
    return axios.request({
        method: 'get',
        url: `${config.baseUrl}/${API_ENDPOINTS.account.subscriptionList}`
    })
}

export function businessDetailAPI(payload) {
    return axios.request({
        method: 'get',
        url: `${config.baseUrl}/${API_ENDPOINTS.account.businessDetails}`,
        params: payload
    })
}

export function updateUserNotificationToken(data) {
    const user = JSON.parse(sessionStorage.getItem(APP_CONSTANTS.USER))
    return axios.request({
        method: 'put',
        url: `${config.baseUrl}/${API_ENDPOINTS.account.updateNotificationToken}`,
        data: data,
        headers: {
            'authToken': user ? user.authToken : config.cid
        }
    })
}

export function updateBusinessNotificationToken(data) {
    const user = JSON.parse(sessionStorage.getItem(APP_CONSTANTS.USER))
    return axios.request({
        method: 'put',
        url: `${config.baseUrl}/${API_ENDPOINTS.account.updateBusinessNotificationToken}`,
        data: data,
        headers: {
            'authToken': user ? user.authToken : config.cid
        }
    })
}

export function updateVisitorConfigAPI(data) {
    const user = JSON.parse(sessionStorage.getItem(APP_CONSTANTS.USER))
    return axios.request({
        method: 'put',
        url: `${config.baseUrl}/${API_ENDPOINTS.account.updateVisitorConfig}`,
        data: data,
        headers: {
            'authToken': user ? user.authToken : config.cid
        }
    })
}

export function forgotPassword(data) {
    return axios.request({
        method: 'put',
        url: `${config.baseUrl}/${API_ENDPOINTS.account.forgotPassword}`,
        data: data
    })
}

export function changePassword(data) {
    return axios.request({
        method: 'put',
        url: `${config.baseUrl}/${API_ENDPOINTS.account.changePassword}`,
        data: data
    })
}

export function contactUsAPI(payload) {
    return axios.request({
        method: 'post',
        url: `${config.baseUrl}/${API_ENDPOINTS.contactUs.contactUsAddRecord}`,
        data: payload
    })
}

export function operatorRegisterationAPI(payload) {
    const user = JSON.parse(sessionStorage.getItem(APP_CONSTANTS.USER))
    return axios.request({
        method: 'post',
        url: `${config.baseUrl}/${API_ENDPOINTS.operator.operatorRegistration}`,
        data: payload,
        headers: {
            "Content-Type": "multipart/form-data",
            'authToken': user ? user.authToken : config.cid
        }
    })
}

export function updateOperatorLocationAPI(payload) {
    const user = JSON.parse(sessionStorage.getItem(APP_CONSTANTS.USER))
    return axios.request({
        method: 'put',
        url: `${config.baseUrl}/${API_ENDPOINTS.operator.updateOperatorLocation}`,
        data: payload,
        headers: {
            'authToken': user ? user.authToken : config.cid
        }
    })
}

export function operatorListAPI(payload) {
    const user = JSON.parse(sessionStorage.getItem(APP_CONSTANTS.USER))
    return axios.request({
        method: 'get',
        url: `${config.baseUrl}/${API_ENDPOINTS.operator.operatorList}`,
        params: payload,
        headers: {
            'authToken': user ? user.authToken : config.cid
        }
    })
}

export function operatorDetailAPI(payload) {
    const user = JSON.parse(sessionStorage.getItem(APP_CONSTANTS.USER))
    return axios.request({
        method: 'get',
        url: `${config.baseUrl}/${API_ENDPOINTS.operator.operatorDetails}/${payload}`,
        headers: {
            'authToken': user ? user.authToken : config.cid
        }
    })
}

export function countryListApi() {
    return axios.request({
        method: 'GET',
        url: `${API_ENDPOINTS.location.countryList}`,
        headers: {
            'X-CSCAPI-KEY': config.countryStateCity
        }
    })
}

export function stateListApi(data) {
    return axios.request({
        method: 'GET',
        url: `${API_ENDPOINTS.location.countryList}/${data.countryId}/${API_ENDPOINTS.location.stateList}`,
        headers: {
            'X-CSCAPI-KEY': config.countryStateCity
        }
    })
}

export function cityListApi(data) {
    return axios.request({
        method: 'GET',
        url: `${API_ENDPOINTS.location.countryList}/${data.countryId}/${API_ENDPOINTS.location.stateList}/${data.stateId}/${API_ENDPOINTS.location.cityList}`,
        headers: {
            'X-CSCAPI-KEY': config.countryStateCity
        }
    })
}

export function ipLocation() {
    return axios.request({
        method: 'get',
        url: 'https://ipapi.co/json/'
    })
}