import { APP_CONSTANTS, ROUTES } from "./utils/AppConstants";

const local = {
    baseUrl: 'http://localhost:3029/clicktran/v1',
    imageUrl: 'http://localhost:3029/',
    socketUrl: 'http://localhost:3029',
    socketConnectionUrl: 'ws://localhost:3029',
    qrCodeUrl: `http://localhost:3000${ROUTES.vCard}`,
    employeeQrCodeUrl: `http://localhost:3000${ROUTES.employeeCard}`,
    webUrl: `http://localhost:3000`,
    dynamicQrCodeUrl: `http://localhost:3000${ROUTES.dynamicQrLink}`,
    visitorFormUrl: `http://localhost:3000${ROUTES.visitorForm}`,
    cid: '911c6649-5930-4357-9afa-b558675a7c6c',
    countryStateCity: 'dnRveHpxQk1Dc1hWVWtvUlZBVDZxWWh4SDFDQ0dVSVhJSWtybFhIUQ==',
    environment: APP_CONSTANTS.ENVIRONMENT.LOCAL,
    KEY: 'DIGITAL_CARD_06_21_2022_CALIFORNIA_UNITED_STATES_OF_AMERICA',
    IP_KEY: 'ee4db5c8b4251c',
    siteAnalyticsPassword: 'U2FsdGVkX1+ViNcgknSX3WMqTztWia5CWZeBVkpCoUs='
};

const dev = {
    baseUrl: 'https://dc-api-dev.clicktran.com/clicktran/v1',
    imageUrl: 'https://dc-api-dev.clicktran.com/',
    socketUrl: 'https://dc-api-dev.clicktran.com',
    socketConnectionUrl: 'ws://dc-api-dev.clicktran.com:443',
    qrCodeUrl: `https://try.clicktran.com${ROUTES.vCard}`,
    employeeQrCodeUrl: `https://try.clicktran.com${ROUTES.employeeCard}`,
    webUrl: `https://try.clicktran.com`,
    dynamicQrCodeUrl: `https://try.clicktran.com${ROUTES.dynamicQrLink}`,
    visitorFormUrl: `https://try-vms.clicktran.com${ROUTES.visitorForm}`,
    cid: '911c6649-5930-4357-9afa-b558675a7c6c',
    countryStateCity: 'dnRveHpxQk1Dc1hWVWtvUlZBVDZxWWh4SDFDQ0dVSVhJSWtybFhIUQ==',
    environment: APP_CONSTANTS.ENVIRONMENT.DEV,
    KEY: 'DIGITAL_CARD_06_21_2022_CALIFORNIA_UNITED_STATES_OF_AMERICA',
    IP_KEY: 'ee4db5c8b4251c',
    siteAnalyticsPassword: 'U2FsdGVkX1+ViNcgknSX3WMqTztWia5CWZeBVkpCoUs='
};

const prod = {
    baseUrl: 'https://dc-api-prod.clicktran.com/clicktran/v1',
    imageUrl: 'https://dc-api-prod.clicktran.com/',
    socketUrl: 'https://dc-api-prod.clicktran.com',
    socketConnectionUrl: 'ws://dc-api-prod.clicktran.com:443',
    qrCodeUrl: `https://www.clicktran.com${ROUTES.vCard}`,
    employeeQrCodeUrl: `https://www.clicktran.com${ROUTES.employeeCard}`,
    webUrl: `https://www.clicktran.com`,
    dynamicQrCodeUrl: `https://www.clicktran.com${ROUTES.dynamicQrLink}`,
    visitorFormUrl: `https://vms.clicktran.com${ROUTES.visitorForm}`,
    cid: '911c6649-5930-4357-9afa-b558675a7c6c',
    countryStateCity: 'dnRveHpxQk1Dc1hWVWtvUlZBVDZxWWh4SDFDQ0dVSVhJSWtybFhIUQ==',
    environment: APP_CONSTANTS.ENVIRONMENT.PROD,
    KEY: 'DIGITAL_CARD_06_21_2022_CALIFORNIA_UNITED_STATES_OF_AMERICA',
    IP_KEY: 'ee4db5c8b4251c',
    siteAnalyticsPassword: 'U2FsdGVkX1+ViNcgknSX3WMqTztWia5CWZeBVkpCoUs='
};

export const config = prod