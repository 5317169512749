import axios from 'axios'
import { config } from '../../config'
import { APP_CONSTANTS } from '../../utils/AppConstants'
import API_ENDPOINTS from './endpoints'

export function addDigitalCardAPI(payload) {
    const user = JSON.parse(sessionStorage.getItem(APP_CONSTANTS.USER))
    return axios.request({
        method: 'post',
        url: `${config.baseUrl}/${API_ENDPOINTS.digitalCard.addCard}`,
        data: payload,
        headers: {
            "Content-Type": "multipart/form-data",
            'authToken': user ? user.authToken : config.cid
        }
    })
}

export function addDigitalCardWithDetailsAPI(payload) {
    const user = JSON.parse(sessionStorage.getItem(APP_CONSTANTS.USER))
    return axios.request({
        method: 'post',
        url: `${config.baseUrl}/${API_ENDPOINTS.digitalCard.createCardWithDetails}`,
        data: payload,
        headers: {
            "Content-Type": "multipart/form-data",
            'authToken': user ? user.authToken : config.cid
        }
    })
}

export function checkAliasAvailabilityAPI(payload) {
    const user = JSON.parse(sessionStorage.getItem(APP_CONSTANTS.USER))
    return axios.request({
        method: 'get',
        url: `${config.baseUrl}/${API_ENDPOINTS.digitalCard.checkAliasAvailability}`,
        params: payload,
        headers: {
            'authToken': user ? user.authToken : config.cid
        }
    })
}

export function digitalCardListAPI(payload) {
    const user = JSON.parse(sessionStorage.getItem(APP_CONSTANTS.USER))
    return axios.request({
        method: 'get',
        url: `${config.baseUrl}/${API_ENDPOINTS.digitalCard.cardList}`,
        params: payload,
        headers: {
            'authToken': user ? user.authToken : config.cid
        }
    })
}

export function updateDigitalCardAPI(payload) {
    const user = JSON.parse(sessionStorage.getItem(APP_CONSTANTS.USER))
    return axios.request({
        method: 'put',
        url: `${config.baseUrl}/${API_ENDPOINTS.digitalCard.updateCard}`,
        data: payload,
        headers: {
            "Content-Type": "multipart/form-data",
            'authToken': user ? user.authToken : config.cid
        }
    })
}

export function addCardDetailsAPI(payload) {
    const user = JSON.parse(sessionStorage.getItem(APP_CONSTANTS.USER))
    return axios.request({
        method: 'post',
        url: `${config.baseUrl}/${API_ENDPOINTS.digitalCard.addCardDetails}`,
        data: payload,
        headers: {
            'authToken': user ? user.authToken : config.cid
        }
    })
}

export function updateCardDetailsAPI(payload) {
    const user = JSON.parse(sessionStorage.getItem(APP_CONSTANTS.USER))
    return axios.request({
        method: 'put',
        url: `${config.baseUrl}/${API_ENDPOINTS.digitalCard.updateCardDetails}`,
        data: payload,
        headers: {
            "Content-Type": "multipart/form-data",
            'authToken': user ? user.authToken : config.cid
        }
    })
}

export function deleteCardAPI(payload) {
    const user = JSON.parse(sessionStorage.getItem(APP_CONSTANTS.USER))
    return axios.request({
        method: 'put',
        url: `${config.baseUrl}/${API_ENDPOINTS.digitalCard.deleteCard}`,
        data: payload,
        headers: {
            'authToken': user ? user.authToken : config.cid
        }
    })
}

export function cardByAliasAPI(payload) {
    return axios.request({
        method: 'get',
        url: `${config.baseUrl}/${API_ENDPOINTS.digitalCard.cardByAlias}/${payload}`
    })
}