import { DigitalCardActionTypes } from './digitalCard.types'

//Add Digital Card
export const addDigitalCardRequest = data => ({
    type: DigitalCardActionTypes.ADD_DIGITAL_CARD_REQUEST,
    payload: data
})

export const addDigitalCardRequestSuccess = data => ({
    type: DigitalCardActionTypes.ADD_DIGITAL_CARD_REQUEST_SUCCESS,
    payload: data
})

export const addDigitalCardRequestError = data => ({
    type: DigitalCardActionTypes.ADD_DIGITAL_CARD_REQUEST_ERROR,
    payload: data
})

export const addDigitalCardRequestStatus = data => ({
    type: DigitalCardActionTypes.ADD_DIGITAL_CARD_REQUEST_STATUS,
    payload: data
})

//Check Card Alias
export const checkCardAliasRequest = data => ({
    type: DigitalCardActionTypes.CHECK_ALIAS_AVAILABILITY_REQUEST,
    payload: data
})

export const checkCardAliasRequestSuccess = data => ({
    type: DigitalCardActionTypes.CHECK_ALIAS_AVAILABILITY_REQUEST_SUCCESS,
    payload: data
})

export const checkCardAliasRequestError = data => ({
    type: DigitalCardActionTypes.CHECK_ALIAS_AVAILABILITY_REQUEST_ERROR,
    payload: data
})

//Digital Card List
export const digitalCardListRequest = data => ({
    type: DigitalCardActionTypes.DIGITAL_CARD_LIST_REQUEST,
    payload: data
})

export const digitalCardListRequestSuccess = data => ({
    type: DigitalCardActionTypes.DIGITAL_CARD_LIST_REQUEST_SUCCESS,
    payload: data
})

export const digitalCardListRequestError = data => ({
    type: DigitalCardActionTypes.DIGITAL_CARD_LIST_REQUEST_ERROR,
    payload: data
})

//Update Digital Card
export const updateDigitalCardRequest = data => ({
    type: DigitalCardActionTypes.UPDATE_DIGITAL_CARD_REQUEST,
    payload: data
})

export const updateDigitalCardRequestSuccess = data => ({
    type: DigitalCardActionTypes.UPDATE_DIGITAL_CARD_REQUEST_SUCCESS,
    payload: data
})

export const updateDigitalCardRequestError = data => ({
    type: DigitalCardActionTypes.UPDATE_DIGITAL_CARD_REQUEST_ERROR,
    payload: data
})

export const updateDigitalCardRequestStatus = data => ({
    type: DigitalCardActionTypes.UPDATE_DIGITAL_CARD_REQUEST_STATUS,
    payload: data
})

//Add Card Details
export const addCardDetailsRequest = data => ({
    type: DigitalCardActionTypes.ADD_CARD_DETAILS_REQUEST,
    payload: data
})

export const addCardDetailsRequestSuccess = data => ({
    type: DigitalCardActionTypes.ADD_CARD_DETAILS_REQUEST_SUCCESS,
    payload: data
})

export const addCardDetailsRequestError = data => ({
    type: DigitalCardActionTypes.ADD_CARD_DETAILS_REQUEST_ERROR,
    payload: data
})

export const addCardDetailsRequestStatus = data => ({
    type: DigitalCardActionTypes.ADD_CARD_DETAILS_REQUEST_STATUS,
    payload: data
})

//Update Card Details
export const updateCardDetailsRequest = data => ({
    type: DigitalCardActionTypes.UPDATE_CARD_DETAILS_REQUEST,
    payload: data
})

export const updateCardDetailsRequestSuccess = data => ({
    type: DigitalCardActionTypes.UPDATE_CARD_DETAILS_REQUEST_SUCCESS,
    payload: data
})

export const updateCardDetailsRequestError = data => ({
    type: DigitalCardActionTypes.UPDATE_CARD_DETAILS_REQUEST_ERROR,
    payload: data
})

export const updateCardDetailsRequestStatus = data => ({
    type: DigitalCardActionTypes.UPDATE_CARD_DETAILS_REQUEST_STATUS,
    payload: data
})

//Card By Alias
export const cardByAliasRequest = data => ({
    type: DigitalCardActionTypes.CARD_BY_ALIAS_REQUEST,
    payload: data
})

export const cardByAliasRequestSuccess = data => ({
    type: DigitalCardActionTypes.CARD_BY_ALIAS_REQUEST_SUCCESS,
    payload: data
})

export const cardByAliasRequestError = data => ({
    type: DigitalCardActionTypes.CARD_BY_ALIAS_REQUEST_ERROR,
    payload: data
})

//Add Digital Card with details
export const addDigitalCardWithDetailsRequest = data => ({
    type: DigitalCardActionTypes.ADD_CARD_WITH_DETAILS_REQUEST,
    payload: data
})

export const addDigitalCardWithDetailsRequestSuccess = data => ({
    type: DigitalCardActionTypes.ADD_CARD_WITH_DETAILS_REQUEST_SUCCESS,
    payload: data
})

export const addDigitalCardWithDetailsRequestError = data => ({
    type: DigitalCardActionTypes.ADD_CARD_WITH_DETAILS_REQUEST_ERROR,
    payload: data
})

export const addDigitalCardWithDetailsRequestStatus = data => ({
    type: DigitalCardActionTypes.ADD_CARD_WITH_DETAILS_REQUEST_STATUS,
    payload: data
})

export const clearAliasDataAct = data => ({
    type: DigitalCardActionTypes.CLEAR_ALIAS_DATA,
    payload: data
})

//Delete Digital Card with details
export const deleteCardRequest = data => ({
    type: DigitalCardActionTypes.DELETE_CARD_REQUEST,
    payload: data
})

export const deleteCardRequestSuccess = data => ({
    type: DigitalCardActionTypes.DELETE_CARD_REQUEST_SUCCESS,
    payload: data
})

export const deleteCardRequestError = data => ({
    type: DigitalCardActionTypes.DELETE_CARD_REQUEST_ERROR,
    payload: data
})

export const deleteCardRequestStatus = data => ({
    type: DigitalCardActionTypes.DELETE_CARD_REQUEST_STATUS,
    payload: data
})

export const clearCardDetailAct = data => ({
    type: DigitalCardActionTypes.CLEAR_CARD_DETAIL,
    payload: data
})