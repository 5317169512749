import { NewsLetterActionTypes } from './newsLetter.types'
import { API_CONSTANTS } from '../../utils/AppConstants'

const INITIAL_STATE = {
    newsletterAddRecordSuccess: null,
    newsletterAddRecordError: null,
    newsletterAddRecordReqStatus: '',
    newsLetterListSuccess: null,
    newsLetterListError: null
}

const newsLetterReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case NewsLetterActionTypes.NEWS_LETTER_ADD_RECORD_REQUEST_SUCCESS:
            return {
                ...state,
                newsletterAddRecordSuccess: action.payload,
                newsletterAddRecordError: null,
                newsletterAddRecordReqStatus: API_CONSTANTS.SUCCESS_STATUS
            }
        case NewsLetterActionTypes.NEWS_LETTER_ADD_RECORD_REQUEST_ERROR:
            return {
                ...state,
                newsletterAddRecordSuccess: null,
                newsletterAddRecordError: action.payload,
                newsletterAddRecordReqStatus: API_CONSTANTS.ERROR_STATUS
            }
        case NewsLetterActionTypes.NEWS_LETTER_ADD_RECORD_REQUEST_STATUS:
            return {
                ...state,
                newsletterAddRecordReqStatus: ''
            }
        case NewsLetterActionTypes.NEWS_LETTER_RECORD_LIST_REQUEST_SUCCESS:
            return {
                ...state,
                newsLetterListSuccess: action.payload,
                newsLetterListError: null
            }
        case NewsLetterActionTypes.NEWS_LETTER_RECORD_LIST_REQUEST_ERROR:
            return {
                ...state,
                newsLetterListSuccess: null,
                newsLetterListError: action.payload
            }
        case NewsLetterActionTypes.LOGOUT:
            return INITIAL_STATE
        default:
            return state
    }
}

export default newsLetterReducer