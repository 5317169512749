export const NewsLetterActionTypes = {
    LOGOUT: 'LOGOUT',
    COMMON_ERROR: 'COMMON_ERROR',

    NEWS_LETTER_ADD_RECORD_REQUEST: 'NEWS_LETTER_ADD_RECORD_REQUEST',
    NEWS_LETTER_ADD_RECORD_REQUEST_SUCCESS: 'NEWS_LETTER_ADD_RECORD_REQUEST_SUCCESS',
    NEWS_LETTER_ADD_RECORD_REQUEST_ERROR: 'NEWS_LETTER_ADD_RECORD_REQUEST_ERROR',
    NEWS_LETTER_ADD_RECORD_REQUEST_STATUS: 'NEWS_LETTER_ADD_RECORD_REQUEST_STATUS',

    NEWS_LETTER_RECORD_LIST_REQUEST: 'NEWS_LETTER_RECORD_LIST_REQUEST',
    NEWS_LETTER_RECORD_LIST_REQUEST_SUCCESS: 'NEWS_LETTER_RECORD_LIST_REQUEST_SUCCESS',
    NEWS_LETTER_RECORD_LIST_REQUEST_ERROR: 'NEWS_LETTER_RECORD_LIST_REQUEST_ERROR'
}
