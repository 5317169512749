import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getMessaging, getToken, onMessage } from "firebase/messaging";

const firebaseConfig = {
    apiKey: "AIzaSyCvC_IkiA7kn-CX3nrsMxJxeUJpMp22vPQ",
    authDomain: "clicktran-dc-web.firebaseapp.com",
    projectId: "clicktran-dc-web",
    storageBucket: "clicktran-dc-web.appspot.com",
    messagingSenderId: "331853680932",
    appId: "1:331853680932:web:40a6dc31290d51481163b1",
    measurementId: "G-X5GBHMSVGX"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const messaging = getMessaging(app);
export const analytics = getAnalytics(app);

export const onMessageListener = () =>
    new Promise((resolve) => {
        onMessage(messaging, (payload) => {
            resolve(payload);
        });
    });