import { NfcCardOrderActionTypes } from './nfcCardOrder.types'
import { API_CONSTANTS } from '../../utils/AppConstants'

const INITIAL_STATE = {
    placeNfcCardOrderSuccess: null,
    placeNfcCardOrderError: null,
    placeNfcCardOrderReqStatus: '',
    updateNfcCardOrderDetailsSuccess: null,
    updateNfcCardOrderDetailsError: null,
    updateNfcCardOrderDetailsReqStatus: '',
    nfcCardOrderListSuccess: null,
    nfcCardOrderListError: null,
    nfcCardOrderDetailSuccess: null,
    nfcCardOrderDetailError: null
}

const nfcCardOrderReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case NfcCardOrderActionTypes.PLACE_NFC_CARD_ORDER_REQUEST_SUCCESS:
            return {
                ...state,
                placeNfcCardOrderSuccess: action.payload,
                placeNfcCardOrderError: null,
                placeNfcCardOrderReqStatus: API_CONSTANTS.SUCCESS_STATUS
            }
        case NfcCardOrderActionTypes.PLACE_NFC_CARD_ORDER_REQUEST_ERROR:
            return {
                ...state,
                placeNfcCardOrderSuccess: null,
                placeNfcCardOrderError: action.payload,
                placeNfcCardOrderReqStatus: API_CONSTANTS.ERROR_STATUS
            }
        case NfcCardOrderActionTypes.PLACE_NFC_CARD_ORDER_REQUEST_STATUS:
            return {
                ...state,
                placeNfcCardOrderReqStatus: ''
            }
        case NfcCardOrderActionTypes.UPDATE_NFC_CARD_ORDER_DETAIL_REQUEST_SUCCESS:
            return {
                ...state,
                updateNfcCardOrderDetailsSuccess: action.payload,
                updateNfcCardOrderDetailsError: null,
                updateNfcCardOrderDetailsReqStatus: API_CONSTANTS.SUCCESS_STATUS
            }
        case NfcCardOrderActionTypes.UPDATE_NFC_CARD_ORDER_DETAIL_REQUEST_ERROR:
            return {
                ...state,
                updateNfcCardOrderDetailsSuccess: null,
                updateNfcCardOrderDetailsError: action.payload,
                updateNfcCardOrderDetailsReqStatus: API_CONSTANTS.ERROR_STATUS
            }
        case NfcCardOrderActionTypes.UPDATE_NFC_CARD_ORDER_DETAIL_REQUEST_STATUS:
            return {
                ...state,
                updateNfcCardOrderDetailsReqStatus: ''
            }
        case NfcCardOrderActionTypes.NFC_CARD_ORDER_LIST_REQUEST_SUCCESS:
            return {
                ...state,
                nfcCardOrderListSuccess: action.payload,
                nfcCardOrderListError: null
            }
        case NfcCardOrderActionTypes.NFC_CARD_ORDER_LIST_REQUEST_ERROR:
            return {
                ...state,
                nfcCardOrderListSuccess: null,
                nfcCardOrderListError: action.payload
            }
        case NfcCardOrderActionTypes.NFC_CARD_ORDER_DETAIL_REQUEST_SUCCESS:
            return {
                ...state,
                nfcCardOrderDetailSuccess: action.payload,
                nfcCardOrderDetailError: null
            }
        case NfcCardOrderActionTypes.NFC_CARD_ORDER_DETAIL_REQUEST_ERROR:
            return {
                ...state,
                nfcCardOrderDetailSuccess: null,
                nfcCardOrderDetailError: action.payload
            }
        case NfcCardOrderActionTypes.LOGOUT:
            return INITIAL_STATE
        default:
            return state
    }
}

export default nfcCardOrderReducer