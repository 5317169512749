import { HotelPointActionTypes } from './hotelPoint.types'

//Add Record
export const addHotelPointRequest = data => ({
    type: HotelPointActionTypes.ADD_HOTEL_POINT_REQUEST,
    payload: data
})

export const addHotelPointRequestSuccess = data => ({
    type: HotelPointActionTypes.ADD_HOTEL_POINT_REQUEST_SUCCESS,
    payload: data
})

export const addHotelPointRequestError = data => ({
    type: HotelPointActionTypes.ADD_HOTEL_POINT_REQUEST_ERROR,
    payload: data
})

export const addHotelPointRequestStatus = data => ({
    type: HotelPointActionTypes.ADD_HOTEL_POINT_REQUEST_STATUS,
    payload: data
})

//Record List
export const hotelPointRecordListRequest = data => ({
    type: HotelPointActionTypes.HOTEL_POINT_RECORD_LIST_REQUEST,
    payload: data
})

export const hotelPointRecordListRequestSuccess = data => ({
    type: HotelPointActionTypes.HOTEL_POINT_RECORD_LIST_REQUEST_SUCCESS,
    payload: data
})

export const hotelPointRecordListRequestError = data => ({
    type: HotelPointActionTypes.HOTEL_POINT_RECORD_LIST_REQUEST_ERROR,
    payload: data
})