export const UserActionTypes = {
    USER_INFO: 'USER_INFO',
    LOGIN_USER_REQUEST: 'LOGIN_USER_REQUEST',
    LOGIN_USER_SUCCESS: 'LOGIN_USER_SUCCESS',
    LOGIN_USER_ERROR: 'LOGIN_USER_ERROR',

    REGISTER_REQUEST: 'REGISTER_REQUEST',
    REGISTER_REQUEST_SUCCESS: 'REGISTER_REQUEST_SUCCESS',
    REGISTER_REQUEST_ERROR: 'REGISTER_REQUEST_ERROR',
    REGISTER_REQUEST_STATUS: 'REGISTER_REQUEST_STATUS',

    REGISTER_REQUEST_BY_MOBILE: 'REGISTER_REQUEST_BY_MOBILE',
    REGISTER_REQUEST_BY_MOBILE_SUCCESS: 'REGISTER_REQUEST_BY_MOBILE_SUCCESS',
    REGISTER_REQUEST_BY_MOBILE_ERROR: 'REGISTER_REQUEST_BY_MOBILE_ERROR',
    REGISTER_REQUEST_BY_MOBILE_STATUS: 'REGISTER_REQUEST_BY_MOBILE_STATUS',

    CUSTOMER_CARD_REQUEST: 'CUSTOMER_CARD_REQUEST',
    CUSTOMER_CARD_REQUEST_SUCCESS: 'CUSTOMER_CARD_REQUEST_SUCCESS',
    CUSTOMER_CARD_REQUEST_ERROR: 'CUSTOMER_CARD_REQUEST_ERROR',
    CUSTOMER_CARD_REQUEST_STATUS: 'CUSTOMER_CARD_REQUEST_STATUS',

    SUBSCRIPTION_LIST_REQUEST: 'SUBSCRIPTION_LIST_REQUEST',
    SUBSCRIPTION_LIST_REQUEST_SUCCESS: 'SUBSCRIPTION_LIST_REQUEST_SUCCESS',
    SUBSCRIPTION_LIST_REQUEST_ERROR: 'SUBSCRIPTION_LIST_REQUEST_ERROR',

    UPDATE_NOTIFICATION_TOKEN_REQUEST: 'UPDATE_NOTIFICATION_TOKEN_REQUEST',
    UPDATE_NOTIFICATION_TOKEN_REQUEST_SUCCESS: 'UPDATE_NOTIFICATION_TOKEN_REQUEST_SUCCESS',
    UPDATE_NOTIFICATION_TOKEN_REQUEST_ERROR: 'UPDATE_NOTIFICATION_TOKEN_REQUEST_ERROR',

    FORGOT_PASSWORD_REQUEST: 'FORGOT_PASSWORD_REQUEST',
    FORGOT_PASSWORD_REQUEST_SUCCESS: 'FORGOT_PASSWORD_REQUEST_SUCCESS',
    FORGOT_PASSWORD_REQUEST_ERROR: 'FORGOT_PASSWORD_REQUEST_ERROR',
    FORGOT_PASSWORD_REQUEST_STATUS: 'FORGOT_PASSWORD_REQUEST_STATUS',

    CHANGE_PASSWORD_REQUEST: 'CHANGE_PASSWORD_REQUEST',
    CHANGE_PASSWORD_REQUEST_SUCCESS: 'CHANGE_PASSWORD_REQUEST_SUCCESS',
    CHANGE_PASSWORD_REQUEST_ERROR: 'CHANGE_PASSWORD_REQUEST_ERROR',
    CHANGE_PASSWORD_REQUEST_STATUS: 'CHANGE_PASSWORD_REQUEST_STATUS',

    LOGOUT: 'LOGOUT',

    COUNTRY_LIST_REQUEST: 'COUNTRY_LIST_REQUEST',
    COUNTRY_LIST_REQUEST_SUCCESS: 'COUNTRY_LIST_REQUEST_SUCCESS',
    COUNTRY_LIST_REQUEST_ERROR: 'COUNTRY_LIST_REQUEST_ERROR',

    STATE_LIST_REQUEST: 'STATE_LIST_REQUEST',
    STATE_LIST_REQUEST_SUCCESS: 'STATE_LIST_REQUEST_SUCCESS',
    STATE_LIST_REQUEST_ERROR: 'STATE_LIST_REQUEST_ERROR',

    CITY_LIST_REQUEST: 'CITY_LIST_REQUEST',
    CITY_LIST_REQUEST_SUCCESS: 'CITY_LIST_REQUEST_SUCCESS',
    CITY_LIST_REQUEST_ERROR: 'CITY_LIST_REQUEST_ERROR',

    RESET_STATE_CITY: 'RESET_STATE_CITY',

    DISPLAY_TOUR: 'DISPLAY_TOUR',

    IP_LOCATION_REQUEST: 'IP_LOCATION_REQUEST',
    IP_LOCATION_REQUEST_SUCCESS: 'IP_LOCATION_REQUEST_SUCCESS',
    IP_LOCATION_REQUEST_ERROR: 'IP_LOCATION_REQUEST_ERROR',

    DISPLAY_SIDE_BAR: 'DISPLAY_SIDE_BAR',

    CONTACT_US_REQUEST: 'CONTACT_US_REQUEST',
    CONTACT_US_REQUEST_SUCCESS: 'CONTACT_US_REQUEST_SUCCESS',
    CONTACT_US_REQUEST_ERROR: 'CONTACT_US_REQUEST_ERROR',
    CONTACT_US_REQUEST_STATUS: 'CONTACT_US_REQUEST_STATUS',

    USER_REGISTRATION_BUSINESS_REQUEST: 'USER_REGISTRATION_BUSINESS_REQUEST',
    USER_REGISTRATION_BUSINESS_REQUEST_SUCCESS: 'USER_REGISTRATION_BUSINESS_REQUEST_SUCCESS',
    USER_REGISTRATION_BUSINESS_REQUEST_ERROR: 'USER_REGISTRATION_BUSINESS_REQUEST_ERROR',
    USER_REGISTRATION_BUSINESS_REQUEST_STATUS: 'USER_REGISTRATION_BUSINESS_REQUEST_STATUS',

    BUSINESS_DETAILS_REQUEST: 'BUSINESS_DETAILS_REQUEST',
    BUSINESS_DETAILS_REQUEST_SUCCESS: 'BUSINESS_DETAILS_REQUEST_SUCCESS',
    BUSINESS_DETAILS_REQUEST_ERROR: 'BUSINESS_DETAILS_REQUEST_ERROR',

    BUSINESS_VISITOR_CONFIG_UPDATE_REQUEST: 'BUSINESS_VISITOR_CONFIG_UPDATE_REQUEST',
    BUSINESS_VISITOR_CONFIG_UPDATE_REQUEST_SUCCESS: 'BUSINESS_VISITOR_CONFIG_UPDATE_REQUEST_SUCCESS',
    BUSINESS_VISITOR_CONFIG_UPDATE_REQUEST_ERROR: 'BUSINESS_VISITOR_CONFIG_UPDATE_REQUEST_ERROR',
    BUSINESS_VISITOR_CONFIG_UPDATE_REQUEST_STATUS: 'BUSINESS_VISITOR_CONFIG_UPDATE_REQUEST_STATUS',

    COMMON_ERROR: 'COMMON_ERROR',

    BUSINESS_UPDATE_NOTIFICATION_REQUEST: 'BUSINESS_UPDATE_NOTIFICATION_REQUEST',
    BUSINESS_UPDATE_NOTIFICATION_REQUEST_SUCCESS: 'BUSINESS_UPDATE_NOTIFICATION_REQUEST_SUCCESS',
    BUSINESS_UPDATE_NOTIFICATION_REQUEST_ERROR: 'BUSINESS_UPDATE_NOTIFICATION_REQUEST_ERROR',
    BUSINESS_UPDATE_NOTIFICATION_REQUEST_STATUS: 'BUSINESS_UPDATE_NOTIFICATION_REQUEST_STATUS',

    OPERATOR_REGISTERATION_REQUEST: 'OPERATOR_REGISTERATION_REQUEST',
    OPERATOR_REGISTERATION_REQUEST_SUCCESS: 'OPERATOR_REGISTERATION_REQUEST_SUCCESS',
    OPERATOR_REGISTERATION_REQUEST_ERROR: 'OPERATOR_REGISTERATION_REQUEST_ERROR',
    OPERATOR_REGISTERATION_REQUEST_STATUS: 'OPERATOR_REGISTERATION_REQUEST_STATUS',

    UPDATE_OPERATOR_LOCATION_REQUEST: 'UPDATE_OPERATOR_LOCATION_REQUEST',
    UPDATE_OPERATOR_LOCATION_REQUEST_SUCCESS: 'UPDATE_OPERATOR_LOCATION_REQUEST_SUCCESS',
    UPDATE_OPERATOR_LOCATION_REQUEST_ERROR: 'UPDATE_OPERATOR_LOCATION_REQUEST_ERROR',
    UPDATE_OPERATOR_LOCATION_REQUEST_STATUS: 'UPDATE_OPERATOR_LOCATION_REQUEST_STATUS',

    OPERATOR_LIST_REQUEST: 'OPERATOR_LIST_REQUEST',
    OPERATOR_LIST_REQUEST_SUCCESS: 'OPERATOR_LIST_REQUEST_SUCCESS',
    OPERATOR_LIST_REQUEST_ERROR: 'OPERATOR_LIST_REQUEST_ERROR',

    OPERATOR_DETAIL_REQUEST: 'OPERATOR_DETAIL_REQUEST',
    OPERATOR_DETAIL_REQUEST_SUCCESS: 'OPERATOR_DETAIL_REQUEST_SUCCESS',
    OPERATOR_DETAIL_REQUEST_ERROR: 'OPERATOR_DETAIL_REQUEST_ERROR'
}
