import '../node_modules/bootstrap/dist/css/bootstrap.min.css'
import '../node_modules/bootstrap/dist/js/bootstrap.bundle.js'
import React, { useEffect, useState, useContext } from 'react';
import './App.css';
import { Routes, Route, Link, useNavigate, useLocation } from "react-router-dom";
import RouteGuard from './components/route-guard/route-guard.component'
import { API_CONSTANTS, APP_CONSTANTS, ROUTES } from './utils/AppConstants'
import { useSelector, useDispatch } from 'react-redux';
import HomePage from './pages/landing-page/home/homePage'
import SignInScreen from './pages/visitor-system/sign-in/signInScreen'
import SignUpScreen from './pages/visitor-system/sign-up/signUpScreen'
import ForgotPassword from './pages/forgot-password/forgotPassword'
import CardList from './pages/business-card/card-list/cardList'
import PrivacyPolicyPage from './pages/privacy-policy/privacyPolicyScreen'
import CookiePolicyScreen from './pages/cookie-policy/cookiePolicyScreen';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { clearLocalStorage, displayConsoleLog, displaySuccessToast, getDeviceType, isEmpty, isLoggedInUserAdmin, isLoggedInUserBusiness, isLoggedInUserCorporate, isLoggedInUserCustomer, isLoggedInUserOperator, isNull } from './utils/Utils';
import CustomSidebar from './components/sidebar/sidebar.component'
import AddCard from './pages/business-card/add-card/addCard'
import CardDetails from './pages/business-card/card-details/cardDetails'
import UserCard from './pages/business-card/user-card/userCard'
import NavBarComponent from './pages/navbar-component/navBarComponent';
import CreateCustomQrCode from './pages/custom-qr-code/create-custom-qr-code/createCustomQrCode'
import CustomQrCodeList from './pages/custom-qr-code/custom-qr-code-list/customQrCodeList'
import AddFeedback from './pages/feedback/add-feedback/addFeedback'
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import Paper from '@mui/material/Paper';
import VisitorForm from './pages/visitor-system/visitor-form/visitorForm'
import VisitorList from './pages/visitor-system/visitor-list/visitorList'
import { config } from './config';
import Modal from 'react-bootstrap/Modal';
import { SocketContext } from './SocketCtx'
import HomeVMS from './pages/landing-page/home-vms/homeVMS'
import VisitorConfiguration from './pages/visitor-system/visitor-configuration/visitorConfiguration'
import { onMessageListener, messaging } from './fcmAnalytics'
import { getToken } from "firebase/messaging";
import { displayErrorToast } from './utils/Utils';
import { businessUpdateNotificationTokenRequest, businessUpdateNotificationTokenRequestStatus } from './redux/user/user.action'
import Point from './pages/campaign/point/point'
import CustomQrDynamicPage from './pages/custom-qr-code/custom-qr-dynamic-page/customQrDynamicPage'
import HomeNew from './pages/landing-page/home-new/homeNew'
import DigitalGoodsPage from './pages/digital-product-page/digitalProductPage'
import CommonInputFloatFull from './components/input/common-input-float-full/commonInputFloatFull';
import { newsLetterAddRecordRequest, newsLetterAddRecordRequestStatus } from './redux/newsletter/newsLetter.action'
import { siteVisitorAnayticsAddRecordRequest, siteVisitorAnayticsAddRecordRequestStatus } from './redux/site-visitor-analytics/siteVisitorAnalytics.action'
import Loader from './components/loader/loader.component'
import { v4 as uuidv4 } from 'uuid';
import NotFoundPage from './pages/not-found/notFoundPage'
import SiteAnalytics from './pages/analytics/site-analytics/siteAnalytics'
import NFCTemplateSelection from './pages/business-card/nfc-card/nfc-template-selection/nfcTemplateSelection'
import NfcCardOrderList from './pages/business-card/nfc-card/nfc-card-order/nfc-card-order-list/nfcCardOrderList'
import NfcCardOrderDetail from './pages/business-card/nfc-card/nfc-card-order/nfc-card-order-detail/nfcCardOrderDetail'
import AdminLoginInScreen from './pages/admin/admin-login/adminLogin'
import CoporateLoginInScreen from './pages/business-card/corporate/login/loginScreen'
import CoporateCreateAccountScreen from './pages/business-card/corporate/create-account/createAccount'
import AddEmployeeScreen from './pages/business-card/corporate/employee/add-employee/addEmployeeScreen'
import EmployeeList from './pages/business-card/corporate/employee/employee-list/employeeList'
import OrderNfcEmployee from './pages/business-card/corporate/order-nfc-employee/orderNfcEmployee'
import EmployeeCardView from './pages/business-card/corporate/employee/employee-card-view/employeeCardView'
import OperatorList from './pages/admin/operator/operator-list/operatorList'
import OperatorDetail from './pages/admin/operator/operator-detail/operatorDetail'
import AddOperator from './pages/admin/operator/add-operator/addOperator'
import TawkMessengerReact from '@tawk.to/tawk-messenger-react';
import NfcWriterPage from './pages/dev/nfc-writer/nfcWriter.js'

function App() {
  const { isLogin, displayBottomBar, user, displaySideBar, commonError,
    businessUpdateTokenSuccess, businessUpdateTokenError, businessUpdateTokenReqStatus } = useSelector((state) => state.user)
  const { newsletterAddRecordSuccess, newsletterAddRecordError, newsletterAddRecordReqStatus } = useSelector((state) => state.newsLetter)
  const { siteVisitorAnalyticsSuccess, siteVisitorAnalyticsError, siteVisitorAnalyticsReqStatus } = useSelector((state) => state.siteVisitorAnalytics)
  let navigate = useNavigate();
  let dispatch = useDispatch()
  // const socket = useContext(SocketContext)
  const [showCookieConsent, setShowCookieConsent] = useState(true)
  const [value, setValue] = useState(0);
  const [isShowSocketBlockModal, setIsShowSocketBlockModal] = useState(false)
  const [isShowInternetErrorModal, setIsShowInternetErrorModal] = useState(false)
  const [isConnectionBlocked, setIsConnectionBlocked] = useState(false)
  const [isSocketBlocked, setIsSocketBlocked] = useState(false)
  const [showConnectionBlockedModal, setShowConnectionBlockedModal] = useState(false)
  const [showSocketBlockedModal, setShowSocketBlockedModal] = useState(false)
  const socket = useContext(SocketContext)
  const location = useLocation();
  const [notification, setNotification] = useState({ title: '', body: '' });
  const [fcmToken, setFcmToken] = useState('')
  const [showNewsLetterConsent, setShowNewsLetterConsent] = useState(false)
  const [newsLetterEmail, setNewsLetterEmail] = useState('')
  const [isLoading, setLoading] = useState(false)
  const [latitude, setLatitude] = useState(null);
  const [longitude, setLongitude] = useState(null);
  const [analyticsQueue, setAnalyticsQueue] = useState([])
  const [uniqueId, setUniqueId] = useState(null);
  const [counter, setCounter] = useState('')
  const [isPageLoaded, setIsPageLoaded] = useState(false)

  useEffect(() => {
    // caches.keys().then(cs => {
    //   cs.forEach(c => caches.delete(c))
    // })
    // const storedUniqueId = localStorage.getItem('uniqueId');
    // if (!storedUniqueId) {
    //   const newUniqueId = uuidv4();
    //   localStorage.setItem('uniqueId', newUniqueId);
    //   setUniqueId(newUniqueId);
    // } else {
    //   setUniqueId(storedUniqueId);
    // }
    setIsPageLoaded(true)
  }, [])

  useEffect(() => {
    if (isPageLoaded) {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const { latitude, longitude } = position.coords;
            setLatitude(parseFloat(latitude));
            setLongitude(parseFloat(longitude));
            addToAnalyticsQueue({
              isFirstLaunch: false,
              idType: 2
            })
          },
          (error) => {
            addToAnalyticsQueue({
              isFirstLaunch: true,
              idType: 1
            })
            displayConsoleLog('Error retrieving location:', error);
          }
        );
      } else {
        addToAnalyticsQueue({
          isFirstLaunch: true,
          idType: 1
        })
        displayConsoleLog('Geolocation is not supported by this browser.');
      }
    }
  }, [isPageLoaded])
  // useEffect(() => {
  //   if (uniqueId) {
  //     displayConsoleLog('========uniqueId', uniqueId)
  //     addToAnalyticsQueue({
  //       isFirstLaunch: true,
  //       idType: 1
  //     })
  //     if (navigator.geolocation) {
  //       navigator.geolocation.getCurrentPosition(
  //         (position) => {
  //           const { latitude, longitude } = position.coords;
  //           setLatitude(parseFloat(latitude));
  //           setLongitude(parseFloat(longitude));
  //           addToAnalyticsQueue({
  //             isFirstLaunch: false,
  //             idType: 2
  //           })
  //         },
  //         (error) => {
  //           displayConsoleLog('Error retrieving location:', error);
  //         }
  //       );
  //     } else {
  //       displayConsoleLog('Geolocation is not supported by this browser.');
  //     }
  //   }
  // }, [uniqueId])

  const processVisitorAnalytics = () => {
    if (analyticsQueue.length === 0) {
      return
    }
    displayConsoleLog('-queue', analyticsQueue)
    const data = {
      deviceType: getDeviceType(window),
      productCode: APP_CONSTANTS.PRODUCT_NAME
    }
    if (isLogin && !isEmpty(user)) {
      data.userId = user.data.userDetails.userId
      if (isLoggedInUserBusiness(user)) {
        data.businessId = user.data.userDetails.businessData.businessId
      }
    }
    if (!isNull(latitude)) {
      data.latitude = latitude
    }
    if (!isNull(longitude)) {
      data.longitude = longitude
    }
    dispatch(siteVisitorAnayticsAddRecordRequest(data))
  }

  useEffect(() => {
    if (businessUpdateTokenReqStatus?.length) {
      dispatch(businessUpdateNotificationTokenRequestStatus(''))
    }
  }, [businessUpdateTokenSuccess, businessUpdateTokenError, businessUpdateTokenReqStatus])

  useEffect(() => {
    if (isLogin === true && isLoggedInUserBusiness(user)) {
      fetchTokenFun()
    }
  }, [isLogin])

  useEffect(() => {
    if (isLogin === true && !isNull(user)) {
      addToAnalyticsQueue({
        isFirstLaunch: false,
        idType: 3
      })
    }
  }, [isLogin, user])

  useEffect(() => {
    if (newsletterAddRecordReqStatus === API_CONSTANTS.SUCCESS_STATUS) {
      setLoading(false)
      displaySuccessToast(newsletterAddRecordSuccess.message)
      localStorage.setItem(APP_CONSTANTS.DISPLAY_NEWS_LETTER_CONSENT, "YES")
      setShowNewsLetterConsent(false)
    } else if (newsletterAddRecordReqStatus === API_CONSTANTS.ERROR_STATUS) {
      setLoading(false)
      displayErrorToast(newsletterAddRecordError.message)
      if (newsletterAddRecordError.message.includes(APP_CONSTANTS.EMAIL_ALREADY_SUBSCRIBED_ERROR_MESSAGE)) {
        localStorage.setItem(APP_CONSTANTS.DISPLAY_NEWS_LETTER_CONSENT, "YES")
        setShowNewsLetterConsent(false)
      }
    }
    if (newsletterAddRecordReqStatus?.length) {
      dispatch(newsLetterAddRecordRequestStatus(''))
    }
  }, [newsletterAddRecordSuccess, newsletterAddRecordError, newsletterAddRecordReqStatus])

  useEffect(() => {
    if (siteVisitorAnalyticsReqStatus === API_CONSTANTS.SUCCESS_STATUS && !isNull(siteVisitorAnalyticsSuccess)) {
      if (!isNull(siteVisitorAnalyticsSuccess)) {
        displayConsoleLog('siteVisitorAnalyticsSuccess', siteVisitorAnalyticsSuccess)
      }
      const updateQueue = analyticsQueue.slice(1)
      setAnalyticsQueue(updateQueue)
    }
    if (siteVisitorAnalyticsReqStatus?.length) {
      dispatch(siteVisitorAnayticsAddRecordRequestStatus(''))
      // setAnalyticsQueue((prevQueue) => prevQueue.slice(1))
    }
  }, [siteVisitorAnalyticsSuccess, siteVisitorAnalyticsError, siteVisitorAnalyticsReqStatus])

  useEffect(() => {
    processVisitorAnalytics()
  }, [analyticsQueue])

  const fetchTokenFun = () => {
    getToken(messaging, { vapidKey: 'BPTuCnWAeR46rWE1mA2eNI-7YJbiQuVLfohaPb0ShDOTfr8PMmD6tOdTBr2HZFYjUsemOStdzyz9Cl3r12kPiyI' })
      .then((currentToken) => {
        if (currentToken) {
          if (isLoggedInUserBusiness(user)) {
            localStorage.setItem(APP_CONSTANTS.KEY_FCM_TOKEN, currentToken)
            sendFcmTokenToServer(currentToken)
          }
        } else {
          if (isLoggedInUserBusiness(user)) {
            displayErrorToast('Require permission to receive notification')
          }
          displayConsoleLog('No registration token available. Request permission to generate one.');
          // shows on the UI that permission is required 
        }
      }).catch((err) => {
        displayConsoleLog('An error occurred while retrieving token. ', err);
        // catch error while creating client token
      })
  }

  const sendFcmTokenToServer = (currentToken) => {
    const data = {
      notificationToken: currentToken,
      businessId: user.data.userDetails.businessData.businessId
    }
    dispatch(businessUpdateNotificationTokenRequest(data))
  }

  const handleCookieConsentClick = () => {
    localStorage.setItem(APP_CONSTANTS.DISPLAY_COOKIE_CONSENT, "YES")
    setShowCookieConsent(false)
    // setDisplayCookieConsent(false)
  }

  const handleNewsLetterConsentClick = () => {
    // localStorage.setItem(APP_CONSTANTS.DISPLAY_NEWS_LETTER_CONSENT, "YES")
    setShowNewsLetterConsent(false)
  }

  const handleCookieLabelClick = () => {
    navigate(ROUTES.cookiePolicy)
  }

  const displayCookieConsent = () => {
    if (!isEmpty(localStorage.getItem(APP_CONSTANTS.DISPLAY_COOKIE_CONSENT)) && localStorage.getItem(APP_CONSTANTS.DISPLAY_COOKIE_CONSENT) === 'YES') {
      return false
    }
    return true
  }

  const displayNewsLetterConsent = () => {
    if (!isEmpty(localStorage.getItem(APP_CONSTANTS.DISPLAY_NEWS_LETTER_CONSENT)) && localStorage.getItem(APP_CONSTANTS.DISPLAY_NEWS_LETTER_CONSENT) === 'YES') {
      return false
    }
    return true
  }

  useEffect(() => {
    if (!isNull(commonError)) {
      displayConsoleLog('commonError', commonError)
    }
  }, [commonError])

  const checkForAPIConnection = () => {
    fetch(`${config.baseUrl}/checkServerConnectivity`)
      .then(response => {
        displayConsoleLog('response', response)
        if (!response.ok) {
          setIsShowInternetErrorModal(true)
        } else {
          setIsShowInternetErrorModal(false)
        }
      })
      .catch(() => {
        setIsShowInternetErrorModal(true)
      })
  }

  const checkIsConnectionBlocked = () => {
    socket.on('connect_error', () => {
      if (!location.pathname.includes(ROUTES.visitorForm)) {
        setIsShowSocketBlockModal(true)
        setIsShowInternetErrorModal(false)
      } else {
        setIsShowSocketBlockModal(false)
        checkForAPIConnection()
      }
      socket.disconnect();
    });

    socket.on('connect', () => {
      setIsShowSocketBlockModal(false)
    });
  }

  // checkIsConnectionBlocked()

  useEffect(() => {
    if (isConnectionBlocked === true) {
      setShowConnectionBlockedModal(true)
    }
  }, [isConnectionBlocked])

  const handleNewsLetterSubmitClick = () => {
    if (isEmpty(newsLetterEmail)) {
      displayErrorToast('Email address cannot be blank')
    } else {
      setLoading(true)
      const data = {
        email: newsLetterEmail,
        productCode: APP_CONSTANTS.PRODUCT_NAME
      }
      dispatch(newsLetterAddRecordRequest(data))
    }
  }

  const addToAnalyticsQueue = (item) => {
    // let currentList = analyticsQueue
    let itemExists = analyticsQueue.find(e => Number(e.idType) === item.idType)
    displayConsoleLog(isEmpty(itemExists))
    if (isEmpty(itemExists)) {
      displayConsoleLog('+++++++++item', item)
      // currentList.push(item)
      // displayConsoleLog('+++++++++currentList', currentList)
      // setAnalyticsQueue(currentList)
      setAnalyticsQueue((prevQueue) => [...prevQueue, item])
    }

    // setAnalyticsQueue((prevQueue) => [...prevQueue, item])
  }

  return (
    // <div style={{ display: 'flex', flexDirection: isLogin ? 'row' : 'column', height: '100vh' }}>
    <div style={{ display: 'flex', flexDirection: 'column', height: '100vh' }}>
      {/* {isLogin && displaySideBar === true && user && <div >
        <NavBarComponent navigate={navigate} logoutClickListener={() => {
          clearLocalStorage()
          dispatch({ type: APP_CONSTANTS.LOGOUT })
        }} />
      </div>} */}
      {/* <div className={isLogin ? "content-area-full" : displayBottomBar ? "content-area" : "content-area-full"}> */}
      <div className={isLogin ? "content-area" : "content-area-full"}>
        <Routes>
          {/* <Route path={ROUTES.home} element={<HomePage />} /> */}
          <Route path={ROUTES.home} element={<CreateCustomQrCode />} />
          <Route path={ROUTES.homeCards} element={<HomeNew />} />
          {/* <Route path={ROUTES.homeNew} element={<HomeNew />} /> */}
          <Route path={ROUTES.privacyPolicy} element={<PrivacyPolicyPage />} />
          <Route path={ROUTES.cookiePolicy} element={<CookiePolicyScreen />} />
          <Route path={ROUTES.adminLogin} element={<AdminLoginInScreen />} />
          <Route path={ROUTES.signIn} element={<SignInScreen />} />
          <Route path={ROUTES.signUp} element={<SignUpScreen />} />
          <Route path={ROUTES.corporateLogin} element={<CoporateLoginInScreen />} />
          <Route path={ROUTES.corporateCreateAccount} element={<CoporateCreateAccountScreen />} />
          <Route path={ROUTES.forgotPassword} element={<ForgotPassword />} />
          {/* <Route path={ROUTES.visitorHome} element={<HomeVMS />} /> */}
          <Route path={ROUTES.point} element={<Point />} />
          <Route path={`${ROUTES.vCard}/:cardAlias`} element={<UserCard />} />
          <Route path={`${ROUTES.employeeCard}/:employeeId`} element={<EmployeeCardView />} />
          <Route path={`${ROUTES.visitorForm}/:businessId`} element={<VisitorForm />} />
          <Route path={`${ROUTES.dynamicQrLink}/:qrCodeId`} element={<CustomQrDynamicPage />} />
          <Route path={ROUTES.generateQrCode} element={<CreateCustomQrCode />} />
          <Route path={ROUTES.qrForYou} element={<CreateCustomQrCode />} />
          <Route path={ROUTES.qrYou} element={<CreateCustomQrCode />} />
          <Route path={ROUTES.digitalProduct} element={<DigitalGoodsPage />} />
          <Route path={ROUTES.siteAnalytics} element={<SiteAnalytics />} />
          <Route path={ROUTES.dev.nfcWriter} element={<NfcWriterPage />} />
          <Route path={ROUTES.cardList} element={
            <RouteGuard>
              <CardList />
            </RouteGuard>
          } />
          <Route path={ROUTES.addCard} element={
            <RouteGuard>
              <AddCard />
            </RouteGuard>
          } />
          <Route path={ROUTES.cardDetail} element={
            <RouteGuard>
              <CardDetails />
            </RouteGuard>
          } />
          <Route path={ROUTES.qrCodeList} element={
            <RouteGuard>
              <CustomQrCodeList />
            </RouteGuard>
          } />
          <Route path={ROUTES.addFeedback} element={
            <RouteGuard>
              <AddFeedback />
            </RouteGuard>
          } />
          {/* <Route path={ROUTES.visitorList} element={
            <RouteGuard>
              <VisitorList />
            </RouteGuard>
          } />
          <Route path={ROUTES.visitorConfig} element={
            <RouteGuard>
              <VisitorConfiguration />
            </RouteGuard>
          } /> */}
          <Route path={ROUTES.nfcTemplateSelection} element={
            <RouteGuard>
              <NFCTemplateSelection />
            </RouteGuard>
          } />
          <Route path={ROUTES.orderList} element={
            <RouteGuard>
              <NfcCardOrderList />
            </RouteGuard>
          } />
          <Route path={ROUTES.orderDetails} element={
            <RouteGuard>
              <NfcCardOrderDetail />
            </RouteGuard>
          } />
          <Route path={ROUTES.addEmployee} element={
            <RouteGuard>
              <AddEmployeeScreen />
            </RouteGuard>
          } />
          <Route path={ROUTES.employeeList} element={
            <RouteGuard>
              <EmployeeList />
            </RouteGuard>
          } />
          <Route path={ROUTES.corporateNfcOrder} element={
            <RouteGuard>
              <OrderNfcEmployee />
            </RouteGuard>
          } />
          <Route path={ROUTES.operatorList} element={
            <RouteGuard>
              <OperatorList />
            </RouteGuard>
          } />
          <Route path={ROUTES.operatorDetail} element={
            <RouteGuard>
              <OperatorDetail />
            </RouteGuard>
          } />
          <Route path={ROUTES.addOperator} element={
            <RouteGuard>
              <AddOperator />
            </RouteGuard>
          } />
          <Route path='*' element={<NotFoundPage />} />
        </Routes>
      </div>

      {isLogin && displaySideBar === true && user && isLoggedInUserCustomer(user) && <div className='bottom-tab-nav'>
        <div className='bottom-tab-nav-container' onClick={() => navigate(ROUTES.cardList)}>
          <div className='bottom-tab-nav-icon'>
            <img src={require('./assets/ic_float_icon.png')} className='img' />
          </div>
          <div className='bottom-tab-nav-label'>ClickTran Card</div>
        </div>

        <div className='bottom-tab-nav-container' onClick={() => navigate(ROUTES.generateQrCode)}>
          <div className='bottom-tab-nav-icon'>
            <img src={require('./assets/ic_nav_tools.png')} className='img' />
          </div>
          <div className='bottom-tab-nav-label'>Tools</div>
        </div>
        <div className='bottom-tab-nav-container' onClick={() => navigate(ROUTES.orderList)}>
          <div className='bottom-tab-nav-icon'>
            <img src={require('./assets/ic_nav_order_icon.png')} className='img' />
          </div>
          <div className='bottom-tab-nav-label'>Orders</div>
        </div>

        <div className='bottom-tab-nav-container' onClick={() => navigate(ROUTES.addFeedback)}>
          <div className='bottom-tab-nav-icon'>
            <img src={require('./assets/ic_nav_feedback.png')} className='img' />
          </div>
          <div className='bottom-tab-nav-label'>Feedback</div>
        </div>
        <div className='bottom-tab-nav-container' onClick={() => {
          clearLocalStorage()
          dispatch({ type: APP_CONSTANTS.LOGOUT })
        }}>
          <div className='bottom-tab-nav-icon'>
            <img src={require('./assets/ic_nav_logout.png')} className='img' />
          </div>
          <div className='bottom-tab-nav-label'>Logout</div>
        </div>

      </div>
      }
      {isLogin && displaySideBar === true && user && isLoggedInUserBusiness(user) && <div className='bottom-tab-nav'>
        <div className='bottom-tab-nav-container' onClick={() => navigate(ROUTES.visitorList)}>
          <div className='bottom-tab-nav-icon'>
            <img src={require('./assets/ic_nav_visitor.png')} className='img' />
          </div>
          <div className='bottom-tab-nav-label'>Visitor List</div>
        </div>
        <div className='bottom-tab-nav-container' onClick={() => navigate(ROUTES.visitorConfig)}>
          <div className='bottom-tab-nav-icon'>
            <img src={require('./assets/ic_nav_settings.png')} className='img' />
          </div>
          <div className='bottom-tab-nav-label'>Settings</div>
        </div>
        <div className='bottom-tab-nav-container' onClick={() => navigate(ROUTES.addFeedback)}>
          <div className='bottom-tab-nav-icon'>
            <img src={require('./assets/ic_nav_feedback.png')} className='img' />
          </div>
          <div className='bottom-tab-nav-label'>Feedback</div>
        </div>
        <div className='bottom-tab-nav-container' onClick={() => {
          clearLocalStorage()
          dispatch({ type: APP_CONSTANTS.LOGOUT })
        }}>
          <div className='bottom-tab-nav-icon'>
            <img src={require('./assets/ic_nav_logout.png')} className='img' />
          </div>
          <div className='bottom-tab-nav-label'>Logout</div>
        </div>

      </div>
      }
      {isLogin && displaySideBar === true && user && isLoggedInUserAdmin(user) && <div className='bottom-tab-nav'>
        <div className='bottom-tab-nav-container' onClick={() => navigate(ROUTES.orderList)}>
          <div className='bottom-tab-nav-icon'>
            <img src={require('./assets/ic_nav_order_icon.png')} className='img' />
          </div>
          <div className='bottom-tab-nav-label'>Orders</div>
        </div>
        <div className='bottom-tab-nav-container' onClick={() => navigate(ROUTES.operatorList)}>
          <div className='bottom-tab-nav-icon'>
            <img src={require('./assets/ic_nav_employees.png')} className='img' />
          </div>
          <div className='bottom-tab-nav-label'>Operators</div>
        </div>
        <div className='bottom-tab-nav-container' onClick={() => {
          clearLocalStorage()
          dispatch({ type: APP_CONSTANTS.LOGOUT })
        }}>
          <div className='bottom-tab-nav-icon'>
            <img src={require('./assets/ic_nav_logout.png')} className='img' />
          </div>
          <div className='bottom-tab-nav-label'>Logout</div>
        </div>

      </div>
      }
      {isLogin && displaySideBar === true && user && isLoggedInUserOperator(user) && <div className='bottom-tab-nav'>
        <div className='bottom-tab-nav-container' onClick={() => navigate(ROUTES.orderList)}>
          <div className='bottom-tab-nav-icon'>
            <img src={require('./assets/ic_nav_order_icon.png')} className='img' />
          </div>
          <div className='bottom-tab-nav-label'>Orders</div>
        </div>
        <div className='bottom-tab-nav-container' onClick={() => {
          clearLocalStorage()
          dispatch({ type: APP_CONSTANTS.LOGOUT })
        }}>
          <div className='bottom-tab-nav-icon'>
            <img src={require('./assets/ic_nav_logout.png')} className='img' />
          </div>
          <div className='bottom-tab-nav-label'>Logout</div>
        </div>

      </div>
      }
      {isLogin && displaySideBar === true && user && isLoggedInUserCorporate(user) && <div className='bottom-tab-nav'>
        <div className='bottom-tab-nav-container' onClick={() => navigate(ROUTES.employeeList)}>
          <div className='bottom-tab-nav-icon'>
            <img src={require('./assets/ic_nav_employees.png')} className='img' />
          </div>
          <div className='bottom-tab-nav-label'>Employees</div>
        </div>
        <div className='bottom-tab-nav-container' onClick={() => navigate(ROUTES.orderList)}>
          <div className='bottom-tab-nav-icon'>
            <img src={require('./assets/ic_nav_order_icon.png')} className='img' />
          </div>
          <div className='bottom-tab-nav-label'>Orders</div>
        </div>
        <div className='bottom-tab-nav-container' onClick={() => {
          clearLocalStorage()
          dispatch({ type: APP_CONSTANTS.LOGOUT })
        }}>
          <div className='bottom-tab-nav-icon'>
            <img src={require('./assets/ic_nav_logout.png')} className='img' />
          </div>
          <div className='bottom-tab-nav-label'>Logout</div>
        </div>

      </div>
      }
      <div className='bottom-consent-container'>
        {displayNewsLetterConsent() === true && showNewsLetterConsent === true ? (<div className='newsLetterConsentContainer'>
          {/* <div className='consentCloseContainer'> */}
          <div className='newsLetterCloseContainer' onClick={() => { handleNewsLetterConsentClick() }}>
            <img className='img' src={require('./assets/ic_close_black.png')} />
          </div>
          {/* </div> */}
          <div className='consentText'>Be the first to know about the latest products, promotions and features available on ClickTran.</div>
          <div className='news-letter-input-container'>
            <CommonInputFloatFull
              type='email'
              id='email'
              onChange={e => setNewsLetterEmail(e.target.value)}
              placeholder='Email Address'
              value={newsLetterEmail}
            />
            <div className='news-letter-submit-btn' onClick={() => { handleNewsLetterSubmitClick() }}>Subscribe</div>
          </div>
        </div>) : null}

        {displayCookieConsent() === true && showCookieConsent === true ? (<div className='consentContainer'>
          <div className='consentText'>This site uses cookies to store information on your computer. Some are essential to make our site work; others help us improve the user experience. By using the site, you consent to the placement of the cookies. <span className='consentClickLabel' onClick={() => { handleCookieLabelClick() }}>Read our cookie policy to learn more</span></div>
          <div className='consentCloseContainer' onClick={() => { handleCookieConsentClick() }}>
            <div className='cookie-btn-container'>
              Got it!
            </div>
          </div>
          {/* <div className='consentCloseContainer'>
          <div className='consentCloseContainerImg' onClick={() => { handleCookieConsentClick() }}>
            <img className='img' src={require('./assets/close1.png')} />
          </div>
        </div> */}
        </div>) : null}
      </div>
      <Modal
        onHide={() => { setIsShowInternetErrorModal(false) }}
        show={isShowInternetErrorModal}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Internet Error
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>Unable to connect with the server. Please try connecting with different network.</div>
        </Modal.Body>
      </Modal>
      <Modal
        onHide={() => { setIsShowSocketBlockModal(false) }}
        show={isShowSocketBlockModal}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Connection Error
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>ClickTran connection is being blocked by the connected network. Please check with network adminstrator or try connecting with different network.</div>
        </Modal.Body>
      </Modal>
      <ToastContainer />
      <Loader active={isLoading} text='Please wait...' />
      {/* <TawkMessengerReact
        propertyId="65c1d0a48d261e1b5f5cc546"
        widgetId="1hluhe1hr" /> */}
    </div>

  );
}

export default App;
