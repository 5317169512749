import React, { useEffect, useState } from "react";
import Loader from '../../../components/loader/loader.component'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useDispatch, useSelector } from 'react-redux'
import { useParams, useNavigate } from "react-router-dom";
import { qrCodeDetailRequest } from '../../../redux/custom-qr-code/customQrCode.action'
import { displayConsoleLog, displayErrorToast, displaySuccessToast, isEmpty, isNull } from "../../../utils/Utils";
import CommonScreenContent from "../../../components/screen/common-screen-content/commonScreenContent";

const CustomQrDynamicPage = props => {
    const [isLoading, setLoading] = useState(false)
    const { commonError } = useSelector((state) => state.user)
    const dispatch = useDispatch()
    const { qrCodeDetailSuccess, qrCodeDetailError } = useSelector((state) => state.qrCode)
    let { qrCodeId } = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        setLoading(true)
        const data = {
            qrCodeId: qrCodeId
        }
        dispatch(qrCodeDetailRequest(data))
    }, [])

    useEffect(() => {
        if (!isNull(qrCodeDetailSuccess)) {
            setLoading(false)
            if (!isNull(qrCodeDetailSuccess.data)) {
                displayConsoleLog('qrCodeDetailSuccess.data.qrCodeText', qrCodeDetailSuccess.data.qrCodeText)
                // navigate(qrCodeDetailSuccess.data.qrCodeText, { replace: true });
                if (qrCodeDetailSuccess.data.content?.type === 'LINK') {
                    if (!/^http?:\/\//i.test(qrCodeDetailSuccess.data.qrCodeText)) {
                        window.location.replace(`https://${qrCodeDetailSuccess.data.qrCodeText}`);
                    } else {
                        window.location.replace(qrCodeDetailSuccess.data.qrCodeText);
                    }
                } else {
                    window.location.replace(qrCodeDetailSuccess.data.qrCodeText);
                }
            }
        } else if (!isNull(qrCodeDetailError)) {
            setLoading(false)
            displayErrorToast(qrCodeDetailError.message)
        }
    }, [qrCodeDetailSuccess, qrCodeDetailError])

    return (
        <>
            <CommonScreenContent>
            </CommonScreenContent>
            <ToastContainer />
            <Loader active={isLoading} text='Please wait...' />
        </>
    )
}

export default CustomQrDynamicPage