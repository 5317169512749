export const ProductActionTypes = {
    LOGOUT: 'LOGOUT',

    ADD_PRODUCT_REQUEST: 'ADD_PRODUCT_REQUEST',
    ADD_PRODUCT_REQUEST_SUCCESS: 'ADD_PRODUCT_REQUEST_SUCCESS',
    ADD_PRODUCT_REQUEST_ERROR: 'ADD_PRODUCT_REQUEST_ERROR',
    ADD_PRODUCT_REQUEST_STATUS: 'ADD_PRODUCT_REQUEST_STATUS',

    PRODUCT_LIST_REQUEST: 'PRODUCT_LIST_REQUEST',
    PRODUCT_LIST_REQUEST_SUCCESS: 'PRODUCT_LIST_REQUEST_SUCCESS',
    PRODUCT_LIST_REQUEST_ERROR: 'PRODUCT_LIST_REQUEST_ERROR',

    UPDATE_PRODUCT_REQUEST: 'UPDATE_PRODUCT_REQUEST',
    UPDATE_PRODUCT_REQUEST_SUCCESS: 'UPDATE_PRODUCT_REQUEST_SUCCESS',
    UPDATE_PRODUCT_REQUEST_ERROR: 'UPDATE_PRODUCT_REQUEST_ERROR',
    UPDATE_PRODUCT_REQUEST_STATUS: 'UPDATE_PRODUCT_REQUEST_STATUS',

    DELETE_PRODUCT_REQUEST: 'DELETE_PRODUCT_REQUEST',
    DELETE_PRODUCT_REQUEST_SUCCESS: 'DELETE_PRODUCT_REQUEST_SUCCESS',
    DELETE_PRODUCT_REQUEST_ERROR: 'DELETE_PRODUCT_REQUEST_ERROR',
    DELETE_PRODUCT_REQUEST_STATUS: 'DELETE_PRODUCT_REQUEST_STATUS',
    
    COMMON_ERROR: 'COMMON_ERROR',
}
