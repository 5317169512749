import React, { useEffect, useState } from "react";
import './styles.css'
import Loader from '../../../../components/loader/loader.component'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'react-phone-number-input/style.css'
import { useDispatch, useSelector } from 'react-redux'
import { employeeListRequest } from '../../../../redux/employee/employee.action'
import { countryListRequest, stateListRequest, cityListRequest } from '../../../../redux/user/user.action'
import CommonScreenContent from "../../../../components/screen/common-screen-content/commonScreenContent";
import ScreenLabel from "../../../../components/label/screen-label/screenLabel";
import { useNavigate } from 'react-router-dom';
import { displayErrorToast, displaySuccessToast, isEmpty, isNull } from "../../../../utils/Utils";
import CommonColorForm from "../../../../components/common-color-form/commonColorForm";
import SubTextLabel from "../../../../components/label/sub-text-label/subTextLabel";
import CommonButton from "../../../../components/button/common-button/commonButton";
import { Checkbox } from "@mui/material";
import NfcCardDesign1 from "../../nfc-card/nfc-template-design/nfcCardDesign1";
import NfcCardDesign2 from "../../nfc-card/nfc-template-design/nfcCardDesign2";
import NfcCardDesign3 from "../../nfc-card/nfc-template-design/nfcCardDesign3";
import CommonInputFloatFull from "../../../../components/input/common-input-float-full/commonInputFloatFull";
import FormFieldLabel from "../../../../components/label/form-field-label/formFieldLabel";
import CommonInputRow from "../../../../components/input/common-input-row/commonInputRow";
import CommonInputFloatHalf from "../../../../components/input/common-input-float-half/commonInputFloafHalf";
import CommonInputHalf from "../../../../components/input/common-input-half/commonInputHalf";
import { API_CONSTANTS, APP_CONSTANTS } from "../../../../utils/AppConstants";
import { placeNfcCardOrderRequest, placeNfcCardOrderRequestStatus } from '../../../../redux/nfc-card-order/nfcCardOrder.action'
import Modal from 'react-bootstrap/Modal';
import Select from 'react-select'

const OrderNfcEmployee = props => {
    const [isLoading, setLoading] = useState(false)
    const dispatch = useDispatch()
    const { user, countryListSuccess, countryListError, stateListSuccess, stateListError,
        cityListSuccess, cityListError } = useSelector(state => state.user)
    const { employeeListSuccess, employeeListError } = useSelector(state => state.employee)
    let navigate = useNavigate();
    const [selectedEmployeeList, setSelectedEmployeeList] = useState([])
    const [showEmployeeContainer, setShowEmployeeContainer] = useState(true)
    const [showNfcDesignContainer, setShowNfcDesignContainer] = useState(false)
    const [showShippingContainer, setShowShippingContainer] = useState(false)
    const [qrCodeUrl, setQrCodeUrl] = useState('')
    const [selectedCard, setSelectedCard] = useState('')
    const [cardQuantity, setCardQuantity] = useState('')
    const [personName, setPersonName] = useState('')
    const [personContactNumber, setPersonContactNumber] = useState('')
    const [personContactEmail, setPersonContactEmail] = useState('')
    const [address, setAddress] = useState('')
    const [landmark, setLandmark] = useState('')
    const [addressCountry, setAddressCountry] = useState('')
    const [addressState, setAddressState] = useState('')
    const [addressCity, setAddressCity] = useState('')
    const [addressPincode, setAddressPincode] = useState('')
    const { placeNfcCardOrderSuccess, placeNfcCardOrderError, placeNfcCardOrderReqStatus } = useSelector((state) => state.nfcCardOrder)
    const [displayOrderSuccessModal, setDisplayOrderSuccessModal] = useState(false)
    const [countryDropdown, setCountryDropdown] = useState();
    const [stateDropdown, setStateDropdown] = useState();
    const [cityDropdown, setCityDropdown] = useState();
    const [countryCode, setCountryCode] = useState('')

    useEffect(() => {
        dispatch({ type: APP_CONSTANTS.RESET_STATE_CITY })
        setLoading(true)
        dispatch(countryListRequest())
        const data = {
            businessId: user.data.userDetails?.businessData?.businessId
        }
        dispatch(employeeListRequest(data))
    }, [])

    useEffect(() => {
        if (!isEmpty(employeeListSuccess)) {
            setLoading(false)
        } else if (!isEmpty(employeeListError)) {
            setLoading(false)
        }
    }, [employeeListSuccess, employeeListError])

    useEffect(() => {
        if (placeNfcCardOrderReqStatus === API_CONSTANTS.SUCCESS_STATUS) {
            setLoading(false)
            displaySuccessToast(placeNfcCardOrderSuccess.message)
            clearFields()
            setDisplayOrderSuccessModal(true)
        } else if (placeNfcCardOrderReqStatus === API_CONSTANTS.ERROR_STATUS) {
            setLoading(false)
            displayErrorToast(placeNfcCardOrderError.message)
        }
        if (placeNfcCardOrderReqStatus?.length > 0) {
            dispatch(placeNfcCardOrderRequestStatus(''))
        }
    }, [placeNfcCardOrderSuccess, placeNfcCardOrderError, placeNfcCardOrderReqStatus])

    const fetchStateList = (countryId) => {
        setLoading(true)
        const data = {
            countryId: countryId
        }
        dispatch(stateListRequest(data))
    }

    const fetchCityList = (countryId, stateId) => {
        setLoading(true)
        const data = {
            countryId: countryId,
            stateId: stateId
        }
        dispatch(cityListRequest(data))
    }

    useEffect(() => {
        if (!isNull(countryListSuccess)) {
            setLoading(false)
        } else if (!isNull(countryListError)) {
            setLoading(false)
        }
    }, [countryListSuccess, countryListError])

    useEffect(() => {
        if (!isNull(stateListSuccess)) {
            setLoading(false)
        } else if (!isNull(stateListError)) {
            setLoading(false)
        }
    }, [stateListSuccess, stateListError])

    useEffect(() => {
        if (!isNull(cityListSuccess)) {
            setLoading(false)
        } else if (!isNull(cityListError)) {
            setLoading(false)
        }
    }, [cityListSuccess, cityListError])

    const handleCountrySelectChange = e => {
        setCountryDropdown(e);
        setCountryCode(e.iso2)
        setStateDropdown(null)
        setCityDropdown(null)
        fetchStateList(e.iso2)
    }

    const isEmployeeSelected = employeeId => {
        if (!selectedEmployeeList.includes(employeeId)) {
            return false
        } else return true
    }

    const addOrRemoveFromSelectedEmployee = (employeeId) => {
        setSelectedEmployeeList((currentList) => {
            const employeeIndex = currentList.indexOf(employeeId);
            if (employeeIndex === -1) {
                return [...currentList, employeeId]; // Add the item if it doesn't exist
            } else {
                const updatedList = [...currentList];
                updatedList.splice(employeeIndex, 1); // Remove the item if it exists
                return updatedList;
            }
        });
    }

    const handleEmployeeNextClick = () => {
        if (isEmpty(selectedEmployeeList)) {
            displayErrorToast('No employee selected')
        } else {
            setShowEmployeeContainer(false)
            setShowNfcDesignContainer(true)
        }
    }


    const handleDesignNextClick = () => {
        if (isEmpty(selectedCard)) {
            displayErrorToast('NFC card design not selected')
        } else {
            setShowNfcDesignContainer(false)
            setShowShippingContainer(true)
        }
    }

    const handleSelectCardClick = (cardId) => {
        setSelectedCard(cardId)
    }

    const handleCardQuantityInput = qty => {
        if (!isEmpty(qty) && qty.length > 3) {
            displayErrorToast('Invalid card quantity to order')
            setCardQuantity('')
        } else {
            setCardQuantity(qty)
        }
    }

    const handlePlaceOrderClick = () => {
        if (isEmpty(selectedEmployeeList)) {
            displayErrorToast('Employee not selected')
        } else if (isEmpty(selectedCard)) {
            displayErrorToast('Card design not selected. Please select a design to order NFC card')
        } else if (isEmpty(cardQuantity)) {
            displayErrorToast('Number of NFC card to order cannot be blank')
        } else if (parseFloat(cardQuantity) <= 0) {
            displayErrorToast('NFC card quantity cannot be less than 0.')
        } else if (isEmpty(personName)) {
            displayErrorToast('Contact person name cannot be blank')
        } else if (isEmpty(personContactNumber)) {
            displayErrorToast('Contact person number cannot be blank')
        } else if (isEmpty(personContactEmail)) {
            displayErrorToast('Contact person email cannot be blank')
        } else if (isEmpty(address)) {
            displayErrorToast('Order shipping address cannot be blank')
        } else if (isNull(countryDropdown)) {
            displayErrorToast('Order shipping country not selected')
        } else if (isNull(stateDropdown)) {
            displayErrorToast('Order shipping state not selected')
        } else if (isNull(cityDropdown)) {
            displayErrorToast('Order shipping city not selected')
        } else if (isEmpty(addressPincode)) {
            displayErrorToast('Address pincode cannot be blank')
        } else {
            setLoading(true)
            const data = {
                businessId: user.data.userDetails?.businessData?.businessId,
                employeeList: JSON.stringify(selectedEmployeeList),
                designTemplateId: selectedCard,
                orderQuantity: cardQuantity,
                customerName: personName,
                customerNumber: personContactNumber,
                customerEmail: personContactEmail,
                orderAddress: address,
                orderAddressLandmark: !isEmpty(landmark) ? landmark : '',
                orderAddressCity: cityDropdown.name,
                orderAddressState: stateDropdown.name,
                orderAddressCountry: countryDropdown.name,
                orderAddressPincode: addressPincode,
                orderType: APP_CONSTANTS.NFC_ORDER_TYPE.CORPORATE
            }
            dispatch(placeNfcCardOrderRequest(data))
        }
    }

    const clearFields = () => {
        setSelectedEmployeeList([])
        setShowEmployeeContainer(true)
        setShowNfcDesignContainer(false)
        setShowShippingContainer(false)
        setQrCodeUrl('')
        setSelectedCard('')
        setCardQuantity('')
        setPersonName('')
        setPersonContactNumber('')
        setPersonContactEmail('')
        setAddress('')
        setLandmark('')
        setAddressCountry('')
        setAddressState('')
        setAddressCity('')
        setAddressPincode('')
    }

    return (
        <>
            <CommonScreenContent>
                <ScreenLabel label='Order NFC Card' />
                {showEmployeeContainer === true && <CommonColorForm>
                    <SubTextLabel label='Select Employees' />
                    {!isNull(employeeListSuccess) && employeeListSuccess.data.map(e => (
                        <div className="ci-receipt-list-item-container">
                            <div>
                                <div className="ci-receipt-number-label">{e.firstName} {e.lastName}</div>
                                <div className="ci-invoice-warning">{e.designation}</div>
                            </div>
                            <Checkbox
                                checked={isEmployeeSelected(e.employeeId)}
                                onChange={e1 => addOrRemoveFromSelectedEmployee(e.employeeId)}
                                inputProps={{ 'aria-label': 'controlled' }}
                                color="success"
                                size="medium" />
                        </div>
                    ))}
                    <div className="one-button-container">
                        <button className="btn one-button" onClick={() => { handleEmployeeNextClick() }}
                            type="button">Next</button>
                    </div>
                </CommonColorForm>}
                {showNfcDesignContainer === true && <CommonColorForm>
                    <SubTextLabel label='Select NFC Design' />
                    <div className="nts-card-row-container">
                        <NfcCardDesign1
                            name='Employee Name'
                            designation='Designation'
                            phoneNumber='Phone Number'
                            email='Contact Email Id'
                            address='Contact Business Address'
                            qrCodeUrl={qrCodeUrl}
                            isSelected={selectedCard === 'CARD_1'}
                            selectDesignHandler={() => { handleSelectCardClick('CARD_1') }}
                            showButton={true}
                        />
                        <NfcCardDesign2
                            name='Employee Name'
                            designation='Designation'
                            phoneNumber='Phone Number'
                            email='Contact Email Id'
                            address='Contact Business Address'
                            qrCodeUrl={qrCodeUrl}
                            isSelected={selectedCard === 'CARD_2'}
                            selectDesignHandler={() => { handleSelectCardClick('CARD_2') }}
                            showButton={true}
                        />
                        <NfcCardDesign3
                            name='Employee Name'
                            designation='Designation'
                            phoneNumber='Phone Number'
                            email='Contact Email Id'
                            address='Contact Business Address'
                            qrCodeUrl={qrCodeUrl}
                            isSelected={selectedCard === 'CARD_3'}
                            selectDesignHandler={() => { handleSelectCardClick('CARD_3') }}
                            showButton={true}
                        />
                    </div>
                    <div className="one-button-container">
                        <button className="btn one-button" onClick={() => { handleDesignNextClick() }}
                            type="button">Next</button>
                    </div>
                </CommonColorForm>}
                {showShippingContainer === true && <CommonColorForm>
                    <SubTextLabel label='Shipping Details' />
                    <CommonInputFloatFull
                        type='number'
                        id='quantity'
                        onChange={e => { handleCardQuantityInput(e.target.value) }}
                        placeholder='Card Quantity'
                        value={cardQuantity}
                        subText='Number of card for each employee'
                        isSubtext={true} />
                    <div className="nts-pricing-label">**Note: We provide special pricing for each customer. We will share it in the email provided after placing the order.</div>
                    <FormFieldLabel label='Enter shipping address' />
                    <CommonInputFloatFull
                        type='text'
                        id='name'
                        onChange={e => setPersonName(e.target.value)}
                        placeholder='Contact Person Name'
                        value={personName} />
                    <CommonInputRow>
                        <CommonInputFloatHalf
                            type='number'
                            id='mobile'
                            onChange={e => setPersonContactNumber(e.target.value)}
                            placeholder='Contact Number'
                            value={personContactNumber} />
                        <CommonInputFloatHalf
                            type='text'
                            id='email'
                            onChange={e => setPersonContactEmail(e.target.value)}
                            placeholder='Contact Email'
                            value={personContactEmail} />
                    </CommonInputRow>
                    <CommonInputFloatFull
                        type='text'
                        id='address'
                        onChange={e => setAddress(e.target.value)}
                        placeholder='Delivery Address'
                        value={address} />
                    <CommonInputFloatFull
                        type='text'
                        id='landmark'
                        onChange={e => setLandmark(e.target.value)}
                        placeholder='Landmark'
                        value={landmark} />
                    {countryListSuccess && <Select
                        menuPlacement="auto"
                        className='select-full-width-no-border'
                        value={countryDropdown}
                        getOptionLabel={e => e.name}
                        getOptionValue={e => e}
                        options={countryListSuccess}
                        placeholder='Select Country'
                        onChange={e => {
                            handleCountrySelectChange(e)
                        }} />}
                    <CommonInputRow>
                        {stateListSuccess && <Select
                            menuPlacement="auto"
                            className='select-half-width up-select-margin'
                            value={stateDropdown}
                            getOptionLabel={e => e.name}
                            getOptionValue={e => e}
                            options={stateListSuccess}
                            placeholder='Select State'
                            onChange={e => {
                                setStateDropdown(e);
                                setCityDropdown(null)
                                fetchCityList(countryCode, e.iso2)
                            }} />}
                        {cityListSuccess && <Select
                            menuPlacement="auto"
                            className='select-half-width up-select-margin'
                            value={cityDropdown}
                            getOptionLabel={e => e.name}
                            getOptionValue={e => e}
                            options={cityListSuccess}
                            placeholder='Select City'
                            onChange={e => {
                                setCityDropdown(e);
                            }} />}
                    </CommonInputRow>
                    {/* <CommonInputRow>
                        <CommonInputFloatHalf
                            type='text'
                            id='landmark'
                            onChange={e => setLandmark(e.target.value)}
                            placeholder='Landmark'
                            value={landmark} />
                        <CommonInputFloatHalf
                            type='text'
                            id='city'
                            onChange={e => setAddressCity(e.target.value)}
                            placeholder='City'
                            value={addressCity} />
                    </CommonInputRow> */}
                    {/* <CommonInputRow>
                        <CommonInputFloatHalf
                            type='text'
                            id='state'
                            onChange={e => setAddressState(e.target.value)}
                            placeholder='State'
                            value={addressState} />
                        <CommonInputFloatHalf
                            type='text'
                            id='country'
                            onChange={e => setAddressCountry(e.target.value)}
                            placeholder='Country'
                            value={addressCountry} />
                    </CommonInputRow> */}
                    <CommonInputFloatFull
                        classname="mt-2"
                        type='text'
                        id='pincode'
                        onChange={e => setAddressPincode(e.target.value)}
                        placeholder='Pincode/Zipcode'
                        value={addressPincode} />
                    <div className="one-button-container">
                        <button className="btn one-place-order-button" onClick={() => { handlePlaceOrderClick() }}
                            type="button">Place Order</button>
                    </div>
                </CommonColorForm>}
            </CommonScreenContent>
            <Modal
                onHide={() => { setDisplayOrderSuccessModal(false) }}
                show={displayOrderSuccessModal}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                </Modal.Header>
                <Modal.Body>
                    <div>Your NFC card order was placed successfully. We provide special pricing for each customer's order. Please keep an eye on your email, we will shortly update you with your order pricing and further updates. Have a great networking lifestyle.</div>
                </Modal.Body>
                <Modal.Footer>
                </Modal.Footer>
            </Modal>
            <ToastContainer />
            <Loader active={isLoading} text='Please wait...' />
        </>
    )
}

export default OrderNfcEmployee