import { takeLatest, call, put } from 'redux-saga/effects'
import {
    addProductAPI, deleteProductAPI, productListAPI, updateProductAPI
} from '../api-requests/product';
import {
    addProductRequestError, addProductRequestSuccess, deleteProductRequestError,
    deleteProductRequestSuccess, productListRequestError, productListRequestSuccess,
    updateProductRequestError, updateProductRequestSuccess
} from './product.action';
import { ProductActionTypes } from './product.types'
import { displayConsoleLog } from '../../utils/Utils'
import { decryptResponseData } from '../../utils/DataDecryptionUtils';
import { APP_CONSTANTS } from '../../utils/AppConstants';

function* handleUpdateProduct({ payload }) {
    try {
        const response = yield call(updateProductAPI, payload);
        if (response.status >= 200 && response.status < 300) {
            const { data } = response
            const decryptedData = decryptResponseData(data)
            if (decryptedData.data !== null) {
                yield put(updateProductRequestSuccess(decryptedData))
            } else {
                yield put(updateProductRequestError(decryptedData))
            }
        } else {
            throw response
        }
    } catch (error) {
        displayConsoleLog(error)
        if (error.response?.status === 401) {
            const errData = {
                status: error.response?.status,
                isSessionError: true,
                message: APP_CONSTANTS.SESSION_EXPIRE_MESSAGE
            }
            yield put({ type: ProductActionTypes.COMMON_ERROR, errData })
        } else {
            displayConsoleLog(error.response?.data)
            yield put(updateProductRequestError(error.response.data))
        }
    }
}

export function* updateProductReq() {
    yield takeLatest(ProductActionTypes.UPDATE_PRODUCT_REQUEST, handleUpdateProduct)
}

function* handleProductList({ payload }) {
    try {
        const response = yield call(productListAPI, payload);
        if (response.status >= 200 && response.status < 300) {
            const { data } = response
            const decryptedData = decryptResponseData(data)
            if (decryptedData.data !== null) {
                yield put(productListRequestSuccess(decryptedData))
            } else {
                yield put(productListRequestError(decryptedData))
            }
        } else {
            throw response
        }
    } catch (error) {
        displayConsoleLog(error)
        if (error.response?.status === 401) {
            const errData = {
                status: error.response?.status,
                isSessionError: true,
                message: APP_CONSTANTS.SESSION_EXPIRE_MESSAGE
            }
            yield put({ type: ProductActionTypes.COMMON_ERROR, errData })
        } else {
            displayConsoleLog(error.response?.data)
            yield put(productListRequestError(error.response.data))
        }
    }
}

export function* productListReq() {
    yield takeLatest(ProductActionTypes.PRODUCT_LIST_REQUEST, handleProductList)
}

function* handleDeleteProduct({ payload }) {
    try {
        const response = yield call(deleteProductAPI, payload);
        if (response.status >= 200 && response.status < 300) {
            const { data } = response
            const decryptedData = decryptResponseData(data)
            if (decryptedData.data !== null) {
                yield put(deleteProductRequestSuccess(decryptedData))
            } else {
                yield put(deleteProductRequestError(decryptedData))
            }
        } else {
            throw response
        }
    } catch (error) {
        displayConsoleLog(error)
        if (error.response?.status === 401) {
            const errData = {
                status: error.response?.status,
                isSessionError: true,
                message: APP_CONSTANTS.SESSION_EXPIRE_MESSAGE
            }
            yield put({ type: ProductActionTypes.COMMON_ERROR, errData })
        } else {
            displayConsoleLog(error.response?.data)
            yield put(deleteProductRequestError(error.response.data))
        }
    }
}

export function* deleteProductReq() {
    yield takeLatest(ProductActionTypes.DELETE_PRODUCT_REQUEST, handleDeleteProduct)
}

function* handleAddProduct({ payload }) {
    try {
        const response = yield call(addProductAPI, payload);
        if (response.status >= 200 && response.status < 300) {
            const { data } = response
            const decryptedData = decryptResponseData(data)
            if (decryptedData.data !== null) {
                yield put(addProductRequestSuccess(decryptedData))
            } else {
                yield put(addProductRequestError(decryptedData))
            }
        } else {
            throw response
        }
    } catch (error) {
        displayConsoleLog(error)
        if (error.response?.status === 401) {
            const errData = {
                status: error.response?.status,
                isSessionError: true,
                message: APP_CONSTANTS.SESSION_EXPIRE_MESSAGE
            }
            yield put({ type: ProductActionTypes.COMMON_ERROR, errData })
        } else {
            displayConsoleLog(error.response?.data)
            yield put(addProductRequestError(error.response.data))
        }
    }
}

export function* addProductReq() {
    yield takeLatest(ProductActionTypes.ADD_PRODUCT_REQUEST, handleAddProduct)
}