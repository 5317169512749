import { all, call } from 'redux-saga/effects'
import {
    changePasswordReq, cityListReq, contactUsReq, countryListReq, forgotPasswordReq,
    ipLocationReq, loginUserReq, registerUserReq, stateListReq, subscriptionListReq,
    userUpdateUserNotificationTokenReq, registerByMobileReq, customerCardReq, userRegistrationBusinessReq,
    businessDetailReq, businessVisitorConfigUpdateReq, businessUpdateNotificationReq,
    operatorListReq, operatorRegisterReq, updateOperatorLocationReq, operatorDetailReq
} from './user/user.saga'

import { addTestimonialReq, deleteTestimonialReq, testimonialListReq, updateTestimonialReq } from './testimonial/testimonial.saga'
import { addServiceReq, deleteServiceReq, serviceListReq, updateServiceReq } from './service/service.saga'
import { addProductReq, deleteProductReq, productListReq, updateProductReq } from './product/product.saga'
import { addBlogReq, blogListReq, deleteBlogReq, updateBlogReq } from './blog/blog.saga'
import { addEnquiryReq, enquiryListReq, updateEnquiryReq } from './enquiry/enquiry.saga'
import { addAppointmentReq, appointmentListReq, updateAppointmentReq } from './appointment/appointment.saga'
import {
    addCustomQrCodeReq, customQrCodeListReq, deleteCustomQrCodeReq,
    updateCustomQrCodeReq, customQrCodeDetailReq
} from './custom-qr-code/customQrCode.saga'
import {
    addCardDetailsReq, addDigitalCardReq, cardByAliasReq, checkAliasAvailabilityReq,
    digitalCardListReq, updateCardDetailsReq, updateDigitalCardReq, addCardWithDetailsReq,
    deleteDigitalCardReq
} from './digital-card/digitalCard.saga'
import {
    addFeedbackReq
} from './feedback/feedback.saga'
import { visitorAddRecordReq, visitorRecordListReq, visitorMarkExitReq } from './visitor-book/visitorBook.saga'

import { addHotelPointRecordReq, hotelPointRecordListReq } from './hotel-point/hotelPoint.saga'

import { newsLetterAddRecordReq, newsLetterListReq } from './newsletter/newsLetter.saga'
import { siteVisitorAnalyticsAddReq, siteVisitorAnalyticsListReq, cardVisitAnalyticsListReq, siteAndUserAnalyticsListReq } from './site-visitor-analytics/siteVisitorAnalytics.saga'

import { nfcCardOrderDetailReq, nfcCardOrderListReq, placeNfcCardOrderReq, updateNfcCardOrderDetailsReq } from './nfc-card-order/nfcCardOrder.saga'
import { addEmployeeReq, employeeDetailReq, employeeListReq } from './employee/employee.saga'

export default function* rootSaga() {
    yield all([
        call(contactUsReq),
        call(loginUserReq),
        call(registerUserReq),
        call(subscriptionListReq),
        call(changePasswordReq),
        call(forgotPasswordReq),
        call(cityListReq),
        call(countryListReq),
        call(stateListReq),
        call(ipLocationReq),
        call(registerByMobileReq),
        call(userUpdateUserNotificationTokenReq),
        call(addTestimonialReq),
        call(deleteTestimonialReq),
        call(testimonialListReq),
        call(updateTestimonialReq),
        call(addServiceReq),
        call(deleteServiceReq),
        call(serviceListReq),
        call(updateServiceReq),
        call(addProductReq),
        call(deleteProductReq),
        call(productListReq),
        call(updateProductReq),
        call(addBlogReq),
        call(blogListReq),
        call(deleteBlogReq),
        call(updateBlogReq),
        call(addEnquiryReq),
        call(enquiryListReq),
        call(updateEnquiryReq),
        call(addAppointmentReq),
        call(appointmentListReq),
        call(updateAppointmentReq),
        call(addCardDetailsReq),
        call(addDigitalCardReq),
        call(cardByAliasReq),
        call(checkAliasAvailabilityReq),
        call(digitalCardListReq),
        call(updateCardDetailsReq),
        call(updateDigitalCardReq),
        call(addCardWithDetailsReq),
        call(deleteDigitalCardReq),
        call(customerCardReq),
        call(addCustomQrCodeReq),
        call(customQrCodeListReq),
        call(deleteCustomQrCodeReq),
        call(addFeedbackReq),
        call(userRegistrationBusinessReq),
        call(visitorAddRecordReq),
        call(visitorRecordListReq),
        call(businessDetailReq),
        call(visitorMarkExitReq),
        call(businessVisitorConfigUpdateReq),
        call(businessUpdateNotificationReq),
        call(addHotelPointRecordReq),
        call(hotelPointRecordListReq),
        call(updateCustomQrCodeReq),
        call(customQrCodeDetailReq),
        call(newsLetterAddRecordReq),
        call(newsLetterListReq),
        call(siteVisitorAnalyticsAddReq),
        call(siteVisitorAnalyticsListReq),
        call(cardVisitAnalyticsListReq),
        call(siteAndUserAnalyticsListReq),
        call(nfcCardOrderDetailReq),
        call(nfcCardOrderListReq),
        call(placeNfcCardOrderReq),
        call(updateNfcCardOrderDetailsReq),
        call(addEmployeeReq),
        call(employeeDetailReq),
        call(employeeListReq),
        call(operatorListReq),
        call(operatorRegisterReq),
        call(updateOperatorLocationReq),
        call(operatorDetailReq),
    ])
}