import React from "react";
import './styles.css'
import { QRCode } from 'react-qrcode-logo';
import ButtonRow from "../../../components/button/button-row/buttonRow";
import CommonButton from "../../../components/button/common-button/commonButton";
import { config } from "../../../config";
import { displayConsoleLog, isEmpty } from "../../../utils/Utils";
import FormFieldLabel from "../../../components/label/form-field-label/formFieldLabel";
import { ROUTES } from "../../../utils/AppConstants";

const UserCardPreview = ({ cardObject, isApiData = false, qrCodeUrl, downloadClickListener, isBannerImageFromUrl = false, isProfileImageFromUrl = false, showProfileImage = false, showBannerImage = false, showDownloadButton = false, qrSize = 140, showQrBorder = false, showBrandLogo = false, isBrandLogoFromUrl = false }) => {
    displayConsoleLog('qrCodeUrl', qrCodeUrl)
    const isLogoDisplay = () => {
        if (showBrandLogo) {
            if ((isApiData && cardObject.brandLogoImg && !cardObject.brandLogoImg.includes('placeholder')) ||
                (isBrandLogoFromUrl && cardObject.brandLogoImg && !cardObject.brandLogoImg.includes('placeholder')) ||
                (cardObject.brandLogoImg)) {
                return true;
            } else {
                return false;
            }
        } else {
            return false;
        }
    }

    return (
        <div className="card-preview-container">
            {isLogoDisplay() && <div className="card-logo-parent-container">
                <div className="card-logo-img-container">
                    {isApiData ?
                        (<img src={`${config.imageUrl}${cardObject.brandLogoImg}`} className='img' />) :
                        (<img alt="BrandImage" src={isBrandLogoFromUrl === true ? `${config.imageUrl}${cardObject.brandLogoImg}` : cardObject.brandLogoImg ? cardObject.brandLogoImg : require('../../../assets/brand_placeholder.png')} className='img' />)
                    }
                </div>
            </div>}
            {showBannerImage && <div className="banner-img-container">
                {isApiData ?
                    (<img src={`${config.imageUrl}${cardObject.bannerImage}`} className='img' />) :
                    (<img alt="BannerImage" src={isBannerImageFromUrl === true ? `${config.imageUrl}${cardObject.bannerImage}` : cardObject.bannerImage ? cardObject.bannerImage : require('../../../assets/banner-placeholder.png')} className='img' />)
                }
            </div>}
            {showProfileImage && <div className={`${isLogoDisplay() === true ? 'profile-img--parent-container1' : 'profile-img--parent-container'} `}>
                <div className="profile-img-container">
                    {isApiData ?
                        (<img src={`${config.imageUrl}${cardObject.profileImage}`} className='img' />) :
                        (<img alt="ProfileImage" src={isProfileImageFromUrl === true ? `${config.imageUrl}${cardObject.profileImage}` : cardObject.profileImage ? cardObject.profileImage : require('../../../assets/profile-placeholder.png')} className='img' />)
                    }
                </div>
            </div>}
            <h1 className="uc-name-label">{cardObject.firstName ? cardObject.firstName : ''} {cardObject.lastName ? cardObject.lastName : ''}</h1>
            {!isEmpty(cardObject.companyName) && <h5 className="uc-email-container uc-email-container-mt">{cardObject.companyName}</h5>}
            {!isEmpty(cardObject.jobTitle) && <div className="uc-email-container">{cardObject.jobTitle}</div>}
            {!isEmpty(cardObject.summary) && <p className="summary-container">{cardObject.summary}</p>}
            {(!isEmpty(cardObject.email1) || !isEmpty(cardObject.email2) || !isEmpty(cardObject.email3) || !isEmpty(cardObject.homeNumber)
                || !isEmpty(cardObject.mobileNumber) || !isEmpty(cardObject.workNumber1) || !isEmpty(cardObject.workNumber2)
                || !isEmpty(cardObject.fax) || !isEmpty(cardObject.homeAddress) || !isEmpty(cardObject.workAddress)) &&
                <div className="cmc-row-container mt-4">
                    <FormFieldLabel label='Contact Information' />
                    {!isEmpty(cardObject.email1) && <div>
                        <div className="uc-address-container">
                            <div className="uc-ic-img-container">
                                <img src={require('../../../assets/ic_email2.png')} className='img' />
                            </div>
                            <div className="card-icon-text">{cardObject.email1}</div>
                        </div>
                    </div>}
                    {!isEmpty(cardObject.email2) && <div>
                        <div className="uc-address-container">
                            <div className="uc-ic-img-container">
                                <img src={require('../../../assets/ic_email2.png')} className='img' />
                            </div>
                            <div className="card-icon-text">{cardObject.email2}</div>
                        </div>
                    </div>}
                    {!isEmpty(cardObject.email3) && <div>
                        <div className="uc-address-container">
                            <div className="uc-ic-img-container">
                                <img src={require('../../../assets/ic_email2.png')} className='img' />
                            </div>
                            <div className="card-icon-text">{cardObject.email3}</div>
                        </div>
                    </div>}
                    {!isEmpty(cardObject.homeNumber) && <div>
                        <div className="uc-address-container">
                            <div className="uc-ic-img-container">
                                <img src={require('../../../assets/ic_phone3.png')} className='img' />
                            </div>
                            <div className="card-icon-text"><span className="type-label">Home</span> : {cardObject.homeNumber}</div>
                        </div>
                    </div>}
                    {!isEmpty(cardObject.mobileNumber) && <div>
                        <div className="uc-address-container">
                            <div className="uc-ic-img-container">
                                <img src={require('../../../assets/ic_phone3.png')} className='img' />
                            </div>
                            <div className="card-icon-text"><span className="type-label">Mobile</span> : {cardObject.mobileNumber}</div>
                        </div>
                    </div>}
                    {!isEmpty(cardObject.workNumber1) && <div>
                        <div className="uc-address-container">
                            <div className="uc-ic-img-container">
                                <img src={require('../../../assets/ic_phone3.png')} className='img' />
                            </div>
                            <div className="card-icon-text"><span className="type-label">Work 1</span> : {cardObject.workNumber1}{!isEmpty(cardObject.workNumber1Ext) ? ` - ${cardObject.workNumber1Ext} (Ext.)` : ''}</div>
                        </div>
                    </div>}
                    {!isEmpty(cardObject.workNumber2) && <div>
                        <div className="uc-address-container">
                            <div className="uc-ic-img-container">
                                <img src={require('../../../assets/ic_phone3.png')} className='img' />
                            </div>
                            <div className="card-icon-text"><span className="type-label">Work 2</span> : {cardObject.workNumber2}{!isEmpty(cardObject.workNumber2Ext) ? ` - ${cardObject.workNumber2Ext} (Ext.)` : ''}</div>
                        </div>
                    </div>}
                    {!isEmpty(cardObject.fax) && <div>
                        <div className="uc-address-container">
                            <div className="uc-ic-img-container">
                                <img src={require('../../../assets/ic_phone3.png')} className='img' />
                            </div>
                            <div className="card-icon-text"><span className="type-label">Fax</span> : {cardObject.fax}</div>
                        </div>
                    </div>}
                    {!isEmpty(cardObject.homeAddress) && <div>
                        <div className="uc-address-container">
                            <div className="uc-ic-img-container">
                                <img src={require('../../../assets/ic_location2.png')} className='img' />
                            </div>
                            <div className="card-icon-text"><span className="type-label">Home</span>: {cardObject.homeAddress}</div>
                        </div>
                    </div>}
                    {!isEmpty(cardObject.workAddress) && <div>
                        <div className="uc-address-container">
                            <div className="uc-ic-img-container">
                                <img src={require('../../../assets/ic_location2.png')} className='img' />
                            </div>
                            <div className="card-icon-text"><span className="type-label">Work</span>: {cardObject.workAddress}</div>
                        </div>
                    </div>}
                </div>
            }
            {(!isEmpty(cardObject.facebookLink) || !isEmpty(cardObject.linkedInLink) || !isEmpty(cardObject.twitterLink) || !isEmpty(cardObject.instagramLink) || !isEmpty(cardObject.snapchatLink) || !isEmpty(cardObject.otherSocialLink)) &&
                <div className="cmc-row-container mt-4">
                    <FormFieldLabel label='Social Information' />
                    {!isEmpty(cardObject.facebookLink) && <div className="uc-address-container">
                        <div className="uc-ic-img-container">
                            <img src={require('../../../assets/ic_facebook1.png')} className='img' />
                        </div>
                        <div className="card-icon-text">{cardObject.facebookLink}</div>
                    </div>}
                    {!isEmpty(cardObject.twitterLink) && <div className="uc-address-container">
                        <div className="uc-ic-img-container">
                            <img src={require('../../../assets/ic_twitter.png')} className='img' />
                        </div>
                        <div className="card-icon-text">{cardObject.twitterLink}</div>
                    </div>}
                    {!isEmpty(cardObject.instagramLink) && <div className="uc-address-container">
                        <div className="uc-ic-img-container">
                            <img src={require('../../../assets/ic_instagram1.png')} className='img' />
                        </div>
                        <div className="card-icon-text">{cardObject.instagramLink}</div>
                    </div>}
                    {!isEmpty(cardObject.linkedInLink) && <div className="uc-address-container">
                        <div className="uc-ic-img-container">
                            <img src={require('../../../assets/ic_linkedin1.png')} className='img' />
                        </div>
                        <div className="card-icon-text">{cardObject.linkedInLink}</div>
                    </div>}
                    {!isEmpty(cardObject.snapchatLink) && <div className="uc-address-container">
                        <div className="uc-ic-img-container">
                            <img src={require('../../../assets/ic_snapchat.png')} className='img' />
                        </div>
                        <div className="card-icon-text">{cardObject.snapchatLink}</div>
                    </div>}
                    {!isEmpty(cardObject.otherSocialLink) && <div>
                        {cardObject.otherSocialLink.map(e => (
                            <div className="uc-address-container">
                                <div className="uc-ic-img-container">
                                    <img src={require('../../../assets/ic_link2.png')} className='img' />
                                </div>
                                <div className="card-icon-text">{e}</div>
                            </div>
                        ))}
                    </div>}
                </div>
            }
            {isApiData ? (<div className="qr-container">
                <div className={`${showQrBorder ? 'cqc-qr-container' : ''}`}>
                    <QRCode value={qrCodeUrl} size={qrSize}
                        removeQrCodeBehindLogo={true}
                    />
                    {showQrBorder && <div className="cqc-powered-label">Powered By ClickTran.com</div>}
                </div>
                {showDownloadButton && <div className="link-container">
                    <div onClick={() => { window.open(`${config.webUrl}${ROUTES.homeCards}`, '_blank') }}>
                        <p className="link-text-container">Scan To Get Your Free<br></br> Digital Card at ClickTran.com</p>
                    </div>
                    <div className="link-img-container">
                        <img src={require('../../../assets/ic_link_1.png')} className='img' />
                    </div>
                </div>}
            </div>) : null}
            {isApiData ? (<ButtonRow>
                {showDownloadButton && <CommonButton label='Download Contact' onClick={downloadClickListener} />}
            </ButtonRow>) : null}
        </div>
    )
}

export default UserCardPreview