import { SocketActionTypes } from './socket.types'

const INITIAL_STATE = {
    newVisitorAddNotif: null,
    visitorConfigUpdate: null
}

const socketReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case SocketActionTypes.NEW_VISITOR_ADD:
            return {
                ...state,
                newVisitorAddNotif: action.payload
            }
        case SocketActionTypes.CLEAR_NEW_VISITOR_ADD:
            return {
                ...state,
                newVisitorAddNotif: null
            }
        case SocketActionTypes.VISITOR_CONFIG_UPDATE:
            return {
                ...state,
                visitorConfigUpdate: action.payload
            }
        case SocketActionTypes.CLEAR_VISITOR_CONFIG_UPDATE:
            return {
                ...state,
                visitorConfigUpdate: null
            }
        default:
            return state
    }
}

export default socketReducer