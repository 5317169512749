import { FeedbackActionTypes } from './feedback.types'

//Add Feedback
export const addFeedbackRequest = data => ({
    type: FeedbackActionTypes.ADD_FEEDBACK_REQUEST,
    payload: data
})

export const addFeedbackRequestSuccess = data => ({
    type: FeedbackActionTypes.ADD_FEEDBACK_REQUEST_SUCCESS,
    payload: data
})

export const addFeedbackRequestError = data => ({
    type: FeedbackActionTypes.ADD_FEEDBACK_REQUEST_ERROR,
    payload: data
})

export const addFeedbackRequestStatus = data => ({
    type: FeedbackActionTypes.ADD_FEEDBACK_REQUEST_STATUS,
    payload: data
})