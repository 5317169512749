import React, { useEffect } from "react";

const DigitalGoodsPage = props => {
    useEffect(() => {
        window.location.replace(`http://dgoods.clicktran.com`);
    }, [])
    return (
        <></>
    )
}

export default DigitalGoodsPage