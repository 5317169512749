import { UserActionTypes } from './user.types'
import { API_CONSTANTS } from '../../utils/AppConstants'

const INITIAL_STATE = {
    user: null,
    isLogin: false,
    loginErrorData: null,
    registerSuccess: null,
    registerError: null,
    registerReqStatus: '',
    subscriptionListSuccess: null,
    subscriptionListError: null,
    updateNotificationTokenSuccess: null,
    updateNotificationTokenError: null,
    forgotPasswordSuccess: null,
    forgotPasswordError: null,
    forgotPasswordReqStatus: '',
    changePasswordSuccess: null,
    changePasswordError: null,
    changePasswordReqStatus: '',
    countryListSuccess: null,
    countryListError: null,
    stateListSuccess: null,
    stateListError: null,
    cityListSuccess: null,
    cityListError: null,
    displayTour: false,
    ipLocationSuccess: null,
    ipLocationError: null,
    displaySideBar: false,
    contactUsSuccess: null,
    contactUsError: null,
    contactUsStatus: '',
    customerCardRequestSuccess: null,
    customerCardRequestError: null,
    customerCardRequestReqStatus: '',
    userRegistrationBusinessSuccess: null,
    userRegistrationBusinessError: null,
    userRegistrationBusinessReqStatus: '',
    businessDetailsSuccess: null,
    businessDetailsError: null,
    commonError: null,
    visitorConfigUpdateSuccess: null,
    visitorConfigUpdateError: null,
    visitorConfigUpdateReqStatus: '',
    businessUpdateTokenSuccess: null,
    businessUpdateTokenError: null,
    businessUpdateTokenReqStatus: '',
    operatorRegistrationSuccess: null,
    operatorRegistrationError: null,
    operatorRegistrationReqStatus: '',
    operatorListSuccess: null,
    operatorListError: null,
    updateOperatorLocationSuccess: null,
    updateOperatorLocationError: null,
    updateOperatorLocationReqStatus: '',
    operatorDetailSuccess: null,
    operatorDetailError: null,
}

const userReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case UserActionTypes.LOGIN_USER_SUCCESS:
            return {
                ...state,
                user: action.payload,
                isLogin: true,
                loginErrorData: null
            }
        case UserActionTypes.LOGIN_USER_ERROR:
            return {
                ...state,
                user: null,
                isLogin: false,
                loginErrorData: action.payload
            }
        case UserActionTypes.REGISTER_REQUEST_SUCCESS:
            return {
                ...state,
                user: action.payload,
                isLogin: true,
                loginErrorData: null
            }
        case UserActionTypes.REGISTER_REQUEST_ERROR:
            return {
                ...state,
                user: null,
                isLogin: false,
                loginErrorData: action.payload
            }
        case UserActionTypes.REGISTER_REQUEST_STATUS:
            return {
                ...state,
                registerReqStatus: ''
            }
        case UserActionTypes.REGISTER_REQUEST_BY_MOBILE_SUCCESS:
            return {
                ...state,
                user: action.payload,
                isLogin: true,
                loginErrorData: null
            }
        case UserActionTypes.REGISTER_REQUEST_BY_MOBILE_ERROR:
            return {
                ...state,
                user: null,
                isLogin: false,
                loginErrorData: action.payload
            }
        case UserActionTypes.REGISTER_REQUEST_BY_MOBILE_STATUS:
            return {
                ...state,
                registerReqStatus: ''
            }
        case UserActionTypes.SUBSCRIPTION_LIST_REQUEST_SUCCESS:
            return {
                ...state,
                subscriptionListSuccess: action.payload,
                subscriptionListError: null
            }
        case UserActionTypes.SUBSCRIPTION_LIST_REQUEST_ERROR:
            return {
                ...state,
                subscriptionListSuccess: null,
                subscriptionListError: action.payload
            }
        case UserActionTypes.UPDATE_NOTIFICATION_TOKEN_REQUEST_SUCCESS:
            return {
                ...state,
                updateNotificationTokenSuccess: action.payload,
                updateNotificationTokenError: null
            }
        case UserActionTypes.UPDATE_NOTIFICATION_TOKEN_REQUEST_ERROR:
            return {
                ...state,
                updateNotificationTokenSuccess: null,
                updateNotificationTokenError: action.payload
            }
        case UserActionTypes.FORGOT_PASSWORD_REQUEST_SUCCESS:
            return {
                ...state,
                forgotPasswordSuccess: action.payload,
                forgotPasswordError: null,
                forgotPasswordReqStatus: API_CONSTANTS.SUCCESS_STATUS
            }
        case UserActionTypes.FORGOT_PASSWORD_REQUEST_ERROR:
            return {
                ...state,
                forgotPasswordSuccess: null,
                forgotPasswordError: action.payload,
                forgotPasswordReqStatus: API_CONSTANTS.ERROR_STATUS
            }
        case UserActionTypes.FORGOT_PASSWORD_REQUEST_STATUS:
            return {
                ...state,
                forgotPasswordReqStatus: ''
            }
        case UserActionTypes.CUSTOMER_CARD_REQUEST_SUCCESS:
            return {
                ...state,
                customerCardRequestSuccess: action.payload,
                customerCardRequestError: null,
                customerCardRequestReqStatus: API_CONSTANTS.SUCCESS_STATUS
            }
        case UserActionTypes.CUSTOMER_CARD_REQUEST_ERROR:
            return {
                ...state,
                customerCardRequestSuccess: null,
                customerCardRequestError: action.payload,
                customerCardRequestReqStatus: API_CONSTANTS.ERROR_STATUS
            }
        case UserActionTypes.CUSTOMER_CARD_REQUEST_STATUS:
            return {
                ...state,
                customerCardRequestReqStatus: ''
            }
        case UserActionTypes.CHANGE_PASSWORD_REQUEST_SUCCESS:
            return {
                ...state,
                changePasswordSuccess: action.payload,
                changePasswordError: null,
                changePasswordReqStatus: API_CONSTANTS.SUCCESS_STATUS
            }
        case UserActionTypes.CHANGE_PASSWORD_REQUEST_ERROR:
            return {
                ...state,
                changePasswordSuccess: null,
                changePasswordError: action.payload,
                changePasswordReqStatus: API_CONSTANTS.ERROR_STATUS
            }
        case UserActionTypes.CHANGE_PASSWORD_REQUEST_STATUS:
            return {
                ...state,
                changePasswordReqStatus: ''
            }
        case UserActionTypes.LOGOUT:
            return INITIAL_STATE
        case UserActionTypes.COUNTRY_LIST_REQUEST_SUCCESS:
            return {
                ...state,
                countryListSuccess: action.payload,
                countryListError: null
            }
        case UserActionTypes.COUNTRY_LIST_REQUEST_ERROR:
            return {
                ...state,
                countryListSuccess: null,
                countryListError: action.payload
            }
        case UserActionTypes.STATE_LIST_REQUEST_SUCCESS:
            return {
                ...state,
                stateListSuccess: action.payload,
                stateListError: null
            }
        case UserActionTypes.STATE_LIST_REQUEST_ERROR:
            return {
                ...state,
                stateListSuccess: null,
                stateListError: action.payload
            }
        case UserActionTypes.CITY_LIST_REQUEST_SUCCESS:
            return {
                ...state,
                cityListSuccess: action.payload,
                cityListError: null
            }
        case UserActionTypes.CITY_LIST_REQUEST_ERROR:
            return {
                ...state,
                cityListSuccess: null,
                cityListError: action.payload
            }
        case UserActionTypes.RESET_STATE_CITY:
            return {
                ...state,
                stateListSuccess: null,
                stateListError: null,
                cityListSuccess: null,
                cityListError: null,
                countryListSuccess: null,
                countryListError: null
            }
        case UserActionTypes.IP_LOCATION_REQUEST_SUCCESS:
            return {
                ...state,
                ipLocationSuccess: action.payload,
                ipLocationError: null
            }
        case UserActionTypes.IP_LOCATION_REQUEST_ERROR:
            return {
                ...state,
                ipLocationSuccess: null,
                ipLocationError: action.payload
            }
        case UserActionTypes.DISPLAY_TOUR:
            return {
                ...state,
                displayTour: action.payload
            }
        case UserActionTypes.CONTACT_US_REQUEST_SUCCESS:
            return {
                ...state,
                contactUsSuccess: action.payload,
                contactUsError: null,
                contactUsStatus: API_CONSTANTS.SUCCESS_STATUS
            }
        case UserActionTypes.CONTACT_US_REQUEST_ERROR:
            return {
                ...state,
                contactUsSuccess: null,
                contactUsError: action.payload,
                contactUsStatus: API_CONSTANTS.ERROR_STATUS
            }
        case UserActionTypes.CONTACT_US_REQUEST_STATUS:
            return {
                ...state,
                contactUsStatus: ''
            }
        case UserActionTypes.BUSINESS_UPDATE_NOTIFICATION_REQUEST_SUCCESS:
            return {
                ...state,
                businessUpdateTokenSuccess: action.payload,
                businessUpdateTokenError: null,
                businessUpdateTokenReqStatus: API_CONSTANTS.SUCCESS_STATUS
            }
        case UserActionTypes.BUSINESS_UPDATE_NOTIFICATION_REQUEST_ERROR:
            return {
                ...state,
                businessUpdateTokenSuccess: null,
                businessUpdateTokenError: action.payload,
                businessUpdateTokenReqStatus: API_CONSTANTS.ERROR_STATUS
            }
        case UserActionTypes.BUSINESS_UPDATE_NOTIFICATION_REQUEST_STATUS:
            return {
                ...state,
                businessUpdateTokenReqStatus: ''
            }
        case UserActionTypes.USER_REGISTRATION_BUSINESS_REQUEST_SUCCESS:
            return {
                ...state,
                user: action.payload,
                isLogin: true,
                loginErrorData: null
            }
        case UserActionTypes.USER_REGISTRATION_BUSINESS_REQUEST_ERROR:
            return {
                ...state,
                user: null,
                isLogin: false,
                loginErrorData: action.payload
            }
        case UserActionTypes.BUSINESS_DETAILS_REQUEST_SUCCESS:
            return {
                ...state,
                businessDetailsSuccess: action.payload,
                businessDetailsError: null
            }
        case UserActionTypes.BUSINESS_DETAILS_REQUEST_ERROR:
            return {
                ...state,
                businessDetailsSuccess: null,
                businessDetailsError: action.payload
            }
        case UserActionTypes.USER_REGISTRATION_BUSINESS_REQUEST_STATUS:
            return {
                ...state,
                userRegistrationBusinessReqStatus: ''
            }
        case UserActionTypes.BUSINESS_VISITOR_CONFIG_UPDATE_REQUEST_SUCCESS:
            return {
                ...state,
                visitorConfigUpdateSuccess: action.payload,
                visitorConfigUpdateError: null,
                visitorConfigUpdateReqStatus: API_CONSTANTS.SUCCESS_STATUS
            }
        case UserActionTypes.BUSINESS_VISITOR_CONFIG_UPDATE_REQUEST_ERROR:
            return {
                ...state,
                visitorConfigUpdateSuccess: null,
                visitorConfigUpdateError: action.payload,
                visitorConfigUpdateReqStatus: API_CONSTANTS.ERROR_STATUS
            }
        case UserActionTypes.BUSINESS_VISITOR_CONFIG_UPDATE_REQUEST_STATUS:
            return {
                ...state,
                visitorConfigUpdateReqStatus: ''
            }
        case UserActionTypes.UPDATE_OPERATOR_LOCATION_REQUEST_SUCCESS:
            return {
                ...state,
                updateOperatorLocationSuccess: action.payload,
                updateOperatorLocationError: null,
                updateOperatorLocationReqStatus: API_CONSTANTS.SUCCESS_STATUS
            }
        case UserActionTypes.UPDATE_OPERATOR_LOCATION_REQUEST_ERROR:
            return {
                ...state,
                updateOperatorLocationSuccess: null,
                updateOperatorLocationError: action.payload,
                updateOperatorLocationReqStatus: API_CONSTANTS.ERROR_STATUS
            }
        case UserActionTypes.UPDATE_OPERATOR_LOCATION_REQUEST_STATUS:
            return {
                ...state,
                updateOperatorLocationReqStatus: ''
            }
        case UserActionTypes.OPERATOR_REGISTERATION_REQUEST_SUCCESS:
            return {
                ...state,
                operatorRegistrationSuccess: action.payload,
                operatorRegistrationError: null,
                operatorRegistrationReqStatus: API_CONSTANTS.SUCCESS_STATUS
            }
        case UserActionTypes.OPERATOR_REGISTERATION_REQUEST_ERROR:
            return {
                ...state,
                operatorRegistrationSuccess: null,
                operatorRegistrationError: action.payload,
                operatorRegistrationReqStatus: API_CONSTANTS.ERROR_STATUS
            }
        case UserActionTypes.OPERATOR_REGISTERATION_REQUEST_STATUS:
            return {
                ...state,
                operatorRegistrationReqStatus: ''
            }
        case UserActionTypes.OPERATOR_LIST_REQUEST_SUCCESS:
            return {
                ...state,
                operatorListSuccess: action.payload,
                operatorListError: null
            }
        case UserActionTypes.OPERATOR_LIST_REQUEST_ERROR:
            return {
                ...state,
                operatorListSuccess: null,
                operatorListError: action.payload
            }
        case UserActionTypes.OPERATOR_DETAIL_REQUEST_SUCCESS:
            return {
                ...state,
                operatorDetailSuccess: action.payload,
                operatorDetailError: null
            }
        case UserActionTypes.OPERATOR_DETAIL_REQUEST_ERROR:
            return {
                ...state,
                operatorDetailSuccess: null,
                operatorDetailError: action.payload
            }
        case UserActionTypes.DISPLAY_SIDE_BAR:
            return {
                ...state,
                displaySideBar: action.payload
            }
        case UserActionTypes.COMMON_ERROR:
            return {
                ...state,
                commonError: action.errData
            }
        default:
            return state
    }
}

export default userReducer