import React, { useEffect, useState, useRef } from "react";
import './styles.css'
import { useLocation, useNavigate } from 'react-router-dom';
import Loader from '../../../../components/loader/loader.component'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useDispatch, useSelector } from 'react-redux'
import { cmToPx, displayErrorToast, displaySuccessToast, isEmpty, isNull } from "../../../../utils/Utils";
import { API_CONSTANTS, APP_CONSTANTS } from "../../../../utils/AppConstants";
import { cardByAliasRequest } from '../../../../redux/digital-card/digitalCard.action'
import { countryListRequest, stateListRequest, cityListRequest } from '../../../../redux/user/user.action'
import CommonScreenContent from "../../../../components/screen/common-screen-content/commonScreenContent";
import ScreenLabel from "../../../../components/label/screen-label/screenLabel";
import CommonColorForm from "../../../../components/common-color-form/commonColorForm";
import SubTextLabel from "../../../../components/label/sub-text-label/subTextLabel";
// import { QRCode } from 'react-qrcode-logo';
import { config } from "../../../../config";
import NfcCardDesign1 from "../nfc-template-design/nfcCardDesign1";
import NfcCardDesign2 from "../nfc-template-design/nfcCardDesign2";
import NfcCardDesign3 from "../nfc-template-design/nfcCardDesign3";
import FormFieldLabel from "../../../../components/label/form-field-label/formFieldLabel";
import CommonInputFull from "../../../../components/input/common-input-full/commonInputFull";
import CommonInputRow from "../../../../components/input/common-input-row/commonInputRow";
import CommonInputHalf from "../../../../components/input/common-input-half/commonInputHalf";
import ButtonRow from "../../../../components/button/button-row/buttonRow";
import CommonButton from "../../../../components/button/common-button/commonButton";
import { placeNfcCardOrderRequest, placeNfcCardOrderRequestStatus } from '../../../../redux/nfc-card-order/nfcCardOrder.action'
import Modal from 'react-bootstrap/Modal';
import Select from 'react-select'

const NFCTemplateSelection = props => {
    const { state } = useLocation()
    const [isLoading, setLoading] = useState(false)
    const dispatch = useDispatch()
    let navigate = useNavigate();
    const { cardByAliasSuccess, cardByAliasError } = useSelector((state) => state.digitalCard)
    const { commonError, user, countryListSuccess, countryListError, stateListSuccess, stateListError,
        cityListSuccess, cityListError } = useSelector((state) => state.user)
    const { placeNfcCardOrderSuccess, placeNfcCardOrderError, placeNfcCardOrderReqStatus } = useSelector((state) => state.nfcCardOrder)
    const [qrCodeUrl, setQrCodeUrl] = useState(`${config.qrCodeUrl}/${state.alias}`)
    const [selectedCard, setSelectedCard] = useState('')
    const [showQuantityView, setShowQuantityView] = useState(false)
    const [showAddressView, setShowAddressView] = useState(false)
    const [showOrderPreview, setShowOrderPreview] = useState(false)
    const [cardQuantity, setCardQuantity] = useState('')
    const [personName, setPersonName] = useState('')
    const [personContactNumber, setPersonContactNumber] = useState('')
    const [personContactEmail, setPersonContactEmail] = useState('')
    const [address, setAddress] = useState('')
    const [landmark, setLandmark] = useState('')
    const [addressCountry, setAddressCountry] = useState('')
    const [addressState, setAddressState] = useState('')
    const [addressCity, setAddressCity] = useState('')
    const [addressPincode, setAddressPincode] = useState('')
    const [displayOrderSuccessModal, setDisplayOrderSuccessModal] = useState(false)
    const cardDesign1Ref = useRef(null);
    const cardDesign2Ref = useRef(null);
    const cardDesign3Ref = useRef(null);
    const [countryDropdown, setCountryDropdown] = useState();
    const [stateDropdown, setStateDropdown] = useState();
    const [cityDropdown, setCityDropdown] = useState();
    const [countryCode, setCountryCode] = useState('')

    useEffect(() => {
        if (!isNull(commonError)) {
            if (commonError.status === 401) {
                setLoading(false)
                displayErrorToast(commonError.message)
                dispatch({ type: APP_CONSTANTS.LOGOUT })
            }
        }
    }, [commonError])

    useEffect(() => {
        dispatch({ type: APP_CONSTANTS.RESET_STATE_CITY })
        setLoading(true)
        dispatch(countryListRequest())
        dispatch(cardByAliasRequest(state.alias))
    }, [])

    useEffect(() => {
        if (!isNull(cardByAliasSuccess)) {
            setLoading(false)
        } else if (!isNull(cardByAliasError)) {
            setLoading(false)
        }
    }, [cardByAliasSuccess, cardByAliasError])

    useEffect(() => {
        if (placeNfcCardOrderReqStatus === API_CONSTANTS.SUCCESS_STATUS) {
            setLoading(false)
            displaySuccessToast(placeNfcCardOrderSuccess.message)
            clearFields()
            setDisplayOrderSuccessModal(true)
        } else if (placeNfcCardOrderReqStatus === API_CONSTANTS.ERROR_STATUS) {
            setLoading(false)
            displayErrorToast(placeNfcCardOrderError.message)
        }
        if (placeNfcCardOrderReqStatus?.length > 0) {
            dispatch(placeNfcCardOrderRequestStatus(''))
        }
    }, [placeNfcCardOrderSuccess, placeNfcCardOrderError, placeNfcCardOrderReqStatus])

    const fetchStateList = (countryId) => {
        setLoading(true)
        const data = {
            countryId: countryId
        }
        dispatch(stateListRequest(data))
    }

    const fetchCityList = (countryId, stateId) => {
        setLoading(true)
        const data = {
            countryId: countryId,
            stateId: stateId
        }
        dispatch(cityListRequest(data))
    }

    useEffect(() => {
        if (!isNull(countryListSuccess)) {
            setLoading(false)
        } else if (!isNull(countryListError)) {
            setLoading(false)
        }
    }, [countryListSuccess, countryListError])

    useEffect(() => {
        if (!isNull(stateListSuccess)) {
            setLoading(false)
        } else if (!isNull(stateListError)) {
            setLoading(false)
        }
    }, [stateListSuccess, stateListError])

    useEffect(() => {
        if (!isNull(cityListSuccess)) {
            setLoading(false)
        } else if (!isNull(cityListError)) {
            setLoading(false)
        }
    }, [cityListSuccess, cityListError])

    const handleCountrySelectChange = e => {
        setCountryDropdown(e);
        setCountryCode(e.iso2)
        setStateDropdown(null)
        setCityDropdown(null)
        fetchStateList(e.iso2)
    }

    const clearFields = () => {
        setSelectedCard('')
        setShowQuantityView(false)
        setShowAddressView(false)
        setShowOrderPreview(false)
        setCardQuantity('')
        setPersonName('')
        setPersonContactNumber('')
        setPersonContactEmail('')
        setAddress('')
        setLandmark('')
        setAddressCountry('')
        setAddressState('')
        setAddressCity('')
        setAddressPincode('')
    }

    const getPersonName = (data) => {
        if (!isEmpty(data.otherDetails)) {
            return `${data.otherDetails.firstName} ${data.otherDetails.lastName}`;
        } else {
            return ''
        }
    }

    const getPersonDesignation = (data) => {
        if (!isEmpty(data.otherDetails)) {
            return `${data.otherDetails.jobTitle}`;
        } else {
            return ''
        }
    }

    const getPersonEmail = (data) => {
        if (!isEmpty(data.otherDetails)) {
            if (!isEmpty(data.otherDetails.email1)) {
                return `${data.otherDetails.email1}`;
            } else {
                if (!isEmpty(data.otherDetails.email2)) {
                    return `${data.otherDetails.email2}`;
                } else {
                    if (!isEmpty(data.otherDetails.email3)) {
                        return `${data.otherDetails.email3}`;
                    } else {
                        return ''
                    }
                }
            }
        } else {
            return ''
        }
    }

    const getPersonAddress = (data) => {
        if (!isEmpty(data.otherDetails)) {
            if (!isEmpty(data.otherDetails.workAddress)) {
                return `${data.otherDetails.workAddress}`;
            } else {
                if (!isEmpty(data.otherDetails.homeAddress)) {
                    return `${data.otherDetails.homeAddress}`;
                } else {
                    return ''
                }
            }
        } else {
            return ''
        }
    }

    const getPersonPhone = (data) => {
        if (!isEmpty(data.otherDetails)) {
            if (!isEmpty(data.otherDetails.mobileNumber)) {
                return `${data.otherDetails.mobileNumber}`;
            } else {
                if (!isEmpty(data.otherDetails.homeNumber)) {
                    return `${data.otherDetails.homeNumber}`;
                } else {
                    if (!isEmpty(data.otherDetails.workNumber1)) {
                        return `${data.otherDetails.workNumber1} - ${data.otherDetails.workNumber1Ext}`;
                    } else {
                        if (!isEmpty(data.otherDetails.workNumber2)) {
                            return `${data.otherDetails.workNumber2} - ${data.otherDetails.workNumber2Ext}`;
                        } else {
                            return ''
                        }
                    }
                }
            }
        } else {
            return ''
        }
    }

    const handleSelectCardClick = (cardId) => {
        setSelectedCard(cardId)
    }

    useEffect(() => {
        if (!isEmpty(selectedCard)) {
            setShowQuantityView(true)
        } else {
            setShowQuantityView(false)
        }
    }, [selectedCard])

    const handleCardQuantityInput = qty => {
        if (!isEmpty(qty) && qty.length > 3) {
            displayErrorToast('Invalid card quantity to order')
            setCardQuantity('')
        } else {
            setCardQuantity(qty)
        }
    }

    useEffect(() => {
        if (!isEmpty(cardQuantity) && parseFloat(cardQuantity) > 0 && cardQuantity.length < 3) {
            setShowAddressView(true)
        } else {
            setShowAddressView(false)
        }
    }, [cardQuantity])

    const handlePlaceOrderClick = () => {
        if (isEmpty(state.alias)) {
            displayErrorToast('Card information not available. Please try again')
        } else if (isEmpty(cardByAliasSuccess)) {
            displayErrorToast('Card details not available. Please try again.')
        } else if (isEmpty(selectedCard)) {
            displayErrorToast('Card design not selected. Please select a design to order NFC card')
        } else if (isEmpty(cardQuantity)) {
            displayErrorToast('Number of NFC card to order cannot be blank')
        } else if (parseFloat(cardQuantity) <= 0) {
            displayErrorToast('NFC card quantity cannot be less than 0.')
        } else if (isEmpty(personName)) {
            displayErrorToast('Contact person name cannot be blank')
        } else if (isEmpty(personContactNumber)) {
            displayErrorToast('Contact person number cannot be blank')
        } else if (isEmpty(personContactEmail)) {
            displayErrorToast('Contact person email cannot be blank')
        } else if (isEmpty(address)) {
            displayErrorToast('Order shipping address cannot be blank')
        } else if (isNull(countryDropdown)) {
            displayErrorToast('Order shipping country not selected')
        } else if (isNull(stateDropdown)) {
            displayErrorToast('Order shipping state not selected')
        } else if (isNull(cityDropdown)) {
            displayErrorToast('Order shipping city not selected')
        } else if (isEmpty(addressPincode)) {
            displayErrorToast('Address pincode cannot be blank')
        } else {
            setLoading(true)
            const data = {
                digitalCardDetailId: cardByAliasSuccess.data.otherDetails.digitalCardDetailId,
                digitalCardId: cardByAliasSuccess.data.digitalCardId,
                customerId: user.data.userDetails.userId,
                nameOnCard: getPersonName(cardByAliasSuccess.data),
                jobTitle: getPersonDesignation(cardByAliasSuccess.data),
                email: getPersonEmail(cardByAliasSuccess.data),
                mobileNumber: getPersonPhone(cardByAliasSuccess.data),
                address: getPersonAddress(cardByAliasSuccess.data),
                designTemplateId: selectedCard,
                qrCodeUrl: qrCodeUrl,
                cardAlias: state.alias,
                orderQuantity: cardQuantity,
                customerName: personName,
                customerNumber: personContactNumber,
                customerEmail: personContactEmail,
                orderAddress: address,
                orderAddressLandmark: !isEmpty(landmark) ? landmark : '',
                orderAddressCity: cityDropdown.name,
                orderAddressState: stateDropdown.name,
                orderAddressCountry: countryDropdown.name,
                orderAddressPincode: addressPincode,
                orderType: APP_CONSTANTS.NFC_ORDER_TYPE.INDIVIDUAL
            }
            dispatch(placeNfcCardOrderRequest(data))
        }
    }

    return (
        <>
            <CommonScreenContent>
                <ScreenLabel label='Get NFC Card' />
                <CommonColorForm>
                    <SubTextLabel label='Select your card design' />
                    {!isNull(cardByAliasSuccess) && !isEmpty(cardByAliasSuccess.data) && <div className="nts-card-row-container">
                        <NfcCardDesign1
                            name={getPersonName(cardByAliasSuccess.data)}
                            designation={getPersonDesignation(cardByAliasSuccess.data)}
                            phoneNumber={getPersonPhone(cardByAliasSuccess.data)}
                            email={getPersonEmail(cardByAliasSuccess.data)}
                            address={getPersonAddress(cardByAliasSuccess.data)}
                            qrCodeUrl={qrCodeUrl}
                            isSelected={selectedCard === 'CARD_1'}
                            selectDesignHandler={() => { handleSelectCardClick('CARD_1') }}
                            showButton={true}
                        />
                        <NfcCardDesign2
                            name={getPersonName(cardByAliasSuccess.data)}
                            designation={getPersonDesignation(cardByAliasSuccess.data)}
                            phoneNumber={getPersonPhone(cardByAliasSuccess.data)}
                            email={getPersonEmail(cardByAliasSuccess.data)}
                            address={getPersonAddress(cardByAliasSuccess.data)}
                            qrCodeUrl={qrCodeUrl}
                            isSelected={selectedCard === 'CARD_2'}
                            selectDesignHandler={() => { handleSelectCardClick('CARD_2') }}
                            showButton={true}
                        />
                        <NfcCardDesign3
                            name={getPersonName(cardByAliasSuccess.data)}
                            designation={getPersonDesignation(cardByAliasSuccess.data)}
                            phoneNumber={getPersonPhone(cardByAliasSuccess.data)}
                            email={getPersonEmail(cardByAliasSuccess.data)}
                            address={getPersonAddress(cardByAliasSuccess.data)}
                            qrCodeUrl={qrCodeUrl}
                            isSelected={selectedCard === 'CARD_3'}
                            selectDesignHandler={() => { handleSelectCardClick('CARD_3') }}
                            showButton={true}
                        />

                    </div>}
                    {showQuantityView === true && <div className="nts-quantity-parent-view">
                        {/* <FormFieldLabel label='Number of Card to order' /> */}
                        <CommonInputFull
                            type='number'
                            id='quantity'
                            onChange={e => { handleCardQuantityInput(e.target.value) }}
                            placeholder='Number of cards'
                            value={cardQuantity} />
                        <div className="nts-pricing-label">**Note: We provide special pricing for each customer. We will share it in the email provided after placing the order.</div>
                    </div>}
                    {showAddressView === true && <div className="nts-quantity-parent-view">
                        <FormFieldLabel label='Enter shipping address' />
                        <CommonInputFull
                            type='text'
                            id='name'
                            onChange={e => setPersonName(e.target.value)}
                            placeholder='Contact Person Name'
                            value={personName} />
                        <CommonInputRow>
                            <CommonInputHalf
                                type='number'
                                id='mobile'
                                onChange={e => setPersonContactNumber(e.target.value)}
                                placeholder='Contact Number'
                                value={personContactNumber} />
                            <CommonInputHalf
                                type='text'
                                id='email'
                                onChange={e => setPersonContactEmail(e.target.value)}
                                placeholder='Contact Email'
                                value={personContactEmail} />
                        </CommonInputRow>
                        <CommonInputFull
                            type='text'
                            id='address'
                            onChange={e => setAddress(e.target.value)}
                            placeholder='Delivery Address'
                            value={address} />
                        <CommonInputFull
                            type='text'
                            id='landmark'
                            onChange={e => setLandmark(e.target.value)}
                            placeholder='Landmark'
                            value={landmark} />
                        {countryListSuccess && <Select
                            menuPlacement="auto"
                            className='select-full-width-no-border'
                            value={countryDropdown}
                            getOptionLabel={e => e.name}
                            getOptionValue={e => e}
                            options={countryListSuccess}
                            placeholder='Select Country'
                            onChange={e => {
                                handleCountrySelectChange(e)
                            }} />}
                        <CommonInputRow>
                            {stateListSuccess && <Select
                                menuPlacement="auto"
                                className='select-half-width up-select-margin'
                                value={stateDropdown}
                                getOptionLabel={e => e.name}
                                getOptionValue={e => e}
                                options={stateListSuccess}
                                placeholder='Select State'
                                onChange={e => {
                                    setStateDropdown(e);
                                    setCityDropdown(null)
                                    fetchCityList(countryCode, e.iso2)
                                }} />}
                            {cityListSuccess && <Select
                                menuPlacement="auto"
                                className='select-half-width up-select-margin'
                                value={cityDropdown}
                                getOptionLabel={e => e.name}
                                getOptionValue={e => e}
                                options={cityListSuccess}
                                placeholder='Select City'
                                onChange={e => {
                                    setCityDropdown(e);
                                }} />}
                        </CommonInputRow>
                        {/* <CommonInputRow>
                            <CommonInputHalf
                                type='text'
                                id='landmark'
                                onChange={e => setLandmark(e.target.value)}
                                placeholder='Landmark'
                                value={landmark} />
                            <CommonInputHalf
                                type='text'
                                id='city'
                                onChange={e => setAddressCity(e.target.value)}
                                placeholder='City'
                                value={addressCity} />
                        </CommonInputRow> */}
                        {/* <CommonInputRow>
                            <CommonInputHalf
                                type='text'
                                id='state'
                                onChange={e => setAddressState(e.target.value)}
                                placeholder='State'
                                value={addressState} />
                            <CommonInputHalf
                                type='text'
                                id='country'
                                onChange={e => setAddressCountry(e.target.value)}
                                placeholder='Country'
                                value={addressCountry} />
                        </CommonInputRow> */}
                        <CommonInputFull
                            classname="mt-2"
                            type='text'
                            id='pincode'
                            onChange={e => setAddressPincode(e.target.value)}
                            placeholder='Pincode/Zipcode'
                            value={addressPincode} />
                        <ButtonRow>
                            <CommonButton
                                label='Place Order'
                                onClick={() => { handlePlaceOrderClick() }} />
                        </ButtonRow>

                    </div>}

                </CommonColorForm>
            </CommonScreenContent>
            <Modal
                onHide={() => { setDisplayOrderSuccessModal(false) }}
                show={displayOrderSuccessModal}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                </Modal.Header>
                <Modal.Body>
                    <div>Your NFC card order was placed successfully. We provide special pricing for each customer's order. Please keep an eye on your email, we will shortly update you with your order pricing and further updates. Have a great networking lifestyle.</div>
                </Modal.Body>
                <Modal.Footer>
                </Modal.Footer>
            </Modal>
            <ToastContainer />
            <Loader active={isLoading} text='Please wait...' />
        </>
    )
}

export default NFCTemplateSelection