import { ServiceActionTypes } from './service.types'
import { API_CONSTANTS } from '../../utils/AppConstants'

const INITIAL_STATE = {
    addServiceSuccess: null,
    addServiceError: null,
    addServiceReqStatus: '',
    serviceListSuccess: null,
    serviceListError: null,
    updateServiceSuccess: null,
    updateServiceError: null,
    updateServiceReqStatus: '',
    deleteServiceSuccess: null,
    deleteServiceError: null,
    deleteServiceReqStatus: ''
}

const serviceReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case ServiceActionTypes.ADD_SERVICE_REQUEST_SUCCESS:
            return {
                ...state,
                addServiceSuccess: action.payload,
                addServiceError: null,
                addServiceReqStatus: API_CONSTANTS.SUCCESS_STATUS
            }
        case ServiceActionTypes.ADD_SERVICE_REQUEST_ERROR:
            return {
                ...state,
                addServiceSuccess: null,
                addServiceError: action.payload,
                addServiceReqStatus: API_CONSTANTS.ERROR_STATUS
            }
        case ServiceActionTypes.ADD_SERVICE_REQUEST_STATUS:
            return {
                ...state,
                addServiceReqStatus: ''
            }
        case ServiceActionTypes.SERVICE_LIST_REQUEST_SUCCESS:
            return {
                ...state,
                serviceListSuccess: action.payload,
                serviceListError: null
            }
        case ServiceActionTypes.SERVICE_LIST_REQUEST_ERROR:
            return {
                ...state,
                serviceListSuccess: null,
                serviceListError: action.payload
            }
        case ServiceActionTypes.UPDATE_SERVICE_REQUEST_SUCCESS:
            return {
                ...state,
                updateServiceSuccess: action.payload,
                updateServiceError: null,
                updateServiceReqStatus: API_CONSTANTS.SUCCESS_STATUS
            }
        case ServiceActionTypes.UPDATE_SERVICE_REQUEST_ERROR:
            return {
                ...state,
                updateServiceSuccess: null,
                updateServiceError: action.payload,
                updateServiceReqStatus: API_CONSTANTS.ERROR_STATUS
            }
        case ServiceActionTypes.UPDATE_SERVICE_REQUEST_STATUS:
            return {
                ...state,
                updateServiceReqStatus: ''
            }
        case ServiceActionTypes.DELETE_SERVICE_REQUEST_SUCCESS:
            return {
                ...state,
                deleteServiceSuccess: action.payload,
                deleteServiceError: null,
                deleteServiceReqStatus: API_CONSTANTS.SUCCESS_STATUS
            }
        case ServiceActionTypes.DELETE_SERVICE_REQUEST_ERROR:
            return {
                ...state,
                deleteServiceSuccess: null,
                deleteServiceError: action.payload,
                deleteServiceReqStatus: API_CONSTANTS.ERROR_STATUS
            }
        case ServiceActionTypes.DELETE_SERVICE_REQUEST_STATUS:
            return {
                ...state,
                deleteServiceReqStatus: ''
            }
        case ServiceActionTypes.LOGOUT:
            return INITIAL_STATE
        default:
            return state
    }
}

export default serviceReducer