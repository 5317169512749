import React, { useEffect, useState } from "react";
import './styles.css'
import Loader from '../../components/loader/loader.component'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useDispatch, useSelector } from 'react-redux'
import {
    forgotPasswordRequest, forgotPasswordRequestStatus, changePasswordRequest,
    changePasswordRequestStatus
} from '../../redux/user/user.action'
import { API_CONSTANTS } from "../../utils/AppConstants";
import { displayErrorToast, displaySuccessToast, isEmpty, isValidEmail, screenCaptureAnalytics } from "../../utils/Utils";
import { useNavigate } from 'react-router-dom';
import ButtonRow from "../../components/button/button-row/buttonRow";
import CommonButton from "../../components/button/common-button/commonButton";
import ScreenLabel from "../../components/label/screen-label/screenLabel";
import CommonScreenContent from "../../components/screen/common-screen-content/commonScreenContent";
import CommonColorForm from "../../components/common-color-form/commonColorForm";
import SubTextLabel from "../../components/label/sub-text-label/subTextLabel";
import Paper from '@mui/material/Paper';
import CommonInputFull from "../../components/input/common-input-full/commonInputFull";
import CommonScreenContentCenter from "../../components/screen/common-screen-content-center/commonScreenContentCenter";
import CommonInputFloatFull from "../../components/input/common-input-float-full/commonInputFloatFull";

const ForgotPassword = props => {
    const [isLoading, setLoading] = useState(false)
    const dispatch = useDispatch()
    const [emailId, setEmailId] = useState('')
    const [accessToken, setAccessToken] = useState('')
    const [password, setPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')
    const [emaildId2, setEmailId2] = useState('')
    const [showAccessToken, setShowAccessToken] = useState(false)
    const { forgotPasswordSuccess, forgotPasswordError, forgotPasswordReqStatus,
        changePasswordSuccess, changePasswordError, changePasswordReqStatus } = useSelector(state => state.user)
    let navigate = useNavigate();

    useEffect(() => {
        const data = {
            screen: 'FORGOT_PASSWORD',
            screenClass: 'FORGOT_PASSWORD'
        }
        screenCaptureAnalytics(data)
    }, [])

    useEffect(() => {
        if (forgotPasswordReqStatus === API_CONSTANTS.SUCCESS_STATUS) {
            setLoading(false)
            displaySuccessToast(forgotPasswordSuccess.message)
            setShowAccessToken(true)
        } else if (forgotPasswordReqStatus === API_CONSTANTS.ERROR_STATUS) {
            setLoading(false)
            displayErrorToast(forgotPasswordError.message)
        }

        if (forgotPasswordReqStatus.length) {
            dispatch(forgotPasswordRequestStatus(''))
        }
    }, [forgotPasswordSuccess, forgotPasswordError, forgotPasswordReqStatus])

    useEffect(() => {
        if (changePasswordReqStatus === API_CONSTANTS.SUCCESS_STATUS) {
            setLoading(false)
            displaySuccessToast(changePasswordSuccess.message)
            navigate(-1)
        } else if (changePasswordReqStatus === API_CONSTANTS.ERROR_STATUS) {
            setLoading(false)
            displayErrorToast(changePasswordError.message)
        }

        if (changePasswordReqStatus.length) {
            dispatch(changePasswordRequestStatus(''))
        }
    }, [changePasswordSuccess, changePasswordError, changePasswordReqStatus])

    const handleChangePassword = () => {
        if (isEmpty(emaildId2)) {
            displayErrorToast('Email id cannot be blank')
        } else if (!isValidEmail(emaildId2)) {
            displayErrorToast('Enter a valid email id')
        } else if (isEmpty(accessToken)) {
            displayErrorToast('Access token cannot be blank')
        } else if (isEmpty(password)) {
            displayErrorToast('Password cannot be blank')
        } else if (password.length <= 6) {
            displayErrorToast('Password should be minimum 7 characters', true)
        } else if (isEmpty(confirmPassword)) {
            displayErrorToast('Confirm Password cannot be blank')
        } else if (password !== confirmPassword) {
            displayErrorToast('Password does not match')
        } else {
            setLoading(true)
            const data = {
                email: emaildId2,
                accessToken: accessToken,
                password: password
            }
            dispatch(changePasswordRequest(data))
        }
    }

    const handleForgorPassword = () => {
        if (isEmpty(emailId)) {
            displayErrorToast('Email id cannot be blank')
        } else if (!isValidEmail(emailId)) {
            displayErrorToast('Enter a valid email address')
        } else {
            setLoading(true)
            const data = {
                email: emailId
            }
            dispatch(forgotPasswordRequest(data))
        }
    }

    return (
        <>
            <CommonScreenContentCenter>
                <div className="logo-img-contain">
                    {/* <img src={require('../../../assets/GoMoBites.png')} className="sign-in-img" /> */}
                    <img src={require('../../assets/nLogo2.png')} className="img" />
                </div>
                <Paper elevation={3} style={{ background: '#fbfbfb' }} className='ac-card paper-container'>
                    {!showAccessToken && <div>
                        <SubTextLabel label='Enter email id to get access token' />
                        <CommonInputFloatFull
                            type="email"
                            id="name"
                            placeholder="Email Id"
                            value={emailId}
                            onChange={e => setEmailId(e.target.value)}
                            isRequired={true}
                        />
                        <ButtonRow>
                            <CommonButton label='Submit'
                                onClick={() => handleForgorPassword()} />
                        </ButtonRow>
                    </div>}
                    {showAccessToken && <div>
                        <SubTextLabel label='Enter details to change password' />
                        <CommonInputFloatFull
                            type="email"
                            id="name"
                            placeholder="Email Id"
                            value={emaildId2}
                            onChange={e => setEmailId2(e.target.value)}
                            isRequired={true}
                        />
                        <CommonInputFloatFull
                            type="text"
                            id="name"
                            placeholder="Access Token"
                            value={accessToken}
                            onChange={e => setAccessToken(e.target.value)}
                            isRequired={true}
                        />
                        <CommonInputFloatFull
                            type="password"
                            id="name"
                            placeholder="Password"
                            value={password}
                            onChange={e => setPassword(e.target.value)}
                            isRequired={true}
                        />
                        <CommonInputFloatFull
                            type="text"
                            id="name"
                            placeholder="Confirm Password"
                            value={confirmPassword}
                            onChange={e => setConfirmPassword(e.target.value)}
                            isRequired={true}
                        />
                        <ButtonRow>
                            <CommonButton label='Submit'
                                onClick={() => handleChangePassword()} />
                        </ButtonRow>
                    </div>}
                </Paper>
            </CommonScreenContentCenter>
            <ToastContainer />
            <Loader active={isLoading} text='Please wait...' />
        </>
    )
}

export default ForgotPassword