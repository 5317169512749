import React, { createContext } from 'react';
import { io, Socket } from 'socket.io-client';
import { config } from './config';
import { store } from './redux/store'
import {
    newVisitorAddNotif, visitorConfigUpdateNotif
} from './redux/socket/socket.action'
import { displayConsoleLog } from './utils/Utils';

export const socket = io(config.socketUrl)
export const SocketContext = createContext();

socket.on('connect', () => displayConsoleLog('connected to socket'));

socket.on("new-visitor-add", (data) => {
    store.dispatch(newVisitorAddNotif(data))
})

socket.on("visitor-config-update-success", (data) => {
    store.dispatch(visitorConfigUpdateNotif(data))
})

export default SocketContext