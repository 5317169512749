import axios from 'axios'
import { config } from '../../config'
import { APP_CONSTANTS } from '../../utils/AppConstants'
import API_ENDPOINTS from './endpoints'

export function addQrCodeAPI(payload) {
    const user = JSON.parse(sessionStorage.getItem(APP_CONSTANTS.USER))
    return axios.request({
        method: 'post',
        url: `${config.baseUrl}/${API_ENDPOINTS.customQrCode.addQrCode}`,
        data: payload,
        headers: {
            'authToken': user ? user.authToken : config.cid,
            "Content-Type": "multipart/form-data"
        }
    })
}

export function updateQrCodeAPI(payload) {
    const user = JSON.parse(sessionStorage.getItem(APP_CONSTANTS.USER))
    return axios.request({
        method: 'put',
        url: `${config.baseUrl}/${API_ENDPOINTS.customQrCode.updateQrCode}`,
        data: payload,
        headers: {
            'authToken': user ? user.authToken : config.cid,
            "Content-Type": "multipart/form-data"
        }
    })
}

export function qrCodeListAPI(payload) {
    const user = JSON.parse(sessionStorage.getItem(APP_CONSTANTS.USER))
    return axios.request({
        method: 'get',
        url: `${config.baseUrl}/${API_ENDPOINTS.customQrCode.qrCodeList}/${payload}`,
        headers: {
            'authToken': user ? user.authToken : config.cid
        }
    })
}

export function qrCodeDetailAPI(payload) {
    return axios.request({
        method: 'get',
        url: `${config.baseUrl}/${API_ENDPOINTS.customQrCode.qrDetail}`,
        params: payload
    })
}

export function deletQrCodeAPI(payload) {
    const user = JSON.parse(sessionStorage.getItem(APP_CONSTANTS.USER))
    return axios.request({
        method: 'put',
        url: `${config.baseUrl}/${API_ENDPOINTS.customQrCode.deleteQrCode}`,
        data: payload,
        headers: {
            'authToken': user ? user.authToken : config.cid
        }
    })
}