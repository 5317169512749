import React from "react";
import './styles.css'

const CommonInputHalf = ({ type, classname = "", placeholder, value, onChange, id, isRequired = false }) => {
    return (
        <div className={`form-floating common-input-half ${classname}`}>
            <input
                type={type}
                className="form-control"
                id={id}
                placeholder={placeholder}
                value={value}
                onChange={onChange} />
            <label for={id} className='label-container'>{placeholder} {isRequired ? <p className="required-field">*</p> : ''}</label>
        </div>
    )
}

export default CommonInputHalf