import { NewsLetterActionTypes } from './newsLetter.types'

//Add Record
export const newsLetterAddRecordRequest = data => ({
    type: NewsLetterActionTypes.NEWS_LETTER_ADD_RECORD_REQUEST,
    payload: data
})

export const newsLetterAddRecordRequestSuccess = data => ({
    type: NewsLetterActionTypes.NEWS_LETTER_ADD_RECORD_REQUEST_SUCCESS,
    payload: data
})

export const newsLetterAddRecordRequestError = data => ({
    type: NewsLetterActionTypes.NEWS_LETTER_ADD_RECORD_REQUEST_ERROR,
    payload: data
})

export const newsLetterAddRecordRequestStatus = data => ({
    type: NewsLetterActionTypes.NEWS_LETTER_ADD_RECORD_REQUEST_STATUS,
    payload: data
})

//Record List
export const newsLetterRecordListRequest = data => ({
    type: NewsLetterActionTypes.NEWS_LETTER_RECORD_LIST_REQUEST,
    payload: data
})

export const newsLetterRecordListRequestSuccess = data => ({
    type: NewsLetterActionTypes.NEWS_LETTER_RECORD_LIST_REQUEST_SUCCESS,
    payload: data
})

export const newsLetterRecordListRequestError = data => ({
    type: NewsLetterActionTypes.NEWS_LETTER_RECORD_LIST_REQUEST_ERROR,
    payload: data
})