import React, { useState, useEffect } from "react";
import './styles.css'
import { useLocation, useNavigate } from 'react-router-dom';
import Loader from '../../../components/loader/loader.component'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useDispatch, useSelector } from 'react-redux'
import CommonScreenContent from "../../../components/screen/common-screen-content/commonScreenContent";
import ScreenLabel from "../../../components/label/screen-label/screenLabel";
import CommonColorForm from "../../../components/common-color-form/commonColorForm";
import SubTextLabel from "../../../components/label/sub-text-label/subTextLabel";
import FormFieldLabel from "../../../components/label/form-field-label/formFieldLabel";
import CommonInputFull from "../../../components/input/common-input-full/commonInputFull";
import CommonInputHalf from "../../../components/input/common-input-half/commonInputHalf";
import CommonInputRow from "../../../components/input/common-input-row/commonInputRow";
import 'react-phone-number-input/style.css'
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input'
import { displayConsoleLog, displayErrorToast, displaySuccessToast, isEmpty, isNull } from "../../../utils/Utils";
import { cardByAliasRequest, updateCardDetailsRequest, updateCardDetailsRequestStatus, clearAliasDataAct, clearCardDetailAct } from '../../../redux/digital-card/digitalCard.action'
import { config } from "../../../config";
import ButtonRow from "../../../components/button/button-row/buttonRow";
import CommonButton from "../../../components/button/common-button/commonButton";
import { API_CONSTANTS, APP_CONSTANTS } from "../../../utils/AppConstants";
import CommonInputRowSmall from "../../../components/input/common-input-row-small/common-input-row-small";
import { addSpecialCharAndChangeToCamel, removeSpecialCharAndChangeToPascal } from "../../../utils/Utils";
import CommonInputFloatFull from "../../../components/input/common-input-float-full/commonInputFloatFull";
import CommonInputFloatHalf from "../../../components/input/common-input-float-half/commonInputFloafHalf";
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import UserCardPreview from '../user-card/userCardPreview'
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Paper from '@mui/material/Paper';

const CardDetails = props => {
    const { state } = useLocation()
    const [isLoading, setLoading] = useState(false)
    const dispatch = useDispatch()
    let navigate = useNavigate();
    const { cardByAliasSuccess, cardByAliasError, updateCardDetailsSuccess,
        updateCardDetailsError, updateCardDetailsReqStatus } = useSelector((state) => state.digitalCard)
    const { commonError } = useSelector((state) => state.user)
    const [cardName, setCardName] = useState('')
    const [cardDescription, setCardDescription] = useState('')
    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [primaryEmail, setPrimaryEmail] = useState('')
    const [secondaryEmail, setSecondaryEmail] = useState('')
    const [alternateEmail, setAlternateEmail] = useState('')
    const [workAddress, setWorkAddress] = useState('')
    const [homeAddress, setHomeAddress] = useState('')
    const [homePhoneNumber, setHomePhoneNumber] = useState('')
    const [mobilePhoneNumber, setMobilePhoneNumber] = useState('')
    const [faxNumber, setFaxNumber] = useState('')
    const [workNumber1, setWorkNumber1] = useState('')
    const [workNumberExt1, setWorkNumberExt1] = useState('')
    const [workNumber2, setWorkNumber2] = useState('')
    const [workNumberExt2, setWorkNumberExt2] = useState('')
    const [summary, setSummary] = useState('')
    const [companyName, setCompanyName] = useState('')
    const [jobTitle, setJobTitle] = useState('')
    const [facebookLink, setFacebookLink] = useState('')
    const [linkedInLink, setLinkedInLink] = useState('')
    const [twitterLink, setTwitterLink] = useState('')
    const [instagramLink, setInstagramLink] = useState('')
    const [snapchatLink, setSnapchatLink] = useState('')
    const [otherLink, setOtherLink] = useState('')
    const [otherSocialLink, setOtherSocialLink] = useState([])
    const [profileImage, setProfileImage] = useState(null);
    const [brandImage, setBrandImage] = useState(null);
    const [bannerImage, setBannerImage] = useState(null)
    const [profileImagePath, setProfileImagePath] = useState(null)
    const [profileImageName, setProfileImageName] = useState('')
    const [bannerImagePath, setBannerImagePath] = useState(null)
    const [bannerImageName, setBannerImageName] = useState('')
    const [brandImagePath, setBrandImagePath] = useState(null)
    const [brandImageName, setBrandImageName] = useState('')
    const [cardLink, setCardLink] = useState('')
    const [bannerDeleteClick, setBannerDeleteClick] = useState(false)
    const [showFieldSelection, setShowFieldSelection] = useState(false)
    const [showNextButton, setShowNextButton] = useState(true)
    const [showGeneralDetails, setShowGeneralDetails] = useState(true)
    const [showContactDetails, setShowContactDetails] = useState(false)
    const [showProfessionDetails, setShowProfessionDetails] = useState(false)
    const [showSocialMediaDetails, setShowSocialMediaDetails] = useState(false)

    const [logoImgFromUrl, setLogoImgFromUrl] = useState(false)
    const [logoImgFromLocal, setLogoImgFromLocal] = useState(false)
    const [logoImgDelete, setLogoImgDelete] = useState(false)

    const [profileImgFromUrl, setProfileImgFromUrl] = useState(false)
    const [profileImgFromLocal, setProfileImgFromLocal] = useState(false)
    const [profileImgDelete, setProfileImgDelete] = useState(false)

    const [cardForm, setCardForm] = useState({
        profile_image: false,
        brand_logo: false,
        banner_image: false,
        card_description: false,
        name: false,
        summary: false,
        email_ids: false,
        primary_email: false,
        secondary_email: false,
        alternate_email: false,
        phone: false,
        home_number: false,
        mobile_number: false,
        work_number: false,
        work_number_1: false,
        work_number_2: false,
        fax_number: false,
        addresses: false,
        work_address: false,
        home_address: false,
        company_name: false,
        job_title: false,
        facebook: false,
        linkedin: false,
        twitter: false,
        instagram: false,
        snapchat: false,
        other_links: false
    });

    useEffect(() => {
        if (!isNull(commonError)) {
            if (commonError.status === 401) {
                setLoading(false)
                displayErrorToast(commonError.message)
                dispatch({ type: APP_CONSTANTS.LOGOUT })
            }
        }
    }, [commonError])

    useEffect(() => {
        setLoading(true)
        dispatch(cardByAliasRequest(state))

    }, [])

    useEffect(() => {
        if (!isNull(cardByAliasSuccess)) {
            setLoading(false)
            if (!isNull(cardByAliasSuccess.data) && !isNull(cardByAliasSuccess.data.otherDetails)) {
                if (!isEmpty(cardByAliasSuccess.data.otherDetails.profileImage) && !cardByAliasSuccess.data.otherDetails.profileImage.includes('placeholder')) {
                    cardForm.profile_image = true
                    setProfileImgFromUrl(true)
                    setProfileImgFromLocal(false)
                    setProfileImgDelete(false)
                }
                if (!isEmpty(cardByAliasSuccess.data.otherDetails.brandLogoImg) && !cardByAliasSuccess.data.otherDetails.brandLogoImg.includes('placeholder')) {
                    cardForm.brand_logo = true
                    setLogoImgFromUrl(true)
                    setLogoImgDelete(false)
                    setLogoImgFromLocal(false)
                }
                if (!isEmpty(cardByAliasSuccess.data.cardName))
                    setCardName(cardByAliasSuccess.data.cardName)
                if (!isEmpty(cardByAliasSuccess.data.description)) {
                    cardForm.card_description = true;
                    setCardDescription(cardByAliasSuccess.data.description)
                }
                if (!isEmpty(cardByAliasSuccess.data.userAlias))
                    setCardLink(`${config.qrCodeUrl}/${cardByAliasSuccess.data.userAlias}`)
                if (!isEmpty(cardByAliasSuccess.data.otherDetails.firstName)) {
                    setFirstName(cardByAliasSuccess.data.otherDetails.firstName)
                    cardForm.name = true;
                }
                if (!isEmpty(cardByAliasSuccess.data.otherDetails.lastName)) {
                    setLastName(cardByAliasSuccess.data.otherDetails.lastName)
                    cardForm.name = true;
                }
                if (!isEmpty(cardByAliasSuccess.data.otherDetails.summary)) {
                    setSummary(cardByAliasSuccess.data.otherDetails.summary)
                    cardForm.summary = true;
                }
                if (!isEmpty(cardByAliasSuccess.data.otherDetails.email1)) {
                    setPrimaryEmail(cardByAliasSuccess.data.otherDetails.email1)
                    cardForm.email_ids = true
                    cardForm.primary_email = true
                }
                if (!isEmpty(cardByAliasSuccess.data.otherDetails.email2)) {
                    setSecondaryEmail(cardByAliasSuccess.data.otherDetails.email2)
                    cardForm.email_ids = true
                    cardForm.secondary_email = true
                }
                if (!isEmpty(cardByAliasSuccess.data.otherDetails.email3)) {
                    setAlternateEmail(cardByAliasSuccess.data.otherDetails.email3)
                    cardForm.email_ids = true
                    cardForm.alternate_email = true
                }
                if (!isEmpty(cardByAliasSuccess.data.otherDetails.homeNumber)) {
                    setHomePhoneNumber(cardByAliasSuccess.data.otherDetails.homeNumber)
                    cardForm.home_number = true
                    cardForm.phone = true
                }
                if (!isEmpty(cardByAliasSuccess.data.otherDetails.mobileNumber)) {
                    setMobilePhoneNumber(cardByAliasSuccess.data.otherDetails.mobileNumber)
                    cardForm.mobile_number = true
                    cardForm.phone = true
                }
                if (!isEmpty(cardByAliasSuccess.data.otherDetails.workNumber1)) {
                    setWorkNumber1(cardByAliasSuccess.data.otherDetails.workNumber1)
                    cardForm.work_number_1 = true
                    cardForm.work_number = true
                    cardForm.phone = true
                }
                if (!isEmpty(cardByAliasSuccess.data.otherDetails.workNumber1Ext)) {
                    setWorkNumberExt1(cardByAliasSuccess.data.otherDetails.workNumber1Ext)
                }
                if (!isEmpty(cardByAliasSuccess.data.otherDetails.workNumber2)) {
                    setWorkNumber2(cardByAliasSuccess.data.otherDetails.workNumber2)
                    cardForm.work_number_2 = true
                    cardForm.work_number = true
                    cardForm.phone = true
                }
                if (!isEmpty(cardByAliasSuccess.data.otherDetails.workNumber2Ext)) {
                    setWorkNumberExt2(cardByAliasSuccess.data.otherDetails.workNumber2Ext)
                }
                if (!isEmpty(cardByAliasSuccess.data.otherDetails.fax)) {
                    setFaxNumber(cardByAliasSuccess.data.otherDetails.fax)
                    cardForm.fax_number = true
                    cardForm.phone = true
                }
                if (!isEmpty(cardByAliasSuccess.data.otherDetails.homeAddress)) {
                    setHomeAddress(cardByAliasSuccess.data.otherDetails.homeAddress)
                    cardForm.addresses = true
                    cardForm.home_address = true
                }
                if (!isEmpty(cardByAliasSuccess.data.otherDetails.workAddress)) {
                    setWorkAddress(cardByAliasSuccess.data.otherDetails.workAddress)
                    cardForm.addresses = true
                    cardForm.work_address = true
                }
                if (!isEmpty(cardByAliasSuccess.data.otherDetails.companyName)) {
                    cardForm.company_name = true
                    setCompanyName(cardByAliasSuccess.data.otherDetails.companyName)
                }
                if (!isEmpty(cardByAliasSuccess.data.otherDetails.jobTitle)) {
                    setJobTitle(cardByAliasSuccess.data.otherDetails.jobTitle)
                    cardForm.job_title = true
                }
                if (!isEmpty(cardByAliasSuccess.data.otherDetails.facebookLink)) {
                    setFacebookLink(cardByAliasSuccess.data.otherDetails.facebookLink)
                    cardForm.facebook = true
                }
                if (!isEmpty(cardByAliasSuccess.data.otherDetails.instagramLink)) {
                    setInstagramLink(cardByAliasSuccess.data.otherDetails.instagramLink)
                    cardForm.instagram = true
                }
                if (!isEmpty(cardByAliasSuccess.data.otherDetails.linkedInLink)) {
                    setLinkedInLink(cardByAliasSuccess.data.otherDetails.linkedInLink)
                    cardForm.linkedin = true
                }
                if (!isEmpty(cardByAliasSuccess.data.otherDetails.snapchatLink)) {
                    setSnapchatLink(cardByAliasSuccess.data.otherDetails.snapchatLink)
                    cardForm.snapchat = true
                }
                if (!isEmpty(cardByAliasSuccess.data.otherDetails.twitterLink)) {
                    setTwitterLink(cardByAliasSuccess.data.otherDetails.twitterLink)
                    cardForm.twitter = true
                }
                if (!isEmpty(cardByAliasSuccess.data.otherDetails.otherSocialLink)) {
                    setOtherSocialLink(cardByAliasSuccess.data.otherDetails.otherSocialLink)
                    cardForm.other_links = true
                }

                setCardForm(oldValue => ({
                    ...oldValue,
                    ...cardForm
                }));
            }
        } else if (!isNull(cardByAliasError)) {
            setLoading(false)
        }
    }, [cardByAliasSuccess, cardByAliasError])

    useEffect(() => {
        if (updateCardDetailsReqStatus === API_CONSTANTS.SUCCESS_STATUS) {
            displaySuccessToast(updateCardDetailsSuccess.message)
            setTimeout(() => {
                navigate(-1)
                dispatch(clearCardDetailAct(''))
            }, 200)
            // dispatch(cardByAliasRequest(state))
        } else if (updateCardDetailsReqStatus === API_CONSTANTS.ERROR_STATUS) {
            setLastName(false)
            displayErrorToast(updateCardDetailsError.message)
        }
        if (updateCardDetailsReqStatus.length) {
            dispatch(updateCardDetailsRequestStatus(''))
        }
    }, [updateCardDetailsSuccess, updateCardDetailsError, updateCardDetailsReqStatus])

    const pickProfileImage = () => {
        const input = document.createElement('input');
        input.type = 'file';
        input.accept = 'image/*';
        input.click();

        input.onchange = e => {
            const file = e.target.files[0];
            setProfileImgFromLocal(true)
            setProfileImgDelete(false)
            setProfileImgFromUrl(false)
            setProfileImagePath(e.target.files[0])
            setProfileImageName(e.target.files[0].name)
            const reader = new FileReader();
            reader.onload = e => {
                setProfileImage(e.target.result);
            };
            reader.readAsDataURL(file);
        };
    };

    const pickBannerImage = () => {
        const input = document.createElement('input');
        input.type = 'file';
        input.accept = 'image/*';
        input.click();

        input.onchange = e => {
            const file = e.target.files[0];
            setBannerDeleteClick(false)
            setBannerImagePath(e.target.files[0])
            setBannerImageName(e.target.files[0].name)
            const reader = new FileReader();
            reader.onload = e => {
                setBannerImage(e.target.result);
            };
            reader.readAsDataURL(file);
        };
    };

    const pickBrandLogo = () => {
        const input = document.createElement('input');
        input.type = 'file';
        input.accept = 'image/*';
        input.click();

        input.onchange = e => {
            const file = e.target.files[0];
            setBrandImagePath(e.target.files[0])
            setBrandImageName(e.target.files[0].name)
            setLogoImgFromUrl(false)
            setLogoImgFromLocal(true)
            setLogoImgDelete(false)
            const reader = new FileReader();
            reader.onload = e => {
                setBrandImage(e.target.result);

            };
            reader.readAsDataURL(file);
        };
    };

    const handleUpdateClick = () => {
        if (isEmpty(cardName)) {
            displayErrorToast('Card name cannot be blank')
        } {
            let fd = new FormData()
            fd.append('digitalCardId', cardByAliasSuccess.data.digitalCardId)
            fd.append('digitalCardDetailId', cardByAliasSuccess.data.otherDetails.digitalCardDetailId)
            fd.append('firstName', firstName)
            fd.append('lastName', lastName)
            fd.append('cardName', cardName)
            if (!isEmpty(cardDescription))
                fd.append('description', cardDescription)
            fd.append('homeNumber', homePhoneNumber)
            fd.append('mobileNumber', mobilePhoneNumber)
            fd.append('fax', faxNumber)
            fd.append('workNumber1', workNumber1)
            fd.append('workNumber1Ext', workNumberExt1)
            fd.append('workNumber2', workNumber2)
            fd.append('workNumber2Ext', workNumberExt2)
            fd.append('homeAddress', homeAddress)
            fd.append('workAddress', workAddress)
            fd.append('email1', primaryEmail)
            fd.append('email2', secondaryEmail)
            fd.append('email3', alternateEmail)
            if (!isEmpty(companyName))
                fd.append('companyName', companyName)
            else fd.append('companyName', "")
            if (!isEmpty(jobTitle))
                fd.append('jobTitle', jobTitle)
            else fd.append('jobTitle', "")
            if (!isEmpty(facebookLink))
                fd.append('facebookLink', facebookLink)
            else {
                fd.append('facebookLink', "")
            }
            if (!isEmpty(twitterLink))
                fd.append('twitterLink', twitterLink)
            else fd.append('twitterLink', "")
            if (!isEmpty(instagramLink))
                fd.append('instagramLink', instagramLink)
            else fd.append('instagramLink', "")
            if (!isEmpty(linkedInLink))
                fd.append('linkedInLink', linkedInLink)
            else fd.append('linkedInLink', "")
            if (!isEmpty(snapchatLink))
                fd.append('snapchatLink', snapchatLink)
            else fd.append('snapchatLink', "")
            fd.append('otherSocialLink', JSON.stringify(otherSocialLink))
            if (profileImgDelete === true) {
                fd.append('profileImageDelete', 'YES')
            } else {
                if (profileImgFromLocal === true) {
                    if (!isNull(profileImage) && !isNull(profileImagePath) && !isEmpty(profileImageName)) {
                        fd.append('profileImage', profileImagePath, profileImageName)
                    }
                }
            }
            if (bannerDeleteClick === true) {
                fd.append('bannerImageDelete', 'YES')
            } else {
                if (!isNull(bannerImage) && !isNull(bannerImagePath) && !isEmpty(bannerImageName)) {
                    fd.append('bannerImage', bannerImagePath, bannerImageName)
                }
            }
            if (logoImgDelete === true) {
                fd.append('brandImageDelete', 'YES')
            } else {
                if (logoImgFromLocal === true) {
                    if (!isNull(brandImage) && !isNull(brandImagePath) && !isEmpty(brandImageName)) {
                        fd.append('brandLogoImg', brandImagePath, brandImageName)
                    }
                }
            }
            setLoading(true)
            dispatch(updateCardDetailsRequest(fd))
        }
    }

    const handleAddOtherLinkClick = () => {
        if (!isEmpty(otherLink)) {
            let currentList = otherSocialLink
            currentList.push(otherLink)
            setOtherSocialLink(currentList)
            setOtherLink('')
        } else {
            displayErrorToast('Other link field cannot be blank')
        }
    }

    const handleLinkDeleteClick = e => {
        let updatedItems = otherSocialLink.filter(item => item !== e)
        setOtherSocialLink(updatedItems)
    }

    const handleLinkEditClick = e => {
        let updatedItems = otherSocialLink.filter(item => item !== e)
        setOtherSocialLink(updatedItems)
        setOtherLink(e)
    }

    const handleBannerDeleteClick = () => {
        setBannerImage(null)
        setBannerImagePath(null)
        setBannerImageName('')
        setBannerDeleteClick(true)
    }

    const handleProfileDeleteClick = () => {
        setProfileImgFromLocal(false)
        setProfileImgFromUrl(false)
        setProfileImgDelete(true)
        setProfileImage(null);
        setProfileImagePath(null)
        setProfileImageName('')
        cardForm.profile_image = false
        setCardForm(oldValue => ({
            ...oldValue,
            ...cardForm
        }));
    }

    const handleBrandLogoDeleteClick = () => {
        setLogoImgFromUrl(false)
        setLogoImgFromLocal(false)
        setLogoImgDelete(true)
        setBrandImage(null);
        setBrandImagePath(null)
        setBrandImageName('')
        cardForm.brand_logo = false
        setCardForm(oldValue => ({
            ...oldValue,
            ...cardForm
        }));
    }

    const isBannerImageFromUrl = () => {
        let flag = false
        if (!isNull(cardByAliasSuccess) && !isNull(cardByAliasSuccess.data) && !isNull(cardByAliasSuccess.data.otherDetails)) {
            if ((!isEmpty(cardByAliasSuccess.data.otherDetails.bannerImage) && !cardByAliasSuccess.data.otherDetails.bannerImage.includes('placeholder'))) {
                if (!bannerDeleteClick) {
                    flag = true
                }
            }
        }
        return flag
    }

    const isProfileImageFromUrl = () => {
        let flag = false
        if (profileImgFromUrl)
            flag = true
        return flag
    }

    const isBrandLogoFromUrl = () => {
        let flag = false
        if (logoImgFromUrl)
            flag = true
        return flag
    }

    useEffect(() => {
        if (cardForm.primary_email === false && cardForm.secondary_email === false && cardForm.alternate_email === false) {
            cardForm.email_ids = false
            setCardForm(oldValue => ({
                ...oldValue,
                ...cardForm
            }));
        }
    }, [cardForm.primary_email, cardForm.secondary_email, cardForm.alternate_email])

    useEffect(() => {
        if (!cardForm.email_ids) {
            setPrimaryEmail('')
            setSecondaryEmail('')
            setAlternateEmail('')
            cardForm.primary_email = false
            cardForm.secondary_email = false
            cardForm.alternate_email = false
            setCardForm(oldValue => ({
                ...oldValue,
                ...cardForm
            }));
        }
    }, [cardForm.email_ids])

    useEffect(() => {
        if (!cardForm.addresses) {
            setWorkAddress('')
            setHomeAddress('')
            cardForm.work_address = false
            cardForm.home_address = false
            setCardForm(oldValue => ({
                ...oldValue,
                ...cardForm
            }));
        }
    }, [cardForm.addresses])

    useEffect(() => {
        if (cardForm.work_address === false && cardForm.home_address === false) {
            cardForm.addresses = false
            setCardForm(oldValue => ({
                ...oldValue,
                ...cardForm
            }));
        }
    }, [cardForm.work_address, cardForm.home_address])

    useEffect(() => {
        if (!cardForm.phone) {
            setHomePhoneNumber('')
            setMobilePhoneNumber('')
            setWorkNumber1('')
            setWorkNumberExt1('')
            setWorkNumber2('')
            setWorkNumberExt2('')
            setFaxNumber('')
            cardForm.home_number = false
            cardForm.mobile_number = false
            cardForm.work_number = false
            cardForm.work_number_1 = false
            cardForm.work_number_2 = false
            cardForm.fax_number = false
            setCardForm(oldValue => ({
                ...oldValue,
                ...cardForm
            }));
        }
    }, [cardForm.phone])

    useEffect(() => {
        if (!cardForm.work_number) {
            setWorkNumber1('')
            setWorkNumberExt1('')
            setWorkNumber2('')
            setWorkNumberExt2('')
            cardForm.work_number_1 = false
            cardForm.work_number_2 = false
            setCardForm(oldValue => ({
                ...oldValue,
                ...cardForm
            }));
        }
    }, [cardForm.work_number])

    useEffect(() => {
        if (cardForm.home_number === false && cardForm.work_number === false && cardForm.mobile_number === false && cardForm.fax_number === false) {
            cardForm.phone = false
            setCardForm(oldValue => ({
                ...oldValue,
                ...cardForm
            }));
        }
    }, [cardForm.home_number, cardForm.work_number, cardForm.mobile_number, cardForm.fax_number])

    useEffect(() => {
        if (cardForm.work_number_1 === false && cardForm.work_number_2 === false) {
            cardForm.work_number = false
            setCardForm(oldValue => ({
                ...oldValue,
                ...cardForm
            }));
        }
    }, [cardForm.work_number_1, cardForm.work_number_2])

    useEffect(() => {
        if (cardForm.profile_image === false) {
            handleProfileDeleteClick()
        }
    }, [cardForm.profile_image])

    useEffect(() => {
        if (cardForm.brand_logo === false) {
            handleBrandLogoDeleteClick()
        }
    }, [cardForm.brand_logo])

    return (
        <>
            <CommonScreenContent>
                <ScreenLabel label='Card Details' />
                {showFieldSelection && <CommonColorForm>
                    <FormFieldLabel className='up-bold-label mb-2' label='Select details to show on card' />
                    <div className="label-row-container" onClick={() => setShowGeneralDetails(!showGeneralDetails)}>
                        <FormFieldLabel label='General Details' />
                        <div className="label-icon-container">
                            <img src={showGeneralDetails ? require('../../../assets/ic_arrow_up.png') : require('../../../assets/ic_arrow_down.png')} className='img' />
                        </div>
                    </div>
                    {showGeneralDetails ? (<div>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={true}
                                    inputProps={{ 'aria-label': 'controlled' }}
                                    color="success"
                                    size="medium"
                                    disabled
                                />}
                            label='Card Nickname'
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={cardForm.name}
                                    onChange={e => {
                                        cardForm.name = e.target.checked
                                        setCardForm(oldValue => ({
                                            ...oldValue,
                                            ...cardForm
                                        }));
                                    }}
                                    inputProps={{ 'aria-label': 'controlled' }}
                                    color="success"
                                    size="medium"
                                />}
                            label='Name'
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={cardForm.summary}
                                    onChange={e => {
                                        cardForm.summary = e.target.checked
                                        setCardForm(oldValue => ({
                                            ...oldValue,
                                            ...cardForm
                                        }));
                                    }}
                                    inputProps={{ 'aria-label': 'controlled' }}
                                    color="success"
                                    size="medium"
                                />}
                            label='Summary'
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={cardForm.profile_image}
                                    onChange={e => {
                                        cardForm.profile_image = e.target.checked
                                        setCardForm(oldValue => ({
                                            ...oldValue,
                                            ...cardForm
                                        }));
                                    }}
                                    inputProps={{ 'aria-label': 'controlled' }}
                                    color="success"
                                    size="medium"
                                />}
                            label='Profile Image'
                        />
                        {/* <FormControlLabel
                            control={
                                <Checkbox
                                    checked={cardForm.banner_image}
                                    onChange={e => {
                                        cardForm.banner_image = e.target.checked
                                        setCardForm(oldValue => ({
                                            ...oldValue,
                                            ...cardForm
                                        }));
                                    }}
                                    inputProps={{ 'aria-label': 'controlled' }}
                                    color="success"
                                    size="medium"
                                />}
                            label='Banner Image'
                        /> */}
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={cardForm.brand_logo}
                                    onChange={e => {
                                        cardForm.brand_logo = e.target.checked
                                        setCardForm(oldValue => ({
                                            ...oldValue,
                                            ...cardForm
                                        }));
                                    }}
                                    inputProps={{ 'aria-label': 'controlled' }}
                                    color="success"
                                    size="medium"
                                />}
                            label='Brand Logo'
                        />
                    </div>) : null}

                    <div className="label-row-container" onClick={() => setShowContactDetails(!showContactDetails)}>
                        <FormFieldLabel label='Contact Details' />
                        <div className="label-icon-container">
                            <img src={showContactDetails ? require('../../../assets/ic_arrow_up.png') : require('../../../assets/ic_arrow_down.png')} className='img' />
                        </div>
                    </div>
                    {showContactDetails ? (<div>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={cardForm.email_ids}
                                    onChange={e => {
                                        cardForm.email_ids = e.target.checked
                                        setCardForm(oldValue => ({
                                            ...oldValue,
                                            ...cardForm
                                        }));
                                    }}
                                    inputProps={{ 'aria-label': 'controlled' }}
                                    color="success"
                                    size="medium"
                                />}
                            label='Email'
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={cardForm.phone}
                                    onChange={e => {
                                        cardForm.phone = e.target.checked
                                        setCardForm(oldValue => ({
                                            ...oldValue,
                                            ...cardForm
                                        }));
                                    }}
                                    inputProps={{ 'aria-label': 'controlled' }}
                                    color="success"
                                    size="medium"
                                />}
                            label='Phone'
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={cardForm.addresses}
                                    onChange={e => {
                                        cardForm.addresses = e.target.checked
                                        setCardForm(oldValue => ({
                                            ...oldValue,
                                            ...cardForm
                                        }));
                                    }}
                                    inputProps={{ 'aria-label': 'controlled' }}
                                    color="success"
                                    size="medium"
                                />}
                            label='Address'
                        />
                        {cardForm.email_ids && <div>
                            <FormFieldLabel label='Email Ids' />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={cardForm.primary_email}
                                        onChange={e => {
                                            cardForm.primary_email = e.target.checked
                                            setCardForm(oldValue => ({
                                                ...oldValue,
                                                ...cardForm
                                            }));
                                        }}
                                        inputProps={{ 'aria-label': 'controlled' }}
                                        color="success"
                                        size="medium"
                                    />}
                                label='Primary Email'
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={cardForm.secondary_email}
                                        onChange={e => {
                                            cardForm.secondary_email = e.target.checked
                                            setCardForm(oldValue => ({
                                                ...oldValue,
                                                ...cardForm
                                            }));
                                        }}
                                        inputProps={{ 'aria-label': 'controlled' }}
                                        color="success"
                                        size="medium"
                                    />}
                                label='Secondary Email'
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={cardForm.alternate_email}
                                        onChange={e => {
                                            cardForm.alternate_email = e.target.checked
                                            setCardForm(oldValue => ({
                                                ...oldValue,
                                                ...cardForm
                                            }));
                                        }}
                                        inputProps={{ 'aria-label': 'controlled' }}
                                        color="success"
                                        size="medium"
                                    />}
                                label='Alternate Email'
                            />
                        </div>}
                        {cardForm.phone && <div>
                            <FormFieldLabel label='Phone Number' />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={cardForm.home_number}
                                        onChange={e => {
                                            cardForm.home_number = e.target.checked
                                            setCardForm(oldValue => ({
                                                ...oldValue,
                                                ...cardForm
                                            }));
                                        }}
                                        inputProps={{ 'aria-label': 'controlled' }}
                                        color="success"
                                        size="medium"
                                    />}
                                label='Home Number'
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={cardForm.mobile_number}
                                        onChange={e => {
                                            cardForm.mobile_number = e.target.checked
                                            setCardForm(oldValue => ({
                                                ...oldValue,
                                                ...cardForm
                                            }));
                                        }}
                                        inputProps={{ 'aria-label': 'controlled' }}
                                        color="success"
                                        size="medium"
                                    />}
                                label='Mobile Number'
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={cardForm.work_number}
                                        onChange={e => {
                                            cardForm.work_number = e.target.checked
                                            setCardForm(oldValue => ({
                                                ...oldValue,
                                                ...cardForm
                                            }));
                                        }}
                                        inputProps={{ 'aria-label': 'controlled' }}
                                        color="success"
                                        size="medium"
                                    />}
                                label='Work Number'
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={cardForm.fax_number}
                                        onChange={e => {
                                            cardForm.fax_number = e.target.checked
                                            setCardForm(oldValue => ({
                                                ...oldValue,
                                                ...cardForm
                                            }));
                                        }}
                                        inputProps={{ 'aria-label': 'controlled' }}
                                        color="success"
                                        size="medium"
                                    />}
                                label='Fax Number'
                            />
                        </div>}
                        {cardForm.work_number && <div>
                            <FormFieldLabel label='Work Phone Numbers' />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={cardForm.work_number_1}
                                        onChange={e => {
                                            cardForm.work_number_1 = e.target.checked
                                            setCardForm(oldValue => ({
                                                ...oldValue,
                                                ...cardForm
                                            }));
                                        }}
                                        inputProps={{ 'aria-label': 'controlled' }}
                                        color="success"
                                        size="medium"
                                    />}
                                label='Work Number 1'
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={cardForm.work_number_2}
                                        onChange={e => {
                                            cardForm.work_number_2 = e.target.checked
                                            setCardForm(oldValue => ({
                                                ...oldValue,
                                                ...cardForm
                                            }));
                                        }}
                                        inputProps={{ 'aria-label': 'controlled' }}
                                        color="success"
                                        size="medium"
                                    />}
                                label='Work Number 2'
                            />
                        </div>}
                        {cardForm.addresses && <div>
                            <FormFieldLabel label='Addresses' />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={cardForm.home_address}
                                        onChange={e => {
                                            cardForm.home_address = e.target.checked
                                            setCardForm(oldValue => ({
                                                ...oldValue,
                                                ...cardForm
                                            }));
                                        }}
                                        inputProps={{ 'aria-label': 'controlled' }}
                                        color="success"
                                        size="medium"
                                    />}
                                label='Home Address'
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={cardForm.work_address}
                                        onChange={e => {
                                            cardForm.work_address = e.target.checked
                                            setCardForm(oldValue => ({
                                                ...oldValue,
                                                ...cardForm
                                            }));
                                        }}
                                        inputProps={{ 'aria-label': 'controlled' }}
                                        color="success"
                                        size="medium"
                                    />}
                                label='Work Address'
                            />
                        </div>}
                    </div>) : null}
                    <div className="label-row-container" onClick={() => setShowProfessionDetails(!showProfessionDetails)}>
                        <FormFieldLabel label='Profession Details' />
                        <div className="label-icon-container">
                            <img src={showProfessionDetails ? require('../../../assets/ic_arrow_up.png') : require('../../../assets/ic_arrow_down.png')} className='img' />
                        </div>
                    </div>
                    {showProfessionDetails ? (<div>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={cardForm.company_name}
                                    onChange={e => {
                                        cardForm.company_name = e.target.checked
                                        setCardForm(oldValue => ({
                                            ...oldValue,
                                            ...cardForm
                                        }));
                                    }}
                                    inputProps={{ 'aria-label': 'controlled' }}
                                    color="success"
                                    size="medium"
                                />}
                            label='Company Name'
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={cardForm.job_title}
                                    onChange={e => {
                                        cardForm.job_title = e.target.checked
                                        setCardForm(oldValue => ({
                                            ...oldValue,
                                            ...cardForm
                                        }));
                                    }}
                                    inputProps={{ 'aria-label': 'controlled' }}
                                    color="success"
                                    size="medium"
                                />}
                            label='Job Title'
                        />
                    </div>) : null}
                    <div className="label-row-container" onClick={() => setShowSocialMediaDetails(!showSocialMediaDetails)}>
                        <FormFieldLabel label='Social Media Details' />
                        <div className="label-icon-container">
                            <img src={showSocialMediaDetails ? require('../../../assets/ic_arrow_up.png') : require('../../../assets/ic_arrow_down.png')} className='img' />
                        </div>
                    </div>
                    {showSocialMediaDetails ? (<div>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={cardForm.facebook}
                                    onChange={e => {
                                        cardForm.facebook = e.target.checked
                                        setCardForm(oldValue => ({
                                            ...oldValue,
                                            ...cardForm
                                        }));
                                    }}
                                    inputProps={{ 'aria-label': 'controlled' }}
                                    color="success"
                                    size="medium"
                                />}
                            label='Facebook'
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={cardForm.linkedin}
                                    onChange={e => {
                                        cardForm.linkedin = e.target.checked
                                        setCardForm(oldValue => ({
                                            ...oldValue,
                                            ...cardForm
                                        }));
                                    }}
                                    inputProps={{ 'aria-label': 'controlled' }}
                                    color="success"
                                    size="medium"
                                />}
                            label='LinkedIn'
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={cardForm.twitter}
                                    onChange={e => {
                                        cardForm.twitter = e.target.checked
                                        setCardForm(oldValue => ({
                                            ...oldValue,
                                            ...cardForm
                                        }));
                                    }}
                                    inputProps={{ 'aria-label': 'controlled' }}
                                    color="success"
                                    size="medium"
                                />}
                            label='X (Twitter)'
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={cardForm.instagram}
                                    onChange={e => {
                                        cardForm.instagram = e.target.checked
                                        setCardForm(oldValue => ({
                                            ...oldValue,
                                            ...cardForm
                                        }));
                                    }}
                                    inputProps={{ 'aria-label': 'controlled' }}
                                    color="success"
                                    size="medium"
                                />}
                            label='Instagram'
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={cardForm.snapchat}
                                    onChange={e => {
                                        cardForm.snapchat = e.target.checked
                                        setCardForm(oldValue => ({
                                            ...oldValue,
                                            ...cardForm
                                        }));
                                    }}
                                    inputProps={{ 'aria-label': 'controlled' }}
                                    color="success"
                                    size="medium"
                                />}
                            label='Snapchat'
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={cardForm.other_links}
                                    onChange={e => {
                                        cardForm.other_links = e.target.checked
                                        setCardForm(oldValue => ({
                                            ...oldValue,
                                            ...cardForm
                                        }));
                                    }}
                                    inputProps={{ 'aria-label': 'controlled' }}
                                    color="success"
                                    size="medium"
                                />}
                            label='Other Links'
                        />
                    </div>) : null}
                    <ButtonRow>
                        {!showNextButton && <CommonButton isInverted label='Cancel' onClick={() => {
                            setShowFieldSelection(false)
                        }} />}
                        <CommonButton isInverted label='Done' onClick={() => {
                            setShowFieldSelection(false)
                        }} />
                    </ButtonRow>
                </CommonColorForm>}
                {!showFieldSelection && <div className="row">
                    <div className="col-sm-6">
                        <CommonColorForm>
                            {!isNull(cardByAliasSuccess) && !isNull(cardByAliasSuccess.data) && !isNull(cardByAliasSuccess.data.otherDetails) && <div>
                                {/* {(!isEmpty(cardByAliasSuccess.data.otherDetails.bannerImage) && !cardByAliasSuccess.data.otherDetails.bannerImage.includes('placeholder')) ? (
                                    <div className="banner">
                                        {!bannerDeleteClick && <img src={`${config.imageUrl}${cardByAliasSuccess.data.otherDetails.bannerImage}`} alt="Banner Image" />}
                                        {bannerDeleteClick && <img src={require('../../../assets/banner_placeholder_2.png')} alt="Banner Image" />}
                                        <div className="edit-image-button" onClick={pickBannerImage}>
                                            <img src={require('../../../assets/ic_circle_edit.png')} className='img' />
                                        </div>
                                        <div className="delete-image-button" onClick={handleBannerDeleteClick}>
                                            <img src={require('../../../assets/ic_delete_circle.png')} className='img' />
                                        </div>
                                    </div>
                                ) : (
                                    <div>
                                        {bannerImage ? (
                                            <div className="banner">
                                                <img src={bannerImage} alt="Banner Image" />
                                                <div className="edit-image-button" onClick={pickBannerImage}>
                                                    <img src={require('../../../assets/ic_circle_edit.png')} className='img' />
                                                </div>
                                                <div className="delete-image-button" onClick={handleBannerDeleteClick}>
                                                    <img src={require('../../../assets/ic_delete_circle.png')} className='img' />
                                                </div>
                                            </div>
                                        ) : (
                                            <div className="banner">
                                                <img src={require('../../../assets/banner_placeholder_2.png')} alt="Banner Image" />
                                                <div className="edit-image-button" onClick={pickBannerImage}>
                                                    <img src={require('../../../assets/ic_circle_edit.png')} className='img' />
                                                </div>
                                                <div className="delete-image-button" onClick={handleBannerDeleteClick}>
                                                    <img src={require('../../../assets/ic_delete_circle.png')} className='img' />
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                )} */}
                            </div>}
                            {!isNull(cardByAliasSuccess) && !isNull(cardByAliasSuccess.data) && !isNull(cardByAliasSuccess.data.otherDetails) && <div>
                                {cardForm.brand_logo && <div>
                                    <div className="ac-brandLogo">
                                        {logoImgFromUrl && <img src={`${config.imageUrl}${cardByAliasSuccess.data.otherDetails.brandLogoImg}`} className="img" />}
                                        {logoImgFromLocal && <img src={brandImage} className="img" />}
                                        {logoImgDelete && <img src={require('../../../assets/brand_placeholder.png')} className="img" />}
                                        <div className="edit-image-button-bl" onClick={pickBrandLogo}>
                                            <img src={require('../../../assets/ic_circle_edit.png')} className='img' />
                                        </div>
                                        <div className="delete-image-button-bl" onClick={handleBrandLogoDeleteClick}>
                                            <img src={require('../../../assets/ic_delete_circle.png')} className='img' />
                                        </div>
                                    </div>
                                </div>}
                                {cardForm.profile_image && <div>
                                    <div className="profile">
                                        {profileImgFromUrl && <img src={`${config.imageUrl}${cardByAliasSuccess.data.otherDetails.profileImage}`} className="img" />}
                                        {profileImgFromLocal && <img src={profileImage} className="img" />}
                                        {profileImgDelete && <img src={require('../../../assets/profile_placeholder_1.png')} className="img" />}
                                        <div className="edit-image-button" onClick={pickProfileImage}>
                                            <img src={require('../../../assets/ic_circle_edit.png')} className='img' />
                                        </div>
                                        <div className="delete-image-button" onClick={handleProfileDeleteClick}>
                                            <img src={require('../../../assets/ic_delete_circle.png')} className='img' />
                                        </div>
                                    </div>
                                </div>}
                            </div>}

                            <FormFieldLabel className='up-bold-label mb-2' label='Card Details' />
                            <Paper elevation={3} style={{ background: '#fbfbfb' }} className='ac-card'>
                                <FormFieldLabel className='mb-3' label='General Details' />
                                <CommonInputFloatFull
                                    id='cardName'
                                    onChange={e => setCardName(e.target.value)}
                                    placeholder='Card Nickname'
                                    type='text'
                                    value={cardName}
                                    isRequired={true}
                                    isSubtext={true}
                                    subText="A nickname to remember your card"
                                />
                                <CommonInputFloatFull id='cardAlias'
                                    onChange={e => setCardLink(e.target.value)}
                                    placeholder='Card Link'
                                    type='text'
                                    value={cardLink}
                                    isDisable={true}
                                    isSubtext={true}
                                    subText='Your connections will find your ClickTran card here.'
                                />
                                {cardForm.name && <CommonInputRow>
                                    <CommonInputFloatHalf
                                        id='firstName'
                                        onChange={e => setFirstName(e.target.value)}
                                        placeholder='First Name'
                                        type='text'
                                        value={firstName}
                                        isRequired={true}
                                    />
                                    <CommonInputFloatHalf
                                        id='lastName'
                                        onChange={e => setLastName(e.target.value)}
                                        placeholder='Last Name'
                                        type='text'
                                        value={lastName}
                                        isRequired={true}
                                    />
                                </CommonInputRow>}
                                {
                                    cardForm.summary &&
                                    <CommonInputRow>
                                        <CommonInputFloatFull id='summary'
                                            onChange={e => setSummary(e.target.value)}
                                            placeholder='Summary'
                                            type='text'
                                            value={summary}
                                            multiline
                                            rows={3}
                                        />
                                        <div className="input-icon-container" onClick={() => {
                                            if (isEmpty(summary)) {
                                                displayErrorToast('Summary is empty. Click delete to remove')
                                            } else {
                                                displaySuccessToast('Summary added successfully')
                                            }
                                        }}>
                                            <img alt="" src={require('../../../assets/ic_done.png')} className='img' />
                                        </div>
                                        <div className="ac-social-link-img-container" onClick={() => {
                                            cardForm.summary = false;
                                            setSummary('')
                                            setCardForm(oldValue => ({
                                                ...oldValue,
                                                ...cardForm
                                            }));
                                        }}>
                                            <img alt="" src={require('../../../assets/ic_delete.png')} className='img' />
                                        </div>
                                    </CommonInputRow>
                                }
                            </Paper>
                            {(cardForm.email_ids || cardForm.phone || cardForm.addresses) &&
                                <Paper elevation={3} style={{ background: '#fbfbfb' }} className='ac-card'>
                                    <FormFieldLabel className='mb-3' label='Contact Details' />
                                    {cardForm.email_ids && <FormFieldLabel label='Email Ids' />}
                                    {
                                        cardForm.primary_email &&
                                        <CommonInputRow>
                                            <CommonInputFloatFull id='primaryEmail'
                                                onChange={e => setPrimaryEmail(e.target.value)}
                                                placeholder='Primary Email'
                                                type='text'
                                                value={primaryEmail}
                                            />
                                            <div className="input-icon-container" onClick={() => {
                                                if (isEmpty(primaryEmail)) {
                                                    displayErrorToast('Primary email is empty. Click delete to remove.')
                                                } else {
                                                    displaySuccessToast('Primary email id added successfully')
                                                }
                                            }}>
                                                <img alt="" src={require('../../../assets/ic_done.png')} className='img' />
                                            </div>
                                            <div className="ac-social-link-img-container" onClick={() => {
                                                cardForm.primary_email = false;
                                                setPrimaryEmail('')
                                                setCardForm(oldValue => ({
                                                    ...oldValue,
                                                    ...cardForm
                                                }));
                                            }}>
                                                <img alt="" src={require('../../../assets/ic_delete.png')} className='img' />
                                            </div>
                                        </CommonInputRow>
                                    }
                                    {
                                        cardForm.secondary_email &&
                                        <CommonInputRow>
                                            <CommonInputFloatFull id='secondaryEmail'
                                                onChange={e => setSecondaryEmail(e.target.value)}
                                                placeholder='Secondary Email'
                                                type='text'
                                                value={secondaryEmail}
                                            />
                                            <div className="input-icon-container" onClick={() => {
                                                if (isEmpty(secondaryEmail)) {
                                                    displayErrorToast('Secondary email is empty. Click delete to remove.')
                                                } else {
                                                    displaySuccessToast('Secondary email id added successfully')
                                                }
                                            }}>
                                                <img alt="" src={require('../../../assets/ic_done.png')} className='img' />
                                            </div>
                                            <div className="ac-social-link-img-container" onClick={() => {
                                                cardForm.secondary_email = false;
                                                setSecondaryEmail('')
                                                setCardForm(oldValue => ({
                                                    ...oldValue,
                                                    ...cardForm
                                                }));
                                            }}>
                                                <img alt="" src={require('../../../assets/ic_delete.png')} className='img' />
                                            </div>
                                        </CommonInputRow>
                                    }
                                    {
                                        cardForm.alternate_email &&
                                        <CommonInputRow>
                                            <CommonInputFloatFull id='alternateEmail'
                                                onChange={e => setAlternateEmail(e.target.value)}
                                                placeholder='Alternate Email'
                                                type='text'
                                                value={alternateEmail}
                                            />
                                            <div className="input-icon-container" onClick={() => {
                                                if (isEmpty(alternateEmail)) {
                                                    displayErrorToast('Alternate email is empty. Click delete to remove.')
                                                } else {
                                                    displaySuccessToast('Alternate email id added successfully')
                                                }
                                            }}>
                                                <img alt="" src={require('../../../assets/ic_done.png')} className='img' />
                                            </div>
                                            <div className="ac-social-link-img-container" onClick={() => {
                                                cardForm.alternate_email = false;
                                                setAlternateEmail('')
                                                setCardForm(oldValue => ({
                                                    ...oldValue,
                                                    ...cardForm
                                                }));
                                            }}>
                                                <img alt="" src={require('../../../assets/ic_delete.png')} className='img' />
                                            </div>
                                        </CommonInputRow>
                                    }
                                    {cardForm.addresses && <FormFieldLabel label='Addresses' />}
                                    {
                                        cardForm.work_address &&
                                        <CommonInputRow>
                                            <CommonInputFloatFull id='workAddress'
                                                onChange={e => setWorkAddress(e.target.value)}
                                                placeholder='Work Address'
                                                type='text'
                                                value={workAddress}
                                            />
                                            <div className="input-icon-container" onClick={() => {
                                                if (isEmpty(workAddress)) {
                                                    displayErrorToast('Work address is empty. Click delete to remove.')
                                                } else {
                                                    displaySuccessToast('Work address is added successfully')
                                                }
                                            }}>
                                                <img alt="" src={require('../../../assets/ic_done.png')} className='img' />
                                            </div>
                                            <div className="ac-social-link-img-container" onClick={() => {
                                                cardForm.work_address = false;
                                                setWorkAddress('')
                                                setCardForm(oldValue => ({
                                                    ...oldValue,
                                                    ...cardForm
                                                }));
                                            }}>
                                                <img alt="" src={require('../../../assets/ic_delete.png')} className='img' />
                                            </div>
                                        </CommonInputRow>
                                    }
                                    {
                                        cardForm.home_address &&
                                        <CommonInputRow>
                                            <CommonInputFloatFull id='homeAddress'
                                                onChange={e => setHomeAddress(e.target.value)}
                                                placeholder='Home Address'
                                                type='text'
                                                value={homeAddress}
                                            />
                                            <div className="input-icon-container" onClick={() => {
                                                if (isEmpty(homeAddress)) {
                                                    displayErrorToast('Home address is empty. Click delete to remove.')
                                                } else {
                                                    displaySuccessToast('Home address is added successfully')
                                                }
                                            }}>
                                                <img alt="" src={require('../../../assets/ic_done.png')} className='img' />
                                            </div>
                                            <div className="ac-social-link-img-container" onClick={() => {
                                                cardForm.home_address = false;
                                                setHomeAddress('')
                                                setCardForm(oldValue => ({
                                                    ...oldValue,
                                                    ...cardForm
                                                }));
                                            }}>
                                                <img alt="" src={require('../../../assets/ic_delete.png')} className='img' />
                                            </div>
                                        </CommonInputRow>
                                    }
                                    {cardForm.phone && <FormFieldLabel label='Phone Numbers' />}
                                    {
                                        cardForm.home_number &&
                                        <CommonInputRow>
                                            <CommonInputFloatFull id='homeNumber'
                                                onChange={e => setHomePhoneNumber(e.target.value)}
                                                placeholder='Home Number'
                                                type='tel'
                                                value={homePhoneNumber}
                                            />
                                            <div className="input-icon-container" onClick={() => {
                                                if (isEmpty(homePhoneNumber)) {
                                                    displayErrorToast('Home phone number is empty. Click delete to remove.')
                                                } else {
                                                    displaySuccessToast('Home phone number is added successfully')
                                                }
                                            }}>
                                                <img alt="" src={require('../../../assets/ic_done.png')} className='img' />
                                            </div>
                                            <div className="ac-social-link-img-container" onClick={() => {
                                                cardForm.home_number = false;
                                                setHomePhoneNumber('')
                                                setCardForm(oldValue => ({
                                                    ...oldValue,
                                                    ...cardForm
                                                }));
                                            }}>
                                                <img alt="" src={require('../../../assets/ic_delete.png')} className='img' />
                                            </div>
                                        </CommonInputRow>
                                    }
                                    {
                                        cardForm.mobile_number &&
                                        <CommonInputRow>
                                            <CommonInputFloatFull id='mobileNumber'
                                                onChange={e => setMobilePhoneNumber(e.target.value)}
                                                placeholder='Mobile Number'
                                                type='tel'
                                                value={mobilePhoneNumber}
                                            />
                                            <div className="input-icon-container" onClick={() => {
                                                if (isEmpty(mobilePhoneNumber)) {
                                                    displayErrorToast('Mobile number is empty. Click delete to remove.')
                                                } else {
                                                    displaySuccessToast('Mobile number is added successfully')
                                                }
                                            }}>
                                                <img alt="" src={require('../../../assets/ic_done.png')} className='img' />
                                            </div>
                                            <div className="ac-social-link-img-container" onClick={() => {
                                                cardForm.mobile_number = false;
                                                setMobilePhoneNumber('')
                                                setCardForm(oldValue => ({
                                                    ...oldValue,
                                                    ...cardForm
                                                }));
                                            }}>
                                                <img alt="" src={require('../../../assets/ic_delete.png')} className='img' />
                                            </div>
                                        </CommonInputRow>
                                    }
                                    {
                                        cardForm.work_number_1 &&
                                        <CommonInputRow>
                                            <CommonInputFloatFull
                                                classname="field-mb-0"
                                                id='workNumber1'
                                                onChange={e => setWorkNumber1(e.target.value)}
                                                placeholder='Work Number 1'
                                                type='number'
                                                value={workNumber1} />
                                            <div className="phone-ext-container ms-2">
                                                <CommonInputFloatFull
                                                    classname="field-mb-0"
                                                    id='workNumberExt1'
                                                    onChange={e => setWorkNumberExt1(e.target.value)}
                                                    placeholder='Ext.'
                                                    type='number'
                                                    value={workNumberExt1}
                                                    color="success" />
                                            </div>
                                            <div className="input-icon-container" onClick={() => {
                                                if (isEmpty(workNumber1)) {
                                                    displayErrorToast('Work number is empty. Click delete to remove.')
                                                } else {
                                                    displaySuccessToast('Work number is added successfully')
                                                }
                                            }}>
                                                <img alt="" src={require('../../../assets/ic_done.png')} className='img' />
                                            </div>
                                            <div className="ac-social-link-img-container" onClick={() => {
                                                cardForm.work_number_1 = false;
                                                setWorkNumber1('')
                                                setWorkNumberExt1('')
                                                setCardForm(oldValue => ({
                                                    ...oldValue,
                                                    ...cardForm
                                                }));
                                            }}>
                                                <img alt="" src={require('../../../assets/ic_delete.png')} className='img' />
                                            </div>
                                        </CommonInputRow>
                                    }
                                    {
                                        cardForm.work_number_2 &&
                                        <CommonInputRow>
                                            <CommonInputFloatFull
                                                classname="field-mb-0"
                                                id='workNumber2'
                                                onChange={e => setWorkNumber2(e.target.value)}
                                                placeholder='Work Number 2'
                                                type='number'
                                                value={workNumber2} />
                                            <div className="phone-ext-container ms-2">
                                                <CommonInputFloatFull
                                                    classname="field-mb-0"
                                                    id='workNumberExt2'
                                                    onChange={e => setWorkNumberExt2(e.target.value)}
                                                    placeholder='Ext.'
                                                    type='number'
                                                    value={workNumberExt2}
                                                    color="success" />
                                            </div>
                                            <div className="input-icon-container" onClick={() => {
                                                if (isEmpty(workNumber2)) {
                                                    displayErrorToast('Work number is empty. Click delete to remove.')
                                                } else {
                                                    displaySuccessToast('Work number is added successfully')
                                                }
                                            }}>
                                                <img alt="" src={require('../../../assets/ic_done.png')} className='img' />
                                            </div>
                                            <div className="ac-social-link-img-container" onClick={() => {
                                                cardForm.work_number_2 = false;
                                                setWorkNumber2('')
                                                setWorkNumberExt2('')
                                                setCardForm(oldValue => ({
                                                    ...oldValue,
                                                    ...cardForm
                                                }));
                                            }}>
                                                <img alt="" src={require('../../../assets/ic_delete.png')} className='img' />
                                            </div>
                                        </CommonInputRow>
                                    }
                                    {
                                        cardForm.fax_number &&
                                        <CommonInputRow>
                                            <CommonInputFloatFull id='faxNumber'
                                                onChange={e => setFaxNumber(e.target.value)}
                                                placeholder='Fax Number'
                                                type='tel'
                                                value={faxNumber}
                                            />
                                            <div className="input-icon-container" onClick={() => {
                                                if (isEmpty(faxNumber)) {
                                                    displayErrorToast('Fax number is empty. Click delete to remove.')
                                                } else {
                                                    displaySuccessToast('Fax number is added successfully')
                                                }
                                            }}>
                                                <img alt="" src={require('../../../assets/ic_done.png')} className='img' />
                                            </div>
                                            <div className="ac-social-link-img-container" onClick={() => {
                                                cardForm.fax_number = false;
                                                setFaxNumber('')
                                                setCardForm(oldValue => ({
                                                    ...oldValue,
                                                    ...cardForm
                                                }));
                                            }}>
                                                <img alt="" src={require('../../../assets/ic_delete.png')} className='img' />
                                            </div>
                                        </CommonInputRow>
                                    }
                                </Paper>}
                            {(cardForm.company_name || cardForm.job_title) &&
                                <Paper elevation={3} style={{ background: '#fbfbfb' }} className='ac-card'>
                                    <FormFieldLabel label='Profession Details' />
                                    {
                                        cardForm.company_name &&
                                        <CommonInputRow>
                                            <CommonInputFloatFull id='companyName'
                                                onChange={e => setCompanyName(e.target.value)}
                                                placeholder='Company Name'
                                                type='text'
                                                value={companyName}
                                            />
                                            <div className="input-icon-container" onClick={() => {
                                                if (isEmpty(companyName)) {
                                                    displayErrorToast('Company name is empty. Click delete to remove.')
                                                } else {
                                                    displaySuccessToast('Company name added successfully')
                                                }
                                            }}>
                                                <img alt="" src={require('../../../assets/ic_done.png')} className='img' />
                                            </div>
                                            <div className="ac-social-link-img-container" onClick={() => {
                                                cardForm.company_name = false;
                                                setCompanyName('')
                                                setCardForm(oldValue => ({
                                                    ...oldValue,
                                                    ...cardForm
                                                }));
                                            }}>
                                                <img alt="" src={require('../../../assets/ic_delete.png')} className='img' />
                                            </div>
                                        </CommonInputRow>
                                    }
                                    {
                                        cardForm.job_title &&
                                        <CommonInputRow>
                                            <CommonInputFloatFull id='jobTitle'
                                                onChange={e => setJobTitle(e.target.value)}
                                                placeholder='Job Title'
                                                type='text'
                                                value={jobTitle}
                                            />
                                            <div className="input-icon-container" onClick={() => {
                                                if (isEmpty(jobTitle)) {
                                                    displayErrorToast('Job title is empty. Click delete to remove.')
                                                } else {
                                                    displaySuccessToast('Job title added successfully')
                                                }
                                            }}>
                                                <img alt="" src={require('../../../assets/ic_done.png')} className='img' />
                                            </div>
                                            <div className="ac-social-link-img-container" onClick={() => {
                                                cardForm.job_title = false;
                                                setJobTitle('')
                                                setCardForm(oldValue => ({
                                                    ...oldValue,
                                                    ...cardForm
                                                }));
                                            }}>
                                                <img alt="" src={require('../../../assets/ic_delete.png')} className='img' />
                                            </div>
                                        </CommonInputRow>
                                    }
                                </Paper>}
                            {(cardForm.facebook || cardForm.linkedin || cardForm.twitter || cardForm.instagram || cardForm.snapchat || cardForm.other_links || !isEmpty(otherSocialLink)) &&
                                <Paper elevation={3} style={{ background: '#fbfbfb' }} className='ac-card'>
                                    <FormFieldLabel label='Social Media Details' />
                                    {
                                        cardForm.facebook && <CommonInputRow>
                                            <CommonInputFloatFull
                                                id='facebookUserName'
                                                onChange={e => setFacebookLink(e.target.value)}
                                                placeholder='Facebook Profile Link'
                                                type='text'
                                                value={facebookLink}
                                            />
                                            <div className="input-icon-container" onClick={() => {
                                                if (isEmpty(facebookLink)) {
                                                    displayErrorToast('Facebook link is empty. Click delete to remove.')
                                                } else {
                                                    displaySuccessToast('Facebook linke added successfully')
                                                }
                                            }}>
                                                <img alt="" src={require('../../../assets/ic_done.png')} className='img' />
                                            </div>
                                            <div className="ac-social-link-img-container" onClick={() => {
                                                cardForm.facebook = false;
                                                setFacebookLink('')
                                                setCardForm(oldValue => ({
                                                    ...oldValue,
                                                    ...cardForm
                                                }));
                                            }}>
                                                <img alt="" src={require('../../../assets/ic_delete.png')} className='img' />
                                            </div>
                                        </CommonInputRow>
                                    }

                                    {
                                        cardForm.linkedin && <CommonInputRow>
                                            <CommonInputFloatFull
                                                id='linkedInUserName'
                                                onChange={e => setLinkedInLink(e.target.value)}
                                                placeholder='LinkedIn Profile Link'
                                                type='text'
                                                value={linkedInLink}
                                            />
                                            <div className="input-icon-container" onClick={() => {
                                                if (isEmpty(linkedInLink)) {
                                                    displayErrorToast('LinkedIn link is empty. Click delete to remove.')
                                                } else {
                                                    displaySuccessToast('LinkedIn link added successfully')
                                                }
                                            }}>
                                                <img alt="" src={require('../../../assets/ic_done.png')} className='img' />
                                            </div>
                                            <div className="ac-social-link-img-container" onClick={() => {
                                                cardForm.linkedin = false;
                                                setLinkedInLink('')
                                                setCardForm(oldValue => ({
                                                    ...oldValue,
                                                    ...cardForm
                                                }));
                                            }}>
                                                <img alt="" src={require('../../../assets/ic_delete.png')} className='img' />
                                            </div>
                                        </CommonInputRow>
                                    }
                                    {
                                        cardForm.twitter && <CommonInputRow>
                                            <CommonInputFloatFull
                                                id='twitterName'
                                                onChange={e => setTwitterLink(e.target.value)}
                                                placeholder='X (Twitter) Profile Link'
                                                type='text'
                                                value={twitterLink}
                                            />
                                            <div className="input-icon-container" onClick={() => {
                                                if (isEmpty(twitterLink)) {
                                                    displayErrorToast('X (Twitter) link is empty. Click delete to remove.')
                                                } else {
                                                    displaySuccessToast('X (Twitter) link added successfully')
                                                }
                                            }}>
                                                <img alt="" src={require('../../../assets/ic_done.png')} className='img' />
                                            </div>
                                            <div className="ac-social-link-img-container" onClick={() => {
                                                cardForm.twitter = false;
                                                setTwitterLink('')
                                                setCardForm(oldValue => ({
                                                    ...oldValue,
                                                    ...cardForm
                                                }));
                                            }}>
                                                <img alt="" src={require('../../../assets/ic_delete.png')} className='img' />
                                            </div>
                                        </CommonInputRow>
                                    }
                                    {
                                        cardForm.instagram && <CommonInputRow>
                                            <CommonInputFloatFull
                                                id='instagramName'
                                                onChange={e => setInstagramLink(e.target.value)}
                                                placeholder='Instagram Profile Link'
                                                type='text'
                                                value={instagramLink}
                                            />
                                            <div className="input-icon-container" onClick={() => {
                                                if (isEmpty(instagramLink)) {
                                                    displayErrorToast('Instagram link is empty. Click delete to remove.')
                                                } else {
                                                    displaySuccessToast('Instagram link added successfully')
                                                }
                                            }}>
                                                <img alt="" src={require('../../../assets/ic_done.png')} className='img' />
                                            </div>
                                            <div className="ac-social-link-img-container" onClick={() => {
                                                cardForm.instagram = false;
                                                setInstagramLink('')
                                                setCardForm(oldValue => ({
                                                    ...oldValue,
                                                    ...cardForm
                                                }));
                                            }}>
                                                <img alt="" src={require('../../../assets/ic_delete.png')} className='img' />
                                            </div>

                                        </CommonInputRow>
                                    }
                                    {
                                        cardForm.snapchat && <CommonInputRow>
                                            <CommonInputFloatFull
                                                id='snapchat'
                                                onChange={e => setSnapchatLink(e.target.value)}
                                                placeholder='Snapchat Profile Link'
                                                type='text'
                                                value={snapchatLink}
                                            />
                                            <div className="input-icon-container" onClick={() => {
                                                if (isEmpty(snapchatLink)) {
                                                    displayErrorToast('Snapchat link is empty. Click delete to remove.')
                                                } else {
                                                    displaySuccessToast('Snapchat link added successfully')
                                                }
                                            }}>
                                                <img alt="" src={require('../../../assets/ic_done.png')} className='img' />
                                            </div>
                                            <div className="ac-social-link-img-container" onClick={() => {
                                                cardForm.snapchat = false;
                                                setSnapchatLink('')
                                                setCardForm(oldValue => ({
                                                    ...oldValue,
                                                    ...cardForm
                                                }));
                                            }}>
                                                <img alt="" src={require('../../../assets/ic_delete.png')} className='img' />
                                            </div>

                                        </CommonInputRow>
                                    }
                                    {
                                        cardForm.other_links && <div className="ac-input-row">
                                            <CommonInputFloatFull
                                                classname="field-mb-0"
                                                type='text'
                                                id='otherLink'
                                                onChange={e => setOtherLink(e.target.value)}
                                                placeholder='Other Link'
                                                isSubtext={true}
                                                subText='Ex. https://www.abc.com'
                                                value={otherLink} />
                                            <div className="ac-add-img-container" onClick={() => handleAddOtherLinkClick()}>
                                                <img alt="" src={require('../../../assets/ic_done.png')} className='img' />
                                            </div>
                                            <div className="input-icon-container" onClick={() => {
                                                cardForm.other_links = false;
                                                setCardForm(oldValue => ({
                                                    ...oldValue,
                                                    ...cardForm
                                                }));
                                            }}>
                                                <img alt="" src={require('../../../assets/ic_delete.png')} className='img' />
                                            </div>
                                            {/* <button type="button" className="btn ac-add-button" onClick={() => handleAddOtherLinkClick()}>Add</button> */}
                                        </div>
                                    }
                                    {!isEmpty(otherSocialLink) && <div className="ac-social-link-container">
                                        {otherSocialLink.map(e => (
                                            <div className="ac-social-link-item-container">
                                                <div className="ac-social-link-item">{e}</div>
                                                <div className="ac-social-link-img-container" onClick={() => { handleLinkDeleteClick(e) }}>
                                                    <img alt="" src={require('../../../assets/ic_edit_blue.png')} className='img' />
                                                </div>
                                            </div>
                                        ))}
                                    </div>}
                                </Paper>
                            }
                            <ButtonRow>
                                <CommonButton label='More Options' onClick={() => {
                                    setShowFieldSelection(true)
                                }} />
                                <CommonButton label='Update'
                                    onClick={() => handleUpdateClick()} />
                            </ButtonRow>
                        </CommonColorForm>
                    </div>
                    <div className="col-sm-6">
                        <UserCardPreview
                            showProfileImage={cardForm.profile_image}
                            isBannerImageFromUrl={isBannerImageFromUrl()}
                            isProfileImageFromUrl={isProfileImageFromUrl()}
                            showBrandLogo={cardForm.brand_logo}
                            isBrandLogoFromUrl={isBrandLogoFromUrl()}
                            cardObject={{
                                bannerImage: isBannerImageFromUrl() ? cardByAliasSuccess?.data?.otherDetails?.bannerImage : bannerImage,
                                profileImage: isProfileImageFromUrl() ? cardByAliasSuccess?.data?.otherDetails?.profileImage : profileImage,
                                brandLogoImg: isBrandLogoFromUrl() ? cardByAliasSuccess?.data?.otherDetails?.brandLogoImg : brandImage,
                                firstName: firstName,
                                lastName: lastName,
                                companyName: companyName,
                                jobTitle: jobTitle,
                                summary: summary,
                                facebookLink: facebookLink,
                                linkedInLink: linkedInLink,
                                twitterLink: twitterLink,
                                instagramLink: instagramLink,
                                snapchatLink: snapchatLink,
                                otherSocialLink: otherSocialLink,
                                email1: primaryEmail,
                                email2: secondaryEmail,
                                email3: alternateEmail,
                                homeNumber: homePhoneNumber,
                                mobileNumber: mobilePhoneNumber,
                                workNumber1: workNumber1,
                                workNumber1Ext: workNumberExt1,
                                workNumber2: workNumber2,
                                workNumber2Ext: workNumberExt2,
                                fax: faxNumber,
                                homeAddress: homeAddress,
                                workAddress: workAddress
                            }}
                        />
                    </div>
                </div>}

            </CommonScreenContent>
            <ToastContainer />
            <Loader active={isLoading} text='Please wait...' />
        </>
    )
}

export default CardDetails