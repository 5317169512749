import React, { useEffect, useState } from "react";
import './styles.css'
import { nfcCardOrderListRequest } from '../../../../../redux/nfc-card-order/nfcCardOrder.action'
import { useLocation, useNavigate } from 'react-router-dom';
import Loader from '../../../../../components/loader/loader.component'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useDispatch, useSelector } from 'react-redux'
import CommonScreenContent from "../../../../../components/screen/common-screen-content/commonScreenContent";
import { displayErrorToast, isEmpty, isLoggedInUserAdmin, isLoggedInUserBusiness, isLoggedInUserCorporate, isLoggedInUserCustomer, isLoggedInUserOperator, isNull, removeSpecialCharAndChangeToPascal } from "../../../../../utils/Utils";
import ScreenLabel from "../../../../../components/label/screen-label/screenLabel";
import moment from "moment";
import { APP_CONSTANTS, ROUTES } from "../../../../../utils/AppConstants";
import Select from 'react-select'
import FormFieldLabel from "../../../../../components/label/form-field-label/formFieldLabel";
import CommonInputRow from "../../../../../components/input/common-input-row/commonInputRow";

const NfcCardOrderList = props => {
    const { state } = useLocation()
    const [isLoading, setLoading] = useState(false)
    const dispatch = useDispatch()
    let navigate = useNavigate();
    const { commonError, user } = useSelector((state) => state.user)
    const { nfcCardOrderListSuccess, nfcCardOrderListError } = useSelector((state) => state.nfcCardOrder)
    const [countryDropdown, setCountryDropdown] = useState();
    const [stateDropdown, setStateDropdown] = useState();
    const [cityDropdown, setCityDropdown] = useState();
    const [offerDropdown, setOfferDropdown] = useState()

    useEffect(() => {
        if (!isNull(commonError)) {
            if (commonError.status === 401) {
                setLoading(false)
                displayErrorToast(commonError.message)
                dispatch({ type: APP_CONSTANTS.LOGOUT })
            }
        }
    }, [commonError])

    useEffect(() => {
        if (isLoggedInUserAdmin(user) ||
            isLoggedInUserCustomer(user) ||
            isLoggedInUserCorporate(user) ||
            isLoggedInUserBusiness(user)) {
            fetchOrderList()
        }
    }, [])

    const fetchOrderList = (country = null, state = null, city = null) => {
        setLoading(true)
        const data = {}
        if (isLoggedInUserCustomer(user)) {
            data.customerId = user.data.userDetails.userId
        }
        if (isLoggedInUserCorporate(user) || isLoggedInUserBusiness(user)) {
            data.businessId = user.data.userDetails?.businessData?.businessId
        }
        if (isLoggedInUserOperator(user)) {
            if (!isEmpty(country)) {
                data.country = country
            }
            if (!isEmpty(state)) {
                data.state = state
            }
            if (!isEmpty(city)) {
                data.city = city
            }
        }
        dispatch(nfcCardOrderListRequest(data))
    }

    useEffect(() => {
        if (!isNull(nfcCardOrderListSuccess)) {
            setLoading(false)
        } else if (!isNull(nfcCardOrderListError)) {
            setLoading(false)
        }
    }, [nfcCardOrderListSuccess, nfcCardOrderListError])

    const handleViewDetailClick = item => {
        navigate(ROUTES.orderDetails, { state: item.nfcCardOrderId })
    }

    return (
        <>
            <CommonScreenContent>
                <div className="mt-2 mb-1">
                    <ScreenLabel label='Orders' />
                    {isLoggedInUserOperator(user) && <div>
                        <FormFieldLabel className='up-bold-label' label='Select Location' />
                        {isEmpty(user.data.userDetails.accessLocations) && <div>No location access provided. Kindly request access to view receipts</div>}
                        {!isEmpty(user.data.userDetails.accessLocations) &&
                            <Select
                                menuPlacement="auto"
                                className='select-full-width-no-border'
                                value={countryDropdown}
                                getOptionLabel={e => e.country}
                                getOptionValue={e => e}
                                options={user.data.userDetails.accessLocations}
                                placeholder='Select Country'
                                onChange={e => {
                                    setCountryDropdown(e)
                                    if (isEmpty(e.stateCity)) {
                                        fetchOrderList(e.country)
                                    }
                                }} />
                        }
                        {!isEmpty(user.data.userDetails.accessLocations) && !isNull(countryDropdown) &&
                            !isEmpty(countryDropdown?.stateCity) &&
                            <CommonInputRow>
                                <Select
                                    menuPlacement="auto"
                                    className='select-half-width'
                                    value={stateDropdown}
                                    getOptionLabel={e => e.state}
                                    getOptionValue={e => e}
                                    options={countryDropdown.stateCity}
                                    placeholder='Select State'
                                    onChange={e => {
                                        setStateDropdown(e)
                                        if (isEmpty(e.city)) {
                                            fetchOrderList(countryDropdown.country, e.state)
                                        }
                                    }} />
                                {!isEmpty(stateDropdown?.city) && <Select
                                    menuPlacement="auto"
                                    className='select-half-width'
                                    value={cityDropdown}
                                    getOptionLabel={e => e.value}
                                    getOptionValue={e => e}
                                    options={stateDropdown.city.map(city => {
                                        return { key: city, value: city };
                                    })}
                                    placeholder='Select City'
                                    onChange={e => {
                                        setCityDropdown(e)
                                        fetchOrderList(countryDropdown.country, stateDropdown.state, e.value)
                                    }} />}
                            </CommonInputRow>}
                    </div>}
                    {!isNull(nfcCardOrderListSuccess) && !isEmpty(nfcCardOrderListSuccess.data) && <table className="table table-hover mt-4">
                        <thead>
                            <tr>
                                <th scope="col" className="text-center">Order Number</th>
                                <th scope="col" className="text-center">Status</th>
                                <th scope="col" className="text-center">Date</th>
                                <th scope="col" className="text-center">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {nfcCardOrderListSuccess.data.map(element => (
                                <tr className="loc-table-row-container">
                                    <td className="loc-list-card-name">{element.nfcCardOrderId}</td>
                                    <td className="loc-list-card-name">{removeSpecialCharAndChangeToPascal(element.orderStatus)}</td>
                                    <td className="loc-list-card-name">{moment(element.createdOn).utc().format('DD MMM YYYY')}</td>
                                    <td className="loc-list-card-name">
                                        <div className="ncol-detail-container-parent">
                                            <div className="ncol-detail-container" onClick={() => { handleViewDetailClick(element) }}>View Details</div></div></td>
                                </tr>
                            ))}

                        </tbody>
                    </table>}
                    {!isNull(nfcCardOrderListError) && <div className="mt-2">{nfcCardOrderListError.message}</div>}
                </div>
            </CommonScreenContent>
            <ToastContainer />
            <Loader active={isLoading} text='Please wait...' />
        </>
    )
}

export default NfcCardOrderList