import { AppointmentActionTypes } from './appointment.types'

//Add Appointment
export const addAppointmentRequest = data => ({
    type: AppointmentActionTypes.ADD_APPOINTMENT_REQUEST,
    payload: data
})

export const addAppointmentRequestSuccess = data => ({
    type: AppointmentActionTypes.ADD_APPOINTMENT_REQUEST_SUCCESS,
    payload: data
})

export const addAppointmentRequestError = data => ({
    type: AppointmentActionTypes.ADD_APPOINTMENT_REQUEST_ERROR,
    payload: data
})

export const addAppointmentRequestStatus = data => ({
    type: AppointmentActionTypes.ADD_APPOINTMENT_REQUEST_STATUS,
    payload: data
})

//Appointment list
export const appointmentListRequest = data => ({
    type: AppointmentActionTypes.APPOINTMENT_LIST_REQUEST,
    payload: data
})

export const appointmentListRequestSuccess = data => ({
    type: AppointmentActionTypes.APPOINTMENT_LIST_REQUEST_SUCCESS,
    payload: data
})

export const appointmentListRequestError = data => ({
    type: AppointmentActionTypes.APPOINTMENT_LIST_REQUEST_ERROR,
    payload: data
})

//Update Appointment
export const updateAppointmentRequest = data => ({
    type: AppointmentActionTypes.UPDATE_APPOINTMENT_REQUEST,
    payload: data
})

export const updateAppointmentRequestSuccess = data => ({
    type: AppointmentActionTypes.UPDATE_APPOINTMENT_REQUEST_SUCCESS,
    payload: data
})

export const updateAppointmentRequestError = data => ({
    type: AppointmentActionTypes.UPDATE_APPOINTMENT_REQUEST_ERROR,
    payload: data
})

export const updateAppointmentRequestStatus = data => ({
    type: AppointmentActionTypes.UPDATE_APPOINTMENT_REQUEST_STATUS,
    payload: data
})