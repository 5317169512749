import { takeLatest, call, put } from 'redux-saga/effects'
import {
    visitorAddRecordAPI, visitorRecordListAPI, visitorMarkExitAPI
} from '../api-requests/visitorBook';
import {
    visitorAddRecordRequestError, visitorAddRecordRequestSuccess, visitorRecordListRequestError, visitorRecordListRequestSuccess,
    visitorMarkExitRequestError, visitorMarkExitRequestSuccess
} from './visitorBook.action';
import { VisitorBookActionTypes } from './visitorBook.types'
import { displayConsoleLog } from '../../utils/Utils'
import { decryptResponseData } from '../../utils/DataDecryptionUtils';
import { APP_CONSTANTS } from '../../utils/AppConstants';

function* handleVisitorMarkExitRequest({ payload }) {
    try {
        const response = yield call(visitorMarkExitAPI, payload);
        if (response.status >= 200 && response.status < 300) {
            const { data } = response
            const decryptedData = decryptResponseData(data)
            if (decryptedData.data !== null) {
                displayConsoleLog('error1')
                yield put(visitorMarkExitRequestSuccess(decryptedData))
            } else {
                displayConsoleLog('error2')
                yield put(visitorMarkExitRequestError(decryptedData))
            }
        } else {
            displayConsoleLog('error3')
            throw response
        }
    } catch (error) {
        displayConsoleLog('error', error)
        if (error.response?.status === 401) {
            const errData = {
                status: error.response?.status,
                isSessionError: true,
                message: APP_CONSTANTS.SESSION_EXPIRE_MESSAGE
            }
            yield put({ type: VisitorBookActionTypes.COMMON_ERROR, errData })
        } else {
            displayConsoleLog(error.response.status)
            displayConsoleLog(error.response?.data)
            yield put(visitorMarkExitRequestError(error.response.data))
        }
    }
}

export function* visitorMarkExitReq() {
    yield takeLatest(VisitorBookActionTypes.VISITOR_MARK_EXIT_REQUEST, handleVisitorMarkExitRequest)
}

function* handleVisitorRecordListRequest({ payload }) {
    try {
        const response = yield call(visitorRecordListAPI, payload);
        if (response.status >= 200 && response.status < 300) {
            const { data } = response
            const decryptedData = decryptResponseData(data)
            if (decryptedData.data !== null) {
                displayConsoleLog('error1')
                yield put(visitorRecordListRequestSuccess(decryptedData))
            } else {
                displayConsoleLog('error2')
                yield put(visitorRecordListRequestError(decryptedData))
            }
        } else {
            displayConsoleLog('error3')
            throw response
        }
    } catch (error) {
        displayConsoleLog('error', error)
        if (error.response?.status === 401) {
            const errData = {
                status: error.response?.status,
                isSessionError: true,
                message: APP_CONSTANTS.SESSION_EXPIRE_MESSAGE
            }
            yield put({ type: VisitorBookActionTypes.COMMON_ERROR, errData })
        } else {
            displayConsoleLog(error.response.status)
            displayConsoleLog(error.response?.data)
            yield put(visitorRecordListRequestError(error.response.data))
        }
    }
}

export function* visitorRecordListReq() {
    yield takeLatest(VisitorBookActionTypes.VISITOR_RECORD_LIST_REQUEST, handleVisitorRecordListRequest)
}

function* handleVisitorAddRecordRequest({ payload }) {
    try {
        const response = yield call(visitorAddRecordAPI, payload);
        if (response.status >= 200 && response.status < 300) {
            const { data } = response
            const decryptedData = decryptResponseData(data)
            if (decryptedData.data !== null) {
                yield put(visitorAddRecordRequestSuccess(decryptedData))
            } else {
                yield put(visitorAddRecordRequestError(decryptedData))
            }
        } else {
            throw response
        }
    } catch (error) {
        displayConsoleLog(error)
        if (error.response?.status === 401) {
            const errData = {
                status: error.response?.status,
                isSessionError: true,
                message: APP_CONSTANTS.SESSION_EXPIRE_MESSAGE
            }
            yield put({ type: VisitorBookActionTypes.COMMON_ERROR, errData })
        } else {
            displayConsoleLog(error.response?.data)
            yield put(visitorAddRecordRequestError(error.response.data))
        }
    }
}

export function* visitorAddRecordReq() {
    yield takeLatest(VisitorBookActionTypes.VISITOR_ADD_RECORD_REQUEST, handleVisitorAddRecordRequest)
}
