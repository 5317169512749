import React, { useEffect, useState, useRef } from "react";
import './styles.css'
import CommonScreenContent from "../../../../../components/screen/common-screen-content/commonScreenContent";
import { useLocation, useNavigate } from 'react-router-dom';
import Loader from '../../../../../components/loader/loader.component'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useDispatch, useSelector } from 'react-redux'
import { nfcCardOrderDetailRequest, updateNfcCardOrderDetailsRequest, updateNfcCardOrderDetailsRequestStatus } from '../../../../../redux/nfc-card-order/nfcCardOrder.action'
import { API_CONSTANTS, APP_CONSTANTS } from "../../../../../utils/AppConstants";
import { displayErrorToast, displaySuccessToast, isEmpty, isLoggedInUserAdmin, isLoggedInUserOperator, isNull, priceWithSymbol, removeSpecialCharAndChangeToPascal } from "../../../../../utils/Utils";
import ScreenLabel from "../../../../../components/label/screen-label/screenLabel";
import CommonColorForm from "../../../../../components/common-color-form/commonColorForm";
import FormFieldLabel from "../../../../../components/label/form-field-label/formFieldLabel";
import NfcCardDesign1 from "../../nfc-template-design/nfcCardDesign1";
import NfcCardDesign2 from "../../nfc-template-design/nfcCardDesign2";
import NfcCardDesign3 from "../../nfc-template-design/nfcCardDesign3";
import { config } from "../../../../../config";
import ButtonRow from "../../../../../components/button/button-row/buttonRow";
import CommonButton from "../../../../../components/button/common-button/commonButton";
import domToImage from 'dom-to-image';
import Modal from 'react-bootstrap/Modal';
import CommonInputFloatFull from "../../../../../components/input/common-input-float-full/commonInputFloatFull";
import CommonInputRow from "../../../../../components/input/common-input-row/commonInputRow";
import CommonInputFloatHalf from "../../../../../components/input/common-input-float-half/commonInputFloafHalf";
import Select from 'react-select'
import { CurrencyLabel } from "../../../../../utils/CurrencyLabel";
import ReactDOM from 'react-dom';

const NfcCardOrderDetail = props => {
    const { state } = useLocation()
    const [isLoading, setLoading] = useState(false)
    const dispatch = useDispatch()
    let navigate = useNavigate();
    const { commonError, user } = useSelector((state) => state.user)
    const { updateNfcCardOrderDetailsSuccess, updateNfcCardOrderDetailsError, updateNfcCardOrderDetailsReqStatus,
        nfcCardOrderDetailSuccess, nfcCardOrderDetailError } = useSelector((state) => state.nfcCardOrder)
    const cardDesignRef = useRef(null);
    const [showPaymentModal, setShowPaymentModal] = useState(false)
    const [showShippingModal, setShowShippingModal] = useState(false)
    const [showStatusModal, setShowStatusModal] = useState(false)
    const ORDER_STATUS_LIST = [
        { key: APP_CONSTANTS.NFC_CARD_ORDER_STATUS.PENDING, value: removeSpecialCharAndChangeToPascal(APP_CONSTANTS.NFC_CARD_ORDER_STATUS.PENDING) },
        { key: APP_CONSTANTS.NFC_CARD_ORDER_STATUS.AWAITING_PAYMENT, value: removeSpecialCharAndChangeToPascal(APP_CONSTANTS.NFC_CARD_ORDER_STATUS.AWAITING_PAYMENT) },
        { key: APP_CONSTANTS.NFC_CARD_ORDER_STATUS.IN_PROCESS, value: removeSpecialCharAndChangeToPascal(APP_CONSTANTS.NFC_CARD_ORDER_STATUS.IN_PROCESS) },
        { key: APP_CONSTANTS.NFC_CARD_ORDER_STATUS.PROCESSED, value: removeSpecialCharAndChangeToPascal(APP_CONSTANTS.NFC_CARD_ORDER_STATUS.PROCESSED) },
        { key: APP_CONSTANTS.NFC_CARD_ORDER_STATUS.HANDED_TO_SHIPMENT, value: removeSpecialCharAndChangeToPascal(APP_CONSTANTS.NFC_CARD_ORDER_STATUS.HANDED_TO_SHIPMENT) },
        { key: APP_CONSTANTS.NFC_CARD_ORDER_STATUS.IN_TRANSIST, value: removeSpecialCharAndChangeToPascal(APP_CONSTANTS.NFC_CARD_ORDER_STATUS.IN_TRANSIST) },
        { key: APP_CONSTANTS.NFC_CARD_ORDER_STATUS.DELIVERED, value: removeSpecialCharAndChangeToPascal(APP_CONSTANTS.NFC_CARD_ORDER_STATUS.DELIVERED) },
        { key: APP_CONSTANTS.NFC_CARD_ORDER_STATUS.CANCELLED, value: removeSpecialCharAndChangeToPascal(APP_CONSTANTS.NFC_CARD_ORDER_STATUS.CANCELLED) },
        { key: APP_CONSTANTS.NFC_CARD_ORDER_STATUS.UN_DELIVERED, value: removeSpecialCharAndChangeToPascal(APP_CONSTANTS.NFC_CARD_ORDER_STATUS.UN_DELIVERED) },
        { key: APP_CONSTANTS.NFC_CARD_ORDER_STATUS.REJECTED, value: removeSpecialCharAndChangeToPascal(APP_CONSTANTS.NFC_CARD_ORDER_STATUS.REJECTED) },
    ]
    const [orderStatusSelected, setOrderStatusSelected] = useState()
    const [orderErrorMessage, setOrderErrorMessage] = useState('')
    const PAYMENT_STATUS_LIST = [
        { key: 'PENDING', value: 'PENDING' },
        { key: 'PAID', value: 'PAID' },
        { key: 'FAILED', value: 'FAILED' }
    ]
    const [paymentErrorMessage, setPaymentErrorMessage] = useState('')
    const [paymentMode, setPaymentMode] = useState('')
    const [paymentStatusSelected, setPaymentStatusSelected] = useState()
    const [amount, setAmount] = useState('')
    const [shippingCharges, setShippingCharges] = useState('')
    const [discount, setDiscount] = useState('')
    const [taxAmount, setTaxAmount] = useState('')
    const [otherCharges, setOtherCharges] = useState('')
    const [finalAmount, setFinalAmount] = useState('')
    const [paymentLink, setPaymentLink] = useState('')
    const [shippingPartner, setShippingPartner] = useState('')
    const [shippingTrackingId, setShippingTrackingId] = useState('')
    const [shippingStatus, setShippingStatus] = useState('')
    const [selectedCurrency, setSelectedCurrency] = useState()
    const employeeContainerRef = useRef(null);

    useEffect(() => {
        fetchOrderDetails()
    }, [])

    const fetchOrderDetails = () => {
        setLoading(true)
        dispatch(nfcCardOrderDetailRequest(state))
    }

    useEffect(() => {
        if (updateNfcCardOrderDetailsReqStatus === API_CONSTANTS.SUCCESS_STATUS) {
            setLoading(false)
            displaySuccessToast(updateNfcCardOrderDetailsSuccess.message)
            fetchOrderDetails()
        } else if (updateNfcCardOrderDetailsReqStatus === API_CONSTANTS.ERROR_STATUS) {
            setLoading(false)
            displayErrorToast(updateNfcCardOrderDetailsError.message)
        }
        if (updateNfcCardOrderDetailsReqStatus?.length > 0) {
            dispatch(updateNfcCardOrderDetailsRequestStatus(''))
        }
    }, [updateNfcCardOrderDetailsSuccess, updateNfcCardOrderDetailsError, updateNfcCardOrderDetailsReqStatus])

    useEffect(() => {
        if (!isNull(nfcCardOrderDetailSuccess)) {
            setLoading(false)
            if (!isEmpty(nfcCardOrderDetailSuccess.data)) {
                setPaymentErrorMessage(nfcCardOrderDetailSuccess.data.paymentStatusError)
                setPaymentMode(nfcCardOrderDetailSuccess.data.paymentMode)
                setAmount(nfcCardOrderDetailSuccess.data.amount)
                setShippingCharges(nfcCardOrderDetailSuccess.data.shippingCharges)
                setDiscount(nfcCardOrderDetailSuccess.data.discountAmount)
                setTaxAmount(nfcCardOrderDetailSuccess.data.taxAmount)
                setOtherCharges(nfcCardOrderDetailSuccess.data.otherCharges)
                setFinalAmount(nfcCardOrderDetailSuccess.data.finalAmount)
                setPaymentLink(nfcCardOrderDetailSuccess.data.paymentOnlineUrl)
                setShippingPartner(nfcCardOrderDetailSuccess.data.shippingPartner)
                setShippingTrackingId(nfcCardOrderDetailSuccess.data.shippingTrackingId)
                setShippingStatus(nfcCardOrderDetailSuccess.data.shippingStatus)
            }
        } else if (!isNull(nfcCardOrderDetailError)) {
            setLoading(false)
        }
    }, [nfcCardOrderDetailSuccess, nfcCardOrderDetailError])

    useEffect(() => {
        if (!isNull(commonError)) {
            if (commonError.status === 401) {
                setLoading(false)
                displayErrorToast(commonError.message)
                dispatch({ type: APP_CONSTANTS.LOGOUT })
            }
        }
    }, [commonError])

    const showButtonContainer = (orderStatus) => {
        let showButton = true
        if (nfcCardOrderDetailSuccess.data.orderStatus === APP_CONSTANTS.NFC_CARD_ORDER_STATUS.DELIVERED) {
            showButton = false
        }
        if (nfcCardOrderDetailSuccess.data.orderStatus === APP_CONSTANTS.NFC_CARD_ORDER_STATUS.CANCELLED) {
            showButton = false
        }
        if (nfcCardOrderDetailSuccess.data.orderStatus === APP_CONSTANTS.NFC_CARD_ORDER_STATUS.REJECTED) {
            showButton = false
        }
        return showButton
    }

    const handleCopy = (event) => {
        event.preventDefault();
        alert('Copying is disabled for order details.');
    }

    const downloadQR = async () => {
        const divElement = cardDesignRef.current;

        domToImage.toPng(divElement)
            .then((dataUrl) => {
                const link = document.createElement('a');
                link.href = dataUrl;
                link.download = 'downloaded-image.png';
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            })
            .catch((error) => {
                console.error('Error generating image:', error);
            });
        // const width = cardDesignRef.current.offsetWidth;
        // toPng(cardDesignRef.current)
        //     .then(function (dataUrl) {
        //         download(dataUrl, "user-nfc-card-design.png");
        //     })
        //     .catch(function (error) {
        //         console.error("oops, something went wrong!", error);
        //     });
    }

    const generateAndDownloadCard = (item, index) => {
        const div = document.createElement('div');
        div.style.height = `190px`;
        div.style.width = `324px`;
        document.body.appendChild(div);
        ReactDOM.render(<EmployeeCardComponentDesign data={item} />, div)
        // ReactDOM.render(<EmployeeCardComponentDesign data={item} />, div);
        // const divElement = <EmployeeCardComponentDesign data={item} />;
        // document.body.appendChild(divElement)
        domToImage.toPng(div)
            .then((dataUrl) => {
                const link = document.createElement('a');
                link.href = dataUrl;
                link.download = `${item.firstName}-${item.lastName}.png`;
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                document.body.removeChild(div)

            })
            .catch((error) => {
                console.error('Error generating image:', error);
                document.body.removeChild(div)
            });
    }

    const handleUpdateOrderStatusSubmitClick = () => {
        if (isEmpty(orderStatusSelected)) {
            displayErrorToast('Order status not selected')
        } else if ((orderStatusSelected.key === APP_CONSTANTS.NFC_CARD_ORDER_STATUS.REJECTED ||
            orderStatusSelected.key === APP_CONSTANTS.NFC_CARD_ORDER_STATUS.CANCELLED ||
            orderStatusSelected.key === APP_CONSTANTS.NFC_CARD_ORDER_STATUS.UN_DELIVERED) && isEmpty(orderErrorMessage)) {
            displayErrorToast('Reason cannot be blank')
        } else {
            const data = {
                nfcCardOrderId: state,
                orderStatus: orderStatusSelected.key
            }
            if (orderStatusSelected.key === APP_CONSTANTS.NFC_CARD_ORDER_STATUS.REJECTED ||
                orderStatusSelected.key === APP_CONSTANTS.NFC_CARD_ORDER_STATUS.CANCELLED ||
                orderStatusSelected.key === APP_CONSTANTS.NFC_CARD_ORDER_STATUS.UN_DELIVERED) {
                data.orderStatusError = orderErrorMessage
            }
            setLoading(true)
            dispatch(updateNfcCardOrderDetailsRequest(data))
            setShowStatusModal(false)
        }
    }

    const handlePaymentDetailSubmitClick = () => {
        if (isEmpty(amount)) {
            displayErrorToast('Amount cannot be blank')
        } else if (isEmpty(finalAmount)) {
            displayErrorToast('Final amount cannot be blank')
        } else if (isEmpty(selectedCurrency)) {
            displayErrorToast('Payment currency not selected')
        } else {
            const data = {
                nfcCardOrderId: state,
            }
            data.amount = parseFloat(amount)
            data.finalAmount = parseFloat(finalAmount)
            if (!isEmpty(shippingCharges)) {
                data.shippingCharges = parseFloat(shippingCharges)
            }
            if (!isEmpty(discount)) {
                data.discountAmount = parseFloat(discount)
            }
            if (!isEmpty(taxAmount)) {
                data.taxAmount = parseFloat(taxAmount)
            }
            if (!isEmpty(otherCharges)) {
                data.otherCharges = parseFloat(otherCharges)
            }
            if (!isEmpty(paymentStatusSelected)) {
                data.paymentStatus = paymentStatusSelected.key
            }
            if (!isEmpty(paymentMode)) {
                data.paymentMode = paymentMode
            }
            if (!isEmpty(paymentLink)) {
                data.paymentOnlineUrl = paymentLink
            }
            if (!isNull(selectedCurrency)) {
                data.currencyLabel = selectedCurrency.code
                data.currencySymbol = selectedCurrency.symbol
                data.currencyRegion = selectedCurrency.region
            }
            setLoading(true)
            dispatch(updateNfcCardOrderDetailsRequest(data))
            setShowPaymentModal(false)
        }
    }

    const handleShippingDetailSubmitClick = () => {
        if (isEmpty(shippingPartner)) {
            displayErrorToast('Shipping Partner name cannot be blank')
        } else {
            const data = {
                nfcCardOrderId: state,
                shippingPartner: shippingPartner
            }
            if (!isEmpty(shippingTrackingId)) {
                data.shippingTrackingId = shippingTrackingId
            }
            if (!isEmpty(shippingStatus)) {
                data.shippingStatus = shippingStatus
            }
            setLoading(true)
            dispatch(updateNfcCardOrderDetailsRequest(data))
            setShowShippingModal(false)
        }
    }

    const EmployeeCardComponentDesign = ({ data }) => {
        if (nfcCardOrderDetailSuccess.data.designTemplateId === 'CARD_1') {
            return (<div className="ncod-card-design-container" ref={cardDesignRef}>
                <NfcCardDesign1
                    name={`${data.firstName} ${data.lastName}`}
                    designation={data.designation}
                    phoneNumber={data.mobile}
                    email={data.email}
                    address={data.address}
                    qrCodeUrl={`${config.employeeQrCodeUrl}/${data.employeeId}`}
                />
            </div>)
        } else if (nfcCardOrderDetailSuccess.data.designTemplateId === 'CARD_2') {
            return (<div className="ncod-card-design-container" ref={cardDesignRef}>
                <NfcCardDesign2
                    name={`${data.firstName} ${data.lastName}`}
                    designation={data.designation}
                    phoneNumber={data.mobile}
                    email={data.email}
                    address={data.address}
                    qrCodeUrl={`${config.employeeQrCodeUrl}/${data.employeeId}`}
                />
            </div>)
        } else if (nfcCardOrderDetailSuccess.data.designTemplateId === 'CARD_3') {
            return (<div className="ncod-card-design-container" ref={cardDesignRef}>
                <NfcCardDesign3
                    name={`${data.firstName} ${data.lastName}`}
                    designation={data.designation}
                    phoneNumber={data.mobile}
                    email={data.email}
                    address={data.address}
                    qrCodeUrl={`${config.employeeQrCodeUrl}/${data.employeeId}`}
                />
            </div>)
        }
    }

    return (
        <div id="order-detail-content" onCopy={handleCopy} className="no-print">
            <CommonScreenContent>
                <ScreenLabel label='Order Details' />
                {!isNull(nfcCardOrderDetailSuccess) && !isNull(nfcCardOrderDetailSuccess.data) && <CommonColorForm>
                    <FormFieldLabel className='label-bold' label='Order Number' />
                    <div className="ncod-display-value">{nfcCardOrderDetailSuccess.data.nfcCardOrderId}</div>
                    <FormFieldLabel className='label-bold' label='Number of card(s)' />
                    <div className="ncod-display-value">{nfcCardOrderDetailSuccess.data.orderType === APP_CONSTANTS.NFC_ORDER_TYPE.CORPORATE ? `${nfcCardOrderDetailSuccess.data.orderQuantity} each` : nfcCardOrderDetailSuccess.data.orderQuantity}</div>
                    {!isEmpty(nfcCardOrderDetailSuccess.data.orderType) && <div>
                        <FormFieldLabel className='label-bold' label='Order Type' />
                        <div className="ncod-display-value">{removeSpecialCharAndChangeToPascal(nfcCardOrderDetailSuccess.data.orderType)}</div>
                    </div>}
                    {(isEmpty(nfcCardOrderDetailSuccess.data.orderType) || (!isEmpty(nfcCardOrderDetailSuccess.data.orderType) && nfcCardOrderDetailSuccess.data.orderType === APP_CONSTANTS.NFC_ORDER_TYPE.INDIVIDUAL)) && <FormFieldLabel className='label-bold' label='Card Printing Information' />}
                    {!isEmpty(nfcCardOrderDetailSuccess.data.nameOnCard) && <div>
                        <FormFieldLabel label='Person Name' />
                        <div className="ncod-display-value">{nfcCardOrderDetailSuccess.data.nameOnCard}</div>
                    </div>}
                    {!isEmpty(nfcCardOrderDetailSuccess.data.jobTitle) && <div>
                        <FormFieldLabel label='Job Title' />
                        <div className="ncod-display-value">{nfcCardOrderDetailSuccess.data.jobTitle}</div>
                    </div>}
                    {!isEmpty(nfcCardOrderDetailSuccess.data.email) && <div>
                        <FormFieldLabel label='Person Email' />
                        <div className="ncod-display-value">{nfcCardOrderDetailSuccess.data.email}</div>
                    </div>}
                    {!isEmpty(nfcCardOrderDetailSuccess.data.mobileNumber) && <div>
                        <FormFieldLabel label='Phone Number' />
                        <div className="ncod-display-value">{nfcCardOrderDetailSuccess.data.mobileNumber}</div>
                    </div>}
                    {!isEmpty(nfcCardOrderDetailSuccess.data.address) && <div>
                        <FormFieldLabel label='Address' />
                        <div className="ncod-display-value">{nfcCardOrderDetailSuccess.data.address}</div>
                    </div>}
                    <FormFieldLabel className='label-bold' label='Order Information' />
                    <FormFieldLabel label='Order Status' />
                    <div className="ncod-display-value">{removeSpecialCharAndChangeToPascal(nfcCardOrderDetailSuccess.data.orderStatus)}</div>
                    {!isEmpty(nfcCardOrderDetailSuccess.data.orderStatusError) && <div>
                        <FormFieldLabel label='Order Status Reason' />
                        <div className="ncod-display-value">{nfcCardOrderDetailSuccess.data.orderStatusError}</div>
                    </div>}
                    <FormFieldLabel label='Payment Status' />
                    <div className="ncod-display-value">{removeSpecialCharAndChangeToPascal(nfcCardOrderDetailSuccess.data.paymentStatus)}</div>
                    {!isEmpty(nfcCardOrderDetailSuccess.data.paymentMode) && <div>
                        <FormFieldLabel label='Payment Mode' />
                        <div className="ncod-display-value">{nfcCardOrderDetailSuccess.data.paymentMode}</div>
                    </div>}
                    {!isEmpty(nfcCardOrderDetailSuccess.data.paymentStatusError) && <div>
                        <FormFieldLabel label='Payment Status Reason' />
                        <div className="ncod-display-value">{nfcCardOrderDetailSuccess.data.paymentStatusError}</div>
                    </div>}
                    <div className="ncod-payment-row-container">
                        <div className="ncod-payment-row-item-container">Amount</div>
                        <div className="ncod-payment-row-item-container">{priceWithSymbol(nfcCardOrderDetailSuccess.data, nfcCardOrderDetailSuccess.data.amount)}</div>
                    </div>
                    <div className="ncod-payment-row-container">
                        <div className="ncod-payment-row-item-container">Discount</div>
                        <div className="ncod-payment-row-item-container">{priceWithSymbol(nfcCardOrderDetailSuccess.data, nfcCardOrderDetailSuccess.data.discountAmount)}</div>
                    </div>
                    <div className="ncod-payment-row-container">
                        <div className="ncod-payment-row-item-container">Tax</div>
                        <div className="ncod-payment-row-item-container">{priceWithSymbol(nfcCardOrderDetailSuccess.data, nfcCardOrderDetailSuccess.data.taxAmount)}</div>
                    </div>
                    <div className="ncod-payment-row-container">
                        <div className="ncod-payment-row-item-container">Other Charges</div>
                        <div className="ncod-payment-row-item-container">{priceWithSymbol(nfcCardOrderDetailSuccess.data, nfcCardOrderDetailSuccess.data.otherCharges)}</div>
                    </div>
                    <div className="ncod-payment-row-container">
                        <div className="ncod-payment-row-item-container">Shipping Charges</div>
                        <div className="ncod-payment-row-item-container">{priceWithSymbol(nfcCardOrderDetailSuccess.data, nfcCardOrderDetailSuccess.data.shippingCharges)}</div>
                    </div>
                    <div className="ncod-payment-row-container">
                        <div className="ncod-payment-row-item-container">Final Amount</div>
                        <div className="ncod-payment-row-item-container">{priceWithSymbol(nfcCardOrderDetailSuccess.data, nfcCardOrderDetailSuccess.data.finalAmount)}</div>
                    </div>
                    <FormFieldLabel className='label-bold' label='Delivery Information' />
                    <div className="ncod-display-value">{nfcCardOrderDetailSuccess.data.customerName}</div>
                    {/* <FormFieldLabel label='Contact Person Email' />
                    <div className="ncod-display-value">{nfcCardOrderDetailSuccess.data.customerEmail}</div> */}
                    <div className="ncod-display-value">{nfcCardOrderDetailSuccess.data.customerNumber}</div>
                    <div className="ncod-display-value">{nfcCardOrderDetailSuccess.data.orderAddress}</div>
                    <div className="ncod-display-value">{nfcCardOrderDetailSuccess.data.orderAddressCity}, {nfcCardOrderDetailSuccess.data.orderAddressState}, {nfcCardOrderDetailSuccess.data.orderAddressCountry}</div>
                    {!isEmpty(nfcCardOrderDetailSuccess.data.orderAddressLandmark) &&
                        <div>
                            <FormFieldLabel label='Address Landmark' />
                            <div className="ncod-display-value">{nfcCardOrderDetailSuccess.data.orderAddressLandmark}</div>
                        </div>}
                    <FormFieldLabel label='Address Pincode' />
                    <div className="ncod-display-value">{nfcCardOrderDetailSuccess.data.orderAddressPincode}</div>
                    {(isLoggedInUserAdmin(user) || isLoggedInUserOperator(user)) && <div>
                        <FormFieldLabel className='label-bold' label='Shipping Information' />
                        <FormFieldLabel label='Shipping Partner' />
                        <div className="ncod-display-value">{!isEmpty(nfcCardOrderDetailSuccess.data.shippingPartner) ? nfcCardOrderDetailSuccess.data.shippingPartner : '-'}</div>
                        <FormFieldLabel label='Shipping Status' />
                        <div className="ncod-display-value">{!isEmpty(nfcCardOrderDetailSuccess.data.shippingStatus) ? nfcCardOrderDetailSuccess.data.shippingStatus : '-'}</div>
                        <FormFieldLabel label='Tracking Id' />
                        <div className="ncod-display-value">{!isEmpty(nfcCardOrderDetailSuccess.data.shippingTrackingId) ? nfcCardOrderDetailSuccess.data.shippingTrackingId : '-'}</div>
                    </div>}
                    {!isEmpty(nfcCardOrderDetailSuccess.data.orderType) && nfcCardOrderDetailSuccess.data.orderType === APP_CONSTANTS.NFC_ORDER_TYPE.CORPORATE && <div>
                        <FormFieldLabel className='label-bold' label='Employee Information' />
                        <div className="ncod-employee-row-parent-container">
                            <div className="ncod-employee-row-container">
                                {nfcCardOrderDetailSuccess.data.employeeList?.map(e => (<div className="ncod-employee-row-item-container">
                                    <div className="ncod-employee-name-label">{e.firstName} {e.lastName}</div>
                                    <div className="ncod-employee-designation-label">{e.designation}</div>
                                    <div className="ncod-employee-contacy-label">{e.mobile}</div>
                                    <div className="ncod-employee-contacy-label">{e.email}</div>
                                    <div className="ncod-employee-contacy-label">{e.address}</div>
                                </div>))}
                            </div>
                        </div>
                    </div>}
                    <div className="ncod-design-container">
                        {nfcCardOrderDetailSuccess.data.designTemplateId === 'CARD_1' &&
                            <div className="ncod-card-design-container" ref={cardDesignRef}>
                                <NfcCardDesign1
                                    name={nfcCardOrderDetailSuccess.data.orderType === APP_CONSTANTS.NFC_ORDER_TYPE.INDIVIDUAL ? nfcCardOrderDetailSuccess.data.nameOnCard : 'Employee Name'}
                                    designation={nfcCardOrderDetailSuccess.data.orderType === APP_CONSTANTS.NFC_ORDER_TYPE.INDIVIDUAL ? nfcCardOrderDetailSuccess.data.jobTitle : 'Designation'}
                                    phoneNumber={nfcCardOrderDetailSuccess.data.orderType === APP_CONSTANTS.NFC_ORDER_TYPE.INDIVIDUAL ? nfcCardOrderDetailSuccess.data.mobileNumber : 'Phone Number'}
                                    email={nfcCardOrderDetailSuccess.data.orderType === APP_CONSTANTS.NFC_ORDER_TYPE.INDIVIDUAL ? nfcCardOrderDetailSuccess.data.email : 'Email Id'}
                                    address={nfcCardOrderDetailSuccess.data.orderType === APP_CONSTANTS.NFC_ORDER_TYPE.INDIVIDUAL ? nfcCardOrderDetailSuccess.data.address : 'Contact Business Address'}
                                    qrCodeUrl={nfcCardOrderDetailSuccess.data.orderType === APP_CONSTANTS.NFC_ORDER_TYPE.INDIVIDUAL ? `${config.qrCodeUrl}/${nfcCardOrderDetailSuccess.data.cardAlias}` : ''}
                                />
                            </div>}
                        {nfcCardOrderDetailSuccess.data.designTemplateId === 'CARD_2' &&
                            <div className="ncod-card-design-container" ref={cardDesignRef}>
                                <NfcCardDesign2
                                    name={nfcCardOrderDetailSuccess.data.orderType === APP_CONSTANTS.NFC_ORDER_TYPE.INDIVIDUAL ? nfcCardOrderDetailSuccess.data.nameOnCard : 'Employee Name'}
                                    designation={nfcCardOrderDetailSuccess.data.orderType === APP_CONSTANTS.NFC_ORDER_TYPE.INDIVIDUAL ? nfcCardOrderDetailSuccess.data.jobTitle : 'Designation'}
                                    phoneNumber={nfcCardOrderDetailSuccess.data.orderType === APP_CONSTANTS.NFC_ORDER_TYPE.INDIVIDUAL ? nfcCardOrderDetailSuccess.data.mobileNumber : 'Phone Number'}
                                    email={nfcCardOrderDetailSuccess.data.orderType === APP_CONSTANTS.NFC_ORDER_TYPE.INDIVIDUAL ? nfcCardOrderDetailSuccess.data.email : 'Email Id'}
                                    address={nfcCardOrderDetailSuccess.data.orderType === APP_CONSTANTS.NFC_ORDER_TYPE.INDIVIDUAL ? nfcCardOrderDetailSuccess.data.address : 'Contact Business Address'}
                                    qrCodeUrl={nfcCardOrderDetailSuccess.data.orderType === APP_CONSTANTS.NFC_ORDER_TYPE.INDIVIDUAL ? `${config.qrCodeUrl}/${nfcCardOrderDetailSuccess.data.cardAlias}` : ''}
                                />
                            </div>}
                        {nfcCardOrderDetailSuccess.data.designTemplateId === 'CARD_3' && <div className="ncod-card-design-container" ref={cardDesignRef}>
                            <NfcCardDesign3
                                name={nfcCardOrderDetailSuccess.data.orderType === APP_CONSTANTS.NFC_ORDER_TYPE.INDIVIDUAL ? nfcCardOrderDetailSuccess.data.nameOnCard : 'Employee Name'}
                                designation={nfcCardOrderDetailSuccess.data.orderType === APP_CONSTANTS.NFC_ORDER_TYPE.INDIVIDUAL ? nfcCardOrderDetailSuccess.data.jobTitle : 'Designation'}
                                phoneNumber={nfcCardOrderDetailSuccess.data.orderType === APP_CONSTANTS.NFC_ORDER_TYPE.INDIVIDUAL ? nfcCardOrderDetailSuccess.data.mobileNumber : 'Phone Number'}
                                email={nfcCardOrderDetailSuccess.data.orderType === APP_CONSTANTS.NFC_ORDER_TYPE.INDIVIDUAL ? nfcCardOrderDetailSuccess.data.email : 'Email Id'}
                                address={nfcCardOrderDetailSuccess.data.orderType === APP_CONSTANTS.NFC_ORDER_TYPE.INDIVIDUAL ? nfcCardOrderDetailSuccess.data.address : 'Contact Business Address'}
                                qrCodeUrl={nfcCardOrderDetailSuccess.data.orderType === APP_CONSTANTS.NFC_ORDER_TYPE.INDIVIDUAL ? `${config.qrCodeUrl}/${nfcCardOrderDetailSuccess.data.cardAlias}` : ''}
                            />
                        </div>}
                    </div>
                    {(isLoggedInUserAdmin(user) || isLoggedInUserOperator(user)) && showButtonContainer(nfcCardOrderDetailSuccess.data.orderStatus) === true && <ButtonRow isVertical={true}>
                        <CommonButton
                            label='Update Status'
                            onClick={() => { setShowStatusModal(true) }} />
                        <CommonButton
                            label='Update Shipping Information'
                            onClick={() => { setShowShippingModal(true) }} />
                        <CommonButton
                            label='Update Payment Information'
                            onClick={() => { setShowPaymentModal(true) }} />
                        <CommonButton
                            label='Download Design'
                            onClick={() => {
                                if (!isEmpty(nfcCardOrderDetailSuccess.data.orderType) && nfcCardOrderDetailSuccess.data.orderType === APP_CONSTANTS.NFC_ORDER_TYPE.CORPORATE) {
                                    nfcCardOrderDetailSuccess.data.employeeList.forEach((item, index) => { generateAndDownloadCard(item, index) });
                                } else {
                                    downloadQR()
                                }
                            }} />
                    </ButtonRow>}
                </CommonColorForm>}
            </CommonScreenContent >
            <Modal
                onHide={() => { setShowStatusModal(false) }}
                show={showStatusModal}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Update Order Status
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <FormFieldLabel label='Enter below details to update order status' />
                    <Select
                        menuPlacement="auto"
                        className='select-full-width-no-border'
                        value={orderStatusSelected}
                        getOptionLabel={e => e.value}
                        getOptionValue={e => e}
                        options={ORDER_STATUS_LIST}
                        placeholder='Select Status'
                        onChange={e => {
                            setOrderStatusSelected(e)
                        }} />
                    {!isEmpty(orderStatusSelected) && (orderStatusSelected.key === APP_CONSTANTS.NFC_CARD_ORDER_STATUS.REJECTED ||
                        orderStatusSelected.key === APP_CONSTANTS.NFC_CARD_ORDER_STATUS.CANCELLED ||
                        orderStatusSelected.key === APP_CONSTANTS.NFC_CARD_ORDER_STATUS.UN_DELIVERED) &&
                        <CommonInputFloatFull
                            id='orderError'
                            onChange={e => setOrderErrorMessage(e.target.value)}
                            placeholder='Reason'
                            type='text'
                            value={orderErrorMessage}
                        />}

                </Modal.Body>
                <Modal.Footer>
                    <CommonButton label='Submit'
                        onClick={() => { handleUpdateOrderStatusSubmitClick() }} />
                </Modal.Footer>
            </Modal>
            <Modal
                onHide={() => { setShowPaymentModal(false) }}
                show={showPaymentModal}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Update Payment Information
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <FormFieldLabel label='Enter below details to update payment information' />
                    <Select

                        menuPlacement="auto"
                        className='select-full-width-no-border'
                        value={selectedCurrency}
                        getOptionLabel={e => `${e.currency} (${e.symbol})`}
                        getOptionValue={e => e}
                        options={CurrencyLabel}
                        placeholder='Select Currency'
                        onChange={e => {
                            setSelectedCurrency(e)
                        }} />
                    <CommonInputFloatFull
                        id='amount'
                        onChange={e => setAmount(e.target.value)}
                        placeholder='Amount'
                        type='number'
                        value={amount}
                    />
                    <CommonInputRow>
                        <CommonInputFloatHalf
                            id='discount'
                            onChange={e => setDiscount(e.target.value)}
                            placeholder='Discount Amount'
                            type='number'
                            value={discount}
                        />
                        <CommonInputFloatHalf
                            id='tax'
                            onChange={e => setTaxAmount(e.target.value)}
                            placeholder='Tax Amount'
                            type='number'
                            value={taxAmount}
                        />
                    </CommonInputRow>
                    <CommonInputRow>
                        <CommonInputFloatHalf
                            id='otherCharges'
                            onChange={e => setOtherCharges(e.target.value)}
                            placeholder='Other Charges'
                            type='number'
                            value={otherCharges}
                        />
                        <CommonInputFloatHalf
                            id='shippingCharges'
                            onChange={e => setShippingCharges(e.target.value)}
                            placeholder='Shipping Charges'
                            type='number'
                            value={shippingCharges}
                        />
                    </CommonInputRow>
                    <CommonInputFloatFull id='finalAmount'
                        onChange={e => setFinalAmount(e.target.value)}
                        placeholder='Final Amount'
                        type='text'
                        value={finalAmount} />

                    <CommonInputFloatFull id='paymentLink'
                        onChange={e => setPaymentLink(e.target.value)}
                        placeholder='Payment Link'
                        type='text'
                        value={paymentLink} />
                    <Select
                        menuPlacement="auto"
                        className='select-full-width-no-border'
                        value={paymentStatusSelected}
                        getOptionLabel={e => e.value}
                        getOptionValue={e => e}
                        options={PAYMENT_STATUS_LIST}
                        placeholder='Select Status'
                        onChange={e => {
                            setPaymentStatusSelected(e)
                        }} />
                    {!isEmpty(paymentStatusSelected) && paymentStatusSelected.key === 'PAID' &&
                        <CommonInputFloatFull
                            id='paymentMode'
                            onChange={e => setPaymentMode(e.target.value)}
                            placeholder='Payment Mode'
                            type='text'
                            value={paymentMode}
                        />}
                    {!isEmpty(paymentStatusSelected) && paymentStatusSelected.key === 'FAILED' && <div>
                        <CommonInputFloatFull
                            id='paymentMode'
                            onChange={e => setPaymentMode(e.target.value)}
                            placeholder='Payment Mode'
                            type='text'
                            value={paymentMode}
                        />
                        <CommonInputFloatFull
                            id='paymentErrorMessage'
                            onChange={e => setPaymentErrorMessage(e.target.value)}
                            placeholder='Reason'
                            type='text'
                            value={paymentErrorMessage}
                        />
                    </div>}

                </Modal.Body>
                <Modal.Footer>
                    <CommonButton label='Submit'
                        onClick={() => { handlePaymentDetailSubmitClick() }} />
                </Modal.Footer>
            </Modal>
            <Modal
                onHide={() => { setShowShippingModal(false) }}
                show={showShippingModal}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Update Shipping Information
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <FormFieldLabel label='Enter below details to update shipping information' />
                    <CommonInputFloatFull id='partner'
                        onChange={e => setShippingPartner(e.target.value)}
                        placeholder='Shipping Partner'
                        type='text'
                        value={shippingPartner} />
                    <CommonInputFloatFull id='trackingId'
                        onChange={e => setShippingTrackingId(e.target.value)}
                        placeholder='Tracking Id/Url'
                        type='text'
                        value={shippingTrackingId} />
                    <CommonInputFloatFull id='shippingStatus'
                        onChange={e => setShippingStatus(e.target.value)}
                        placeholder='Shipping Status'
                        type='text'
                        value={shippingStatus} />
                </Modal.Body>
                <Modal.Footer>
                    <CommonButton label='Submit'
                        onClick={() => { handleShippingDetailSubmitClick() }} />
                </Modal.Footer>
            </Modal>
            <ToastContainer />
            <Loader active={isLoading} text='Please wait...' />
        </div>
    )
}

export default NfcCardOrderDetail