import React, { useEffect, useState } from "react";
import './styles.css'
import { useParams, useSearchParams } from "react-router-dom";
import Loader from '../../../components/loader/loader.component'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useDispatch, useSelector } from 'react-redux'
import { clearLocalStorage, displayConsoleLog, getDeviceType, isEmpty, isNull } from "../../../utils/Utils";
import { cardByAliasRequest } from '../../../redux/digital-card/digitalCard.action'
import CommonScreenContent from "../../../components/screen/common-screen-content/commonScreenContent";
import { config } from "../../../config";
import FormFieldLabel from "../../../components/label/form-field-label/formFieldLabel";
import { QRCode } from 'react-qrcode-logo';
import ButtonRow from "../../../components/button/button-row/buttonRow";
import CommonButton from "../../../components/button/common-button/commonButton";
import { API_CONSTANTS, APP_CONSTANTS, ROUTES } from "../../../utils/AppConstants";
import UserCardPreview from './userCardPreview'
import { cardVisitAnayticsAddRecordRequest, cardVisitAnayticsAddRecordRequestStatus } from '../../../redux/site-visitor-analytics/siteVisitorAnalytics.action'

const UserCard = props => {
    const dispatch = useDispatch()
    const [isLoading, setLoading] = useState(false)
    let { cardAlias } = useParams();
    const { cardByAliasSuccess, cardByAliasError } = useSelector((state) => state.digitalCard)
    const [qrCodeUrl, setQRCodeUrl] = useState('')
    const { cardVisitAnalyticsSuccess, cardVisitAnalyticsError, cardVisitAnalyticsReqStatus } = useSelector((state) => state.siteVisitorAnalytics)

    function isFetchFromApi() {
        let fetchFromApi = true
        if (!isEmpty(props)) {
            if (!isEmpty(props.isPreview) && props.isPreview === true) {
                fetchFromApi = false
            }
        }
        return fetchFromApi
    }

    useEffect(() => {
        if (isFetchFromApi() === true) {
            clearLocalStorage()
            dispatch({ type: APP_CONSTANTS.LOGOUT })
            setLoading(true)
            dispatch(cardByAliasRequest(cardAlias))
        }
    }, [])

    useEffect(() => {
        if (isFetchFromApi() === true) {
            if (!isNull(cardByAliasSuccess)) {
                setLoading(false)
                if (!isNull(cardByAliasSuccess.data) && !isNull(cardByAliasSuccess.data.otherDetails)) {
                    // setQRCodeUrl(`${config.qrCodeUrl}/${cardByAliasSuccess.data.otherDetails.userAlias}`)
                    setQRCodeUrl(`${config.webUrl}${ROUTES.homeCards}`)
                    processCardVisitAnalytics(cardByAliasSuccess.data)
                }
            } else if (!isNull(cardByAliasError)) {
                setLoading(false)
            }
        }
    }, [cardByAliasSuccess, cardByAliasError])

    useEffect(() => {
        if (cardVisitAnalyticsReqStatus === API_CONSTANTS.SUCCESS_STATUS) {
            if (!isNull(cardVisitAnalyticsSuccess) && !isNull(cardVisitAnalyticsSuccess.data)) {
                displayConsoleLog('cardVisitAnalyticsSuccess', cardVisitAnalyticsSuccess)
                // if (!localStorage.getItem('cardRecordId')) {
                displayConsoleLog('----Set visit Record Id')
                localStorage.setItem('cardRecordId', cardVisitAnalyticsSuccess.data.cardVisitAnalyticsId)
                // }
            }
        }
        if (cardVisitAnalyticsReqStatus?.length) {
            dispatch(cardVisitAnayticsAddRecordRequestStatus(''))
        }
    }, [cardVisitAnalyticsSuccess, cardVisitAnalyticsError, cardVisitAnalyticsReqStatus])

    const processCardVisitAnalytics = (cardData) => {
        if (!isEmpty(cardData.digitalCardId)) {
            const data = {
                digitalCardId: cardData.digitalCardId,
                deviceType: getDeviceType(window)
            }
            const recordId = localStorage.getItem('cardRecordId');
            if (recordId) {
                data.recordId = recordId
            }
            dispatch(cardVisitAnayticsAddRecordRequest(data))
        }
    }

    const isIOS = () => {
        return /iPad|iPhone|iPod/.test(navigator.userAgent);
    };

    const downloadContactFile = vcfText => {
        const file = new Blob([vcfText], { type: "text/vcard;charset=utf-8" });
        const element = document.createElement("a");
        const fileURL = URL.createObjectURL(file);
        let rNumber = Math.floor(100 + Math.random() * 900);

        if (isIOS()) {
            window.open(fileURL, "_blank");
        } else {
            element.href = fileURL;
            element.download = `contactCard${rNumber}.vcf`;
            document.body.appendChild(element);
            element.click();
            URL.revokeObjectURL(fileURL);
            document.body.removeChild(element);
        }
        // const element = document.createElement("a");
        // const file = new Blob([vcfText], { type: "text/plain;charset=utf-8" });
        // element.href = URL.createObjectURL(file);
        // let rNumber = Math.floor(100 + Math.random() * 900)
        // element.download = `contactCard${rNumber}.vcf`;
        // document.body.appendChild(element);
        // element.click();
    }

    const getPhoneLabel = type => {
        if (type === 'Home') {
            return 'HOME,VOICE:'
        } else if (type === 'Mobile') {
            return 'CELL:'
        } else if (type === 'Work') {
            return 'WORK,VOICE:'
        } else if (type === 'Fax') {
            return 'WORK,FAX:'
        }
    }

    const getAddressLabel = type => {
        if (type === 'Home') {
            return 'HOME'
        } else if (type === 'Work') {
            return 'WORK'
        }
    }

    const CreateVCard = () => {
        // var vCardsJS = require("vcards-js");

        //create a new vCard
        // var vCard = vCardsJS();

        //set properties
        // vCard.firstName = cardByAliasSuccess.data.otherDetails.firstName;
        // if (!isEmpty(cardByAliasSuccess.data.otherDetails.lastName))
        //     vCard.lastName = cardByAliasSuccess.data.otherDetails.lastName;
        // if (!isEmpty(cardByAliasSuccess.data.otherDetails.email))
        //     vCard.email = cardByAliasSuccess.data.otherDetails.email
        // let cellPhoneList = []
        // if (!isEmpty(cardByAliasSuccess.data.otherDetails.mobile) && Number(cardByAliasSuccess.data.otherDetails.mobile) > 0)
        //     cellPhoneList.push(`+${cardByAliasSuccess.data.otherDetails.mobile}`)
        // if (!isEmpty(cardByAliasSuccess.data.otherDetails.mobile1) && Number(cardByAliasSuccess.data.otherDetails.mobile1) > 0)
        //     cellPhoneList.push(`+${cardByAliasSuccess.data.otherDetails.mobile1}`)
        // if (!isEmpty(cardByAliasSuccess.data.otherDetails.mobile2) && Number(cardByAliasSuccess.data.otherDetails.mobile2) > 0)
        //     cellPhoneList.push(`+${cardByAliasSuccess.data.otherDetails.mobile2}`)
        // if (!isEmpty(cardByAliasSuccess.data.otherDetails.mobile3) && Number(cardByAliasSuccess.data.otherDetails.mobile3) > 0)
        //     cellPhoneList.push(`+${cardByAliasSuccess.data.otherDetails.mobile3}`)
        // if (!isEmpty(cellPhoneList))
        //     vCard.cellPhone = cellPhoneList
        // if (!isEmpty(cardByAliasSuccess.data.otherDetails.whatsappLink) && Number(cardByAliasSuccess.data.otherDetails.whatsappLink) > 0)
        //     vCard.homePhone = `+${cardByAliasSuccess.data.otherDetails.whatsappLink}`
        // if (!isEmpty(cardByAliasSuccess.data.otherDetails.companyName))
        //     vCard.organization = cardByAliasSuccess.data.otherDetails.companyName
        // if (!isEmpty(cardByAliasSuccess.data.otherDetails.companyNumber) && Number(cardByAliasSuccess.data.otherDetails.companyNumber) > 0)
        //     vCard.workPhone = `+${cardByAliasSuccess.data.otherDetails.companyNumber}`;
        // if (!isEmpty(cardByAliasSuccess.data.otherDetails.jobTitle))
        //     vCard.title = cardByAliasSuccess.data.otherDetails.jobTitle;
        // if (!isEmpty(cardByAliasSuccess.data.otherDetails.facebookLink))
        //     vCard.socialUrls['facebook'] = `${cardByAliasSuccess.data.otherDetails.facebookLink}`;
        // if (!isEmpty(cardByAliasSuccess.data.otherDetails.linkedInLink))
        //     vCard.socialUrls['linkedIn'] = `${cardByAliasSuccess.data.otherDetails.linkedInLink}`;
        // if (!isEmpty(cardByAliasSuccess.data.otherDetails.twitterLink))
        //     vCard.socialUrls['twitter'] = `${cardByAliasSuccess.data.otherDetails.twitterLink}`;
        // if (!isEmpty(cardByAliasSuccess.data.otherDetails.instagramLink))
        //     vCard.socialUrls['instagram'] = `${cardByAliasSuccess.data.otherDetails.instagramLink}`;
        // vCard.url = `${config.qrCodeUrl}/${cardByAliasSuccess.data.userAlias}`;
        let vCardData = `BEGIN:VCARD
        VERSION:3.0
        FN;CHARSET=UTF-8:${cardByAliasSuccess.data.otherDetails.firstName} ${cardByAliasSuccess.data.otherDetails.lastName ? cardByAliasSuccess.data.otherDetails.lastName : ''}
        N;CHARSET=UTF-8:${cardByAliasSuccess.data.otherDetails.lastName ? cardByAliasSuccess.data.otherDetails.lastName : ''};${cardByAliasSuccess.data.otherDetails.firstName};;;
        ${!isEmpty(cardByAliasSuccess.data.otherDetails.email1) ? `EMAIL;CHARSET=UTF-8;type=HOME,INTERNET:${cardByAliasSuccess.data.otherDetails.email1}` : ''}
        ${!isEmpty(cardByAliasSuccess.data.otherDetails.email2) ? `EMAIL;CHARSET=UTF-8;type=WORK,INTERNET:${cardByAliasSuccess.data.otherDetails.email2}` : ''}
        ${!isEmpty(cardByAliasSuccess.data.otherDetails.homeNumber) ? `TEL;TYPE=${getPhoneLabel('Home')}${cardByAliasSuccess.data.otherDetails.homeNumber}` : ''}
        ${!isEmpty(cardByAliasSuccess.data.otherDetails.mobileNumber) ? `TEL;TYPE=${getPhoneLabel('Mobile')}+91${cardByAliasSuccess.data.otherDetails.mobileNumber}` : ''}
        ${!isEmpty(cardByAliasSuccess.data.otherDetails.fax) ? `TEL;TYPE=${getPhoneLabel('Fax')}${cardByAliasSuccess.data.otherDetails.fax}` : ''}
        ${!isEmpty(cardByAliasSuccess.data.otherDetails.workNumber1) ? `TEL;TYPE=${getPhoneLabel('Work')}${cardByAliasSuccess.data.otherDetails.workNumber1}` : ''}
        ${!isEmpty(cardByAliasSuccess.data.otherDetails.workNumber2) ? `TEL;TYPE=${getPhoneLabel('Work')}${cardByAliasSuccess.data.otherDetails.workNumber2}` : ''}
        ${!isEmpty(cardByAliasSuccess.data.otherDetails.homeAddress) ? `ADR;TYPE=${getAddressLabel('Home')};LABEL="${cardByAliasSuccess.data.otherDetails.homeAddress}":;;${cardByAliasSuccess.data.otherDetails.homeAddress}` : ''}
        ${!isEmpty(cardByAliasSuccess.data.otherDetails.workAddress) ? `ADR;TYPE=${getAddressLabel('Work')};LABEL="${cardByAliasSuccess.data.otherDetails.workAddress}":;;${cardByAliasSuccess.data.otherDetails.workAddress}` : ''}
        ${!isEmpty(cardByAliasSuccess.data.otherDetails.jobTitle) ? `TITLE;CHARSET=UTF-8:${cardByAliasSuccess.data.otherDetails.jobTitle}` : ''}
        ${!isEmpty(cardByAliasSuccess.data.otherDetails.companyName) ? `ORG;CHARSET=UTF-8:${cardByAliasSuccess.data.otherDetails.companyName}` : ''}
        URL;CHARSET=UTF-8:${config.qrCodeUrl}/${cardByAliasSuccess.data.userAlias}
        ${!isEmpty(cardByAliasSuccess.data.otherDetails.facebookLink) ? `X-SOCIALPROFILE;CHARSET=UTF-8;TYPE=facebook:${cardByAliasSuccess.data.otherDetails.facebookLink}` : ''}
        ${!isEmpty(cardByAliasSuccess.data.otherDetails.linkedInLink) ? `X-SOCIALPROFILE;CHARSET=UTF-8;TYPE=linkedIn:${cardByAliasSuccess.data.otherDetails.linkedInLink}` : ''}
        ${!isEmpty(cardByAliasSuccess.data.otherDetails.twitterLink) ? `X-SOCIALPROFILE;CHARSET=UTF-8;TYPE=twitter:${cardByAliasSuccess.data.otherDetails.twitterLink}` : ''}
        ${!isEmpty(cardByAliasSuccess.data.otherDetails.instagramLink) ? `X-SOCIALPROFILE;CHARSET=UTF-8;TYPE=instagram:${cardByAliasSuccess.data.otherDetails.instagramLink}` : ''}
        REV:${new Date().toISOString()}
        END:VCARD`
        // console.log('vCard:--', vCard.getFormattedString())
        // console.log('vCar:--', vCardData.replace(/^(?=\n)$|^\s*|\s*$|\n\n+/gm, ""))

        let data = `BEGIN: VCARD
        VERSION: 3.0
        FN; CHARSET = UTF - 8:Devesh Sharma
        N; CHARSET = UTF - 8: Sharma; Devesh;;;
        EMAIL; CHARSET = UTF - 8; type = HOME, INTERNET:devesh @xyz.com
        TEL; TYPE = CELL: +911234
        TEL; TYPE = CELL: +919876
        TEL; TYPE = HOME, VOICE: +9100000
        TEL; TYPE = WORK, VOICE: +9178905
        TITLE; CHARSET = UTF - 8: CTO
        ORG; CHARSET = UTF - 8: GoMoBites
        URL; CHARSET = UTF - 8: http://localhost:3000/vCard/officecard
        X - SOCIALPROFILE; CHARSET = UTF - 8; TYPE = facebook: https://www.facebook.com/devesh.s28
        X - SOCIALPROFILE; CHARSET = UTF - 8; TYPE = linkedIn: www.linkedin.com /in /deveshsharma28
        REV: 2023 - 01 - 06T05: 47: 29.067Z
        END: VCARD`

        //save to file
        // vCard.saveToFile("./eric-nesser.vcf");

        //get as formatted string
        // console.log(vCard.getFormattedString());
        // return vCard.getFormattedString();
        return vCardData.replace(/^(?=\n)$|^\s*|\s*$|\n\n+/gm, "");
    };

    const displayProfileImage = (cardObj) => {
        let flag = false
        if (!isNull(cardObj)) {
            if ((!isEmpty(cardObj.profileImage) && !cardObj.profileImage.includes('placeholder'))) {
                flag = true
            }
        }
        return flag
    }

    const displayLogoImage = (cardObj) => {
        let flag = false
        if (!isNull(cardObj)) {
            if ((!isEmpty(cardObj.brandLogoImg) && !cardObj.brandLogoImg.includes('placeholder'))) {
                flag = true
            }
        }
        return flag
    }

    return (<>
        {!isNull(cardByAliasSuccess) && !isNull(cardByAliasSuccess.data) && !isNull(cardByAliasSuccess.data.otherDetails) &&
            <UserCardPreview
                cardObject={cardByAliasSuccess.data.otherDetails}
                isApiData={isFetchFromApi()}
                qrCodeUrl={qrCodeUrl}
                showDownloadButton={true}
                isProfileImageFromUrl={displayProfileImage(cardByAliasSuccess.data.otherDetails)}
                isBrandLogoFromUrl={displayLogoImage(cardByAliasSuccess.data.otherDetails)}
                showProfileImage={displayProfileImage(cardByAliasSuccess.data.otherDetails)}
                showBrandLogo={displayLogoImage(cardByAliasSuccess.data.otherDetails)}
                downloadClickListener={() => downloadContactFile(CreateVCard())} />
        }
        <ToastContainer />
        <Loader active={isLoading} text='Please wait...' />
    </>)
}

export default UserCard