import { ProductActionTypes } from './product.types'

//Add Product
export const addProductRequest = data => ({
    type: ProductActionTypes.ADD_PRODUCT_REQUEST,
    payload: data
})

export const addProductRequestSuccess = data => ({
    type: ProductActionTypes.ADD_PRODUCT_REQUEST_SUCCESS,
    payload: data
})

export const addProductRequestError = data => ({
    type: ProductActionTypes.ADD_PRODUCT_REQUEST_ERROR,
    payload: data
})

export const addProductRequestStatus = data => ({
    type: ProductActionTypes.ADD_PRODUCT_REQUEST_STATUS,
    payload: data
})

//Product List
export const productListRequest = data => ({
    type: ProductActionTypes.PRODUCT_LIST_REQUEST,
    payload: data
})

export const productListRequestSuccess = data => ({
    type: ProductActionTypes.PRODUCT_LIST_REQUEST_SUCCESS,
    payload: data
})

export const productListRequestError = data => ({
    type: ProductActionTypes.PRODUCT_LIST_REQUEST_ERROR,
    payload: data
})

//Update Product
export const updateProductRequest = data => ({
    type: ProductActionTypes.UPDATE_PRODUCT_REQUEST,
    payload: data
})

export const updateProductRequestSuccess = data => ({
    type: ProductActionTypes.UPDATE_PRODUCT_REQUEST_SUCCESS,
    payload: data
})

export const updateProductRequestError = data => ({
    type: ProductActionTypes.UPDATE_PRODUCT_REQUEST_ERROR,
    payload: data
})

export const updateProductRequestStatus = data => ({
    type: ProductActionTypes.UPDATE_PRODUCT_REQUEST_STATUS,
    payload: data
})

//Delete Product
export const deleteProductRequest = data => ({
    type: ProductActionTypes.DELETE_PRODUCT_REQUEST,
    payload: data
})

export const deleteProductRequestSuccess = data => ({
    type: ProductActionTypes.DELETE_PRODUCT_REQUEST_SUCCESS,
    payload: data
})

export const deleteProductRequestError = data => ({
    type: ProductActionTypes.DELETE_PRODUCT_REQUEST_ERROR,
    payload: data
})

export const deleteProductRequestStatus = data => ({
    type: ProductActionTypes.DELETE_PRODUCT_REQUEST_STATUS,
    payload: data
})