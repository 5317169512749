export const SiteVisitorAnalyticsActionTypes = {
    LOGOUT: 'LOGOUT',
    COMMON_ERROR: 'COMMON_ERROR',

    SITE_VISITOR_ANALYTICS_ADD_RECORD_REQUEST: 'SITE_VISITOR_ANALYTICS_ADD_RECORD_REQUEST',
    SITE_VISITOR_ANALYTICS_ADD_RECORD_REQUEST_SUCCESS: 'SITE_VISITOR_ANALYTICS_ADD_RECORD_REQUEST_SUCCESS',
    SITE_VISITOR_ANALYTICS_ADD_RECORD_REQUEST_ERROR: 'SITE_VISITOR_ANALYTICS_ADD_RECORD_REQUEST_ERROR',
    SITE_VISITOR_ANALYTICS_ADD_RECORD_REQUEST_STATUS: 'SITE_VISITOR_ANALYTICS_ADD_RECORD_REQUEST_STATUS',

    SITE_VISITOR_ANALYTICS_LIST_REQUEST: 'SITE_VISITOR_ANALYTICS_LIST_REQUEST',
    SITE_VISITOR_ANALYTICS_LIST_REQUEST_SUCCESS: 'SITE_VISITOR_ANALYTICS_LIST_REQUEST_SUCCESS',
    SITE_VISITOR_ANALYTICS_LIST_REQUEST_ERROR: 'SITE_VISITOR_ANALYTICS_LIST_REQUEST_ERROR',

    CARD_VISITOR_ANALYTICS_ADD_RECORD_REQUEST: 'CARD_VISITOR_ANALYTICS_ADD_RECORD_REQUEST',
    CARD_VISITOR_ANALYTICS_ADD_RECORD_REQUEST_SUCCESS: 'CARD_VISITOR_ANALYTICS_ADD_RECORD_REQUEST_SUCCESS',
    CARD_VISITOR_ANALYTICS_ADD_RECORD_REQUEST_ERROR: 'CARD_VISITOR_ANALYTICS_ADD_RECORD_REQUEST_ERROR',
    CARD_VISITOR_ANALYTICS_ADD_RECORD_REQUEST_STATUS: 'CARD_VISITOR_ANALYTICS_ADD_RECORD_REQUEST_STATUS',

    SITE_AND_USER_ANALYTICS_LIST_REQUEST: 'SITE_AND_USER_ANALYTICS_LIST_REQUEST',
    SITE_AND_USER_ANALYTICS_LIST_REQUEST_SUCCESS: 'SITE_AND_USER_ANALYTICS_LIST_REQUEST_SUCCESS',
    SITE_AND_USER_ANALYTICS_LIST_REQUEST_ERROR: 'SITE_AND_USER_ANALYTICS_LIST_REQUEST_ERROR'
}
