import React from "react";
import './styles.css'
import CommonScreenContentCenter from "../../components/screen/common-screen-content-center/commonScreenContentCenter";

const NotFoundPage = props => {
    return (
        <CommonScreenContentCenter>
            <div className="error-container">
                <div className="error-code">404</div>
                <h2>Page not found</h2>
                <div>Uh oh, we can’t seem to find the page you’re looking for. Try going back to the previous page.</div>
            </div>
        </CommonScreenContentCenter>
    )
}

export default NotFoundPage