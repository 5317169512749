import React, { useEffect, useState } from "react";
import './styles.css'
import QRCode from "react-qr-code";
import { isEmpty } from "../../../../utils/Utils";

const NfcCardDesign2 = props => {
    let { name, designation, phoneNumber, email, address, qrCodeUrl,
        selectDesignHandler, isSelected, showButton = false } = props
    // phoneNumber = (!isEmpty(phoneNumber) && !phoneNumber.includes('+')) ? `+${phoneNumber}` : phoneNumber
    return (
        <div className="nts-card-parent-container">
            <div className="nts-card-container-two">
                <div className="nts-card-container-two-name-container">
                    <div className="nts-card-container-two-name">{name}</div>
                    <div className="nts-card-container-two-designation">{designation}</div>
                </div>
                <div className="nts-card-container-two-content-container">
                    <div className="nts-card-container-two-qr-container">
                        <QRCode value={qrCodeUrl} size={75}
                            quietZone={0} fgColor="#000080"
                        />
                    </div>
                    <div className="nts-card-container-two-other-container">
                        <div className="nts-card-container-two-other-row">
                            <div>{!isEmpty(phoneNumber) ? (<div className="nts-card-container-one-icon-container">
                                <img src={require('../../../../assets/nfc/ic_phone_blue.png')} className="img" alt="..." />
                            </div>) : null}</div>
                            <span className="nts-card-container-two-other-text">{phoneNumber}</span>
                        </div>
                        <div className="nts-card-container-two-other-row">
                            <div>{!isEmpty(email) ? (<div className="nts-card-container-one-icon-container">
                                <img src={require('../../../../assets/nfc/ic_email_blue.png')} className="img" alt="..." />
                            </div>) : null}</div>
                            <span className="nts-card-container-two-other-text">{email}</span>
                        </div>
                        <div className="nts-card-container-two-other-row-address">
                            <div>{!isEmpty(address) ? (<div className="nts-card-container-one-icon-container">
                                <img src={require('../../../../assets/nfc/ic_address_blue.png')} className="img" alt="..." />
                            </div>) : null}</div>
                            <span className="nts-card-container-two-other-text">{address}</span>
                        </div>
                    </div>
                </div>
            </div>
            {
                showButton === true && <div className="nts-select-design-label-container">
                    <div className={`${isSelected === true ? "nts-select-design-label-selected" : "nts-select-design-label"}`} onClick={selectDesignHandler}>Select Design</div>
                </div>
            }
        </div >
    )
}

export default NfcCardDesign2