import React, { useEffect, useState } from "react";
import './styles.css'
import QRCode from "react-qr-code";
import { isEmpty } from "../../../../utils/Utils";

const NfcCardDesign3 = props => {
    const { name, designation, phoneNumber, email, address, qrCodeUrl,
        selectDesignHandler, isSelected, showButton = false } = props
    return (
        <div className="nts-card-parent-container">
            <div className="nts-card-container-three">
                <div className="nts-card-container-three-qr-parent-container">
                    <div className="nts-card-container-three-qr-container">
                        <QRCode value={qrCodeUrl} size={75}
                            quietZone={0} fgColor="#6f2884"
                        />
                    </div>
                </div>
                <div className="nts-card-three-vertical-seprator"></div>
                <div className="nts-card-container-three-content-container">
                    <div className="nts-card-container-three-name">{name}</div>
                    <div className="nts-card-container-three-designation">{designation}</div>
                    <div className="nts-card-container-three-other-container">
                        <div className="nts-card-container-three-other">
                            {!isEmpty(phoneNumber) ? (<div className="nts-card-container-one-icon-container">
                                <img src={require('../../../../assets/nfc/ic_phone_purple.png')} className="img" alt="..." />
                            </div>) : null}
                            <span className="nts-card-container-three-other-text">{phoneNumber}</span>
                        </div>
                        <div className="nts-card-container-three-other">
                            {!isEmpty(email) ? (<div className="nts-card-container-one-icon-container">
                                <img src={require('../../../../assets/nfc/ic_email_purple.png')} className="img" alt="..." />
                            </div>) : null}
                            <span className="nts-card-container-three-other-text">{email}</span>
                        </div>
                        <div className="nts-card-container-three-other">
                            {!isEmpty(address) ? (<div className="nts-card-container-one-icon-container">
                                <img src={require('../../../../assets/nfc/ic_address_purple.png')} className="img" alt="..." />
                            </div>) : null}
                            <span className="nts-card-container-three-other-text">{address}</span>
                        </div>
                    </div>
                </div>
            </div>
            {showButton === true && <div className="nts-select-design-label-container">
                <div className={`${isSelected === true ? "nts-select-design-label-selected" : "nts-select-design-label"}`} onClick={selectDesignHandler}>Select Design</div>
            </div>}
        </div>
    )
}

export default NfcCardDesign3