const API_ENDPOINTS = {
    account: {
        signIn: 'user/login',
        userRegistration: 'user/userRegistration',
        userRegistrationByMobile: 'user/userRegistrationByMobile',
        forgotPassword: 'user/forgotPassword',
        changePassword: 'user/changePassword',
        subscriptionList: 'subscription/list',
        updateNotificationToken: 'user/updateNotificationToken',
        customerCardRequest: 'user/customerCardRequest',
        userRegistrationBusiness: 'user/userRegistrationBusiness',
        businessDetails: 'business/businessDetails',
        updateVisitorConfig: 'business/updateVisitorConfig',
        updateBusinessNotificationToken: 'business/updateNotificationToken'
    },
    contactUs: {
        contactUsAddRecord: 'contactUs/addRecord'
    },
    digitalCard: {
        addCard: 'digitalCard/addRecord',
        checkAliasAvailability: 'digitalCard/checkAliasAvailability',
        cardList: 'digitalCard/cardList',
        updateCard: 'digitalCard/updateCard',
        addCardDetails: 'digitalCard/addCardDetail',
        updateCardDetails: 'digitalCard/updateDetail',
        cardByAlias: 'digitalCard/cardByAlias',
        createCardWithDetails: 'digitalCard/createCardWithDetails',
        deleteCard: 'digitalCard/deleteCard'
    },
    blog: {
        addBlog: 'dcBlog/addBlog',
        blogList: 'dcBlog/blogList',
        updateBlog: 'dcBlog/updateBlog',
        deleteBlog: 'dcBlog/deleteBlog'
    },
    product: {
        addProduct: 'dcProduct/addProduct',
        productList: 'dcProduct/productList',
        updateProduct: 'dcProduct/updateProduct',
        deleteProduct: 'dcProduct/deleteProduct'
    },
    service: {
        addService: 'dcService/addService',
        serviceList: 'dcService/serviceList',
        updateService: 'dcService/updateService',
        deleteService: 'dcService/deleteService'
    },
    testimonial: {
        addTestimonial: 'dcTestimonial/addTestimonial',
        testimonialList: 'dcTestimonial/testimonialList',
        updateTestimonial: 'dcTestimonial/updateTestimonial',
        deleteTestimonial: 'dcTestimonial/deleteTestimonial'
    },
    appointment: {
        addAppointment: 'dcAppointment/addAppointment',
        appointmentList: 'dcAppointment/appointmentList',
        updateAppointmentStatus: 'dcAppointment/updateStatus'
    },
    enquiry: {
        addEnquiry: 'dcEnquiry/addEnquiry',
        enquiryList: 'dcEnquiry/enquiryList',
        updateEnquiryStatus: 'dcEnquiry/updateStatus'
    },
    location: {
        countryList: 'https://api.countrystatecity.in/v1/countries',
        stateList: 'states',
        cityList: 'cities'
    },
    customQrCode: {
        addQrCode: 'qrCode/addQrCode',
        qrCodeList: 'qrCode/qrCodeList',
        deleteQrCode: 'qrCode/deleteQrCode',
        updateQrCode: 'qrCode/updateQrCode',
        qrDetail: 'qrCode/qrDetails'
    },
    feedback: {
        addFeedback: 'feedback/submit'
    },
    visitor: {
        addRecord: 'visitor/addRecord',
        visitorList: 'visitor/visitorList',
        markExit: 'visitor/markExit'
    },
    hotelPoint: {
        addRecord: 'point/addRecord',
        recordList: 'point/recordList'
    },
    newsLetter: {
        addRecord: 'newsLetter/addRecord',
        recordList: 'newsLetter/recordList'
    },
    visitorAnalytics: {
        addRecord: 'siteAnalytics/addRecord',
        recordList: 'siteAnalytics/recordList',
        cardVisitAddRecord: 'siteAnalytics/cardVisitRecord',
        siteAndUserList: 'siteAnalytics/siteAndUserList'
    },
    nfcCardOrder: {
        placeOrder: 'nfcCard/placeOrder',
        orderList: 'nfcCard/orderList',
        orderDetails: 'nfcCard/orderDetail',
        updateOrder: 'nfcCard/updateOrder'
    },
    employee: {
        addEmployee: 'employee/addEmployee',
        employeeList: 'employee/employeeList',
        employeeDetail: 'employee/employeeDetail'
    },
    operator: {
        operatorRegistration: 'user/operatorRegistration',
        operatorList: 'user/operatorList',
        updateOperatorLocation: 'user/updateOperatorLocation',
        operatorDetails: 'user/operatorDetail'
    }
}

export default API_ENDPOINTS