import axios from 'axios'
import { config } from '../../config'
import { APP_CONSTANTS } from '../../utils/AppConstants'
import API_ENDPOINTS from './endpoints'

export function addBlogAPI(payload) {
    const user = JSON.parse(sessionStorage.getItem(APP_CONSTANTS.USER))
    return axios.request({
        method: 'post',
        url: `${config.baseUrl}/${API_ENDPOINTS.blog.addBlog}`,
        data: payload,
        headers: {
            "Content-Type": "multipart/form-data",
            'authToken': user ? user.authToken : config.cid
        }
    })
}

export function blogListAPI(payload) {
    const user = JSON.parse(sessionStorage.getItem(APP_CONSTANTS.USER))
    return axios.request({
        method: 'get',
        url: `${config.baseUrl}/${API_ENDPOINTS.blog.blogList}/${payload}`,
        headers: {
            'authToken': user ? user.authToken : config.cid
        }
    })
}

export function updateBlogAPI(payload) {
    const user = JSON.parse(sessionStorage.getItem(APP_CONSTANTS.USER))
    return axios.request({
        method: 'put',
        url: `${config.baseUrl}/${API_ENDPOINTS.blog.updateBlog}`,
        data: payload,
        headers: {
            "Content-Type": "multipart/form-data",
            'authToken': user ? user.authToken : config.cid
        }
    })
}

export function deleteBlogAPI(payload) {
    const user = JSON.parse(sessionStorage.getItem(APP_CONSTANTS.USER))
    return axios.request({
        method: 'put',
        url: `${config.baseUrl}/${API_ENDPOINTS.blog.deleteBlog}`,
        data: payload,
        headers: {
            'authToken': user ? user.authToken : config.cid
        }
    })
}