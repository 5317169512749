import { DigitalCardActionTypes } from './digitalCard.types'
import { API_CONSTANTS } from '../../utils/AppConstants'

const INITIAL_STATE = {
    addDigitalCardSuccess: null,
    addDigitalCardError: null,
    addDigitalCardReqStatus: '',
    addDigitalCardWithDetailSuccess: null,
    addDigitalCardWithDetailError: null,
    addDigitalCardWithDetailReqStatus: '',
    checkAliasAvailabilitySuccess: null,
    checkAliasAvailabilityError: null,
    cardListSuccess: null,
    cardListError: null,
    updateDigitalCardSuccess: null,
    updateDigitalCardError: null,
    updateDigitalCardReqStatus: '',
    addCardDetailsSuccess: null,
    addCardDetailsError: null,
    addCardDetailsReqStatus: '',
    updateCardDetailsSuccess: null,
    updateCardDetailsError: null,
    updateCardDetailsReqStatus: '',
    cardByAliasSuccess: null,
    cardByAliasError: null,
    deleteCardSuccess: null,
    deleteCardError: null,
    deleteCardReqStatus: ''
}

const digitalCardReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case DigitalCardActionTypes.ADD_DIGITAL_CARD_REQUEST_SUCCESS:
            return {
                ...state,
                addDigitalCardSuccess: action.payload,
                addDigitalCardError: null,
                addDigitalCardReqStatus: API_CONSTANTS.SUCCESS_STATUS
            }
        case DigitalCardActionTypes.ADD_DIGITAL_CARD_REQUEST_ERROR:
            return {
                ...state,
                addDigitalCardSuccess: null,
                addDigitalCardError: action.payload,
                addDigitalCardReqStatus: API_CONSTANTS.ERROR_STATUS
            }
        case DigitalCardActionTypes.ADD_DIGITAL_CARD_REQUEST_STATUS:
            return {
                ...state,
                addDigitalCardReqStatus: ''
            }
        case DigitalCardActionTypes.ADD_CARD_WITH_DETAILS_REQUEST_SUCCESS:
            return {
                ...state,
                addDigitalCardWithDetailSuccess: action.payload,
                addDigitalCardWithDetailError: null,
                addDigitalCardWithDetailReqStatus: API_CONSTANTS.SUCCESS_STATUS
            }
        case DigitalCardActionTypes.ADD_CARD_WITH_DETAILS_REQUEST_ERROR:
            return {
                ...state,
                addDigitalCardWithDetailSuccess: null,
                addDigitalCardWithDetailError: action.payload,
                addDigitalCardWithDetailReqStatus: API_CONSTANTS.ERROR_STATUS
            }
        case DigitalCardActionTypes.ADD_CARD_WITH_DETAILS_REQUEST_STATUS:
            return {
                ...state,
                addDigitalCardWithDetailReqStatus: ''
            }
        case DigitalCardActionTypes.DELETE_CARD_REQUEST_SUCCESS:
            return {
                ...state,
                deleteCardSuccess: action.payload,
                deleteCardError: null,
                deleteCardReqStatus: API_CONSTANTS.SUCCESS_STATUS
            }
        case DigitalCardActionTypes.DELETE_CARD_REQUEST_ERROR:
            return {
                ...state,
                deleteCardSuccess: null,
                deleteCardError: action.payload,
                deleteCardReqStatus: API_CONSTANTS.ERROR_STATUS
            }
        case DigitalCardActionTypes.DELETE_CARD_REQUEST_STATUS:
            return {
                ...state,
                deleteCardReqStatus: ''
            }
        case DigitalCardActionTypes.CHECK_ALIAS_AVAILABILITY_REQUEST_SUCCESS:
            return {
                ...state,
                checkAliasAvailabilitySuccess: action.payload,
                checkAliasAvailabilityError: null
            }
        case DigitalCardActionTypes.CHECK_ALIAS_AVAILABILITY_REQUEST_ERROR:
            return {
                ...state,
                checkAliasAvailabilitySuccess: null,
                checkAliasAvailabilityError: action.payload
            }
        case DigitalCardActionTypes.DIGITAL_CARD_LIST_REQUEST_SUCCESS:
            return {
                ...state,
                cardListSuccess: action.payload,
                cardListError: null
            }
        case DigitalCardActionTypes.DIGITAL_CARD_LIST_REQUEST_ERROR:
            return {
                ...state,
                cardListSuccess: null,
                cardListError: action.payload
            }
        case DigitalCardActionTypes.UPDATE_DIGITAL_CARD_REQUEST_SUCCESS:
            return {
                ...state,
                updateDigitalCardSuccess: action.payload,
                updateDigitalCardError: null,
                updateDigitalCardReqStatus: API_CONSTANTS.SUCCESS_STATUS
            }
        case DigitalCardActionTypes.UPDATE_DIGITAL_CARD_REQUEST_ERROR:
            return {
                ...state,
                updateDigitalCardSuccess: null,
                updateDigitalCardError: action.payload,
                updateDigitalCardReqStatus: API_CONSTANTS.ERROR_STATUS
            }
        case DigitalCardActionTypes.UPDATE_DIGITAL_CARD_REQUEST_STATUS:
            return {
                ...state,
                updateDigitalCardReqStatus: ''
            }
        case DigitalCardActionTypes.ADD_CARD_DETAILS_REQUEST_SUCCESS:
            return {
                ...state,
                addCardDetailsSuccess: action.payload,
                addCardDetailsError: null,
                addCardDetailsReqStatus: API_CONSTANTS.SUCCESS_STATUS
            }
        case DigitalCardActionTypes.ADD_CARD_DETAILS_REQUEST_ERROR:
            return {
                ...state,
                addCardDetailsSuccess: null,
                addCardDetailsError: action.payload,
                addCardDetailsReqStatus: API_CONSTANTS.ERROR_STATUS
            }
        case DigitalCardActionTypes.ADD_CARD_DETAILS_REQUEST_STATUS:
            return {
                ...state,
                addCardDetailsReqStatus: ''
            }
        case DigitalCardActionTypes.UPDATE_CARD_DETAILS_REQUEST_SUCCESS:
            return {
                ...state,
                updateCardDetailsSuccess: action.payload,
                updateCardDetailsError: null,
                updateCardDetailsReqStatus: API_CONSTANTS.SUCCESS_STATUS
            }
        case DigitalCardActionTypes.UPDATE_CARD_DETAILS_REQUEST_ERROR:
            return {
                ...state,
                updateCardDetailsSuccess: null,
                updateCardDetailsError: action.payload,
                updateCardDetailsReqStatus: API_CONSTANTS.ERROR_STATUS
            }
        case DigitalCardActionTypes.UPDATE_CARD_DETAILS_REQUEST_STATUS:
            return {
                ...state,
                updateCardDetailsReqStatus: ''
            }
        case DigitalCardActionTypes.CARD_BY_ALIAS_REQUEST_SUCCESS:
            return {
                ...state,
                cardByAliasSuccess: action.payload,
                cardByAliasError: null
            }
        case DigitalCardActionTypes.CLEAR_CARD_DETAIL:
            return {
                ...state,
                cardByAliasSuccess: null,
                cardByAliasError: null
            }
        case DigitalCardActionTypes.CARD_BY_ALIAS_REQUEST_ERROR:
            return {
                ...state,
                cardByAliasSuccess: null,
                cardByAliasError: action.payload
            }
        case DigitalCardActionTypes.CLEAR_ALIAS_DATA:
            return {
                ...state,
                checkAliasAvailabilitySuccess: null,
                checkAliasAvailabilityError: null
            }
        case DigitalCardActionTypes.LOGOUT:
            return INITIAL_STATE
        default:
            return state
    }
}

export default digitalCardReducer