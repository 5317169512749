import { combineReducers } from 'redux'
import userReducer from './user/user.reducer'
import serviceReducer from './service/service.reducer'
import productReducer from './product/product.reducer'
import enquiryReducer from './enquiry/enquiry.reducer'
import appointmentReducer from './appointment/appointment.reducer'
import digitalCardReducer from './digital-card/digitalCard.reducer'
import blogReducer from './blog/blog.reducer'
import testimonialReducer from './testimonial/testimonial.reducer'
import qrCodeReducer from './custom-qr-code/customQrCode.reducer'
import feedbackReducer from './feedback/feedback.reducer'
import visitorBookReducer from './visitor-book/visitorBook.reducer'
import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import socketReducer from './socket/socket.reducer'
import hotelPointReducer from './hotel-point/hotelPoint.reducer'
import newsLetterReducer from './newsletter/newsLetter.reducer'
import siteVisitorAnalyticsReducer from './site-visitor-analytics/siteVisitorAnalytics.reducer'
import nfcCardOrderReducer from './nfc-card-order/nfcCardOrder.reducer'
import employeeReducer from './employee/employee.reducer'

const persistConfig = {
    key: 'root',
    storage,
    whitelist: ['user']
}

const rootReducer = combineReducers({
    user: userReducer,
    socket: socketReducer,
    testimonial: testimonialReducer,
    service: serviceReducer,
    product: productReducer,
    blog: blogReducer,
    enquiry: enquiryReducer,
    appointment: appointmentReducer,
    digitalCard: digitalCardReducer,
    qrCode: qrCodeReducer,
    feedback: feedbackReducer,
    visitor: visitorBookReducer,
    hotelPoint: hotelPointReducer,
    newsLetter: newsLetterReducer,
    siteVisitorAnalytics: siteVisitorAnalyticsReducer,
    nfcCardOrder: nfcCardOrderReducer,
    employee: employeeReducer
})

// export default rootReducer
export default persistReducer(persistConfig, rootReducer)