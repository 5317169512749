import { SiteVisitorAnalyticsActionTypes } from './siteVisitorAnalytics.types'
import { API_CONSTANTS } from '../../utils/AppConstants'

const INITIAL_STATE = {
    siteVisitorAnalyticsSuccess: null,
    siteVisitorAnalyticsError: null,
    siteVisitorAnalyticsReqStatus: '',
    siteVisitorAnalyticsListSuccess: null,
    siteVisitorAnalyticsListError: null,
    cardVisitAnalyticsSuccess: null,
    cardVisitAnalyticsError: null,
    cardVisitAnalyticsReqStatus: '',
    siteAndUserAnalyticsListSuccess: null,
    siteAndUserAnalyticsListError: null
}

const siteVisitorAnalyticsReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case SiteVisitorAnalyticsActionTypes.SITE_VISITOR_ANALYTICS_ADD_RECORD_REQUEST_SUCCESS:
            return {
                ...state,
                siteVisitorAnalyticsSuccess: action.payload,
                siteVisitorAnalyticsError: null,
                siteVisitorAnalyticsReqStatus: API_CONSTANTS.SUCCESS_STATUS
            }
        case SiteVisitorAnalyticsActionTypes.SITE_VISITOR_ANALYTICS_ADD_RECORD_REQUEST_ERROR:
            return {
                ...state,
                siteVisitorAnalyticsSuccess: null,
                siteVisitorAnalyticsError: action.payload,
                siteVisitorAnalyticsReqStatus: API_CONSTANTS.ERROR_STATUS
            }
        case SiteVisitorAnalyticsActionTypes.SITE_VISITOR_ANALYTICS_ADD_RECORD_REQUEST_STATUS:
            return {
                ...state,
                siteVisitorAnalyticsReqStatus: ''
            }
        case SiteVisitorAnalyticsActionTypes.CARD_VISITOR_ANALYTICS_ADD_RECORD_REQUEST_SUCCESS:
            return {
                ...state,
                cardVisitAnalyticsSuccess: action.payload,
                cardVisitAnalyticsError: null,
                cardVisitAnalyticsReqStatus: API_CONSTANTS.SUCCESS_STATUS
            }
        case SiteVisitorAnalyticsActionTypes.CARD_VISITOR_ANALYTICS_ADD_RECORD_REQUEST_ERROR:
            return {
                ...state,
                cardVisitAnalyticsSuccess: null,
                cardVisitAnalyticsError: action.payload,
                cardVisitAnalyticsReqStatus: API_CONSTANTS.ERROR_STATUS
            }
        case SiteVisitorAnalyticsActionTypes.CARD_VISITOR_ANALYTICS_ADD_RECORD_REQUEST_STATUS:
            return {
                ...state,
                cardVisitAnalyticsReqStatus: ''
            }
        case SiteVisitorAnalyticsActionTypes.SITE_VISITOR_ANALYTICS_LIST_REQUEST_SUCCESS:
            return {
                ...state,
                siteVisitorAnalyticsListSuccess: action.payload,
                siteVisitorAnalyticsListError: null
            }
        case SiteVisitorAnalyticsActionTypes.SITE_VISITOR_ANALYTICS_LIST_REQUEST_ERROR:
            return {
                ...state,
                siteVisitorAnalyticsListSuccess: null,
                siteVisitorAnalyticsListError: action.payload
            }
        case SiteVisitorAnalyticsActionTypes.SITE_AND_USER_ANALYTICS_LIST_REQUEST_SUCCESS:
            return {
                ...state,
                siteAndUserAnalyticsListSuccess: action.payload,
                siteAndUserAnalyticsListError: null
            }
        case SiteVisitorAnalyticsActionTypes.SITE_AND_USER_ANALYTICS_LIST_REQUEST_ERROR:
            return {
                ...state,
                siteAndUserAnalyticsListSuccess: null,
                siteAndUserAnalyticsListError: action.payload
            }
        case SiteVisitorAnalyticsActionTypes.LOGOUT:
            return INITIAL_STATE
        default:
            return state
    }
}

export default siteVisitorAnalyticsReducer