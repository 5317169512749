import { TestimonialActionTypes } from './testimonial.types'

//Add Testimonial
export const addTestimonialRequest = data => ({
    type: TestimonialActionTypes.ADD_TESTIMONIAL_REQUEST,
    payload: data
})

export const addTestimonialRequestSuccess = data => ({
    type: TestimonialActionTypes.ADD_TESTIMONIAL_REQUEST_SUCCESS,
    payload: data
})

export const addTestimonialRequestError = data => ({
    type: TestimonialActionTypes.ADD_TESTIMONIAL_REQUEST_ERROR,
    payload: data
})

export const addTestimonialRequestStatus = data => ({
    type: TestimonialActionTypes.ADD_TESTIMONIAL_REQUEST_STATUS,
    payload: data
})

//Testimonial list
export const testimonialListRequest = data => ({
    type: TestimonialActionTypes.TESTIMONIAL_LIST_REQUEST,
    payload: data
})

export const testimonialListRequestSuccess = data => ({
    type: TestimonialActionTypes.TESTIMONIAL_LIST_REQUEST_SUCCESS,
    payload: data
})

export const testimonialListRequestError = data => ({
    type: TestimonialActionTypes.TESTIMONIAL_LIST_REQUEST_ERROR,
    payload: data
})

//Update Testimonial
export const updateTestimonialRequest = data => ({
    type: TestimonialActionTypes.UPDATE_TESTIMONIAL_REQUEST,
    payload: data
})

export const updateTestimonialRequestSuccess = data => ({
    type: TestimonialActionTypes.UPDATE_TESTIMONIAL_REQUEST_SUCCESS,
    payload: data
})

export const updateTestimonialRequestError = data => ({
    type: TestimonialActionTypes.UPDATE_TESTIMONIAL_REQUEST_ERROR,
    payload: data
})

export const updateTestimonialRequestStatus = data => ({
    type: TestimonialActionTypes.UPDATE_TESTIMONIAL_REQUEST_STATUS,
    payload: data
})

//Delete Testimonial
export const deleteTestimonialRequest = data => ({
    type: TestimonialActionTypes.DELETE_TESTIMONIAL_REQUEST,
    payload: data
})

export const deleteTestimonialRequestSuccess = data => ({
    type: TestimonialActionTypes.DELETE_TESTIMONIAL_REQUEST_SUCCESS,
    payload: data
})

export const deleteTestimonialRequestError = data => ({
    type: TestimonialActionTypes.DELETE_TESTIMONIAL_REQUEST_ERROR,
    payload: data
})

export const deleteTestimonialRequestStatus = data => ({
    type: TestimonialActionTypes.DELETE_TESTIMONIAL_REQUEST_STATUS,
    payload: data
})