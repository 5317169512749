export const ROUTES = {
    home: `/`,
    homeCards: `/cards`,
    homeNew: `/new`,
    signIn: `/visitor/signIn`,
    signUp: `/visitor/signUp`,
    privacyPolicy: '/privacyPolicy',
    cookiePolicy: '/cookiePolicy',
    termsOfUse: '/termsOfUse',
    forgotPassword: '/forgotPassword',
    contactUs: '/contactUs',
    cardList: '/cardList',
    addCard: '/addCard',
    cardDetail: '/cardDetail',
    vCard: '/vCard',
    employeeCard: '/employeeCard',
    generateQrCode: '/generateQrCode',
    qrCodeList: '/qrCodeList',
    addFeedback: '/addFeedback',
    visitorForm: '/visitorForm',
    visitorList: '/visitorList',
    visitorHome: '/visitor/home',
    visitorConfig: '/config',
    point: '/point',
    dynamicQrLink: '/qrLink',
    qrForYou: '/4u',
    qrYou: '/u',
    digitalProduct: '/dgoods',
    siteAnalytics: '/admin/siteAnalytics',
    nfcTemplateSelection: '/nfcTemplateSelection',
    orderList: '/orderList',
    orderDetails: '/orderDetails',
    adminLogin: '/admin/login',
    corporateLogin: '/coporate/login',
    corporateCreateAccount: '/coporate/createAccount',
    employeeList: '/corporate/employeeList',
    addEmployee: '/corporate/addEmployee',
    corporateNfcOrder: '/corporate/orderEmployeeNfc',
    operatorList: '/admin/operatorList',
    addOperator: '/admin/addOperator',
    operatorDetail: '/admin/operatorDetail',
    dev: {
        nfcWriter: '/dev/nfcwriter'
    }
}

export const API_CONSTANTS = {
    SUCCESS_STATUS: 'SUCCESS_STATUS',
    ERROR_STATUS: 'ERROR_STATUS'
}

export const ORGANISATION_DETAIL = {
    ORGANISATION_NAME: 'GoMoBites LLC',
    ORG_ADDRESS_LINE1: '11501 Dublin Blvd Suite 200',
    ORG_ADDRESS_LINE2: 'Dublin, CA 94568',
    ORG_SUPPORT_EMAIL: 'support@gomobites.com'
}

export const APP_CONSTANTS = {
    PRODUCT_NAME: 'CLICKTRAN_CARD',
    USER_TYPE_ADMIN: 'ADMIN',
    USER_TYPE: 'USER_TYPE',
    USER_TYPE_CUSTOMER: 'CUSTOMER',
    USER_TYPE_BUSINESS: 'BUSINESS',
    USER_TYPE_ADMIN: 'ADMIN',
    USER_TYPE_OPERATOR: 'OPERATOR',
    USER: 'USER',
    USER_TOKEN: 'USER_TOKEN',
    LOGOUT: 'LOGOUT',
    QR_CODE: 'QR_CODE',
    DASHBOARD: 'DASHBOARD',
    ORDER_LIST: 'ORDER_LIST',
    EMPLOYEE_LIST: 'EMPLOYEE_LIST',
    REQUEST_TICKET_LIST: 'REQUEST_TICKET_LIST',
    RESERVATION_LIST: 'RESERVATION_LIST',
    PRODUCT_LIST: 'PRODUCT_LIST',
    LOCATION_LIST: 'LOCATION_LIST',
    RESET_STATE_CITY: 'RESET_STATE_CITY',
    DISPLAY_COOKIE_CONSENT: 'DISPLAY_COOKIE_CONSENT',
    DISPLAY_NEWS_LETTER_CONSENT: 'DISPLAY_NEWS_LETTER_CONSENT',
    MENU_ITEM_TYPE: {
        LOCATION: 'LOCATION',
        QRCODE: 'QRCODE',
        PRODUCT: 'PRODUCT',
        ORDER: 'ORDER',
        EMPLOYEE: 'EMPLOYEE',
        RESERVATION: 'RESERVATION',
        DASHBOARD: 'DASHBOARD',
        REQUEST_TICKET: 'REQUEST_TICKET'
    },
    MENU_ITEM_DISPLAY_NAME: {
        DASHBOARD: 'Dashboard',
        RESTAURANTS: 'Restaurants',
        LOCATIONS: 'Locations',
        QRCODE: 'QR Code',
        CARD_LIST: 'Card List',
        CLIENTS: 'Customers',
        SETTINGS: 'Settings',
        MENU: 'Menu',
        ORDERS: 'Orders',
        EMPLOYEES: 'Employees',
        RESERVATIONS: 'Reservations',
        LOGOUT: 'Log Out',
        REQUEST_TICKET: 'Request Ticket',
        BULK_MENU_UPLOAD: 'Menu Upload',
        CONTACT_MESSAGE_LIST: 'Contact Messages'
    },
    ENVIRONMENT: {
        LOCAL: 'LOCAL',
        LOCAL_CUSTOMER: 'LOCAL_CUSTOMER',
        DEV: 'DEV',
        PROD: 'PROD'
    },
    KEY_FCM_TOKEN: 'KEY_FCM_TOKEN',
    SESSION_EXPIRE_MESSAGE: 'Your session expire. Please try login again',
    COMMON_ERROR: 'COMMON_ERROR',
    EMAIL_ALREADY_SUBSCRIBED_ERROR_MESSAGE: 'Email already subscribed',
    DEVICE_TYPE: {
        ANDROID: 'ANDROID',
        IOS: 'IOS',
        DESKTOP: 'DESKTOP',
        WINDOWS: 'WINDOWS',
        MAC_OS: 'MAC_OS'
    },
    KEY_DEVICE_ID: 'deviceId',
    ANALYTICS_DATE_TYPE: {
        PAST_24_HOURS: 'PAST_24_HOURS',
        PAST_2_DAYS: 'PAST_2_DAYS',
        PAST_5_DAYS: 'PAST_5_DAYS',
        PAST_WEEK: 'PAST_WEEK',
        PAST_15_DAYS: 'PAST_15_DAYS',
        PAST_MONTH: 'PAST_MONTH',
        CUSTOM_DATE: 'CUSTOM_DATE'
    },
    NFC_CARD_ORDER_STATUS: {
        PENDING: 'PENDING',
        AWAITING_PAYMENT: 'AWAITING_PAYMENT',
        IN_PROCESS: 'IN_PROCESS',
        PROCESSED: 'PROCESSED',
        HANDED_TO_SHIPMENT: 'HANDED_TO_SHIPMENT',
        IN_TRANSIST: 'IN_TRANSIST',
        DELIVERED: 'DELIVERED',
        CANCELLED: 'CANCELLED',
        UN_DELIVERED: 'UN_DELIVERED',
        REJECTED: 'REJECTED'
    },
    NFC_ORDER_TYPE: {
        INDIVIDUAL: 'INDIVIDUAL',
        CORPORATE: 'CORPORATE'
    },
    REGISTERED_PLATFORM: {
        VISITOR_MANAGEMENT: 'VISITOR_MANAGEMENT',
        CLICKTRAN_CORPORATE: 'CLICKTRAN_CORPORATE'
    }
}