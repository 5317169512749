import { takeLatest, call, put } from 'redux-saga/effects'
import {
    addServiceAPI, deleteServiceAPI, serviceListAPI, updateServiceAPI
} from '../api-requests/service';
import {
    addServiceRequestError, addServiceRequestSuccess, deleteServiceRequestError,
    deleteServiceRequestSuccess, serviceListRequestError, serviceListRequestSuccess,
    updateServiceRequestError, updateServiceRequestSuccess
} from './service.action';
import { ServiceActionTypes } from './service.types'
import { displayConsoleLog } from '../../utils/Utils'
import { decryptResponseData } from '../../utils/DataDecryptionUtils';
import { APP_CONSTANTS } from '../../utils/AppConstants';

function* handleUpdateService({ payload }) {
    try {
        const response = yield call(updateServiceAPI, payload);
        if (response.status >= 200 && response.status < 300) {
            const { data } = response
            const decryptedData = decryptResponseData(data)
            if (decryptedData.data !== null) {
                yield put(updateServiceRequestSuccess(decryptedData))
            } else {
                yield put(updateServiceRequestError(decryptedData))
            }
        } else {
            throw response
        }
    } catch (error) {
        displayConsoleLog(error)
        if (error.response?.status === 401) {
            const errData = {
                status: error.response?.status,
                isSessionError: true,
                message: APP_CONSTANTS.SESSION_EXPIRE_MESSAGE
            }
            yield put({ type: ServiceActionTypes.COMMON_ERROR, errData })
        } else {
            displayConsoleLog(error.response?.data)
            yield put(updateServiceRequestError(error.response.data))
        }
    }
}

export function* updateServiceReq() {
    yield takeLatest(ServiceActionTypes.UPDATE_SERVICE_REQUEST, handleUpdateService)
}

function* handleServiceList({ payload }) {
    try {
        const response = yield call(serviceListAPI, payload);
        if (response.status >= 200 && response.status < 300) {
            const { data } = response
            const decryptedData = decryptResponseData(data)
            if (decryptedData.data !== null) {
                yield put(serviceListRequestSuccess(decryptedData))
            } else {
                yield put(serviceListRequestError(decryptedData))
            }
        } else {
            throw response
        }
    } catch (error) {
        displayConsoleLog(error)
        if (error.response?.status === 401) {
            const errData = {
                status: error.response?.status,
                isSessionError: true,
                message: APP_CONSTANTS.SESSION_EXPIRE_MESSAGE
            }
            yield put({ type: ServiceActionTypes.COMMON_ERROR, errData })
        } else {
            displayConsoleLog(error.response?.data)
            yield put(serviceListRequestError(error.response.data))
        }
    }
}

export function* serviceListReq() {
    yield takeLatest(ServiceActionTypes.SERVICE_LIST_REQUEST, handleServiceList)
}

function* handleDeleteService({ payload }) {
    try {
        const response = yield call(deleteServiceAPI, payload);
        if (response.status >= 200 && response.status < 300) {
            const { data } = response
            const decryptedData = decryptResponseData(data)
            if (decryptedData.data !== null) {
                yield put(deleteServiceRequestSuccess(decryptedData))
            } else {
                yield put(deleteServiceRequestError(decryptedData))
            }
        } else {
            throw response
        }
    } catch (error) {
        displayConsoleLog(error)
        if (error.response?.status === 401) {
            const errData = {
                status: error.response?.status,
                isSessionError: true,
                message: APP_CONSTANTS.SESSION_EXPIRE_MESSAGE
            }
            yield put({ type: ServiceActionTypes.COMMON_ERROR, errData })
        } else {
            displayConsoleLog(error.response?.data)
            yield put(deleteServiceRequestError(error.response.data))
        }
    }
}

export function* deleteServiceReq() {
    yield takeLatest(ServiceActionTypes.DELETE_SERVICE_REQUEST, handleDeleteService)
}

function* handleAddService({ payload }) {
    try {
        const response = yield call(addServiceAPI, payload);
        if (response.status >= 200 && response.status < 300) {
            const { data } = response
            const decryptedData = decryptResponseData(data)
            if (decryptedData.data !== null) {
                yield put(addServiceRequestSuccess(decryptedData))
            } else {
                yield put(addServiceRequestError(decryptedData))
            }
        } else {
            throw response
        }
    } catch (error) {
        displayConsoleLog(error)
        if (error.response?.status === 401) {
            const errData = {
                status: error.response?.status,
                isSessionError: true,
                message: APP_CONSTANTS.SESSION_EXPIRE_MESSAGE
            }
            yield put({ type: ServiceActionTypes.COMMON_ERROR, errData })
        } else {
            displayConsoleLog(error.response?.data)
            yield put(addServiceRequestError(error.response.data))
        }
    }
}

export function* addServiceReq() {
    yield takeLatest(ServiceActionTypes.ADD_SERVICE_REQUEST, handleAddService)
}