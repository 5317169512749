import React, { useEffect, useState, useRef } from "react";
import './styles.css'
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import Loader from '../../../components/loader/loader.component'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'react-phone-number-input/style.css'
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input'
import { useDispatch, useSelector } from 'react-redux'
import {
    clearLocalStorage,
    displayErrorToast, displaySuccessToast, isEmpty, isLoggedInUserBusiness, isNull, isValidEmail, screenCaptureAnalytics
} from '../../../utils/Utils'
import { API_CONSTANTS, APP_CONSTANTS, ROUTES } from "../../../utils/AppConstants";
import { registerByMobileRequest, displaySideBarAct } from '../../../redux/user/user.action'
import { useNavigate } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import CommonButton from "../../../components/button/common-button/commonButton";
import FormFieldLabel from "../../../components/label/form-field-label/formFieldLabel";
import ScreenFooter from "../screen-footer/screenFooter";
import { config } from "../../../config";

const HomeNew = props => {
    const anchorRef = useRef(null);
    const [open, setOpen] = useState(false);
    const [value, setValue] = React.useState(0);
    const [mobile, setMobile] = useState()
    let navigate = useNavigate();
    const [isLoading, setLoading] = useState(false)
    const dispatch = useDispatch()
    const { user, loginErrorData, isLogin } = useSelector(state => state.user)
    const [showLoginDialog, setShowLoginDialog] = useState(false)

    const featureCT = [
        { img: require('../../../assets/ic_2.png'), heading: 'No Integration Required', content: "Our platform is designed to be a standalone solution, meaning you don't need to integrate it with any other services or platforms. Simply create your digital card, generate your QR code, and start sharing." },
        { img: require('../../../assets/ic_1.png'), heading: 'Quick and Easy Creation', content: "While we currently do not offer dynamic templates, we provide a range of customizable options to make your digital card unique and representative of your brand or personality. Add your own logo or images." },
        { img: require('../../../assets/ic_3.png'), heading: 'Mobile-Friendly Design', content: 'Your digital card will be optimized for mobile devices, ensuring that it looks great and functions seamlessly across different screen sizes.' }
    ]

    const featureQR = [
        { img: require('../../../assets/ic_6.png'), heading: 'Multiple Use Cases', content: "Generate QR codes for a variety of use cases, including website URLs, WIFI networks, vCards (contact details), phone calls, emails, SMS messages, and social media links. Whatever your communication needs, we have a QR code solution for you." },
        { img: require('../../../assets/ic_5.png'), heading: 'Static and Dynamic Codes', content: "Choose between static QR codes that remain fixed and dynamic QR codes that can be updated with new content. Use static codes for permanent information, and dynamic codes for campaigns, promotions, or real-time updates." },
        { img: require('../../../assets/ic_4.png'), heading: 'Save for Later Access', content: 'Easily save and organize your QR codes within your account for future reference. Access and manage your codes whenever you need them, without the hassle of recreating them from scratch.' },
        { img: require('../../../assets/ic_1.png'), heading: 'Customization Options', content: 'Customize your QR codes to align with your branding or personal preferences. Adjust colors, add logos or icons, and choose from various design templates to make your QR codes visually appealing and representative of your unique identity.' },
        { img: require('../../../assets/ic_3.png'), heading: 'Mobile-Friendly Design', content: 'Our QR codes are optimized for mobile devices, ensuring seamless scanning and functionality across a wide range of smartphones and tablets.' }
    ]

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }

        setOpen(false);
    };

    function handleListKeyDown(event) {
        if (event.key === 'Tab') {
            event.preventDefault();
            setOpen(false);
        } else if (event.key === 'Escape') {
            setOpen(false);
        }
    }

    // return focus to the button when we transitioned from !open -> open
    const prevOpen = React.useRef(open);
    useEffect(() => {
        if (prevOpen.current === true && open === false) {
            anchorRef.current.focus();
        }

        prevOpen.current = open;
    }, [open]);

    useEffect(() => {
        // sessionStorage.clear()
        // dispatch({ type: APP_CONSTANTS.LOGOUT })

        localStorage.removeItem("addCardDialog")
        if (localStorage.getItem(APP_CONSTANTS.USER)) {
            if (isLoggedInUserBusiness(user)) {
                navigate(ROUTES.visitorList, { replace: true })
            } else {
                navigate(ROUTES.cardList, { replace: true })
            }

        }
    }, [])

    useEffect(() => {
        if (isLogin) {
            setLoading(false)
            displaySuccessToast('User registration successfull')
            sessionStorage.setItem(APP_CONSTANTS.USER, JSON.stringify(user.data))
            sessionStorage.setItem(APP_CONSTANTS.USER_TOKEN, user.data.authToken)
            localStorage.setItem(APP_CONSTANTS.USER, user.data.authToken)
            dispatch(displaySideBarAct(true))
            if (isLoggedInUserBusiness(user)) {
                navigate(ROUTES.visitorList, { replace: true })
            } else {
                navigate(ROUTES.cardList, { replace: true })
            }
            // navigate(ROUTES.cardList, { replace: true })
        } else {
            clearLocalStorage()
            sessionStorage.clear()
            if (loginErrorData) {
                setLoading(false)
                displayErrorToast(loginErrorData.message)
            }
        }
    }, [user, isLogin, loginErrorData])

    const handleLoginClick = () => {
        // navigate(ROUTES.signIn)
        setShowLoginDialog(true)
    }

    const handleSignUpClick = () => {
        // navigate(ROUTES.signUp)
        setShowLoginDialog(true)
    }

    const processRegistration = () => {
        if (isEmpty(mobile)) {
            displayErrorToast('Mobile number cannot be blank')
        } else if (!isValidPhoneNumber(mobile)) {
            displayErrorToast('Invalid mobile number')
        } else {
            setShowLoginDialog(false)
            setLoading(true)
            const mobileWithoutPlusSymbol = mobile?.replace('+', "")
            const data = {
                mobile: parseInt(mobileWithoutPlusSymbol),
                productCode: APP_CONSTANTS.PRODUCT_NAME,
                userType: APP_CONSTANTS.USER_TYPE_CUSTOMER
            }
            dispatch(registerByMobileRequest(data))
        }
    }

    const handleLoginDialogCloseClick = () => {
        setShowLoginDialog(false)
    }

    const handleClickCustomQrCodeClick = (e) => {
        // navigate(ROUTES.signIn)
        handleClose(e)
        navigate(ROUTES.generateQrCode)

    }

    const handleVisitorManagementClick = (e) => {
        handleClose(e)
        navigate(ROUTES.visitorHome)
    }

    const handleFeedbackManagementClick = (e) => {
        if (config.environment === APP_CONSTANTS.ENVIRONMENT.DEV) {
            window.open('https://try-feedback.clicktran.com', '_blank')
        } else if (config.environment === APP_CONSTANTS.ENVIRONMENT.PROD) {
            window.open('https://feedback.clicktran.com', '_blank')
        }
    }

    const handleForTeamsClick = () => {
        navigate(ROUTES.corporateLogin)
    }

    return (
        <>
            <div>
                <div className='hp-nav-header'>
                    <div className="hp-logo-container margin-left-10">
                        <img alt="" src={require('../../../assets/nLogo2.png')} className='img' />
                    </div>
                    <div className="nav-item-button-container">
                        <div>
                            <div
                                ref={anchorRef}
                                id="composition-button"
                                aria-controls={open ? 'composition-menu' : undefined}
                                aria-expanded={open ? 'true' : undefined}
                                aria-haspopup="true"
                                onClick={handleToggle}
                                className="nav-product-btn black-font"
                            >
                                SOLUTIONS
                            </div>
                            <Popper
                                open={open}
                                anchorEl={anchorRef.current}
                                role={undefined}
                                placement="bottom-start"
                                transition
                                disablePortal
                            >
                                {({ TransitionProps, placement }) => (
                                    <Grow
                                        {...TransitionProps}
                                        style={{
                                            transformOrigin:
                                                placement === 'bottom-start' ? 'left top' : 'left bottom',
                                        }}
                                    >
                                        <Paper>
                                            <ClickAwayListener onClickAway={handleClose}>
                                                <MenuList
                                                    autoFocusItem={open}
                                                    id="composition-menu"
                                                    aria-labelledby="composition-button"
                                                    onKeyDown={handleListKeyDown}
                                                    className="nav-menu-list-container"
                                                >
                                                    <MenuItem onClick={handleLoginClick}>ClickTran Card</MenuItem>
                                                    <MenuItem onClick={handleClickCustomQrCodeClick}>Generate Custom QR</MenuItem>
                                                    <MenuItem onClick={handleVisitorManagementClick}>Visitor Management</MenuItem>
                                                    <MenuItem onClick={handleFeedbackManagementClick}>Feedback Management</MenuItem>
                                                </MenuList>
                                            </ClickAwayListener>
                                        </Paper>
                                    </Grow>
                                )}
                            </Popper>
                        </div>
                        <div className="nav-product-btn black-font" onClick={() => handleLoginClick()}>LOGIN</div>

                        {/* <button type="button" className="btn hp-login-button ms-2" onClick={() => { handleLoginClick() }}>Login</button> */}
                    </div>
                </div>
                <div className="header-parent-container">
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-6 header-text-center header-item-container">
                                <h1 className='nhp-sub-title header-title'>Create Next Level Free ClickTran Cards</h1>
                                <h4 className='hp-sub-title-subheading black-font'>Create and customize Smart ClickTran cards in just 5 minutes and share them with everyone, near or far.</h4>
                                <button type="button" className="btn nhp-get-started-button" onClick={() => { handleSignUpClick() }}>Get Your Card</button>
                            </div>
                            <div className="col-sm-6 header-item-container">
                                <img src={require('../../../assets/ct_card_header.png')} className="img" />
                            </div>
                        </div>
                    </div>
                </div>
                <div className='hp-section-container'>
                    <h2 className='hp-title-h2'><span className="hp-title-h2-yellow hp-title-h2-green cursive">Net</span>Work Like a Pro</h2>
                    <div className='hp-title-h2-text-container'>
                        <p className='hp-title-h2-text hp-title-h2-text-pb'>

                            <span className="overlayText cursive">ClickTran</span>
                            <span className="strikethrough">Digital business</span> cards are not just for in-person meetings, but also for online networking and virtual events. Compatible with all modern web browsers and smartphones inculding Apple and Android.</p>
                        <p className='hp-title-quote'>When you want to <span className="hp-title-quote-click">CLICK</span> - ClickTran</p>
                        <p className="hp-title-sub-quote">Build your Social or Business network.</p>
                    </div>
                </div>
                <section>
                    <Tabs value={value} onChange={handleChange} centered textColor="secondary" indicatorColor="secondary">
                        <Tab label="ClickTran Card" />
                        <Tab label="Generate Custom QR" />
                    </Tabs>
                    {value === 0 && <div className="nhp-container1">
                        <h5 className="nhp-container1-heading">
                            We provide you with a simple and efficient solution to create your own free digital business or social cards that can be easily shared using QR codes. We understand the importance of having a digital presence in today's fast-paced world, and we aim to make it accessible to everyone. Whether you're an entrepreneur, professional, or simply want to showcase your personal information, our platform has got you covered.
                        </h5>
                        <h4 className="nhp-container1-heading1">Why ClickTran Card?</h4>
                        <div className="row">
                            <div className="col-sm-2">
                                <div className="nhp-feature-icon-container1">
                                    <img src={require('../../../assets/free_ic.png')} className="img" />
                                </div>
                            </div>
                            <div className="col-sm-10">
                                <div className="nhp-container1-sub-heading"><span className="nhp-container1-sub-heading-label">Free and User-Friendly:</span> We believe in democratizing access to digital business cards. Our platform is completely free to use, and we've designed it to be user-friendly, ensuring that you can effortlessly create your own personalized digital card.</div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-2">
                                <div className="nhp-feature-icon-container1">
                                    <img src={require('../../../assets/qr_share_ic.png')} className="img" />
                                </div>
                            </div>
                            <div className="col-sm-10">
                                <div className="nhp-container1-sub-heading"><span className="nhp-container1-sub-heading-label">QR Code Sharing:</span> Stand out from the crowd by sharing your digital card through QR codes. With a simple scan, your contacts can instantly save your information, eliminating the need for manual data entry. It's quick, convenient, and leaves a lasting impression.</div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-2">
                                <div className="nhp-feature-icon-container1">
                                    <img src={require('../../../assets/login_ic.png')} className="img" />
                                </div>
                            </div>
                            <div className="col-sm-10">
                                <div className="nhp-container1-sub-heading"><span className="nhp-container1-sub-heading-label">Mobile Number Login:</span> We understand the importance of security and convenience. Our platform offers hassle-free login through your mobile number, ensuring that your information is protected and easily accessible whenever you need it.</div>
                            </div>
                        </div>

                        {/* <div className="nhp-row nhp-row-wrap">
                            <div className="nhp-feature-icon-container nhp-feature-icon-container1">
                                <img src={require('../../../assets/free_ic.png')} className="img" />
                            </div>
                            <div className="nhp-container1-sub-heading"><span className="nhp-container1-sub-heading-label">Free and User-Friendly:</span> We believe in democratizing access to digital business cards. Our platform is completely free to use, and we've designed it to be user-friendly, ensuring that you can effortlessly create your own personalized digital card.</div>
                        </div> */}
                        {/* <div className="nhp-row nhp-row-wrap">
                            <div className="nhp-feature-icon-container nhp-feature-icon-container1">
                                <img src={require('../../../assets/qr_share_ic.png')} className="img" />
                            </div>
                            <div className="nhp-container1-sub-heading"><span className="nhp-container1-sub-heading-label">QR Code Sharing:</span> Stand out from the crowd by sharing your digital card through QR codes. With a simple scan, your contacts can instantly save your information, eliminating the need for manual data entry. It's quick, convenient, and leaves a lasting impression.</div>
                        </div> */}
                        {/* <div className="nhp-row nhp-row-wrap">
                            <div className="nhp-feature-icon-container nhp-feature-icon-container1">
                                <img src={require('../../../assets/login_ic.png')} className="img" />
                            </div>
                            <div className="nhp-container1-sub-heading"><span className="nhp-container1-sub-heading-label">Mobile Number Login:</span> We understand the importance of security and convenience. Our platform offers hassle-free login through your mobile number, ensuring that your information is protected and easily accessible whenever you need it.</div>
                        </div> */}
                        <h4 className="nhp-container1-heading1">Key Features</h4>
                        <div className="feature-row">
                            {featureCT.map(e => (<Card sx={{ maxWidth: 315, minHeight: 350, marginRight: 5, marginTop: 5 }}>
                                <div className="nhp-feature-icon-container">
                                    <img src={e.img} className="img" />
                                </div>
                                <CardContent>
                                    <Typography gutterBottom variant="h5" component="div">
                                        {e.heading}
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary">
                                        {e.content}
                                    </Typography>
                                </CardContent>
                            </Card>))}
                        </div>
                    </div>}
                    {value === 1 && <div className="nhp-container1">
                        <h5 className="nhp-container1-heading">
                            We provide you one-stop solution for generating custom static or dynamic QR codes for various use cases. We understand the importance of QR codes in modern marketing and communication strategies, and we strive to provide a user-friendly platform that empowers individuals and businesses to create and utilize QR codes effectively. Whether you need a QR code for a URL, WIFI, vCard, Call, Email, SMS, or Social link, we've got you covered.
                        </h5>
                        <h4 className="nhp-container1-heading1">Why Choose Us?</h4>
                        <div className="row">
                            <div className="col-sm-2">
                                <div className="nhp-feature-icon-container1">
                                    <img src={require('../../../assets/convenient.png')} className="img" />
                                </div>
                            </div>
                            <div className="col-sm-10">
                                <div className="nhp-container1-sub-heading"><span className="nhp-container1-sub-heading-label">Versatility and Convenience:</span> ClickTran.com offers a comprehensive range of QR code generation options, allowing you to create codes for multiple use cases. From website URLs to contact details, phone calls to social media profiles, our platform provides the flexibility you need to meet your specific requirements. All this can be achieved in one place, making it convenient and hassle-free.</div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-2">
                                <div className="nhp-feature-icon-container1">
                                    <img src={require('../../../assets/process.png')} className="img" />
                                </div>
                            </div>
                            <div className="col-sm-10">
                                <div className="nhp-container1-sub-heading"><span className="nhp-container1-sub-heading-label">Static and Dynamic Codes:</span> We understand that different situations call for different QR code functionalities. With ClickTran.com, you can generate both static and dynamic QR codes. Static codes remain fixed, while dynamic codes can be updated with new content, making them ideal for campaigns or time-sensitive information updates.</div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-2">
                                <div className="nhp-feature-icon-container1">
                                    <img src={require('../../../assets/delay.png')} className="img" />
                                </div>
                            </div>
                            <div className="col-sm-10">
                                <div className="nhp-container1-sub-heading"><span className="nhp-container1-sub-heading-label">Save for Later Access:</span> Our platform allows you to save your QR codes for later access. Whether you're managing multiple codes or need to retrieve them at a later date, you can easily organize and retrieve your QR codes whenever you need them.</div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-2">
                                <div className="nhp-feature-icon-container1">
                                    <img src={require('../../../assets/easy.png')} className="img" />
                                </div>
                            </div>
                            <div className="col-sm-10">
                                <div className="nhp-container1-sub-heading"><span className="nhp-container1-sub-heading-label">Easy-to-Use Interface:</span> We've designed ClickTran.com to be user-friendly and intuitive. Our streamlined interface ensures that you can quickly and effortlessly generate custom QR codes, even if you have no prior experience with QR code creation. Creating and managing codes has never been easier.</div>
                            </div>
                        </div>

                        <h4 className="nhp-container1-heading1">Key Features</h4>
                        <div className="feature-row">
                            {featureQR.map(e => (<Card sx={{ maxWidth: 315, minHeight: 350, marginRight: 5, marginTop: 5 }}>
                                <div className="nhp-feature-icon-container">
                                    <img src={e.img} className="img" />
                                </div>
                                <CardContent>
                                    <Typography gutterBottom variant="h5" component="div">
                                        {e.heading}
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary">
                                        {e.content}
                                    </Typography>
                                </CardContent>
                            </Card>))}
                        </div>
                    </div>}
                </section>
                <div className='hp-section-container'>
                    <h2 className='hp-title-h2'>HOW IT WORKS?</h2>
                    <div className='container mt-5'>
                        <div className='row justify-content-center'>
                            <div className='col-sm-3 hp-how-it-work-card'>
                                <div className='hp-social-img-container'>
                                    <img alt="" src={require('../../../assets/ic_signup.png')} className='img' />
                                </div>
                                <h3 className='hp-how-it-work-card-title'>Get Started</h3>
                                <p className='hp-how-it-work-card-description'>Onboard on ClickTran with your mobile number only. Once onboarded, continue to create your ClickTran card.</p>
                            </div>
                            <div className='col-sm-3 hp-how-it-work-card'>
                                <div className='hp-social-img-container'>
                                    <img alt="" src={require('../../../assets/ic_template.png')} className='img' />
                                </div>
                                <h3 className='hp-how-it-work-card-title'>Create ClickTran Card</h3>
                                <p className='hp-how-it-work-card-description'>Fill up card details. Showcase your work by uploading rich media content such as photos, videos and custom links.</p>
                            </div>
                            <div className='col-sm-3 hp-how-it-work-card'>
                                <div className='hp-social-img-container'>
                                    <img alt="" src={require('../../../assets/ic_share.png')} className='img' />
                                </div>
                                <h3 className='hp-how-it-work-card-title'>Share Your Card</h3>
                                <p className='hp-how-it-work-card-description'>Share your ClickTran card with your prospects, customers, friends, and colleagues through a variety of channels including Facebook, WhatsApp, Text Message, Email, QR code and more.</p>
                            </div>
                        </div>
                    </div>
                </div>

                {/* <div className="credit-container">
                    <div>Created In</div>
                    <div className="flag-container">
                        <img className="img" src={require('../../../assets/united-states.png')} />
                    </div>
                </div> */}
                <ScreenFooter navigate={navigate} />
            </div>
            <Modal
                onHide={() => { handleLoginDialogCloseClick() }}
                show={showLoginDialog}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Login via Mobile Number
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="qr-container">
                        <div className="hp-login-image-container">
                            {/* <img src={require('../../../assets/GoMoBites.png')} className="img" /> */}
                            {/* <img src={require('../../assets/NewLogo2.png')} className="img" /> */}
                            <img alt="" src={require('../../../assets/nLogo2.png')} className="img" />
                        </div>

                        <div className="mt-3 w-100">
                            {/* <FormFieldLabel className='mb-3' label='Login using your mobile number' /> */}
                            <PhoneInput
                                defaultCountry="US"
                                placeholder="Mobile Number"
                                value={mobile}
                                onChange={setMobile}
                            />
                        </div>
                        <FormFieldLabel className='modal-btn-start' label="*No password required, only mobile number needed to login." />
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <div className="modal-btn-container">
                        <CommonButton isBig={true} onClick={() => processRegistration()}
                            label='Login' />
                        <div className="or-label-container"><span className="or-horizontal-line" /><span className="or-label">or</span><span className="or-horizontal-line" /></div>
                        <CommonButton isBig={true} isInverted={true} onClick={() => handleForTeamsClick()}
                            label='For Teams' />
                    </div>
                </Modal.Footer>
            </Modal>
            <ToastContainer />
            <Loader active={isLoading} text='Please wait...' />
        </>
    )
}

export default HomeNew