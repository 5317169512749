import React from "react";
import './styles.css'

const CommonInputFull = ({ type, classname = "", placeholder, value, onChange, id, onBlur, isRequired = false, isDisable = false, isSubtext = false, subText }) => {
    return (
        <div className={`${isSubtext ? 'common-input-margin' : ''} common-input-full-container`}>
            <div className={`form-floating common-input-full ${!isSubtext ? 'common-input-margin' : ''} ${classname}`}>
                <input
                    type={type}
                    className="form-control"
                    id={id}
                    placeholder={placeholder}
                    value={value}
                    onBlur={onBlur ? onBlur : null}
                    disabled={isDisable}
                    onChange={onChange} />
                <label for={id} className='label-container'>{placeholder} {isRequired ? <p className="required-field">*</p> : ''}</label>
            </div>
            {isSubtext && <div className="input-hint-label">{subText}</div>}
        </div>
    )
}

export default CommonInputFull