import { VisitorBookActionTypes } from './visitorBook.types'
import { API_CONSTANTS } from '../../utils/AppConstants'

const INITIAL_STATE = {
    visitorAddRecordSuccess: null,
    visitorAddRecordError: null,
    visitorAddRecordReqStatus: '',
    visitorRecordListSuccess: null,
    visitorRecordListError: null,
    visitorMarkExitSuccess: null,
    visitorMarkExitError: null,
    visitorMarkExitReqStatus: ''
}

const visitorBookReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case VisitorBookActionTypes.VISITOR_ADD_RECORD_REQUEST_SUCCESS:
            return {
                ...state,
                visitorAddRecordSuccess: action.payload,
                visitorAddRecordError: null,
                visitorAddRecordReqStatus: API_CONSTANTS.SUCCESS_STATUS
            }
        case VisitorBookActionTypes.VISITOR_ADD_RECORD_REQUEST_ERROR:
            return {
                ...state,
                visitorAddRecordSuccess: null,
                visitorAddRecordError: action.payload,
                visitorAddRecordReqStatus: API_CONSTANTS.ERROR_STATUS
            }
        case VisitorBookActionTypes.VISITOR_ADD_RECORD_REQUEST_STATUS:
            return {
                ...state,
                visitorAddRecordReqStatus: ''
            }
        case VisitorBookActionTypes.VISITOR_MARK_EXIT_REQUEST_SUCCESS:
            return {
                ...state,
                visitorMarkExitSuccess: action.payload,
                visitorMarkExitError: null,
                visitorMarkExitReqStatus: API_CONSTANTS.SUCCESS_STATUS
            }
        case VisitorBookActionTypes.VISITOR_MARK_EXIT_REQUEST_ERROR:
            return {
                ...state,
                visitorMarkExitSuccess: null,
                visitorMarkExitError: action.payload,
                visitorMarkExitReqStatus: API_CONSTANTS.ERROR_STATUS
            }
        case VisitorBookActionTypes.VISITOR_MARK_EXIT_REQUEST_STATUS:
            return {
                ...state,
                visitorMarkExitReqStatus: ''
            }
        case VisitorBookActionTypes.VISITOR_RECORD_LIST_REQUEST_SUCCESS:
            return {
                ...state,
                visitorRecordListSuccess: action.payload,
                visitorRecordListError: null
            }
        case VisitorBookActionTypes.VISITOR_RECORD_LIST_REQUEST_ERROR:
            return {
                ...state,
                visitorRecordListSuccess: null,
                visitorRecordListError: action.payload
            }
        case VisitorBookActionTypes.LOGOUT:
            return INITIAL_STATE
        default:
            return state
    }
}

export default visitorBookReducer