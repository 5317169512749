import React, { useEffect, useState } from "react";
import './styles.css'
import { APP_CONSTANTS, ROUTES } from "../../../utils/AppConstants";
import { config } from "../../../config";

const ScreenFooter = ({ navigate }) => {
    const handlePrivacyPolicyClick = () => {
        navigate(ROUTES.privacyPolicy)
    }

    const handleCookiePolicyClick = () => {
        navigate(ROUTES.cookiePolicy)
    }

    const handleContactUsClick = () => {
        navigate(ROUTES.contactUs)
    }

    const mailToSupportClickHandler = () => {
        window.location = "mailto:contact@clicktran.com"
    }

    const handleLiveDemoClick = () => {
        window.open('https://try.clicktran.com/', '_blank')
    }

    return (
        <div className="screenFooter">
            <div className="container">
                <div className="row">
                    <div className="col-sm-4 colElementItemCenter">
                        <div className="footer-logo-container">
                            <img src={require('../../../assets/nLogo2.png')} className="img" />
                        </div>
                    </div>
                    <div className="col-sm-4 colElementCenter">
                        <div className="colElementHeadingLogo">Links</div>
                        <div className="h-sub-link-text" onClick={() => { handlePrivacyPolicyClick() }}>Privacy Policy</div>
                        <div className="h-sub-link-text" onClick={() => { handleCookiePolicyClick() }}>Cookie Policy</div>
                        {config.environment === APP_CONSTANTS.ENVIRONMENT.PROD ? (<div className="h-sub-link-text" onClick={() => { handleLiveDemoClick() }} >Try Live Demo</div>) : null}
                    </div>
                    <div className="col-sm-4 colElementCenter">
                        <div className="colElementHeadingLogo">Contact</div>
                        <div className="colElement14Label" onClick={() => { mailToSupportClickHandler() }}>contact@clicktran.com</div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ScreenFooter