export const QRCodeActionTypes = {
    LOGOUT: 'LOGOUT',

    ADD_CUSTOM_QR_CODE_REQUEST: 'ADD_CUSTOM_QR_CODE_REQUEST',
    ADD_CUSTOM_QR_CODE_REQUEST_SUCCESS: 'ADD_CUSTOM_QR_CODE_REQUEST_SUCCESS',
    ADD_CUSTOM_QR_CODE_REQUEST_ERROR: 'ADD_CUSTOM_QR_CODE_REQUEST_ERROR',
    ADD_CUSTOM_QR_CODE_REQUEST_STATUS: 'ADD_CUSTOM_QR_CODE_REQUEST_STATUS',

    CUSTOM_QR_CODE_LIST_REQUEST: 'CUSTOM_QR_CODE_LIST_REQUEST',
    CUSTOM_QR_CODE_LIST_REQUEST_SUCCESS: 'CUSTOM_QR_CODE_LIST_REQUEST_SUCCESS',
    CUSTOM_QR_CODE_LIST_REQUEST_ERROR: 'CUSTOM_QR_CODE_LIST_REQUEST_ERROR',

    DELETE_CUSTOM_QR_CODE_REQUEST: 'DELETE_CUSTOM_QR_CODE_REQUEST',
    DELETE_CUSTOM_QR_CODE_REQUEST_SUCCESS: 'DELETE_CUSTOM_QR_CODE_REQUEST_SUCCESS',
    DELETE_CUSTOM_QR_CODE_REQUEST_ERROR: 'DELETE_CUSTOM_QR_CODE_REQUEST_ERROR',
    DELETE_CUSTOM_QR_CODE_REQUEST_STATUS: 'DELETE_CUSTOM_QR_CODE_REQUEST_STATUS',

    UPDATE_QR_CODE_REQUEST: 'UPDATE_QR_CODE_REQUEST',
    UPDATE_QR_CODE_REQUEST_SUCCESS: 'UPDATE_QR_CODE_REQUEST_SUCCESS',
    UPDATE_QR_CODE_REQUEST_ERROR: 'UPDATE_QR_CODE_REQUEST_ERROR',
    UPDATE_QR_CODE_REQUEST_STATUS: 'UPDATE_QR_CODE_REQUEST_STATUS',

    COMMON_ERROR: 'COMMON_ERROR',

    QR_CODE_DETAIL_REQUEST: 'QR_CODE_DETAIL_REQUEST',
    QR_CODE_DETAIL_REQUEST_SUCCESS: 'QR_CODE_DETAIL_REQUEST_SUCCESS',
    QR_CODE_DETAIL_REQUEST_ERROR: 'QR_CODE_DETAIL_REQUEST_ERROR'
}
