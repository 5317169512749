import React, { useEffect, useState } from "react";
import './styles.css'
import Loader from '../../../../../components/loader/loader.component'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'react-phone-number-input/style.css'
import { useDispatch, useSelector } from 'react-redux'
import { employeeListRequest } from '../../../../../redux/employee/employee.action'
import { isEmpty, isNull } from "../../../../../utils/Utils";
import CommonScreenContent from "../../../../../components/screen/common-screen-content/commonScreenContent";
import ScreenLabel from "../../../../../components/label/screen-label/screenLabel";
import CommonInputRow from "../../../../../components/input/common-input-row/commonInputRow";
import TopButton from "../../../../../components/button/top-button/topButton";
import { useNavigate } from 'react-router-dom';
import { ROUTES } from "../../../../../utils/AppConstants";

const EmployeeList = props => {
    const [isLoading, setLoading] = useState(false)
    const dispatch = useDispatch()
    const { user } = useSelector(state => state.user)
    const { employeeListSuccess, employeeListError } = useSelector(state => state.employee)
    let navigate = useNavigate();

    useEffect(() => {
        setLoading(true)
        const data = {
            businessId: user.data.userDetails?.businessData?.businessId
        }
        dispatch(employeeListRequest(data))
    }, [])

    useEffect(() => {
        if (!isEmpty(employeeListSuccess)) {
            setLoading(false)
        } else if (!isEmpty(employeeListError)) {
            setLoading(false)
        }
    }, [employeeListSuccess, employeeListError])

    const handleOrderNfcClick = () => {
        navigate(ROUTES.corporateNfcOrder)
    }

    return (
        <>
            <CommonScreenContent>
                <CommonInputRow>
                    <ScreenLabel label='Employees' />
                    <TopButton label='Add Employee' onClick={() => { navigate(ROUTES.addEmployee) }} />
                </CommonInputRow>
                <div className="table-scroll-container">
                    {employeeListSuccess && !isNull(employeeListSuccess.data) && <table className="table table-hover mt-4">
                        <thead>
                            <tr>
                                <th scope="col" className="text-center">Name</th>
                                <th scope="col" className="text-center">Designation</th>
                                <th scope="col" className="text-center">Email Id</th>
                                <th scope="col" className="text-center">Phone Number</th>
                            </tr>
                        </thead>
                        <tbody>
                            {employeeListSuccess.data.map(element => (
                                <tr className="loc-table-row-container">
                                    <td className="loc-list-card-name">{element.firstName} {element.lastName}</td>
                                    <td className="loc-list-card-name">{element.designation}</td>
                                    <td className="loc-list-card-name">{element.email}</td>
                                    <td className="loc-list-card-name">{element.mobile}</td>
                                </tr>
                            ))}

                        </tbody>
                    </table>}
                </div>
                <div className="round-fab-container" onClick={() => { handleOrderNfcClick() }}>
                    <div className="round-order-nfc-fab-container-icon">
                        <img src={require('../../../../../assets/ic_fab_order_nfc.png')} className="img" />
                    </div>

                </div>
                {!isNull(employeeListError) && <div className="mt-2">{employeeListError.message}</div>}
            </CommonScreenContent>
            <ToastContainer />
            <Loader active={isLoading} text='Please wait...' />
        </>
    )
}

export default EmployeeList