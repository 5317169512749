import { VisitorBookActionTypes } from './visitorBook.types'

//Add Record
export const visitorAddRecordRequest = data => ({
    type: VisitorBookActionTypes.VISITOR_ADD_RECORD_REQUEST,
    payload: data
})

export const visitorAddRecordRequestSuccess = data => ({
    type: VisitorBookActionTypes.VISITOR_ADD_RECORD_REQUEST_SUCCESS,
    payload: data
})

export const visitorAddRecordRequestError = data => ({
    type: VisitorBookActionTypes.VISITOR_ADD_RECORD_REQUEST_ERROR,
    payload: data
})

export const visitorAddRecordRequestStatus = data => ({
    type: VisitorBookActionTypes.VISITOR_ADD_RECORD_REQUEST_STATUS,
    payload: data
})

//Record List
export const visitorRecordListRequest = data => ({
    type: VisitorBookActionTypes.VISITOR_RECORD_LIST_REQUEST,
    payload: data
})

export const visitorRecordListRequestSuccess = data => ({
    type: VisitorBookActionTypes.VISITOR_RECORD_LIST_REQUEST_SUCCESS,
    payload: data
})

export const visitorRecordListRequestError = data => ({
    type: VisitorBookActionTypes.VISITOR_RECORD_LIST_REQUEST_ERROR,
    payload: data
})

//Mark Exit
export const visitorMarkExitRequest = data => ({
    type: VisitorBookActionTypes.VISITOR_MARK_EXIT_REQUEST,
    payload: data
})

export const visitorMarkExitRequestSuccess = data => ({
    type: VisitorBookActionTypes.VISITOR_MARK_EXIT_REQUEST_SUCCESS,
    payload: data
})

export const visitorMarkExitRequestError = data => ({
    type: VisitorBookActionTypes.VISITOR_MARK_EXIT_REQUEST_ERROR,
    payload: data
})

export const visitorMarkExitRequestStatus = data => ({
    type: VisitorBookActionTypes.VISITOR_MARK_EXIT_REQUEST_STATUS,
    payload: data
})