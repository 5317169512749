import { takeLatest, call, put } from 'redux-saga/effects'
import {
    addTestimonialAPI, deleteTestimonialAPI, testimonialListAPI, updateTestimonialAPI
} from '../api-requests/testimonial';
import {
    addTestimonialRequestError, addTestimonialRequestSuccess, deleteTestimonialRequestError,
    deleteTestimonialRequestSuccess, testimonialListRequestError, testimonialListRequestSuccess,
    updateTestimonialRequestError, updateTestimonialRequestSuccess
} from './testimonial.action';
import { TestimonialActionTypes } from './testimonial.types'
import { displayConsoleLog } from '../../utils/Utils'
import { decryptResponseData } from '../../utils/DataDecryptionUtils';
import { APP_CONSTANTS } from '../../utils/AppConstants';

function* handleUpdateTestimonial({ payload }) {
    try {
        const response = yield call(updateTestimonialAPI, payload);
        if (response.status >= 200 && response.status < 300) {
            const { data } = response
            const decryptedData = decryptResponseData(data)
            if (decryptedData.data !== null) {
                yield put(updateTestimonialRequestSuccess(decryptedData))
            } else {
                yield put(updateTestimonialRequestError(decryptedData))
            }
        } else {
            throw response
        }
    } catch (error) {
        displayConsoleLog(error)
        if (error.response?.status === 401) {
            const errData = {
                status: error.response?.status,
                isSessionError: true,
                message: APP_CONSTANTS.SESSION_EXPIRE_MESSAGE
            }
            yield put({ type: TestimonialActionTypes.COMMON_ERROR, errData })
        } else {
            displayConsoleLog(error.response?.data)
            yield put(updateTestimonialRequestError(error.response.data))
        }
    }
}

export function* updateTestimonialReq() {
    yield takeLatest(TestimonialActionTypes.UPDATE_TESTIMONIAL_REQUEST, handleUpdateTestimonial)
}

function* handleTestimonialList({ payload }) {
    try {
        const response = yield call(testimonialListAPI, payload);
        if (response.status >= 200 && response.status < 300) {
            const { data } = response
            const decryptedData = decryptResponseData(data)
            if (decryptedData.data !== null) {
                yield put(testimonialListRequestSuccess(decryptedData))
            } else {
                yield put(testimonialListRequestError(decryptedData))
            }
        } else {
            throw response
        }
    } catch (error) {
        displayConsoleLog(error)
        if (error.response?.status === 401) {
            const errData = {
                status: error.response?.status,
                isSessionError: true,
                message: APP_CONSTANTS.SESSION_EXPIRE_MESSAGE
            }
            yield put({ type: TestimonialActionTypes.COMMON_ERROR, errData })
        } else {
            displayConsoleLog(error.response?.data)
            yield put(testimonialListRequestError(error.response.data))
        }
    }
}

export function* testimonialListReq() {
    yield takeLatest(TestimonialActionTypes.TESTIMONIAL_LIST_REQUEST, handleTestimonialList)
}

function* handleDeleteTestimonial({ payload }) {
    try {
        const response = yield call(deleteTestimonialAPI, payload);
        if (response.status >= 200 && response.status < 300) {
            const { data } = response
            const decryptedData = decryptResponseData(data)
            if (decryptedData.data !== null) {
                yield put(deleteTestimonialRequestSuccess(decryptedData))
            } else {
                yield put(deleteTestimonialRequestError(decryptedData))
            }
        } else {
            throw response
        }
    } catch (error) {
        displayConsoleLog(error)
        if (error.response?.status === 401) {
            const errData = {
                status: error.response?.status,
                isSessionError: true,
                message: APP_CONSTANTS.SESSION_EXPIRE_MESSAGE
            }
            yield put({ type: TestimonialActionTypes.COMMON_ERROR, errData })
        } else {
            displayConsoleLog(error.response?.data)
            yield put(deleteTestimonialRequestError(error.response.data))
        }
    }
}

export function* deleteTestimonialReq() {
    yield takeLatest(TestimonialActionTypes.DELETE_TESTIMONIAL_REQUEST, handleDeleteTestimonial)
}

function* handleAddTestimonial({ payload }) {
    try {
        const response = yield call(addTestimonialAPI, payload);
        if (response.status >= 200 && response.status < 300) {
            const { data } = response
            const decryptedData = decryptResponseData(data)
            if (decryptedData.data !== null) {
                yield put(addTestimonialRequestSuccess(decryptedData))
            } else {
                yield put(addTestimonialRequestError(decryptedData))
            }
        } else {
            throw response
        }
    } catch (error) {
        displayConsoleLog(error)
        if (error.response?.status === 401) {
            const errData = {
                status: error.response?.status,
                isSessionError: true,
                message: APP_CONSTANTS.SESSION_EXPIRE_MESSAGE
            }
            yield put({ type: TestimonialActionTypes.COMMON_ERROR, errData })
        } else {
            displayConsoleLog(error.response?.data)
            yield put(addTestimonialRequestError(error.response.data))
        }
    }
}

export function* addTestimonialReq() {
    yield takeLatest(TestimonialActionTypes.ADD_TESTIMONIAL_REQUEST, handleAddTestimonial)
}