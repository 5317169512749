import { SiteVisitorAnalyticsActionTypes } from './siteVisitorAnalytics.types'

//Add Record
export const siteVisitorAnayticsAddRecordRequest = data => ({
    type: SiteVisitorAnalyticsActionTypes.SITE_VISITOR_ANALYTICS_ADD_RECORD_REQUEST,
    payload: data
})

export const siteVisitorAnayticsAddRecordRequestSuccess = data => ({
    type: SiteVisitorAnalyticsActionTypes.SITE_VISITOR_ANALYTICS_ADD_RECORD_REQUEST_SUCCESS,
    payload: data
})

export const siteVisitorAnayticsAddRecordRequestError = data => ({
    type: SiteVisitorAnalyticsActionTypes.SITE_VISITOR_ANALYTICS_ADD_RECORD_REQUEST_ERROR,
    payload: data
})

export const siteVisitorAnayticsAddRecordRequestStatus = data => ({
    type: SiteVisitorAnalyticsActionTypes.SITE_VISITOR_ANALYTICS_ADD_RECORD_REQUEST_STATUS,
    payload: data
})

//Record List
export const siteVisitorAnayticsListRequest = data => ({
    type: SiteVisitorAnalyticsActionTypes.SITE_VISITOR_ANALYTICS_LIST_REQUEST,
    payload: data
})

export const siteVisitorAnayticsListRequestSuccess = data => ({
    type: SiteVisitorAnalyticsActionTypes.SITE_VISITOR_ANALYTICS_LIST_REQUEST_SUCCESS,
    payload: data
})

export const siteVisitorAnayticsListRequestError = data => ({
    type: SiteVisitorAnalyticsActionTypes.SITE_VISITOR_ANALYTICS_LIST_REQUEST_ERROR,
    payload: data
})

//Card Visit Add Record
export const cardVisitAnayticsAddRecordRequest = data => ({
    type: SiteVisitorAnalyticsActionTypes.CARD_VISITOR_ANALYTICS_ADD_RECORD_REQUEST,
    payload: data
})

export const cardVisitAnayticsAddRecordRequestSuccess = data => ({
    type: SiteVisitorAnalyticsActionTypes.CARD_VISITOR_ANALYTICS_ADD_RECORD_REQUEST_SUCCESS,
    payload: data
})

export const cardVisitAnayticsAddRecordRequestError = data => ({
    type: SiteVisitorAnalyticsActionTypes.CARD_VISITOR_ANALYTICS_ADD_RECORD_REQUEST_ERROR,
    payload: data
})

export const cardVisitAnayticsAddRecordRequestStatus = data => ({
    type: SiteVisitorAnalyticsActionTypes.CARD_VISITOR_ANALYTICS_ADD_RECORD_REQUEST_STATUS,
    payload: data
})

//Site And User List
export const siteAndUserAnayticsListRequest = data => ({
    type: SiteVisitorAnalyticsActionTypes.SITE_AND_USER_ANALYTICS_LIST_REQUEST,
    payload: data
})

export const siteAndUserAnayticsListRequestSuccess = data => ({
    type: SiteVisitorAnalyticsActionTypes.SITE_AND_USER_ANALYTICS_LIST_REQUEST_SUCCESS,
    payload: data
})

export const siteAndUserAnayticsListRequestError = data => ({
    type: SiteVisitorAnalyticsActionTypes.SITE_AND_USER_ANALYTICS_LIST_REQUEST_ERROR,
    payload: data
})