import { takeLatest, call, put } from 'redux-saga/effects'
import {
    addSiteVisitorAnalyticsRecordAPI, siteVisitorAnalyticsListAPI,
    addCardVisitAnalyticsRecordAPI, siteAndUserAnalyticsListAPI
} from '../api-requests/siteVisitorAnalytics';
import {
    siteVisitorAnayticsAddRecordRequestError, siteVisitorAnayticsAddRecordRequestSuccess,
    siteVisitorAnayticsListRequestError, siteVisitorAnayticsListRequestSuccess,
    cardVisitAnayticsAddRecordRequestError, cardVisitAnayticsAddRecordRequestSuccess,
    siteAndUserAnayticsListRequestError, siteAndUserAnayticsListRequestSuccess
} from './siteVisitorAnalytics.action';
import { SiteVisitorAnalyticsActionTypes } from './siteVisitorAnalytics.types'
import { displayConsoleLog } from '../../utils/Utils'
import { decryptResponseData } from '../../utils/DataDecryptionUtils';
import { APP_CONSTANTS } from '../../utils/AppConstants';

function* handleSiteAndUserAnalyticsListRequest({ payload }) {
    try {
        const response = yield call(siteAndUserAnalyticsListAPI, payload);
        if (response.status >= 200 && response.status < 300) {
            const { data } = response
            const decryptedData = decryptResponseData(data)
            if (decryptedData.data !== null) {
                yield put(siteAndUserAnayticsListRequestSuccess(decryptedData))
            } else {
                yield put(siteAndUserAnayticsListRequestError(decryptedData))
            }
        } else {
            throw response
        }
    } catch (error) {
        if (error.response?.status === 401) {
            const errData = {
                status: error.response?.status,
                isSessionError: true,
                message: APP_CONSTANTS.SESSION_EXPIRE_MESSAGE
            }
            yield put({ type: SiteVisitorAnalyticsActionTypes.COMMON_ERROR, errData })
        } else {
            displayConsoleLog(error.response.status)
            displayConsoleLog(error.response?.data)
            yield put(siteAndUserAnayticsListRequestError(error.response.data))
        }
    }
}

export function* siteAndUserAnalyticsListReq() {
    yield takeLatest(SiteVisitorAnalyticsActionTypes.SITE_AND_USER_ANALYTICS_LIST_REQUEST, handleSiteAndUserAnalyticsListRequest)
}

function* handleCardVisitAnalyticsListRequest({ payload }) {
    try {
        const response = yield call(addCardVisitAnalyticsRecordAPI, payload);
        if (response.status >= 200 && response.status < 300) {
            const { data } = response
            const decryptedData = decryptResponseData(data)
            if (decryptedData.data !== null) {
                yield put(cardVisitAnayticsAddRecordRequestSuccess(decryptedData))
            } else {
                yield put(cardVisitAnayticsAddRecordRequestError(decryptedData))
            }
        } else {
            throw response
        }
    } catch (error) {
        if (error.response?.status === 401) {
            const errData = {
                status: error.response?.status,
                isSessionError: true,
                message: APP_CONSTANTS.SESSION_EXPIRE_MESSAGE
            }
            yield put({ type: SiteVisitorAnalyticsActionTypes.COMMON_ERROR, errData })
        } else {
            displayConsoleLog(error.response.status)
            displayConsoleLog(error.response?.data)
            yield put(cardVisitAnayticsAddRecordRequestError(error.response.data))
        }
    }
}

export function* cardVisitAnalyticsListReq() {
    yield takeLatest(SiteVisitorAnalyticsActionTypes.CARD_VISITOR_ANALYTICS_ADD_RECORD_REQUEST, handleCardVisitAnalyticsListRequest)
}

function* handleSiteVisitorAnalyticsListRequest({ payload }) {
    try {
        const response = yield call(siteVisitorAnalyticsListAPI, payload);
        if (response.status >= 200 && response.status < 300) {
            const { data } = response
            const decryptedData = decryptResponseData(data)
            if (decryptedData.data !== null) {
                yield put(siteVisitorAnayticsListRequestSuccess(decryptedData))
            } else {
                yield put(siteVisitorAnayticsListRequestError(decryptedData))
            }
        } else {
            throw response
        }
    } catch (error) {
        if (error.response?.status === 401) {
            const errData = {
                status: error.response?.status,
                isSessionError: true,
                message: APP_CONSTANTS.SESSION_EXPIRE_MESSAGE
            }
            yield put({ type: SiteVisitorAnalyticsActionTypes.COMMON_ERROR, errData })
        } else {
            displayConsoleLog(error.response.status)
            displayConsoleLog(error.response?.data)
            yield put(siteVisitorAnayticsListRequestError(error.response.data))
        }
    }
}

export function* siteVisitorAnalyticsListReq() {
    yield takeLatest(SiteVisitorAnalyticsActionTypes.SITE_VISITOR_ANALYTICS_LIST_REQUEST, handleSiteVisitorAnalyticsListRequest)
}

function* handleSiteVisitorAnalyticsAddRequest({ payload }) {
    try {
        const response = yield call(addSiteVisitorAnalyticsRecordAPI, payload);
        if (response.status >= 200 && response.status < 300) {
            const { data } = response
            const decryptedData = decryptResponseData(data)
            if (decryptedData.data !== null) {
                yield put(siteVisitorAnayticsAddRecordRequestSuccess(decryptedData))
            } else {
                yield put(siteVisitorAnayticsAddRecordRequestError(decryptedData))
            }
        } else {
            throw response
        }
    } catch (error) {
        if (error.response?.status === 401) {
            const errData = {
                status: error.response?.status,
                isSessionError: true,
                message: APP_CONSTANTS.SESSION_EXPIRE_MESSAGE
            }
            yield put({ type: SiteVisitorAnalyticsActionTypes.COMMON_ERROR, errData })
        } else {
            displayConsoleLog(error.response.status)
            displayConsoleLog(error.response?.data)
            yield put(siteVisitorAnayticsAddRecordRequestError(error.response.data))
        }
    }
}

export function* siteVisitorAnalyticsAddReq() {
    yield takeLatest(SiteVisitorAnalyticsActionTypes.SITE_VISITOR_ANALYTICS_ADD_RECORD_REQUEST, handleSiteVisitorAnalyticsAddRequest)
}