export const EnquiryActionTypes = {
    LOGOUT: 'LOGOUT',

    ADD_ENQUIRY_REQUEST: 'ADD_ENQUIRY_REQUEST',
    ADD_ENQUIRY_REQUEST_SUCCESS: 'ADD_ENQUIRY_REQUEST_SUCCESS',
    ADD_ENQUIRY_REQUEST_ERROR: 'ADD_ENQUIRY_REQUEST_ERROR',
    ADD_ENQUIRY_REQUEST_STATUS: 'ADD_ENQUIRY_REQUEST_STATUS',

    ENQUIRY_LIST_REQUEST: 'ENQUIRY_LIST_REQUEST',
    ENQUIRY_LIST_REQUEST_SUCCESS: 'ENQUIRY_LIST_REQUEST_SUCCESS',
    ENQUIRY_LIST_REQUEST_ERROR: 'ENQUIRY_LIST_REQUEST_ERROR',

    UPDATE_ENQUIRY_REQUEST: 'UPDATE_ENQUIRY_REQUEST',
    UPDATE_ENQUIRY_REQUEST_SUCCESS: 'UPDATE_ENQUIRY_REQUEST_SUCCESS',
    UPDATE_ENQUIRY_REQUEST_ERROR: 'UPDATE_ENQUIRY_REQUEST_ERROR',
    UPDATE_ENQUIRY_REQUEST_STATUS: 'UPDATE_ENQUIRY_REQUEST_STATUS',
    
    COMMON_ERROR: 'COMMON_ERROR',
}
