import React, { useEffect, useState } from "react";
import './styles.css'
import CommonScreenContent from "../../../../components/screen/common-screen-content/commonScreenContent";
import ScreenLabel from "../../../../components/label/screen-label/screenLabel";
import Loader from '../../../../components/loader/loader.component'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom';
import {
    operatorListRequest, countryListRequest, stateListRequest, cityListRequest,
    operatorRegisterationRequest, operatorRegisterationRequestStatus
} from '../../../../redux/user/user.action'
import CommonFormBorder from "../../../../components/common-form-border/commonFormBorder";
import SubTextLabel from "../../../../components/label/sub-text-label/subTextLabel";
import Select from 'react-select'
import CommonInputRow from "../../../../components/input/common-input-row/commonInputRow";
import CommonInputFloatHalf from "../../../../components/input/common-input-float-half/commonInputFloafHalf";
import CommonInputFloatFull from "../../../../components/input/common-input-float-full/commonInputFloatFull";
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
import FormFieldLabel from "../../../../components/label/form-field-label/formFieldLabel";
import { API_CONSTANTS, APP_CONSTANTS } from "../../../../utils/AppConstants";
import { displayErrorToast, displaySuccessToast, isEmpty, isNull, isValidEmail } from "../../../../utils/Utils";
import ButtonRow from "../../../../components/button/button-row/buttonRow";
import CommonButton from "../../../../components/button/common-button/commonButton";

const AddOperator = props => {
    const [isLoading, setLoading] = useState(false)
    const { user, countryListSuccess,
        countryListError, stateListSuccess, stateListError,
        cityListSuccess, cityListError, operatorRegistrationSuccess, operatorRegistrationError, operatorRegistrationReqStatus } = useSelector(state => state.user)
    const dispatch = useDispatch()
    let navigate = useNavigate();
    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [email, setEmail] = useState('')
    const [mobile, setMobile] = useState()
    const [allowedLocationList, setAllowedLocationList] = useState([])
    const [allowedLocation, setAllowedLocation] = useState(null)
    const [countryDropdown, setCountryDropdown] = useState();
    const [stateDropdown, setStateDropdown] = useState();
    const [cityDropdown, setCityDropdown] = useState();
    const [countryCode, setCountryCode] = useState('')

    useEffect(() => {
        dispatch({ type: APP_CONSTANTS.RESET_STATE_CITY })
        setLoading(true)
        dispatch(countryListRequest())
    }, [])

    const fetchStateList = (countryId) => {
        setLoading(true)
        const data = {
            countryId: countryId
        }
        dispatch(stateListRequest(data))
    }

    const fetchCityList = (countryId, stateId) => {
        setLoading(true)
        const data = {
            countryId: countryId,
            stateId: stateId
        }
        dispatch(cityListRequest(data))
    }

    useEffect(() => {
        if (!isNull(countryListSuccess)) {
            setLoading(false)
        } else if (!isNull(countryListError)) {
            setLoading(false)
        }
    }, [countryListSuccess, countryListError])

    useEffect(() => {
        if (!isNull(stateListSuccess)) {
            setLoading(false)
        } else if (!isNull(stateListError)) {
            setLoading(false)
        }
    }, [stateListSuccess, stateListError])

    useEffect(() => {
        if (!isNull(cityListSuccess)) {
            setLoading(false)
        } else if (!isNull(cityListError)) {
            setLoading(false)
        }
    }, [cityListSuccess, cityListError])

    const handleCountrySelectChange = e => {
        setCountryDropdown(e);
        setCountryCode(e.iso2)
        setStateDropdown(null)
        setCityDropdown(null)
        fetchStateList(e.iso2)
        updateLocationList(e.name)
    }

    useEffect(() => {
        if (operatorRegistrationReqStatus === API_CONSTANTS.SUCCESS_STATUS) {
            setLoading(false)
            displaySuccessToast(operatorRegistrationSuccess.message)
            clearFields()
        } else if (operatorRegistrationReqStatus === API_CONSTANTS.ERROR_STATUS) {
            setLoading(false)
            displayErrorToast(operatorRegistrationError.message)
        }
        if (operatorRegistrationReqStatus.length) {
            dispatch(operatorRegisterationRequestStatus(''))
        }
    }, [operatorRegistrationSuccess, operatorRegistrationError, operatorRegistrationReqStatus])

    const clearFields = () => {
        setFirstName('')
        setLastName('')
        setEmail('')
        setMobile()
        setAllowedLocationList([])
        setAllowedLocation(null)
        setCountryDropdown();
        setStateDropdown();
        setCityDropdown();
        setCountryCode('')
    }

    function updateLocationList(country, state = null, city = null) {
        setAllowedLocationList((prevList) => {
            // Create a shallow copy of the previous state
            let currentList = [...prevList];

            if (!isNull(country)) {
                const existingCountryIndex = currentList.findIndex((item) => item.country === country);

                if (existingCountryIndex === -1) {
                    // Country does not exist, create a new object and add it to the list
                    currentList.push({
                        country: country,
                        stateCity: [],
                    });
                }

                if (!isNull(state)) {
                    // Country exists, check if the state exists in the stateCity list
                    const existingStateIndex =
                        currentList[existingCountryIndex].stateCity.findIndex((item) => item.state === state);

                    if (existingStateIndex === -1) {
                        // State does not exist, add state and city in the same format for the provided country
                        currentList[existingCountryIndex].stateCity.push({
                            state: state,
                            city: [],
                        });
                    }

                    if (!isNull(city)) {
                        // State exists, check if the city exists in the city list of the state object
                        const existingCityIndex =
                            currentList[existingCountryIndex].stateCity[existingStateIndex].city?.findIndex(
                                (item) => item === city
                            );

                        if (existingCityIndex === -1) {
                            // City does not exist, add the provided city to the list
                            currentList[existingCountryIndex].stateCity[existingStateIndex].city.push(city);
                        }
                    }
                }
            }

            return currentList;
        });
    }

    const handleSubmitClick = () => {
        if (isEmpty(firstName)) {
            displayErrorToast('First name cannot be blank')
        } else if (isEmpty(lastName)) {
            displayErrorToast('Last name cannot be blank')
        } else if (isEmpty(email)) {
            displayErrorToast('Email cannot be blank')
        } else if (!isValidEmail(email)) {
            displayErrorToast('Invalid email id')
        } else if (isEmpty(mobile)) {
            displayErrorToast('Mobile number cannot be blank')
        } else if (isEmpty(allowedLocationList)) {
            displayErrorToast('Allowed locations cannot be blank')
        } else {
            const mobileWithPlusSymbol = mobile.replace('+', "")
            let fd = new FormData()
            fd.append('firstName', firstName)
            fd.append('lastName', lastName)
            fd.append('email', email)
            fd.append('mobile', parseInt(mobileWithPlusSymbol))
            fd.append('userType', APP_CONSTANTS.USER_TYPE_OPERATOR)
            fd.append('productCode', APP_CONSTANTS.PRODUCT_NAME)
            fd.append('accessLocations', JSON.stringify(allowedLocationList))
            setLoading(true)
            dispatch(operatorRegisterationRequest(fd))
        }
    }

    function removeCountry(countryToRemove) {
        setAllowedLocationList((prevList) => {
            const updatedList = prevList.filter((item) => item.country !== countryToRemove);
            return updatedList;
        });
    }

    function removeState(countryToRemove, stateToRemove) {
        setAllowedLocationList((prevList) => {
            const updatedList = prevList.map((item) => {
                if (item.country === countryToRemove) {
                    const updatedStateCity = item.stateCity.filter((state) => state.state !== stateToRemove);
                    return { ...item, stateCity: updatedStateCity };
                }
                return item;
            });
            return updatedList;
        });
    }

    function removeCity(countryToRemove, stateToRemove, cityToRemove) {
        setAllowedLocationList((prevList) => {
            const updatedList = prevList.map((item) => {
                if (item.country === countryToRemove) {
                    const updatedStateCity = item.stateCity.map((state) => {
                        if (state.state === stateToRemove) {
                            const updatedCities = state.city.filter((city) => city !== cityToRemove);
                            return { ...state, city: updatedCities };
                        }
                        return state;
                    });
                    return { ...item, stateCity: updatedStateCity };
                }
                return item;
            });
            return updatedList;
        });
    }


    return (
        <>
            <CommonScreenContent>
                <ScreenLabel label='Add Operator' />
                <CommonFormBorder>
                    <SubTextLabel label='Enter details to add new operator' />
                    <CommonInputRow>
                        <CommonInputFloatHalf
                            type='text'
                            id='firstName'
                            onChange={e => setFirstName(e.target.value)}
                            placeholder='First Name'
                            value={firstName} />
                        <CommonInputFloatHalf
                            type='text'
                            id='lastName'
                            onChange={e => setLastName(e.target.value)}
                            placeholder='Last Name'
                            value={lastName} />
                    </CommonInputRow>
                    <CommonInputFloatFull
                        type='text'
                        id='email'
                        onChange={e => setEmail(e.target.value)}
                        placeholder='Email'
                        value={email}
                    />
                    <div className="cl-input">
                        <PhoneInput
                            placeholder="Mobile Number"
                            defaultCountry="US"
                            value={mobile}
                            onChange={setMobile} />
                    </div>
                    <FormFieldLabel className='up-bold-label' label='Select Allowed Locations' />
                    {countryListSuccess && <Select
                        menuPlacement="auto"
                        className='select-full-width-no-border'
                        value={countryDropdown}
                        getOptionLabel={e => e.name}
                        getOptionValue={e => e}
                        options={countryListSuccess}
                        placeholder='Select Country'
                        onChange={e => {
                            handleCountrySelectChange(e)
                        }} />}
                    <CommonInputRow>
                        {stateListSuccess && <Select
                            menuPlacement="auto"
                            className='select-half-width'
                            value={stateDropdown}
                            getOptionLabel={e => e.name}
                            getOptionValue={e => e}
                            options={stateListSuccess}
                            placeholder='Select State'
                            onChange={e => {
                                setStateDropdown(e);
                                setCityDropdown(null)
                                fetchCityList(countryCode, e.iso2)
                                updateLocationList(countryDropdown.name, e.name)
                            }} />}
                        {cityListSuccess && <Select
                            menuPlacement="auto"
                            className='select-half-width'
                            value={cityDropdown}
                            getOptionLabel={e => e.name}
                            getOptionValue={e => e}
                            options={cityListSuccess}
                            placeholder='Select City'
                            onChange={e => {
                                setCityDropdown(e);
                                updateLocationList(countryDropdown.name, stateDropdown.name, e.name)
                            }} />}
                    </CommonInputRow>
                    {!isEmpty(allowedLocationList) && <FormFieldLabel className='up-bold-label' label='Selected Locations' />}
                    {!isEmpty(allowedLocationList) && <div className="ao-location-container">
                        {allowedLocationList.map(e => (
                            <div className="ao-location-country-container">
                                <div className="ao-location-country-content-container">
                                    <div className="ao-location-country-label">{e.country}</div>
                                    {!isEmpty(e.stateCity) && <div>
                                        {e.stateCity.map(e1 => (
                                            <div className="ao-location-country-state-container">
                                                <div className="ao-location-remove-state-icon" onClick={() => { removeState(e.country, e1.state) }}>
                                                    <img src={require('../../../../assets/ic_edit_blue.png')} className="img" />
                                                </div>
                                                <div className="ao-location-country-state-content-container">
                                                    <div className="ao-location-state-label">{e1.state}</div>
                                                    {!isEmpty(e1.city) && <div className="ao-location-city-container">
                                                        {e1.city.map(e2 => (
                                                            <div className="ao-location-city-label">
                                                                <div>{e2}</div>
                                                                <div className="ao-location-remove-country-icon" onClick={() => { removeCity(e.country, e1.state, e2) }}>
                                                                    <img src={require('../../../../assets/ic_edit_blue.png')} className="img" />
                                                                </div>
                                                            </div>

                                                        ))}
                                                    </div>}
                                                </div>

                                            </div>
                                        ))}
                                    </div>}
                                </div>
                                <div className="ao-location-remove-country-icon" onClick={() => { removeCountry(e.country) }}>
                                    <img src={require('../../../../assets/ic_edit_blue.png')} className="img" />
                                </div>
                            </div>
                        ))}
                    </div>}

                    <ButtonRow>
                        <CommonButton label='Submit'
                            onClick={() => { handleSubmitClick() }} />
                    </ButtonRow>
                </CommonFormBorder>
            </CommonScreenContent>
            <ToastContainer />
            <Loader active={isLoading} text='Please wait...' />
        </>
    )
}

export default AddOperator