import axios from 'axios'
import { config } from '../../config'
import { APP_CONSTANTS } from '../../utils/AppConstants'
import API_ENDPOINTS from './endpoints'

export function addPointRecordAPI(payload) {
    return axios.request({
        method: 'post',
        url: `${config.baseUrl}/${API_ENDPOINTS.hotelPoint.addRecord}`,
        data: payload
    })
}

export function pointRecordListAPI(payload) {
    return axios.request({
        method: 'get',
        url: `${config.baseUrl}/${API_ENDPOINTS.hotelPoint.recordList}`
    })
}