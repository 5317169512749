export const TestimonialActionTypes = {
    LOGOUT: 'LOGOUT',

    ADD_TESTIMONIAL_REQUEST: 'ADD_TESTIMONIAL_REQUEST',
    ADD_TESTIMONIAL_REQUEST_SUCCESS: 'ADD_TESTIMONIAL_REQUEST_SUCCESS',
    ADD_TESTIMONIAL_REQUEST_ERROR: 'ADD_TESTIMONIAL_REQUEST_ERROR',
    ADD_TESTIMONIAL_REQUEST_STATUS: 'ADD_TESTIMONIAL_REQUEST_STATUS',

    TESTIMONIAL_LIST_REQUEST: 'TESTIMONIAL_LIST_REQUEST',
    TESTIMONIAL_LIST_REQUEST_SUCCESS: 'TESTIMONIAL_LIST_REQUEST_SUCCESS',
    TESTIMONIAL_LIST_REQUEST_ERROR: 'TESTIMONIAL_LIST_REQUEST_ERROR',

    UPDATE_TESTIMONIAL_REQUEST: 'UPDATE_TESTIMONIAL_REQUEST',
    UPDATE_TESTIMONIAL_REQUEST_SUCCESS: 'UPDATE_TESTIMONIAL_REQUEST_SUCCESS',
    UPDATE_TESTIMONIAL_REQUEST_ERROR: 'UPDATE_TESTIMONIAL_REQUEST_ERROR',
    UPDATE_TESTIMONIAL_REQUEST_STATUS: 'UPDATE_TESTIMONIAL_REQUEST_STATUS',

    DELETE_TESTIMONIAL_REQUEST: 'DELETE_TESTIMONIAL_REQUEST',
    DELETE_TESTIMONIAL_REQUEST_SUCCESS: 'DELETE_TESTIMONIAL_REQUEST_SUCCESS',
    DELETE_TESTIMONIAL_REQUEST_ERROR: 'DELETE_TESTIMONIAL_REQUEST_ERROR',
    DELETE_TESTIMONIAL_REQUEST_STATUS: 'DELETE_TESTIMONIAL_REQUEST_STATUS',
    
    COMMON_ERROR: 'COMMON_ERROR',
}
