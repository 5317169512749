import { SocketActionTypes } from './socket.types'

//Visitor add
export const newVisitorAddNotif = data => ({
    type: SocketActionTypes.NEW_VISITOR_ADD,
    payload: data
})

export const clearNewVisitorAddNotif = data => ({
    type: SocketActionTypes.CLEAR_NEW_VISITOR_ADD,
    payload: data
})

//Visitor config update
export const visitorConfigUpdateNotif = data => ({
    type: SocketActionTypes.VISITOR_CONFIG_UPDATE,
    payload: data
})

export const clearVisitorConfigUpdateNotif = data => ({
    type: SocketActionTypes.CLEAR_VISITOR_CONFIG_UPDATE,
    payload: data
})