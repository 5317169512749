import axios from 'axios'
import { config } from '../../config'
import { APP_CONSTANTS } from '../../utils/AppConstants'
import API_ENDPOINTS from './endpoints'

export function addEnquiryAPI(payload) {
    return axios.request({
        method: 'post',
        url: `${config.baseUrl}/${API_ENDPOINTS.enquiry.addEnquiry}`,
        data: payload
    })
}

export function enquiryListAPI(payload) {
    const user = JSON.parse(sessionStorage.getItem(APP_CONSTANTS.USER))
    return axios.request({
        method: 'get',
        url: `${config.baseUrl}/${API_ENDPOINTS.enquiry.enquiryList}/${payload}`,
        headers: {
            'authToken': user ? user.authToken : config.cid
        }
    })
}

export function updateEnquiryStatusAPI(payload) {
    const user = JSON.parse(sessionStorage.getItem(APP_CONSTANTS.USER))
    return axios.request({
        method: 'put',
        url: `${config.baseUrl}/${API_ENDPOINTS.enquiry.updateEnquiryStatus}`,
        data: payload,
        headers: {
            'authToken': user ? user.authToken : config.cid
        }
    })
}