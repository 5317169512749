import { BlogActionTypes } from './blog.types'

//Add Blog
export const addBlogRequest = data => ({
    type: BlogActionTypes.ADD_BLOG_REQUEST,
    payload: data
})

export const addBlogRequestSuccess = data => ({
    type: BlogActionTypes.ADD_BLOG_REQUEST_SUCCESS,
    payload: data
})

export const addBlogRequestError = data => ({
    type: BlogActionTypes.ADD_BLOG_REQUEST_ERROR,
    payload: data
})

export const addBlogRequestStatus = data => ({
    type: BlogActionTypes.ADD_BLOG_REQUEST_STATUS,
    payload: data
})

//Blog List
export const blogListRequest = data => ({
    type: BlogActionTypes.BLOG_LIST_REQUEST,
    payload: data
})

export const blogListRequestSuccess = data => ({
    type: BlogActionTypes.BLOG_LIST_REQUEST_SUCCESS,
    payload: data
})

export const blogListRequestError = data => ({
    type: BlogActionTypes.BLOG_LIST_REQUEST_ERROR,
    payload: data
})

//Update Blog
export const updateBlogRequest = data => ({
    type: BlogActionTypes.UPDATE_BLOG_REQUEST,
    payload: data
})

export const updateBlogRequestSuccess = data => ({
    type: BlogActionTypes.UPDATE_BLOG_REQUEST_SUCCESS,
    payload: data
})

export const updateBlogRequestError = data => ({
    type: BlogActionTypes.UPDATE_BLOG_REQUEST_ERROR,
    payload: data
})

export const updateBlogRequestStatus = data => ({
    type: BlogActionTypes.UPDATE_BLOG_REQUEST_STATUS,
    payload: data
})

//Delete Blog
export const deleteBlogRequest = data => ({
    type: BlogActionTypes.DELETE_BLOG_REQUEST,
    payload: data
})

export const deleteBlogRequestSuccess = data => ({
    type: BlogActionTypes.DELETE_BLOG_REQUEST_SUCCESS,
    payload: data
})

export const deleteBlogRequestError = data => ({
    type: BlogActionTypes.DELETE_BLOG_REQUEST_ERROR,
    payload: data
})

export const deleteBlogRequestStatus = data => ({
    type: BlogActionTypes.DELETE_BLOG_REQUEST_STATUS,
    payload: data
})