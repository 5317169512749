import { takeLatest, call, put } from 'redux-saga/effects'
import {
    changePassword, cityListApi, contactUsAPI, countryListApi, forgotPassword,
    ipLocation, loginAPI, stateListApi, subscriptionList, updateUserNotificationToken,
    userRegistrationAPI, userRegistrationByMobileAPI, customerCardRequestAPI, userRegistrationBusinessAPI,
    businessDetailAPI, updateVisitorConfigAPI, updateBusinessNotificationToken,
    operatorListAPI, operatorRegisterationAPI, updateOperatorLocationAPI, operatorDetailAPI
} from '../api-requests/user';
import {
    changePasswordRequestError, changePasswordRequestSuccess, cityListRequestError,
    cityListRequestSuccess, contactUsRequestError, contactUsRequestSuccess, countryListRequestError,
    countryListRequestSuccess, forgotPasswordRequestError, forgotPasswordRequestSuccess,
    ipLocationRequestError, ipLocationRequestSuccess, loginError, loginSuccess,
    registerRequestError, registerRequestSuccess, stateListRequestError, stateListRequestSuccess,
    subscriptionListRequestError, subscriptionListRequestSuccess, updateUserNotificationTokenRequestError,
    updateUserNotificationTokenRequestSuccess, registerByMobileRequestError, registerByMobileRequestSuccess,
    customerCardRequestError, customerCardRequestSuccess, userRegistrationBusinessRequestError, userRegistrationBusinessRequestSuccess,
    businessDetailsRequestError, businessDetailsRequestSuccess, businessVisitorConfigUpdateRequestError,
    businessVisitorConfigUpdateRequestSuccess, businessUpdateNotificationTokenRequestError,
    businessUpdateNotificationTokenRequestSuccess, operatorListRequestError, operatorListRequestSuccess,
    operatorRegisterationRequestError, operatorRegisterationRequestSuccess, updateOperatorLocationRequestError,
    updateOperatorLocationRequestSuccess, operatorDetailRequestError, operatorDetailRequestSuccess
} from './user.action';
import { UserActionTypes } from './user.types'
import { displayConsoleLog } from '../../utils/Utils'
import { decryptResponseData } from '../../utils/DataDecryptionUtils';
import { APP_CONSTANTS } from '../../utils/AppConstants';

function* handleOperatorDetailRequest({ payload }) {
    try {
        const response = yield call(operatorDetailAPI, payload);
        if (response.status >= 200 && response.status < 300) {
            const { data } = response
            const decryptedData = decryptResponseData(data)
            if (decryptedData.data !== null) {
                yield put(operatorDetailRequestSuccess(decryptedData))
            } else {
                yield put(operatorDetailRequestError(decryptedData))
            }
        } else {
            throw response
        }
    } catch (error) {
        displayConsoleLog(error)
        if (error.response?.status === 401) {
            const errData = {
                status: error.response?.status,
                isSessionError: true,
                message: APP_CONSTANTS.SESSION_EXPIRE_MESSAGE
            }
            yield put({ type: UserActionTypes.COMMON_ERROR, errData })
        } else {
            displayConsoleLog(error.response?.data)
            yield put(operatorDetailRequestError(error.response.data))
        }
    }
}

export function* operatorDetailReq() {
    yield takeLatest(UserActionTypes.OPERATOR_DETAIL_REQUEST, handleOperatorDetailRequest)
}

function* handleOperatorListRequest() {
    try {
        const response = yield call(operatorListAPI);
        if (response.status >= 200 && response.status < 300) {
            const { data } = response
            const decryptedData = decryptResponseData(data)
            if (decryptedData.data !== null) {
                yield put(operatorListRequestSuccess(decryptedData))
            } else {
                yield put(operatorListRequestError(decryptedData))
            }
        } else {
            throw response
        }
    } catch (error) {
        displayConsoleLog(error)
        if (error.response?.status === 401) {
            const errData = {
                status: error.response?.status,
                isSessionError: true,
                message: APP_CONSTANTS.SESSION_EXPIRE_MESSAGE
            }
            yield put({ type: UserActionTypes.COMMON_ERROR, errData })
        } else {
            displayConsoleLog(error.response?.data)
            yield put(operatorListRequestError(error.response.data))
        }
    }
}

export function* operatorListReq() {
    yield takeLatest(UserActionTypes.OPERATOR_LIST_REQUEST, handleOperatorListRequest)
}

function* handleOperatorRegisterRequest({ payload }) {
    try {
        const response = yield call(operatorRegisterationAPI, payload);
        if (response.status >= 200 && response.status < 300) {
            const { data } = response
            const decryptedData = decryptResponseData(data)
            if (decryptedData.data !== null) {
                yield put(operatorRegisterationRequestSuccess(decryptedData))
            } else {
                yield put(operatorRegisterationRequestError(decryptedData))
            }
        } else {
            throw response
        }
    } catch (error) {
        displayConsoleLog(error)
        if (error.response?.status === 401) {
            const errData = {
                status: error.response?.status,
                isSessionError: true,
                message: APP_CONSTANTS.SESSION_EXPIRE_MESSAGE
            }
            yield put({ type: UserActionTypes.COMMON_ERROR, errData })
        } else {
            displayConsoleLog(error.response?.data)
            yield put(operatorRegisterationRequestError(error.response.data))
        }
    }
}

export function* operatorRegisterReq() {
    yield takeLatest(UserActionTypes.OPERATOR_REGISTERATION_REQUEST, handleOperatorRegisterRequest)
}

function* handleUpdateOperatorLocationRequest({ payload }) {
    try {
        const response = yield call(updateOperatorLocationAPI, payload);
        if (response.status >= 200 && response.status < 300) {
            const { data } = response
            const decryptedData = decryptResponseData(data)
            if (decryptedData.data !== null) {
                yield put(updateOperatorLocationRequestSuccess(decryptedData))
            } else {
                yield put(updateOperatorLocationRequestError(decryptedData))
            }
        } else {
            throw response
        }
    } catch (error) {
        displayConsoleLog(error)
        if (error.response?.status === 401) {
            const errData = {
                status: error.response?.status,
                isSessionError: true,
                message: APP_CONSTANTS.SESSION_EXPIRE_MESSAGE
            }
            yield put({ type: UserActionTypes.COMMON_ERROR, errData })
        } else {
            displayConsoleLog(error.response?.data)
            yield put(updateOperatorLocationRequestError(error.response.data))
        }
    }
}

export function* updateOperatorLocationReq() {
    yield takeLatest(UserActionTypes.UPDATE_OPERATOR_LOCATION_REQUEST, handleUpdateOperatorLocationRequest)
}

function* handleBusinessUpdateTokenRequest({ payload }) {
    try {
        const response = yield call(updateBusinessNotificationToken, payload);
        if (response.status >= 200 && response.status < 300) {
            const { data } = response
            const decryptedData = decryptResponseData(data)
            if (decryptedData.data !== null) {
                yield put(businessUpdateNotificationTokenRequestSuccess(decryptedData))
            } else {
                yield put(businessUpdateNotificationTokenRequestError(decryptedData))
            }
        } else {
            throw response
        }
    } catch (error) {
        displayConsoleLog(error)
        if (error.response?.status === 401) {
            const errData = {
                status: error.response?.status,
                isSessionError: true,
                message: APP_CONSTANTS.SESSION_EXPIRE_MESSAGE
            }
            yield put({ type: UserActionTypes.COMMON_ERROR, errData })
        } else {
            displayConsoleLog(error.response?.data)
            yield put(businessUpdateNotificationTokenRequestError(error.response.data))
        }
    }
}

export function* businessUpdateNotificationReq() {
    yield takeLatest(UserActionTypes.BUSINESS_UPDATE_NOTIFICATION_REQUEST, handleBusinessUpdateTokenRequest)
}

function* handleBusinessVisitorConfigUpdateRequest({ payload }) {
    try {
        const response = yield call(updateVisitorConfigAPI, payload);
        if (response.status >= 200 && response.status < 300) {
            const { data } = response
            const decryptedData = decryptResponseData(data)
            if (decryptedData.data !== null) {
                yield put(businessVisitorConfigUpdateRequestSuccess(decryptedData))
            } else {
                yield put(businessVisitorConfigUpdateRequestError(decryptedData))
            }
        } else {
            throw response
        }
    } catch (error) {
        displayConsoleLog(error)
        if (error.response?.status === 401) {
            const errData = {
                status: error.response?.status,
                isSessionError: true,
                message: APP_CONSTANTS.SESSION_EXPIRE_MESSAGE
            }
            yield put({ type: UserActionTypes.COMMON_ERROR, errData })
        } else {
            displayConsoleLog(error.response?.data)
            yield put(businessVisitorConfigUpdateRequestError(error.response.data))
        }
    }
}

export function* businessVisitorConfigUpdateReq() {
    yield takeLatest(UserActionTypes.BUSINESS_VISITOR_CONFIG_UPDATE_REQUEST, handleBusinessVisitorConfigUpdateRequest)
}

function* handleBusinessDetailRequest({ payload }) {
    try {
        const response = yield call(businessDetailAPI, payload);
        if (response.status >= 200 && response.status < 300) {
            const { data } = response
            const decryptedData = decryptResponseData(data)
            if (decryptedData.data !== null) {
                yield put(businessDetailsRequestSuccess(decryptedData))
            } else {
                yield put(businessDetailsRequestError(decryptedData))
            }
        } else {
            throw response
        }
    } catch (error) {
        displayConsoleLog(error)
        if (error.response?.status === 401) {
            const errData = {
                status: error.response?.status,
                isSessionError: true,
                message: APP_CONSTANTS.SESSION_EXPIRE_MESSAGE
            }
            yield put({ type: UserActionTypes.COMMON_ERROR, errData })
        } else {
            displayConsoleLog(error.response?.data)
            yield put(businessDetailsRequestError(error.response.data))
        }
    }
}

export function* businessDetailReq() {
    yield takeLatest(UserActionTypes.BUSINESS_DETAILS_REQUEST, handleBusinessDetailRequest)
}

function* handleUserRegistrationBusinessRequest({ payload }) {
    try {
        const response = yield call(userRegistrationBusinessAPI, payload);
        if (response.status >= 200 && response.status < 300) {
            const { data } = response
            const decryptedData = decryptResponseData(data)
            if (decryptedData.data !== null) {
                yield put(userRegistrationBusinessRequestSuccess(decryptedData))
            } else {
                yield put(userRegistrationBusinessRequestError(decryptedData))
            }
        } else {
            throw response
        }
    } catch (error) {
        displayConsoleLog(error)
        if (error.response?.status === 401) {
            const errData = {
                status: error.response?.status,
                isSessionError: true,
                message: APP_CONSTANTS.SESSION_EXPIRE_MESSAGE
            }
            yield put({ type: UserActionTypes.COMMON_ERROR, errData })
        } else {
            displayConsoleLog(error.response?.data)
            yield put(userRegistrationBusinessRequestError(error.response.data))
        }
    }
}

export function* userRegistrationBusinessReq() {
    yield takeLatest(UserActionTypes.USER_REGISTRATION_BUSINESS_REQUEST, handleUserRegistrationBusinessRequest)
}

function* handleCustomerCardRequest({ payload }) {
    try {
        const response = yield call(customerCardRequestAPI, payload);
        if (response.status >= 200 && response.status < 300) {
            const { data } = response
            const decryptedData = decryptResponseData(data)
            if (decryptedData.data !== null) {
                yield put(customerCardRequestSuccess(decryptedData))
            } else {
                yield put(customerCardRequestError(decryptedData))
            }
        } else {
            throw response
        }
    } catch (error) {
        displayConsoleLog(error)
        if (error.response?.status === 401) {
            const errData = {
                status: error.response?.status,
                isSessionError: true,
                message: APP_CONSTANTS.SESSION_EXPIRE_MESSAGE
            }
            yield put({ type: UserActionTypes.COMMON_ERROR, errData })
        } else {
            displayConsoleLog(error.response?.data)
            yield put(customerCardRequestError(error.response.data))
        }
    }
}

export function* customerCardReq() {
    yield takeLatest(UserActionTypes.CUSTOMER_CARD_REQUEST, handleCustomerCardRequest)
}

function* handleRegisterByMobile({ payload }) {
    try {
        const response = yield call(userRegistrationByMobileAPI, payload);
        if (response.status >= 200 && response.status < 300) {
            const { data } = response
            const decryptedData = decryptResponseData(data)
            if (decryptedData.data !== null) {
                yield put(registerByMobileRequestSuccess(decryptedData))
            } else {
                yield put(registerByMobileRequestError(decryptedData))
            }
        } else {
            throw response
        }
    } catch (error) {
        displayConsoleLog(error)
        if (error.response?.status === 401) {
            const errData = {
                status: error.response?.status,
                isSessionError: true,
                message: APP_CONSTANTS.SESSION_EXPIRE_MESSAGE
            }
            yield put({ type: UserActionTypes.COMMON_ERROR, errData })
        } else {
            displayConsoleLog(error.response?.data)
            yield put(registerByMobileRequestError(error.response.data))
        }
    }
}

export function* registerByMobileReq() {
    yield takeLatest(UserActionTypes.REGISTER_REQUEST_BY_MOBILE, handleRegisterByMobile)
}

function* handleContactUs({ payload }) {
    try {
        const response = yield call(contactUsAPI, payload);
        if (response.status >= 200 && response.status < 300) {
            const { data } = response
            const decryptedData = decryptResponseData(data)
            if (decryptedData.data !== null) {
                yield put(contactUsRequestSuccess(decryptedData))
            } else {
                yield put(contactUsRequestError(decryptedData))
            }
        } else {
            throw response
        }
    } catch (error) {
        displayConsoleLog(error)
        if (error.response?.status === 401) {
            const errData = {
                status: error.response?.status,
                isSessionError: true,
                message: APP_CONSTANTS.SESSION_EXPIRE_MESSAGE
            }
            yield put({ type: UserActionTypes.COMMON_ERROR, errData })
        } else {
            displayConsoleLog(error.response?.data)
            yield put(contactUsRequestError(error.response.data))
        }
    }
}

export function* contactUsReq() {
    yield takeLatest(UserActionTypes.CONTACT_US_REQUEST, handleContactUs)
}

function* handleUpdateUserNotificationToken({ payload }) {
    try {
        const response = yield call(updateUserNotificationToken, payload);
        if (response.status >= 200 && response.status < 300) {
            const { data } = response
            const decryptedData = decryptResponseData(data)
            if (decryptedData.data !== null) {
                yield put(updateUserNotificationTokenRequestSuccess(decryptedData))
            } else {
                yield put(updateUserNotificationTokenRequestError(decryptedData))
            }
        } else {
            throw response
        }
    } catch (error) {
        displayConsoleLog(error)
        if (error.response?.status === 401) {
            const errData = {
                status: error.response?.status,
                isSessionError: true,
                message: APP_CONSTANTS.SESSION_EXPIRE_MESSAGE
            }
            yield put({ type: UserActionTypes.COMMON_ERROR, errData })
        } else {
            displayConsoleLog(error.response?.data)
            yield put(updateUserNotificationTokenRequestError(error.response.data))
        }
    }
}

export function* userUpdateUserNotificationTokenReq() {
    yield takeLatest(UserActionTypes.UPDATE_NOTIFICATION_TOKEN_REQUEST, handleUpdateUserNotificationToken)
}

function* handleIpLocation({ payload }) {
    try {
        const response = yield call(ipLocation, payload);
        if (response.status >= 200 && response.status < 300) {
            const { data } = response
            if (data.error) {
                yield put(ipLocationRequestError(data))
            } else {
                yield put(ipLocationRequestSuccess(data))
            }
        } else {
            throw response
        }
    } catch (error) {
        yield put(ipLocationRequestError(error.response))
    }
}

export function* ipLocationReq() {
    yield takeLatest(UserActionTypes.IP_LOCATION_REQUEST, handleIpLocation)
}

function* handleChangePassword({ payload }) {
    try {
        const response = yield call(changePassword, payload);
        if (response.status >= 200 && response.status < 300) {
            const { data } = response
            const decryptedData = decryptResponseData(data)
            if (decryptedData.data !== null) {
                yield put(changePasswordRequestSuccess(decryptedData))
            } else {
                yield put(changePasswordRequestError(decryptedData))
            }
        } else {
            throw response
        }
    } catch (error) {
        displayConsoleLog(error.response.data)
        yield put(changePasswordRequestError(error.response.data))
    }
}

export function* changePasswordReq() {
    yield takeLatest(UserActionTypes.CHANGE_PASSWORD_REQUEST, handleChangePassword)
}

function* handleForgotPassword({ payload }) {
    try {
        const response = yield call(forgotPassword, payload);
        if (response.status >= 200 && response.status < 300) {
            const { data } = response
            const decryptedData = decryptResponseData(data)
            if (decryptedData.data !== null) {
                yield put(forgotPasswordRequestSuccess(decryptedData))
            } else {
                yield put(forgotPasswordRequestError(decryptedData))
            }
        } else {
            throw response
        }
    } catch (error) {
        displayConsoleLog(error.response.data)
        yield put(forgotPasswordRequestError(error.response.data))
    }
}

export function* forgotPasswordReq() {
    yield takeLatest(UserActionTypes.FORGOT_PASSWORD_REQUEST, handleForgotPassword)
}

function* handleSubscriptionList() {
    try {
        const response = yield call(subscriptionList);
        if (response.status >= 200 && response.status < 300) {
            const { data } = response
            const decryptedData = decryptResponseData(data)
            if (decryptedData.data !== null) {
                yield put(subscriptionListRequestSuccess(decryptedData))
            } else {
                yield put(subscriptionListRequestError(decryptedData))
            }
        } else {
            throw response
        }
    } catch (error) {
        displayConsoleLog(error)
        displayConsoleLog(error.response.data)
        yield put(subscriptionListRequestError(error.response.data))
    }
}

export function* subscriptionListReq() {
    yield takeLatest(UserActionTypes.SUBSCRIPTION_LIST_REQUEST, handleSubscriptionList)
}

function* handleRegisterUser({ payload }) {
    try {
        const response = yield call(userRegistrationAPI, payload);
        if (response.status >= 200 && response.status < 300) {
            const { data } = response
            const decryptedData = decryptResponseData(data)
            if (decryptedData.data !== null) {
                yield put(registerRequestSuccess(decryptedData))
            } else {
                yield put(registerRequestError(decryptedData))
            }
        } else {
            throw response
        }
    } catch (error) {
        displayConsoleLog(error.response.data)
        yield put(registerRequestError(error.response.data))
    }
}

export function* registerUserReq() {
    yield takeLatest(UserActionTypes.REGISTER_REQUEST, handleRegisterUser)
}

function* handleLoginUser({ payload }) {
    try {
        const response = yield call(loginAPI, payload);
        if (response.status >= 200 && response.status < 300) {
            const { data } = response
            const decryptedData = decryptResponseData(data)
            if (decryptedData.data !== null) {
                yield put(loginSuccess(decryptedData))
            } else {
                yield put(loginError(decryptedData))
            }
        } else {
            throw response
        }
    } catch (error) {
        displayConsoleLog(error.response)
        yield put(loginError(error.response.data))
    }
}

export function* loginUserReq() {
    yield takeLatest(UserActionTypes.LOGIN_USER_REQUEST, handleLoginUser)
}

function* handleCityList({ payload }) {
    try {
        const response = yield call(cityListApi, payload)
        if (response.status >= 200 && response.status < 300) {
            const { data } = response
            yield put(cityListRequestSuccess(data))
        } else {
            throw response
        }
    } catch (error) {
        displayConsoleLog('error', error.response)
        yield put(cityListRequestError(error.response))
    }
}

export function* cityListReq() {
    yield takeLatest(UserActionTypes.CITY_LIST_REQUEST, handleCityList)
}

function* handleStateList({ payload }) {
    try {
        const response = yield call(stateListApi, payload)
        if (response.status >= 200 && response.status < 300) {
            const { data } = response
            yield put(stateListRequestSuccess(data))
        } else {
            throw response
        }
    } catch (error) {
        displayConsoleLog('error', error.response)
        yield put(stateListRequestError(error.response))
    }
}

export function* stateListReq() {
    yield takeLatest(UserActionTypes.STATE_LIST_REQUEST, handleStateList)
}

function* handleCountryList() {
    try {
        const response = yield call(countryListApi)
        if (response.status >= 200 && response.status < 300) {
            const { data } = response
            yield put(countryListRequestSuccess(data))
        } else {
            throw response
        }
    } catch (error) {
        displayConsoleLog('error', error.response)
        yield put(countryListRequestError(error.response))
    }
}

export function* countryListReq() {
    yield takeLatest(UserActionTypes.COUNTRY_LIST_REQUEST, handleCountryList)
}