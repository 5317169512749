import { toast } from 'react-toastify';
import { APP_CONSTANTS } from './AppConstants';
import { logEvent } from "firebase/analytics";
// import { analytics } from '../fcmAnalytics';

export const displayErrorToast = message => {
    toast.error(message, {
        position: "top-right",
        autoClose: 4000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        toastId: 123
    });
}

export const displaySuccessToast = message => {
    toast.success(message, {
        position: "top-right",
        autoClose: 4000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        toastId: 123
    });
}

export const displayConsoleLog = (tag, message = '') => {
    if (process.env.NODE_ENV === 'development') {
        console.log(tag, message)
    }
}

let trim = (x) => {
    let value = String(x);
    return value.replace(/^\s+|\s+$/gm, "");
};

export const isEmpty = (value) => {
    if (
        value === null ||
        value === undefined ||
        trim(value) === "" ||
        value.length === 0
    ) {
        return true;
    } else {
        return false;
    }
};

export const isNull = (value) => {
    if (value === null ||
        value === undefined ||
        value.length === 0) {
        return true
    } else {
        return false
    }
}

export const isUserNotNull = (value) => {
    if (!isNull(value) && !isNull(value.data) && !isNull(value.data.userDetails))
        return true
    else return false
}

export const isValidEmail = (email) => {
    let emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (email.match(emailRegex)) {
        return true;
    } else {
        return false;
    }
};

export const convertToPascal = (text) => {
    return text.replace(/(\w)(\w*)/g,
        function (g0, g1, g2) { return g1.toUpperCase() + g2.toLowerCase(); })
}

export const removeSpecialCharAndChangeToPascal = (text) => {
    let text1 = text.replace(/[^a-zA-Z ]/g, " ")
    return text1.replace(/(\w)(\w*)/g,
        function (g0, g1, g2) { return g1.toUpperCase() + g2.toLowerCase(); })
}

export const addSpecialCharAndChangeToCamel = (text) => {
    let text1 = text.replace(" ", "_")
    return text1.replace(/(\w)(\w*)/g,
        function (g0, g1, g2) { return g1.toLowerCase() + g2.toLowerCase(); })
}

export const screenCaptureAnalytics = data => {
    // logEvent(analytics, 'screen_view', {
    //     firebase_screen: data.screen,
    //     firebase_screen_class: data.screenClass
    // })
}

export const convertToCamelcaseWithSpecialChar = (text) => {
    const arr = text.split(" ")
    for (var i = 0; i < arr.length; i++) {
        arr[i] = arr[i].charAt(0).toLowerCase() + arr[i].slice(1);
    }
}


export const isLoggedInUserAdmin = (user) => {
    if (!isNull(user) && !isNull(user.data) && !isNull(user.data.userDetails) && !isEmpty(user.data.userDetails.userType) &&
        user.data.userDetails.userType === APP_CONSTANTS.USER_TYPE_ADMIN) {
        return true
    } else {
        return false
    }
}

export const isLoggedInUserOperator = (user) => {
    if (!isNull(user) && !isNull(user.data) && !isNull(user.data.userDetails) && !isEmpty(user.data.userDetails.userType) &&
        user.data.userDetails.userType === APP_CONSTANTS.USER_TYPE_OPERATOR) {
        return true
    } else {
        return false
    }
}

export const isLoggedInUserBusiness = (user) => {
    if (!isNull(user) && !isNull(user.data) && !isNull(user.data.userDetails) && !isEmpty(user.data.userDetails.userType) &&
        user.data.userDetails.userType === APP_CONSTANTS.USER_TYPE_BUSINESS) {
        return true
    } else {
        return false
    }
}

export const isLoggedInUserCorporate = (user) => {
    if (isLoggedInUserBusiness(user) && user.data.userDetails?.businessData?.registeredPlatform === APP_CONSTANTS.REGISTERED_PLATFORM.CLICKTRAN_CORPORATE) {
        return true
    } else {
        return false
    }
}

export const isLoggedInUserCustomer = (user) => {
    if (!isNull(user) && !isNull(user.data) && !isNull(user.data.userDetails) && !isEmpty(user.data.userDetails.userType) &&
        user.data.userDetails.userType === APP_CONSTANTS.USER_TYPE_CUSTOMER) {
        return true
    } else {
        return false
    }
}

export const getDeviceType = (window) => {
    const userAgent = window.navigator.platform;

    if (/Win/i.test(userAgent)) {
        return APP_CONSTANTS.DEVICE_TYPE.WINDOWS;
    } else if (/Mac/i.test(userAgent)) {
        return APP_CONSTANTS.DEVICE_TYPE.MAC_OS;
    } else if (/Android/i.test(userAgent)) {
        return APP_CONSTANTS.DEVICE_TYPE.ANDROID;
    } else if (/iPhone|iPad|iPod/i.test(userAgent)) {
        return APP_CONSTANTS.DEVICE_TYPE.IOS;
    } else {
        return userAgent;
    }
};

export const clearLocalStorage = () => {
    for (let i = 0; i < localStorage.length; i++) {
        const key = localStorage.key(i)
        let isKeyToRetain = false
        if (key === 'recordId') {
            isKeyToRetain = true
        }
        if (key === 'cardRecordId') {
            isKeyToRetain = true
        }
        if (isKeyToRetain === false) {
            localStorage.removeItem(key)
        }
    }
}

export const cmToPx = (cm, dpi = 96) => {
    const inchesPerCm = 2.54;
    return cm * (dpi / inchesPerCm);
}

export const displayCurrencySymbolLeft = (region) => {
    if (region === 'Europe') {
        return false
    } else {
        return true
    }
}

function convertToIntegerIfDecimal(number) {
    // Check if the number is a float with two decimal places
    if (`${number}`.includes('.00')) {
        return parseInt(number); // Convert to integer if it has two decimal places
    } else {
        return number; // Return the number as is if it's not .00
    }
}

export const priceWithSymbol = (element, price) => {
    if (!`${price}`.includes('.')) {
        price = price.toFixed(2)
    }
    const priceValue = convertToIntegerIfDecimal(price).toLocaleString('en-US')
    if (!isEmpty(element.currencyRegion)) {
        if (displayCurrencySymbolLeft(element.currencyRegion) === false) {
            return `${priceValue} ${element.currencySymbol}`
        } else {
            return `${element.currencySymbol} ${priceValue}`
        }
    } else {
        return `${priceValue}`
    }
}

export const calculateDiscountedPrice = (price, discount) => {
    const discountAmt = price * discount / 100
    const finalPrice = price - discountAmt
    return finalPrice.toFixed(2)
}