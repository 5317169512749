import React from "react";
import TextField from '@mui/material/TextField';

const CommonInputFloatHalf = ({ type, classname = "", placeholder, value, onChange, id, isRequired = false, onFocus }) => {
    return (
        <div className='common-input-half'>
            <TextField
                className="common-input-full"
                type={type}
                required={isRequired}
                id={id}
                label={placeholder}
                value={value}
                fullWidth
                onChange={onChange}
                onFocus={onFocus ? onFocus : null}
                color='success'
                size="medium"
            />
        </div>
    )
}

export default CommonInputFloatHalf