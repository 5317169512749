import { ServiceActionTypes } from './service.types'

//Add Service
export const addServiceRequest = data => ({
    type: ServiceActionTypes.ADD_SERVICE_REQUEST,
    payload: data
})

export const addServiceRequestSuccess = data => ({
    type: ServiceActionTypes.ADD_SERVICE_REQUEST_SUCCESS,
    payload: data
})

export const addServiceRequestError = data => ({
    type: ServiceActionTypes.ADD_SERVICE_REQUEST_ERROR,
    payload: data
})

export const addServiceRequestStatus = data => ({
    type: ServiceActionTypes.ADD_SERVICE_REQUEST_STATUS,
    payload: data
})

//Service list
export const serviceListRequest = data => ({
    type: ServiceActionTypes.SERVICE_LIST_REQUEST,
    payload: data
})

export const serviceListRequestSuccess = data => ({
    type: ServiceActionTypes.SERVICE_LIST_REQUEST_SUCCESS,
    payload: data
})

export const serviceListRequestError = data => ({
    type: ServiceActionTypes.SERVICE_LIST_REQUEST_ERROR,
    payload: data
})

//Update Service
export const updateServiceRequest = data => ({
    type: ServiceActionTypes.UPDATE_SERVICE_REQUEST,
    payload: data
})

export const updateServiceRequestSuccess = data => ({
    type: ServiceActionTypes.UPDATE_SERVICE_REQUEST_SUCCESS,
    payload: data
})

export const updateServiceRequestError = data => ({
    type: ServiceActionTypes.UPDATE_SERVICE_REQUEST_ERROR,
    payload: data
})

export const updateServiceRequestStatus = data => ({
    type: ServiceActionTypes.UPDATE_SERVICE_REQUEST_STATUS,
    payload: data
})

//Delete Service
export const deleteServiceRequest = data => ({
    type: ServiceActionTypes.DELETE_SERVICE_REQUEST,
    payload: data
})

export const deleteServiceRequestSuccess = data => ({
    type: ServiceActionTypes.DELETE_SERVICE_REQUEST_SUCCESS,
    payload: data
})

export const deleteServiceRequestError = data => ({
    type: ServiceActionTypes.DELETE_SERVICE_REQUEST_ERROR,
    payload: data
})

export const deleteServiceRequestStatus = data => ({
    type: ServiceActionTypes.DELETE_SERVICE_REQUEST_STATUS,
    payload: data
})
