/* global NDEFReader */
import React, { useState } from "react";
import './styles.css'
import CommonScreenContentCenter from "../../../components/screen/common-screen-content-center/commonScreenContentCenter";
import CommonFormBorder from "../../../components/common-form-border/commonFormBorder";
import CommonInputFloatFull from "../../../components/input/common-input-float-full/commonInputFloatFull";
import ButtonRow from "../../../components/button/button-row/buttonRow";
import CommonButton from "../../../components/button/common-button/commonButton";
import { displayErrorToast, displaySuccessToast, isEmpty } from "../../../utils/Utils";

const NfcWriterPage = props => {
    const [nfcText, setNfcText] = useState('')

    const handleWriteClick = async () => {
        if (isEmpty(nfcText)) {
            displayErrorToast('Nfc text cannot be blank')
        } else {
            if ('NDEFReader' in window) {
                try {
                    const ndef = new NDEFReader()
                    await ndef.write(nfcText)
                    displaySuccessToast('NFC tag written successfully')
                } catch (e) {
                    displayErrorToast(`Error occurred : ${e}`)
                }
            } else {
                displayErrorToast('Web NFC is not supported on this device or browser.')
            }
        }
    }

    return (<>
        <CommonScreenContentCenter>
            <CommonFormBorder>
                <CommonInputFloatFull
                    type='text'
                    id='nfcText'
                    onChange={e => setNfcText(e.target.value)}
                    placeholder='Enter text to write'
                    value={nfcText}
                />
                <ButtonRow>
                    <CommonButton label='Write NFC' onClick={() => { handleWriteClick() }} />
                </ButtonRow>
            </CommonFormBorder>
        </CommonScreenContentCenter>
    </>)
}

export default NfcWriterPage