import React, { useEffect, useState } from "react";
import './styles.css'
import Loader from '../../../../../components/loader/loader.component'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'react-phone-number-input/style.css'
import { useDispatch, useSelector } from 'react-redux'
import CommonScreenContent from "../../../../../components/screen/common-screen-content/commonScreenContent";
import ScreenLabel from "../../../../../components/label/screen-label/screenLabel";
import CommonColorForm from "../../../../../components/common-color-form/commonColorForm";
import SubTextLabel from "../../../../../components/label/sub-text-label/subTextLabel";
import ButtonRow from "../../../../../components/button/button-row/buttonRow";
import CommonButton from "../../../../../components/button/common-button/commonButton";
import { API_CONSTANTS, APP_CONSTANTS } from "../../../../../utils/AppConstants";
import { addEmployeeRequest, addEmployeeRequestStatus } from '../../../../../redux/employee/employee.action'
import { displayErrorToast, displaySuccessToast, isEmpty } from "../../../../../utils/Utils";
import CommonInputRow from "../../../../../components/input/common-input-row/commonInputRow";
import CommonInputFloatHalf from "../../../../../components/input/common-input-float-half/commonInputFloafHalf";
import CommonInputFloatFull from "../../../../../components/input/common-input-float-full/commonInputFloatFull";

const AddEmployeeScreen = props => {
    const [isLoading, setLoading] = useState(false)
    const dispatch = useDispatch()
    const { user } = useSelector(state => state.user)
    const { addEmployeeSuccess, addEmployeeError, addEmployeeReqStatus } = useSelector(state => state.employee)
    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [phoneNumber, setPhoneNumber] = useState('')
    const [emailId, setEmailId] = useState('')
    const [businessAddress, setBusinessAddress] = useState('')
    const [designation, setDesignation] = useState('')

    useEffect(() => {
        if (addEmployeeReqStatus === API_CONSTANTS.SUCCESS_STATUS) {
            setLoading(false)
            displaySuccessToast(addEmployeeSuccess.message)
            clearFields()
        } else if (addEmployeeReqStatus === API_CONSTANTS.ERROR_STATUS) {
            setLoading(false)
            displayErrorToast(addEmployeeError.message)
        }
        if (addEmployeeReqStatus?.length > 0) {
            dispatch(addEmployeeRequestStatus(''))
        }
    }, [addEmployeeSuccess, addEmployeeError, addEmployeeReqStatus])

    const clearFields = () => {
        setFirstName('')
        setLastName('')
        setPhoneNumber('')
        setEmailId('')
        setBusinessAddress('')
        setDesignation('')
    }


    const handleSubmitClick = () => {
        if (isEmpty(firstName)) {
            displayErrorToast('First name cannot be blank')
        } else if (isEmpty(lastName)) {
            displayErrorToast('Last name cannot be blank')
        } else if (isEmpty(designation)) {
            displayErrorToast('Designation cannot be blank')
        } else {
            setLoading(true)
            const data = {
                firstName: firstName,
                lastName: lastName,
                designation: designation,
                productCode: APP_CONSTANTS.PRODUCT_NAME,
                businessId: user.data.userDetails?.businessData?.businessId
            }
            if (!isEmpty(emailId)) {
                data.email = emailId
            }
            if (!isEmpty(phoneNumber)) {
                data.mobile = phoneNumber
            }
            if (!isEmpty(businessAddress)) {
                data.address = businessAddress
            }
            dispatch(addEmployeeRequest(data))
        }
    }

    return (
        <>
            <CommonScreenContent>
                <ScreenLabel label='Add Employee' />
                <CommonColorForm>
                    <SubTextLabel label='Enter details to add new employee' />
                    <CommonInputRow>
                        <CommonInputFloatHalf
                            type='text'
                            id='firstName'
                            onChange={e => setFirstName(e.target.value)}
                            placeholder='First Name'
                            value={firstName} />
                        <CommonInputFloatHalf
                            type='text'
                            id='lastName'
                            onChange={e => setLastName(e.target.value)}
                            placeholder='Last Name'
                            value={lastName} />
                    </CommonInputRow>
                    <CommonInputFloatFull
                        type='text'
                        id='email'
                        onChange={e => setEmailId(e.target.value)}
                        placeholder='Email Id'
                        value={emailId}
                    />
                    <CommonInputFloatFull
                        type='text'
                        id='mobile'
                        onChange={e => setPhoneNumber(e.target.value)}
                        placeholder='Contact Number'
                        value={phoneNumber}
                    />
                    <CommonInputFloatFull
                        type='text'
                        id='designation'
                        onChange={e => setDesignation(e.target.value)}
                        placeholder='Designation'
                        value={designation}
                    />
                    <CommonInputFloatFull
                        type='text'
                        id='address'
                        onChange={e => setBusinessAddress(e.target.value)}
                        placeholder='Business Address'
                        value={businessAddress}
                    />
                    <ButtonRow>
                        <CommonButton label='Submit'
                            onClick={() => { handleSubmitClick() }} />
                    </ButtonRow>
                </CommonColorForm>
            </CommonScreenContent>
            <ToastContainer />
            <Loader active={isLoading} text='Please wait...' />
        </>
    )
}

export default AddEmployeeScreen