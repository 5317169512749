export const AppointmentActionTypes = {
    LOGOUT: 'LOGOUT',

    ADD_APPOINTMENT_REQUEST: 'ADD_APPOINTMENT_REQUEST',
    ADD_APPOINTMENT_REQUEST_SUCCESS: 'ADD_APPOINTMENT_REQUEST_SUCCESS',
    ADD_APPOINTMENT_REQUEST_ERROR: 'ADD_APPOINTMENT_REQUEST_ERROR',
    ADD_APPOINTMENT_REQUEST_STATUS: 'ADD_APPOINTMENT_REQUEST_STATUS',

    APPOINTMENT_LIST_REQUEST: 'APPOINTMENT_LIST_REQUEST',
    APPOINTMENT_LIST_REQUEST_SUCCESS: 'APPOINTMENT_LIST_REQUEST_SUCCESS',
    APPOINTMENT_LIST_REQUEST_ERROR: 'APPOINTMENT_LIST_REQUEST_ERROR',

    UPDATE_APPOINTMENT_REQUEST: 'UPDATE_APPOINTMENT_REQUEST',
    UPDATE_APPOINTMENT_REQUEST_SUCCESS: 'UPDATE_APPOINTMENT_REQUEST_SUCCESS',
    UPDATE_APPOINTMENT_REQUEST_ERROR: 'UPDATE_APPOINTMENT_REQUEST_ERROR',
    UPDATE_APPOINTMENT_REQUEST_STATUS: 'UPDATE_APPOINTMENT_REQUEST_STATUS',

    COMMON_ERROR: 'COMMON_ERROR'
}
