export const HotelPointActionTypes = {
    LOGOUT: 'LOGOUT',
    COMMON_ERROR: 'COMMON_ERROR',

    ADD_HOTEL_POINT_REQUEST: 'ADD_HOTEL_POINT_REQUEST',
    ADD_HOTEL_POINT_REQUEST_SUCCESS: 'ADD_HOTEL_POINT_REQUEST_SUCCESS',
    ADD_HOTEL_POINT_REQUEST_ERROR: 'ADD_HOTEL_POINT_REQUEST_ERROR',
    ADD_HOTEL_POINT_REQUEST_STATUS: 'ADD_HOTEL_POINT_REQUEST_STATUS',

    HOTEL_POINT_RECORD_LIST_REQUEST: 'HOTEL_POINT_RECORD_LIST_REQUEST',
    HOTEL_POINT_RECORD_LIST_REQUEST_SUCCESS: 'HOTEL_POINT_RECORD_LIST_REQUEST_SUCCESS',
    HOTEL_POINT_RECORD_LIST_REQUEST_ERROR: 'HOTEL_POINT_RECORD_LIST_REQUEST_ERROR'
}
