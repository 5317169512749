import { API_CONSTANTS } from '../../utils/AppConstants'
import { HotelPointActionTypes } from './hotelPoint.types'

const INITIAL_STATE = {
    addHotelPointSuccess: null,
    addHotelPointError: null,
    addHotelPointReqStatus: '',
    hotelPointListSuccess: null,
    hotelPointListError: null
}

const hotelPointReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case HotelPointActionTypes.ADD_HOTEL_POINT_REQUEST_SUCCESS:
            return {
                ...state,
                addHotelPointSuccess: action.payload,
                addHotelPointError: null,
                addHotelPointReqStatus: API_CONSTANTS.SUCCESS_STATUS
            }
        case HotelPointActionTypes.ADD_HOTEL_POINT_REQUEST_ERROR:
            return {
                ...state,
                addHotelPointSuccess: null,
                addHotelPointError: action.payload,
                addHotelPointReqStatus: API_CONSTANTS.ERROR_STATUS
            }
        case HotelPointActionTypes.ADD_HOTEL_POINT_REQUEST_STATUS:
            return {
                ...state,
                addHotelPointReqStatus: ''
            }
        case HotelPointActionTypes.HOTEL_POINT_RECORD_LIST_REQUEST_SUCCESS:
            return {
                ...state,
                hotelPointListSuccess: action.payload,
                hotelPointListError: null
            }
        case HotelPointActionTypes.HOTEL_POINT_RECORD_LIST_REQUEST_ERROR:
            return {
                ...state,
                hotelPointListSuccess: null,
                hotelPointListError: action.payload
            }
        default:
            return state
    }
}

export default hotelPointReducer