import { QRCodeActionTypes } from './customQrCode.types'
import { API_CONSTANTS } from '../../utils/AppConstants'

const INITIAL_STATE = {
    addQrCodeSuccess: null,
    addQrCodeError: null,
    addQrCodeReqStatus: '',
    qrCodeListSuccess: null,
    qrCodeListError: null,
    deleteQrCodeSuccess: null,
    deleteQrCodeError: null,
    deleteQrCodeReqStatus: '',
    updateQrCodeSuccess: null,
    updateQrCodeError: null,
    updateQrCodeReqStatus: '',
    qrCodeDetailSuccess: null,
    qrCodeDetailError: null
}

const qrCodeReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case QRCodeActionTypes.ADD_CUSTOM_QR_CODE_REQUEST_SUCCESS:
            return {
                ...state,
                addQrCodeSuccess: action.payload,
                addQrCodeError: null,
                addQrCodeReqStatus: API_CONSTANTS.SUCCESS_STATUS
            }
        case QRCodeActionTypes.ADD_CUSTOM_QR_CODE_REQUEST_ERROR:
            return {
                ...state,
                addQrCodeSuccess: null,
                addQrCodeError: action.payload,
                addQrCodeReqStatus: API_CONSTANTS.ERROR_STATUS
            }
        case QRCodeActionTypes.ADD_CUSTOM_QR_CODE_REQUEST_STATUS:
            return {
                ...state,
                addQrCodeReqStatus: ''
            }
        case QRCodeActionTypes.UPDATE_QR_CODE_REQUEST_SUCCESS:
            return {
                ...state,
                updateQrCodeSuccess: action.payload,
                updateQrCodeError: null,
                updateQrCodeReqStatus: API_CONSTANTS.SUCCESS_STATUS
            }
        case QRCodeActionTypes.UPDATE_QR_CODE_REQUEST_ERROR:
            return {
                ...state,
                updateQrCodeSuccess: null,
                updateQrCodeError: action.payload,
                updateQrCodeReqStatus: API_CONSTANTS.ERROR_STATUS
            }
        case QRCodeActionTypes.UPDATE_QR_CODE_REQUEST_STATUS:
            return {
                ...state,
                updateQrCodeReqStatus: ''
            }
        case QRCodeActionTypes.CUSTOM_QR_CODE_LIST_REQUEST_SUCCESS:
            return {
                ...state,
                qrCodeListSuccess: action.payload,
                qrCodeListError: null
            }
        case QRCodeActionTypes.CUSTOM_QR_CODE_LIST_REQUEST_ERROR:
            return {
                ...state,
                qrCodeListSuccess: null,
                qrCodeListError: action.payload
            }
        case QRCodeActionTypes.QR_CODE_DETAIL_REQUEST_SUCCESS:
            return {
                ...state,
                qrCodeDetailSuccess: action.payload,
                qrCodeDetailError: null
            }
        case QRCodeActionTypes.QR_CODE_DETAIL_REQUEST_ERROR:
            return {
                ...state,
                qrCodeDetailSuccess: null,
                qrCodeDetailError: action.payload
            }
        case QRCodeActionTypes.DELETE_CUSTOM_QR_CODE_REQUEST_SUCCESS:
            return {
                ...state,
                deleteQrCodeSuccess: action.payload,
                deleteQrCodeError: null,
                deleteQrCodeReqStatus: API_CONSTANTS.SUCCESS_STATUS
            }
        case QRCodeActionTypes.DELETE_CUSTOM_QR_CODE_REQUEST_ERROR:
            return {
                ...state,
                deleteQrCodeSuccess: null,
                deleteQrCodeError: action.payload,
                deleteQrCodeReqStatus: API_CONSTANTS.ERROR_STATUS
            }
        case QRCodeActionTypes.DELETE_CUSTOM_QR_CODE_REQUEST_STATUS:
            return {
                ...state,
                deleteQrCodeReqStatus: ''
            }
        case QRCodeActionTypes.LOGOUT:
            return INITIAL_STATE
        default:
            return state
    }
}

export default qrCodeReducer