import { takeLatest, call, put } from 'redux-saga/effects'
import {
    addAppointmentAPI, appointmentListAPI, updateAppointmentStatusAPI
} from '../api-requests/appointment';
import {
    addAppointmentRequestError, addAppointmentRequestSuccess, appointmentListRequestError,
    appointmentListRequestSuccess, updateAppointmentRequestError, updateAppointmentRequestSuccess
} from './appointment.action';
import { AppointmentActionTypes } from './appointment.types'
import { displayConsoleLog } from '../../utils/Utils'
import { decryptResponseData } from '../../utils/DataDecryptionUtils';
import { APP_CONSTANTS } from '../../utils/AppConstants';

function* handleUpdateAppointment({ payload }) {
    try {
        const response = yield call(updateAppointmentStatusAPI, payload);
        if (response.status >= 200 && response.status < 300) {
            const { data } = response
            const decryptedData = decryptResponseData(data)
            if (decryptedData.data !== null) {
                yield put(updateAppointmentRequestSuccess(decryptedData))
            } else {
                yield put(updateAppointmentRequestError(decryptedData))
            }
        } else {
            throw response
        }
    } catch (error) {
        displayConsoleLog(error)
        if (error.response?.status === 401) {
            const errData = {
                status: error.response?.status,
                isSessionError: true,
                message: APP_CONSTANTS.SESSION_EXPIRE_MESSAGE
            }
            yield put({ type: APP_CONSTANTS.COMMON_ERROR, errData })
        } else {
            displayConsoleLog(error.response?.data)
            yield put(updateAppointmentRequestError(error.response.data))
        }
    }
}

export function* updateAppointmentReq() {
    yield takeLatest(AppointmentActionTypes.UPDATE_APPOINTMENT_REQUEST, handleUpdateAppointment)
}

function* handleAppointmentList({ payload }) {
    try {
        const response = yield call(appointmentListAPI, payload);
        if (response.status >= 200 && response.status < 300) {
            const { data } = response
            const decryptedData = decryptResponseData(data)
            if (decryptedData.data !== null) {
                yield put(appointmentListRequestSuccess(decryptedData))
            } else {
                yield put(appointmentListRequestError(decryptedData))
            }
        } else {
            throw response
        }
    } catch (error) {
        displayConsoleLog(error)
        if (error.response?.status === 401) {
            const errData = {
                status: error.response?.status,
                isSessionError: true,
                message: APP_CONSTANTS.SESSION_EXPIRE_MESSAGE
            }
            yield put({ type: APP_CONSTANTS.COMMON_ERROR, errData })
        } else {
            displayConsoleLog(error.response?.data)
            yield put(appointmentListRequestError(error.response.data))
        }
    }
}

export function* appointmentListReq() {
    yield takeLatest(AppointmentActionTypes.APPOINTMENT_LIST_REQUEST, handleAppointmentList)
}

function* handleAddAppointment({ payload }) {
    try {
        const response = yield call(addAppointmentAPI, payload);
        if (response.status >= 200 && response.status < 300) {
            const { data } = response
            const decryptedData = decryptResponseData(data)
            if (decryptedData.data !== null) {
                yield put(addAppointmentRequestSuccess(decryptedData))
            } else {
                yield put(addAppointmentRequestError(decryptedData))
            }
        } else {
            throw response
        }
    } catch (error) {
        displayConsoleLog(error)
        if (error.response?.status === 401) {
            const errData = {
                status: error.response?.status,
                isSessionError: true,
                message: APP_CONSTANTS.SESSION_EXPIRE_MESSAGE
            }
            yield put({ type: APP_CONSTANTS.COMMON_ERROR, errData })
        } else {
            displayConsoleLog(error.response?.data)
            yield put(addAppointmentRequestError(error.response.data))
        }
    }
}

export function* addAppointmentReq() {
    yield takeLatest(AppointmentActionTypes.ADD_APPOINTMENT_REQUEST, handleAddAppointment)
}