import { NfcCardOrderActionTypes } from './nfcCardOrder.types'

//Place order
export const placeNfcCardOrderRequest = data => ({
    type: NfcCardOrderActionTypes.PLACE_NFC_CARD_ORDER_REQUEST,
    payload: data
})

export const placeNfcCardOrderRequestSuccess = data => ({
    type: NfcCardOrderActionTypes.PLACE_NFC_CARD_ORDER_REQUEST_SUCCESS,
    payload: data
})

export const placeNfcCardOrderRequestError = data => ({
    type: NfcCardOrderActionTypes.PLACE_NFC_CARD_ORDER_REQUEST_ERROR,
    payload: data
})

export const placeNfcCardOrderRequestStatus = data => ({
    type: NfcCardOrderActionTypes.PLACE_NFC_CARD_ORDER_REQUEST_STATUS,
    payload: data
})

//order list
export const nfcCardOrderListRequest = data => ({
    type: NfcCardOrderActionTypes.NFC_CARD_ORDER_LIST_REQUEST,
    payload: data
})

export const nfcCardOrderListRequestSuccess = data => ({
    type: NfcCardOrderActionTypes.NFC_CARD_ORDER_LIST_REQUEST_SUCCESS,
    payload: data
})

export const nfcCardOrderListRequestError = data => ({
    type: NfcCardOrderActionTypes.NFC_CARD_ORDER_LIST_REQUEST_ERROR,
    payload: data
})

//order detail
export const nfcCardOrderDetailRequest = data => ({
    type: NfcCardOrderActionTypes.NFC_CARD_ORDER_DETAIL_REQUEST,
    payload: data
})

export const nfcCardOrderDetailRequestSuccess = data => ({
    type: NfcCardOrderActionTypes.NFC_CARD_ORDER_DETAIL_REQUEST_SUCCESS,
    payload: data
})

export const nfcCardOrderDetailRequestError = data => ({
    type: NfcCardOrderActionTypes.NFC_CARD_ORDER_DETAIL_REQUEST_ERROR,
    payload: data
})

//update order
export const updateNfcCardOrderDetailsRequest = data => ({
    type: NfcCardOrderActionTypes.UPDATE_NFC_CARD_ORDER_DETAIL_REQUEST,
    payload: data
})

export const updateNfcCardOrderDetailsRequestSuccess = data => ({
    type: NfcCardOrderActionTypes.UPDATE_NFC_CARD_ORDER_DETAIL_REQUEST_SUCCESS,
    payload: data
})

export const updateNfcCardOrderDetailsRequestError = data => ({
    type: NfcCardOrderActionTypes.UPDATE_NFC_CARD_ORDER_DETAIL_REQUEST_ERROR,
    payload: data
})

export const updateNfcCardOrderDetailsRequestStatus = data => ({
    type: NfcCardOrderActionTypes.UPDATE_NFC_CARD_ORDER_DETAIL_REQUEST_STATUS,
    payload: data
})